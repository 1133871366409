import { userService } from '../services';
import { authActionTypes, authToastMessages } from '../constants';
import { actionCreator, tokenHelper } from '../helpers';
import { routes } from '../constants';
import { toast } from 'react-toastify';

const loginUser = (loginData, history, showToastMessage) => (dispatch) => {
  dispatch(actionCreator(authActionTypes.AUTH_LOGIN_USER_REQUEST));
  userService
    .loginUser(loginData)
    .then((res) => {
      tokenHelper.setTokenToLocalStorage(res.token);
      history.push(routes.DASHBOARD.path);
      dispatch(
        actionCreator(authActionTypes.AUTH_LOGIN_USER_SUCCESS, {
          user: res.user,
        })
      );
    })
    .catch((err) => {
      toast(authToastMessages[authActionTypes.AUTH_LOGIN_USER_FAILURE].message);
      dispatch(
        actionCreator(authActionTypes.AUTH_LOGIN_USER_FAILURE, { error: err })
      );
    });
};

const resetPasswordAndLogin = (data, history) => (dispatch) => {
  userService
    .resetPassword(data)
    .then((res) => {
      tokenHelper.setTokenToLocalStorage(res.token);

      dispatch(
        loginUser({ email: data.email, password: data.password }, history)
      );
    })
    .catch((error) => {
      toast(error);
    });
};

const fetchUser = () => (dispatch) => {
  const userId = tokenHelper.getUserIdFromToken();
  dispatch(actionCreator(authActionTypes.AUTH_FETCH_USER_INFO_REQUEST));
  userService
    .fetchUserInfo(userId)
    .then((res) => {
      dispatch(
        actionCreator(authActionTypes.AUTH_FETCH_USER_INFO_SUCCESS, {
          user: res.data,
        })
      );
    })
    .catch((err) => {
      window.localStorage.removeItem('crm-client-token');
      dispatch(
        actionCreator(authActionTypes.AUTH_FETCH_USER_INFO_FAILURE, {
          error: err,
        })
      );
    });
};

const fetchUsers = () => (dispatch) => {
  dispatch(actionCreator(authActionTypes.AUTH_FETCH_USERS_INFO_REQUEST));
  userService
    .fetchUsersInfo()
    .then((res) => {
      dispatch(
        actionCreator(authActionTypes.AUTH_FETCH_USERS_INFO_SUCCESS, {
          users: res,
        })
      );
    })
    .catch((err) => {
      window.localStorage.removeItem('crm-client-token');
      dispatch(
        actionCreator(authActionTypes.AUTH_FETCH_USERS_INFO_FAILURE, {
          error: err,
        })
      );
    });
};

const logoutUser = (history) => (dispatch) => {
  dispatch(actionCreator(authActionTypes.AUTH_LOGOUT_USER));
  tokenHelper.removeTokenFromLocalStorage();
  history.push(routes.LOGIN.path);
};

const deactivateUser = (history) => (dispatch) => {
  dispatch(actionCreator(authActionTypes.AUTH_DEACTIVATE_USER));
  userService.deactivateUser().then((res) => {
    tokenHelper.removeTokenFromLocalStorage();
    history.push(routes.LOGIN.path);
  });
};
const reactivateUser = (id) => (dispatch) => {
  dispatch(actionCreator(authActionTypes.AUTH_REACTIVATE_USER));
  userService.reactivateUser(id);
};

export const authActions = {
  loginUser,
  fetchUser,
  fetchUsers,
  logoutUser,
  deactivateUser,
  reactivateUser,
  resetPasswordAndLogin,
};
