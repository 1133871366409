import { React } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import PostIcon from 'src/assets/icons/post-icon.svg';
import { Box, Grid, Typography, IconButton } from '@material-ui/core';
const useStyles = makeStyles({
  root: {
    minHeight: '80vh',
  },
  buttonBlack: {
    color: '#000000',
    fontSize: '140px',
  },
  iconButtoncolor: {
    backgroundColor: '#ffffff',
    padding: '62.5px 68.75px',
  },
  // button: {
  //     padding: '15px 40px',
  // },
});

export const DashboardCallEmpty = (props) => {
  const classes = useStyles();

  const handleMailSend = (values) => {
    props.handleMailSend(values, true);
  };
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Box>
        {' '}
        <IconButton className={classes.iconButtoncolor}>
          <img src={PostIcon} alt="post" style={{ marginBottom: '30px' }} />
        </IconButton>
      </Box>
      <Box
        style={{
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '34.75px',
          color: '#000000',
        }}
        pt={4}
      >
        There are no latest campaigns.
      </Box>
      <Box mb={3}>
        <Typography
          style={{
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '300',
            lineHeight: '20.27px',
            color: '#4B4B4B',
          }}
          variant="caption"
        >
          {' '}
          Try checking later.
        </Typography>
      </Box>
    </Grid>
  );
};
