import { React } from 'react';
import { Grid } from '@material-ui/core';
import DashboardItem from './DashboardItem';

import PaperIcon from 'src/assets/icons/paper.svg';
import PaperPlusIcon from 'src/assets/icons/paper-plus.svg';
import WalletIcon from 'src/assets/icons/wallet.svg';
import DiscountIcon from 'src/assets/icons/discount.svg';
import CallingIcon from 'src/assets/icons/calling.svg';
import CallIcon from 'src/assets/icons/call.svg';

const DashboardItems = ({ stats, filteredList }) => {
  let DashboardItemsData = [];

  if (stats) {
    let data = stats;
    DashboardItemsData = [
      {
        value: Number(data.opened_offers),
        title: 'Open offers',
        icon: PaperIcon,
      },
      {
        value: Number(data.signed_offers),
        title: 'Accepted offers',
        icon: PaperPlusIcon,
      },
      {
        value: '€ ' + Number(filteredList ? filteredList : 0),
        title: 'Closed Revenue',
        icon: WalletIcon,
      },
      {
        value: '€ ' + Number(data.provision),
        title: 'Provision',
        icon: DiscountIcon,
      },
      {
        value: data.personal_call_time,
        title: 'Personal call time',
        icon: CallingIcon,
      },
      {
        value: Number(data.number_of_phone_calls),
        title: 'Number of phone calls',
        icon: CallIcon,
      },
      {
        value: '€ ' + Number(data.provision),
        title: 'Provision',
        icon: DiscountIcon,
      },
      {
        value: data.personal_call_time,
        title: 'Personal call time',
        icon: CallingIcon,
      },
      {
        value: Number(data.number_of_phone_calls),
        title: 'Number of phone calls',
        icon: CallIcon,
      },
      {
        value: '€ ' + Number(data.provision),
        title: 'Provision',
        icon: DiscountIcon,
      },
      {
        value: data.personal_call_time,
        title: 'Personal call time',
        icon: CallingIcon,
      },
      {
        value: Number(data.number_of_phone_calls),
        title: 'Number of phone calls',
        icon: CallIcon,
      },
    ];
  }
  return (
    <>
      {console.log(CallIcon, 'ovo je ikonica')}
      <Grid container direction="row" justify="left" alignItems="flex-start">
        {DashboardItemsData.map((item, index) => {
          return (
            <DashboardItem
              key={index}
              value={item.value}
              title={item.title}
              icon={item.icon}
            />
          );
        })}
      </Grid>
    </>
  );
};

export default DashboardItems;
