import { actionCreator } from '../helpers';
import { calendarService } from '../services';

export const calendarActionTypes = {
  CALENDAR_GET_CALENDAR_ITEM_REQUEST: 'CALENDAR_GET_CALENDAR_ITEM_REQUEST',
  CALENDAR_GET_CALENDAR_ITEM_SUCCESS: 'CALENDAR_GET_CALENDAR_ITEM_SUCCESS',
  CALENDAR_GET_CALENDAR_ITEM_FAILURE: 'CALENDAR_GET_CALENDAR_ITEM_FAILURE',

  CALENDAR_CREATE_CALENDAR_ITEM_REQUEST:
    'CALENDAR_CREATE_CALENDAR_ITEM_REQUEST',
  CALENDAR_CREATE_CALENDAR_ITEM_SUCCESS:
    'CALENDAR_CREATE_CALENDAR_ITEM_SUCCESS',
  CALENDAR_CREATE_CALENDAR_ITEM_FAILURE:
    'CALENDAR_CREATE_CALENDAR_ITEM_FAILURE',

  CALENDAR_UPDATE_CALENDAR_ITEM_REQUEST:
    'CALENDAR_UPDATE_CALENDAR_ITEM_REQUEST',
  CALENDAR_UPDATE_CALENDAR_ITEM_SUCCESS:
    'CALENDAR_UPDATE_CALENDAR_ITEM_SUCCESS',
  CALENDAR_UPDATE_CALENDAR_ITEM_FAILURE:
    'CALENDAR_UPDATE_CALENDAR_ITEM_FAILURE',

  CALENDAR_DELETE_CALENDAR_ITEM_REQUEST:
    'CALENDAR_DELETE_CALENDAR_ITEM_REQUEST',
  CALENDAR_DELETE_CALENDAR_ITEM_SUCCESS:
    'CALENDAR_DELETE_CALENDAR_ITEM_SUCCESS',
  CALENDAR_DELETE_CALENDAR_ITEM_FAILURE:
    'CALENDAR_DELETE_CALENDAR_ITEM_FAILURE',
};

const getCalendarItems = (periodFrom, periodTo) => (dispatch) => {
  dispatch(
    actionCreator(calendarActionTypes.CALENDAR_GET_CALENDAR_ITEM_REQUEST)
  );
  calendarService
    .getCalendarItems(periodFrom, periodTo)
    .then((res) => {
      dispatch(
        actionCreator(
          calendarActionTypes.CALENDAR_GET_CALENDAR_ITEM_SUCCESS,
          res
        )
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(calendarActionTypes.CALENDAR_GET_CALENDAR_ITEM_FAILURE, {
          error: err,
        })
      );
    });
};

const createCalendarItem = (data, onSuccess, onFail) => (dispatch) => {
  dispatch(
    actionCreator(calendarActionTypes.CALENDAR_CREATE_CALENDAR_ITEM_REQUEST)
  );
  calendarService
    .createCalendarItem(data)
    .then((res) => {
      dispatch(
        actionCreator(
          calendarActionTypes.CALENDAR_CREATE_CALENDAR_ITEM_SUCCESS,
          res
        )
      );

      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          calendarActionTypes.CALENDAR_CREATE_CALENDAR_ITEM_FAILURE,
          {
            error: err,
          }
        )
      );

      if (onFail) onFail(err);
    });
};

const updateCalendarItem = (calendarItemId, data, onSuccess, onFail) => (
  dispatch
) => {
  dispatch(
    actionCreator(calendarActionTypes.CALENDAR_UPDATE_CALENDAR_ITEM_REQUEST)
  );
  calendarService
    .updateCalendarItem(calendarItemId, data)
    .then((res) => {
      dispatch(
        actionCreator(
          calendarActionTypes.CALENDAR_UPDATE_CALENDAR_ITEM_SUCCESS,
          { id: calendarItemId, res }
        )
      );

      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          calendarActionTypes.CALENDAR_UPDATE_CALENDAR_ITEM_FAILURE,
          {
            error: err,
          }
        )
      );

      if (onFail) onFail(err);
    });
};

const deleteCalendarItem = (calendarItemId, onSuccess, onFail) => (
  dispatch
) => {
  dispatch(
    actionCreator(calendarActionTypes.CALENDAR_DELETE_CALENDAR_ITEM_REQUEST)
  );
  calendarService
    .deleteCalendarItem(calendarItemId)
    .then(() => {
      dispatch(
        actionCreator(
          calendarActionTypes.CALENDAR_DELETE_CALENDAR_ITEM_SUCCESS,
          { id: calendarItemId }
        )
      );

      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          calendarActionTypes.CALENDAR_DELETE_CALENDAR_ITEM_FAILURE,
          {
            error: err,
          }
        )
      );

      if (onFail) onFail(err);
    });
};

export const calendarActions = {
  getCalendarItems,
  createCalendarItem,
  updateCalendarItem,
  deleteCalendarItem,
};
