import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../../components/Modal/Modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ReactComponent as SvgCheckCircle } from './.././../../assets/icons/icon-check-circle.svg';
import SearchInput from '../../../components/Form/components/SearchInput';
import FieldInput from '../../../components/Form/components/FieldInput';
import FavoritesAddButton from '../../../components/FavoritesAddButton/FavoritesAddButton';
import { contactActions, mailCampaignsActions } from '../../../common/actions';
import Button from '../../../components/Button';
import { toast } from 'react-toastify';
import EditAudienceTable from '../../Campaigns/components/EditAudienceTable';
import EditAudienceProductType from '../../Campaigns/components/EditAudienceTable/EditAudienceProductType';

const newAudienceSchema = yup.object().shape({
  username: yup
    .string()
    .min(8, 'Enter minimium 8 characters')
    .required('This field is required'),
});

function EditAudienceModal({
  toggleEditAudience,
  isEditAudienceModalOpened,
  audienceForEdit,
}) {
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contacts.contactsList);

  const [audienceName, setAudienceName] = useState('');
  const [pickedContacts, setPickedContacts] = useState([]);

  const areContactsLoaded = useSelector(
    (state) => state.contacts.areContactsLoaded
  );

  const [searchContacts, setSearchContacts] = useState('');

  const [pickedProducts, setPickedProducts] = useState([]);
  const [searchProducts, setSearchProducts] = useState([]);

  useEffect(() => {
    if (audienceForEdit) setAudienceName(audienceForEdit.name);
  }, [audienceForEdit]);

  useEffect(() => {
    if (!isEditAudienceModalOpened) {
      if (searchContacts && searchContacts.length > 0) setSearchContacts(null);
      if (searchProducts && searchProducts.length > 0) setSearchProducts(null);
      if (audienceForEdit) {
        if (audienceName !== audienceForEdit.name) {
          setAudienceName(audienceForEdit.name);
        }
      }
    }
  });

  useEffect(() => {
    if (!areContactsLoaded) {
      dispatch(contactActions.getAllContacts());
    }
  }, [areContactsLoaded]);
  const { handleSubmit, watch, errors, register } = useForm({
    resolver: yupResolver(newAudienceSchema),
    mode: 'onChange',
  });

  const submitNewAudience = () => {
    if (
      audienceForEdit.audience_type === null ||
      audienceForEdit.audience_type === 'contacts'
    ) {
      if (audienceName.length > 0 && pickedContacts.length > 0) {
        let sendingArray = [];
        for (let i = 0; i < pickedContacts.length; i++) {
          //let singleObject = {};
          //singleObject = { contacts_id: pickedContacts[i] };
          sendingArray.push(pickedContacts[i]);
        }
        dispatch(
          mailCampaignsActions.audienceAssign(
            {
              name: audienceName,
              contacts: sendingArray,
            },
            toggleEditAudience,
            audienceForEdit.id
          )
        );
      }
    } else if (audienceForEdit.audience_type === 'products') {
      if (audienceName.length > 0 && pickedProducts.length > 0) {
        let sendingArray = [];
        for (let i = 0; i < pickedProducts.length; i++) {
          ///let singleObject = {};
          //singleObject = { products_id: pickedProducts[i] };
          sendingArray.push(pickedProducts[i]);
        }
        dispatch(
          mailCampaignsActions.audienceAssign(
            {
              name: audienceName,
              products: sendingArray,
            },
            toggleEditAudience,
            audienceForEdit.id
          )
        );
      }
    }
  };

  const renderTable = () => {
    if (audienceForEdit) {
      if (
        audienceForEdit.audience_type === null ||
        audienceForEdit.audience_type === 'contacts'
      ) {
        return (
          <div>
            <SearchInput
              name="search"
              type="text"
              errors={errors}
              labelText="search"
              placeholder="Search here"
              className="mb-20"
              value={watch('search')}
              onChange={(e) => {
                setSearchContacts(e.target.value);
              }}
            />
            <EditAudienceTable
              search={searchContacts}
              setSearchContacts={setSearchContacts}
              pickedContacts={pickedContacts}
              setPickedContacts={setPickedContacts}
              audienceForEdit={audienceForEdit}
              name="pickedContacts"
              value={pickedContacts}
            />
          </div>
        );
      }
      if (audienceForEdit.audience_type === 'products') {
        return (
          <div>
            <SearchInput
              className="mb-20"
              name="search"
              type="text"
              errors={errors}
              labelText="search"
              placeholder="Search here"
              value={watch('search')}
              onChange={(e) => {
                setSearchProducts(e.target.value);
              }}
            />
            <EditAudienceProductType
              search={searchProducts}
              setSearchProducts={setSearchProducts}
              pickedProducts={pickedProducts}
              setPickedProducts={setPickedProducts}
              audienceForEdit={audienceForEdit}
            />
          </div>
        );
      }
    }
  };
  return (
    <Modal
      isOpen={isEditAudienceModalOpened}
      headerTitle="Edit audience list"
      className="modal--audience"
      onRequestClose={toggleEditAudience}
    >
      <div className="flex items-center mb-40 ">
        <h2 className={`text-2x font-bold text-black mr-20`}>
          Edit audience list
        </h2>
      </div>
      <form noValidate className="flex flex-col sm:mb-50 md:mb-0 w-full">
        <div className="flex items-center">
          <FieldInput
            register={register}
            name="audience"
            defaultValue={audienceName}
            type="text"
            errors={errors}
            labelText="Audience Name"
            placeholder=""
            value={audienceName}
            className="w-half mb-0 "
            onChange={(e) => {
              setAudienceName(e.target.value);
            }}
          />
          <FavoritesAddButton className="ml-40" labelText="Add to favorites" />
        </div>
        {renderTable()}
        <Button
          type="submit"
          className="ml-auto mt-20 flex text-center justify-center items-center rounded-default pl-0 px-30 py-10 text-sm text-white bg-primary hover:bg-secondary outline-none"
          role="menuitem"
          onClick={submitNewAudience}
        >
          <SvgCheckCircle className="ml-20 mr-20" />
          Confirm
        </Button>
      </form>
    </Modal>
  );
}

export { EditAudienceModal };
