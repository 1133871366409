import React from 'react';
import styles from './ButtonBack.module.scss';
import { ReactComponent as SvgBack } from '../../assets/icons/back-arrow.svg';
import { withRouter } from 'react-router-dom';

const ButtonBack = ({ history, className, variant, onClick }) => {
  if (variant === 'click') {
    return (
      <div
        className={`${className} ${styles.ButtonBackSmall} w-full inline-flex`}
      >
        <button
          onClick={onClick}
          className="flex group text-sm outline-none transition duration-300 ease-in-out transform"
        >
          <SvgBack
            className="relative flex w-icon-xs h-icon-xs text-black group-hover:text-primary mr-15 z-10 pointer-events-none transition duration-300 ease-in-out"
            alt="soul-group-back-button"
          />
          <span className="flex text-black group-hover:text-primary font-medium text-sm transition duration-300 ease-in-out">
            Back
          </span>
        </button>
      </div>
    );
  }
  if (variant === 'small') {
    return (
      <div
        className={`${className} ${styles.ButtonBackSmall}  w-full inline-flex`}
      >
        <button
          slot="start"
          className="flex group text-sm text-sm outline-none transition duration-300 ease-in-out transform"
          onClick={() => history.goBack()}
        >
          <SvgBack
            className="relative flex w-icon-xs h-icon-xs text-black group-hover:text-primary mr-15 z-10 pointer-events-none transition duration-300 ease-in-out"
            alt="soul-group-back-button"
          />
          <span className="flex text-black group-hover:text-primary font-medium text-sm transition duration-300 ease-in-out">
            Go back
          </span>
        </button>
      </div>
    );
  }
  if (variant === 'transparent') {
    return (
      <div className={`w-full inline-flex`}>
        <button
          type="button"
          className={`group bg-transparent hover:bg-transparent hover:border-gray-500 border-1 solid border-primary text-sm p-15 px-20 text-sm rounded-default h-44 outline-none flex justify-center items-center transition duration-300 ease-in-out transform ${className}`}
          onClick={() => history.goBack()}
        >
          <span className="flex text-gray-500 group-hover:text-gray-500 font-medium text-sm transition duration-300 ease-in-out">
            Go back
          </span>
        </button>
      </div>
    );
  }
  return (
    <div className={`${className} ${styles.ButtonBack} inline-flex`}>
      <button
        slot="start"
        className="group bg-gray-300 hover:bg-transparent hover:border-primary border-1 solid border-gray-300 text-sm p-15 px-20 text-sm rounded-md h-44 outline-none flex justify-center items-center transition duration-300 ease-in-out transform"
        onClick={() => history.goBack()}
      >
        <SvgBack
          className="relative flex w-icon-xs h-icon-xs text-black group-hover:text-primary mr-15 z-10 pointer-events-none transition duration-300 ease-in-out"
          alt="soul-group-back-button"
        />
        <span className="flex text-black group-hover:text-primary font-medium text-sm transition duration-300 ease-in-out">
          Go back
        </span>
      </button>
    </div>
  );
};
export default withRouter(ButtonBack);
