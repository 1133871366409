import { notificationActionTypes } from '../actions/notificationActions';

const initialState = {
  notifications: [],
  searchResults: {},
  isLoading: false,
  error: [],
  isLoaded: false,
  hasMore: true,
  page: 0,
};

export const notification = (state = initialState, action) => {
  switch (action.type) {
    case notificationActionTypes.NOTIFICATIONS_PING:
      const ids = state.notifications.map((n) => n.id);

      return {
        ...state,
        notifications: [
          ...action.payload.filter((n) => ids.indexOf(n.id) === -1),
          ...state.notifications,
        ],
      };
    // Top notifications
    case notificationActionTypes.NOTIFICATION_GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case notificationActionTypes.NOTIFICATION_GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        notifications: [...state.notifications, ...action.payload],
        hasMore: action.payload.length === 10,
        page: state.page + 1,
      };
    case notificationActionTypes.NOTIFICATION_GET_NOTIFICATIONS_FAILURE:
      return {
        ...initialState,
      };

    // Read single notification
    case notificationActionTypes.NOTIFICATION_READ_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.map((n) => {
          return n.id == action.payload ? { ...n, read: true } : n;
        }),
      };

    // Read all notifications
    case notificationActionTypes.NOTIFICATION_READ_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.map((n) => {
          return { ...n, read: true };
        }),
      };
    case notificationActionTypes.SEARCH_RESULTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        searchResults: {},
      };
    case notificationActionTypes.SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        searchResults: action.payload,
      };
    case notificationActionTypes.SEARCH_RESULTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.payload.error,
      };
    // Delete notficitaions
    case notificationActionTypes.NOTIFICATION_DELETE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.filter(
          (n) => action.payload.indexOf(n.id) == -1
        ),
      };

    default:
      return state;
  }
};
