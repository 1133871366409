import React, { useState } from 'react';
import FieldInput from 'src/components/Form/components/FieldInput';
import { ReactComponent as SvgNext } from './../../../../assets/icons/icon-next.svg';
import { ReactComponent as SvgCheck } from './../../../../assets/icons/check-icon.svg';
import { ReactComponent as SvgExit } from './../../../../assets/icons/exit-icon.svg';

const CreditCheck = ({ onPrevious, onNext }) => {
  const [creditWorthy, creditUnWorthy] = useState(true);

  const handleChange = () => {
    creditUnWorthy(!creditWorthy);
  };

  const checkCreditWorthyness = () => {
    if (creditWorthy) {
      return (
        <div className="text-center mt-10 flex justify-center">
          <div className="border-2 border-green-100 rounded-full p-3">
            <SvgCheck className="text-green-100" />
          </div>
        </div>
      );
    } else {
      return (
        <div className="text-center mt-10">
          <div className="flex justify-center">
            <div className="border-2 border-red rounded-full p-5">
              <SvgExit className="text-red" />
            </div>
          </div>
          <div className="flex justify-center mt-8">
            <FieldInput
              type="text"
              className="mr-10"
              placeholder="Sales Manager Passwort"
            />
            <button
              type="submit"
              className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-50 outline-none transition duration-500 mt-5"
            >
              <SvgNext />
            </button>
          </div>

          <div className="text-center mt-8">
            <button className="bg-gray-200 hover:bg-gray-400 transition duration-500 p-10 px-20 rounded-default whitespace-nowrap">
              Save offer with status: insufficient creditworthiness
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col rounded-default p-20 bg-white">
      <header className="flex justify-center mb-20">
        <div>
          <h1 className="text-3xl font-bold text-gray-500 text-center">
            Check creditworthiness
          </h1>
          <p className="text-sm text-gray-400 text-center whitespace-nowrap">
            In the background, Creditreform checks the customer&#39;s
            creditworthiness
          </p>
        </div>
      </header>
      <main className="flex justify-center mb-10">
        <div className="items-center w-3/5">
          <div className="flex flex-col items-center text-center">
            <div className="text-3x font-bold">0</div>
            <div>{checkCreditWorthyness()}</div>
          </div>
        </div>
      </main>
      <footer className="flex justify-between items-center mb-10">
        <button
          onClick={onPrevious}
          className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-50 outline-none transition duration-500"
        >
          Back
        </button>
        <button
          onClick={onNext}
          className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-50 outline-none transition duration-500"
        >
          Continue
        </button>
      </footer>
    </div>
  );
};

export default CreditCheck;
