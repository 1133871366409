import React from 'react';
import Loader from '../Loader';

const Button = ({
  variant,
  isLoading,
  onClick,
  children,
  disabled,
  type = 'submit',
  height = '45',
  width,
  className = 'string',
  target,
}) => {
  if (variant === 'primary') {
    return (
      <button
        type={type}
        style={{ minWidth: width ? width : null }}
        disabled={disabled}
        onClick={onClick}
        className={` bg-primary hover:bg-secondary text-white p-15 px-20 text-sm rounded-default h-${height} outline-none flex justify-center items-center ${className}`}
      >
        {isLoading ? <Loader /> : children}
      </button>
    );
  }
  if (variant === 'secondary') {
    //underlined
    return (
      <button
        type={type}
        style={{ minWidth: width ? width : null }}
        disabled={disabled}
        onClick={onClick}
        className={`bg-secondary hover:bg-primary text-white p-15 px-20 text-sm h-${height} outline-none flex justify-center items-center border-1 solid border-primary rounded-default`}
      >
        {isLoading ? <Loader /> : children}
      </button>
    );
  }
  if (variant === 'link') {
    return (
      <a
        onClick={onClick}
        disabled={disabled}
        className={`${className} bg-primary hover:bg-navyBlue text-white p-15 px-20 text-sm rounded-default h-${height} outline-none flex justify-center items-center transition duration-500`}
        href={target}
      >
        {isLoading ? <Loader /> : children}
      </a>
    );
  }
  if (variant === 'transparent') {
    return (
      <a
        onClick={onClick}
        disabled={disabled}
        className={`${className} bg-transparent text-primary border-1 solid border-primary p-15 px-20 text-sm rounded-default h-${height} outline-none flex justify-center items-center transition duration-500`}
        href={target}
      >
        {isLoading ? <Loader /> : children}
      </a>
    );
  }
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={`${className} h-${height}  bg-gray-100 text-black text-sm p-10 rounded-md  outline-none flex justify-center items-center`}
    >
      {children}
    </button>
  );
};

export default Button;
