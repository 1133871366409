import React, { useState } from 'react';
import {
  Box,
  ClickAwayListener,
  Input,
  InputAdornment,
  InputBase,
  makeStyles,
} from '@material-ui/core';
import { ReactComponent as SvgSearch } from '../../../assets/icons/icon-search.svg';
import { Form, Formik } from 'formik';
import { routes } from 'src/common/constants';
import { useHistory } from 'react-router';
const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});
const Search = () => {
  const classes = useStyles();
  const [search, setSearch] = useState();
  const history = useHistory();
  const toggle = () => {
    setSearch(true);
  };

  const closeSearch = () => {
    if (search) {
      setSearch(false);
    }
  };

  return (
    <Box className="my-auto">
      <Formik
        initialValues={{ search: '' }}
        validate={(values) => {
          const errors = {};
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          history.push(`${routes.SEARCH.path}?search=${values.search}`);
        }}
        // onSubmit={(values, { setSubmitting }) => {
        //     setTimeout(() => {
        //         let data = values
        //         // alert(JSON.stringify(values, null, 2));
        //         setSubmitting(false);
        //     }, 400);
        // }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <ClickAwayListener onClickAway={closeSearch}>
            <form
              id="search-form"
              onSubmit={handleSubmit}
              className={
                search === false
                  ? 'searchbar fadeOutWidth'
                  : search === true
                  ? 'searchbar fadeInWidth'
                  : 'searchbar'
              }
            >
              {search === true && (
                <InputBase
                  value={values.search}
                  onChange={handleChange}
                  className={
                    search === true
                      ? 'search-input fadeIn'
                      : search === false
                      ? 'search-input fadeOut'
                      : 'search-input'
                  }
                  type="text"
                  id="search"
                  startAdornment={
                    <InputAdornment position="start">
                      <SvgSearch
                        onClick={toggle}
                        className={
                          search === true
                            ? 'search-icon w-25 h-25'
                            : search === false
                            ? 'search-icon w-25 h-25'
                            : 'search-icon w-25 h-25'
                        }
                      />
                    </InputAdornment>
                  }
                  placeholder="Search..."
                />
              )}
              <div
                onClick={toggle}
                className={
                  search === true
                    ? 'icon-bg fadeOut'
                    : search === false
                    ? 'icon-bg fadeIn'
                    : 'icon-bg'
                }
              >
                {search !== true && (
                  <SvgSearch
                    className={
                      search === true
                        ? 'search-icon fadeOut w-25 h-25'
                        : search === false
                        ? 'search-icon w-25 h-25'
                        : 'search-icon w-25 h-25'
                    }
                  />
                )}
              </div>
            </form>
          </ClickAwayListener>
        )}
      </Formik>
    </Box>
  );
};

export default Search;
