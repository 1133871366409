import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import LayoutComponent from '../../components/commons/LayoutComponent';
import ProfileContent from './components';

const Profile = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const onLogout = () => {
    dispatch(authActions.logoutUser(history));
  };

  return (
    <LayoutComponent title="User profile">
      <ProfileContent user={user} />
    </LayoutComponent>
  );
};

export { Profile };
