import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SvgAdd } from '../../../assets/icons/icon-add.svg';
import ArrowDownAZ from '../../../assets/icons/sort-az-down.svg';
import ArrowDown from '../../../assets/icons/sort-down.svg';
import ArrowDownAZActive from '../../../assets/icons/sort-az-down-active.svg';
import ArrowUpAZActive from '../../../assets/icons/sort-za-down-active.svg';
import ArrowUpActive from '../../../assets/icons/sort-up-active.svg';
import ArrowDownActive from '../../../assets/icons/sort-down-active.svg';
import Button from '../../../components/Button';
import SearchInput from '../../../components/Form/components/SearchInput';
import { contactActions } from '../../../common/actions';
import ServerlessPagination from '../../../components/commons/ServerlessPagination/ServerlessPagination';
import { Box } from '@material-ui/core';
import TagsRow from './TagsRow';
import { TagsModal } from './TagsModal/TagsModal';
import axios from 'axios';
import { API_ENDPOINT } from 'src/utils/endpoints';
import moment from 'moment';

const TagsContent = () => {
  const dispatch = useDispatch();
  const tagsList = useSelector((state) => state.contacts.tagsList?.data);
  const [isTagModalOpened, setIsTagModalOpened] = useState(false);
  const [searchContactList, setSearchContactList] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [sortBy, setSortBy] = useState('CreatedAtReversed');
  const totalRows = tagsList?.filter((item) =>
    searchContactList
      ? item.name.toLowerCase().includes(searchContactList.toLowerCase()) ||
        moment(item.created_at)
          .format('DD.MM.YYYY. hh:mm a')
          .includes(searchContactList.toLowerCase())
      : item
  ).length;
  const lastPage = Math.ceil(totalRows / 10);
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState('');

  const handleEdit = async (id, item) => {
    if (!edit) {
      setEditId(id);
      setEdit(true);
    } else if (edit) {
      await axios
        .patch(
          `${API_ENDPOINT}/tags/${id}`,
          { name: item },
          {
            headers: {
              Authorization:
                'Bearer' + localStorage.getItem('crm-client-token'),
              Accept: 'application/json',
            },
          }
        )
        .then(() => {
          dispatch(contactActions.getAllTags());
          setEditId('');
          setEdit(false);
        })
        .catch((e) => {
          let errors = e.response.data.message + '\n';
          for (let error in e.response.data.errors) {
            errors += e.response.data.errors[error][0] + '\n';
          }
          // setAlertMessage(errors);
          // handleAlertOpen();
        });
    }
  };
  const renderContacts = () => {
    return tagsList;
  };
  const handleSort = (item) => {
    if (sortBy === 'Name' && item === 'Name') {
      setSortBy('NameReversed');
    } else if (sortBy === 'Company' && item === 'Company') {
      setSortBy('CompanyReversed');
    } else if (sortBy === 'CreatedAt' && item === 'CreatedAt') {
      setSortBy('CreatedAtReversed');
    } else {
      setSortBy(item);
    }
  };
  useEffect(() => {
    dispatch(contactActions.getAllTags());
  }, []);
  return (
    <>
      <div className="flex mt-50">
        <h2 className="text-3xl font-bold  mr-20 text-black  border-l-0 border-t-0 border-b-0 pr-20 border-1 border-gray-400">
          Tag list
        </h2>
        <SearchInput
          name="search"
          type="text"
          //errors={errors}
          labelText="search"
          placeholder="Search tags..."
          defaultValue={searchContactList}
          onChange={(e) => {
            setSearchContactList(e.target.value);
          }}
        />
        <div className="flex justify-end relative z-9 ml-auto ">
          <Button
            variant="link"
            height="44"
            width="220"
            className="text-sm "
            onClick={() => {
              setIsTagModalOpened(true);
            }}
          >
            <SvgAdd className="mr-20 text-white" />
            Add New Tag
          </Button>
        </div>
      </div>
      <div className="flex flex-col mt-50">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-20 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      colSpan="4"
                      className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      style={{ cursor: 'pointer', userSelect: 'none' }}
                      onClick={() => handleSort('Name')}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ gap: 10 }}
                      >
                        Tag Name{' '}
                        <img
                          src={
                            sortBy == 'NameReversed'
                              ? ArrowUpAZActive
                              : sortBy == 'Name'
                              ? ArrowDownAZActive
                              : ArrowDownAZ
                          }
                        />
                      </Box>
                    </th>
                    <th
                      scope="col"
                      className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      style={{ cursor: 'pointer', userSelect: 'none' }}
                      onClick={() => handleSort('CreatedAt')}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ gap: 5 }}
                      >
                        Created at{' '}
                        <img
                          style={{ height: 14 }}
                          src={
                            sortBy == 'CreatedAtReversed'
                              ? ArrowUpActive
                              : sortBy == 'CreatedAt'
                              ? ArrowDownActive
                              : ArrowDown
                          }
                        />
                      </Box>
                    </th>
                    <th
                      scope="col"
                      className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {renderContacts()
                    ?.filter((item) =>
                      searchContactList
                        ? item.name
                            .toLowerCase()
                            .includes(searchContactList.toLowerCase()) ||
                          moment(item.created_at)
                            .format('DD.MM.YYYY. hh:mm a')
                            .includes(searchContactList.toLowerCase())
                        : item
                    )
                    .sort((a, b) =>
                      sortBy == 'Name'
                        ? a.name.localeCompare(b.name)
                        : sortBy == 'NameReversed'
                        ? b.name.localeCompare(a.name)
                        : sortBy == 'Company'
                        ? a.company.localeCompare(b.company)
                        : sortBy == 'CompanyReversed'
                        ? b.company.localeCompare(a.company)
                        : sortBy == 'CreatedAt'
                        ? new Date(a.created_at) - new Date(b.created_at)
                        : sortBy == 'CreatedAtReversed'
                        ? new Date(b.created_at) - new Date(a.created_at)
                        : null
                    )
                    .slice(currentPage * 10, (currentPage + 1) * 10)
                    .map((contact, index) => {
                      return (
                        <TagsRow
                          edit={edit}
                          setEditId={setEditId}
                          editId={editId}
                          handleEdit={handleEdit}
                          tag={contact}
                          key={index}
                        />
                      );
                    })}
                </tbody>
              </table>

              <ServerlessPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                lastPage={lastPage}
                total={totalRows}
              />

              <TagsModal
                setIsTagModalOpened={setIsTagModalOpened}
                isTagModalOpened={isTagModalOpened}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { TagsContent };
