import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from '../../../components/Modal/Modal';
import ButtonBack from '../../../components/ButtonBack/ButtonBack';
import CountInput from '../../../components/Form/components/CountInput/CountInput';
import { ReactComponent as SvgCheckCircle } from './.././../../assets/icons/icon-check-circle.svg';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { mailCampaignsActions } from '../../../common/actions';

function CampaignInactivityModal({
  toggleCampaignInactivityModal,
  isCampaignInactivityModalOpened,
}) {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(0);
  console.log(inputValue);
  const submitCampaignInactivity = () => {
    dispatch(mailCampaignsActions.setInactivity(inputValue));
    toggleCampaignInactivityModal();
    toast.success('Campaign inactivity set', { className: 'text-white' });
  };
  return (
    <Modal
      isOpen={isCampaignInactivityModalOpened}
      className="modal--schedule"
      onRequestClose={toggleCampaignInactivityModal}
    >
      <div className="flex items-center mb-40 flex-col">
        {/* <ButtonBack variant="small" className="mb-15" /> */}
        <h2 className={`text-2x font-bold text-black mr-20`}>
          Set inactivity alert
        </h2>
        <p className="text-xs font-medium text-black tracking-wide my-25 text-center">
          Define here after how many days from email not marked as opened the
          user should receive a SMS.
        </p>
        <CountInput
          name="inactivity"
          type="text"
          labelText="Days"
          placeholder="1"
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
        <button
          type="submit"
          className="ml-auto mt-20 flex text-center justify-center items-center rounded-default pl-0 px-30 py-10 text-sm text-white bg-primary hover:bg-secondary outline-none"
          role="menuitem"
          onClick={submitCampaignInactivity}
        >
          <SvgCheckCircle className="ml-20 mr-20" />
          Shedule inactivity
        </button>
      </div>
    </Modal>
  );
}

export { CampaignInactivityModal };
