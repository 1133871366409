import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LayoutComponent from '../../components/commons/LayoutComponent';
import { contactActions } from '../../common/actions';
import ContactContent from './components/ContactContent';
import { getQueryStringParam } from '../../utils/getQueryParams';

const Contact = () => {
  const dispatch = useDispatch();

  const contactId = getQueryStringParam('contactId');

  const isContactLoaded = useSelector((state) => state.contact.isContactLoaded);

  useEffect(() => {
    if (!isContactLoaded) {
      dispatch(contactActions.getContact(contactId));
    }
  }, [isContactLoaded]);

  useEffect(() => {
    return () => {
      dispatch(contactActions.clearContactState());
    };
  }, []);
  return (
    <LayoutComponent title="Contact profile">
      {isContactLoaded && <ContactContent />}
    </LayoutComponent>
  );
};

export { Contact };
