import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Moment from 'moment';
import { ReactComponent as SvgFavorite } from './../../../../assets/icons/icon-avatars.svg';
import Checkbox from '../../../../components/Form/components/Checkbox/Checkbox';
import FavoritesAddButton from '../../../../components/FavoritesAddButton/FavoritesAddButton';
import { ReactComponent as SvgEditAudience } from './../../../../assets/icons/icon-edit-audience.svg';

const AudienceRow = ({
  audience,
  updatePickedAudiences,
  isPreselected,
  handleEditAudience,
}) => {
  //Moment.locale('en');
  //const dt = `{mailCampaign.updated_at}`;
  //const dispatch = useDispatch();
  const handleChange = () => {
    updatePickedAudiences(audience, !isPreselected);
  };

  const handleOnEditClick = () => {
    handleEditAudience(audience);
  };

  return (
    <>
      <tr>
        <td className="px-20 py-0 whitespace-nowrap border-t-1 border-b-1 border-solid border-gray-300">
          <div className="flex items-center">
            <Checkbox
              name="1"
              labelText=""
              onChange={handleChange}
              checked={isPreselected}
            />
          </div>
        </td>
        <td className="px-20 py-0 whitespace-nowrap border-t-1 border-b-1 border-solid border-gray-300">
          <div className="flex items-center">
            <div className="text-xxs text-gray-500">{audience.name}</div>
          </div>
        </td>
        <td className="px-20 py-0 whitespace-nowrap border-t-1 border-b-1 border-solid border-gray-300">
          <div className="text-xxs text-gray-500">
            {audience.number_of_contacts}
          </div>
        </td>
        <td className="px-20 py-0 whitespace-nowrap border-t-1 border-b-1 border-solid border-gray-300">
          <div
            className="inline text-xxs font-bold px-8 py-2 rounded-sm"
            style={{
              color: '#FFA879',
              border: '1px solid #FFA879',
              backgroundColor: '#FFEBE0',
            }}
          >
            33%
          </div>
        </td>
        <td className="px-20 py-0 whitespace-nowrap border-t-1 border-b-1 border-solid border-gray-300 text-xxs text-gray-500">
          <div
            className="inline text-xxs font-bold px-8 py-2 rounded-sm"
            style={{
              color: '#54CB60',
              border: '1px solid #54CB60',
              backgroundColor: '#E0F5E2',
            }}
          >
            33%
          </div>
        </td>
        <td className="px-20 py-0 whitespace-nowrap border-t-1 border-b-1 border-solid border-gray-300 text-xxs text-gray-500">
          <div className="text-xxs text-gray-500">
            <FavoritesAddButton isFavorited="true" />
          </div>
        </td>
        <td className="px-20 py-0 whitespace-nowrap border-t-1 border-b-1 border-solid border-gray-300 text-right">
          <div className="text-xxs text-gray-500">
            {/* {Moment({ dt }).format('L')} */}
            Jan 31, 2021
          </div>
        </td>
        <td className="px-20 py-0 whitespace-nowrap border-t-1 border-b-1 border-solid border-gray-300 text-right">
          <div onClick={handleOnEditClick} className="text-xxs text-gray-500">
            <SvgEditAudience />
          </div>
        </td>
      </tr>
    </>
  );
};

export default AudienceRow;
