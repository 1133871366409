import React, { useEffect } from 'react';
import ModalPlugin from 'react-modal';
import styles from './Modal.module.scss';
import './Transitions.scss';
import Header from '../Modal/components/Header';
import { string } from 'yup';

export default function Modal({
  children,
  isOpen,
  onRequestClose,
  closeContent,
  headerTitle,
  className,
  //large = false,
}) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      ModalPlugin.setAppElement('#root');
    }
  }, []);
  if (!isOpen) {
    return <></>;
  }
  return (
    <div>
      <ModalPlugin
        closeTimeoutMS={300}
        isOpen={isOpen}
        //onRequestClose={onRequestClose}
        overlayClassName={`${styles.ReactModal__Overlay} ${className} bg-gray-500 overflow-y-hidden bg-opacity-50 justify-center items-end md:items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none m-auto`}
        className={`${styles.ModalWindow} modal-body relative mx-auto overflow-y-auto  md:rounded-default md:h-auto flex flex-col w-full bg-white outline-none max-w-2xl pb-40 px-30 md:px-80`}
      >
        <Header onRequestClose={onRequestClose} closeContent={closeContent}>
          {headerTitle}
        </Header>
        <div className="pt-0">{children}</div>
      </ModalPlugin>
    </div>
  );
}
