import React from 'react';
import { useForm } from 'react-hook-form';
import Button from 'src/components/Button';
import FieldInput from 'src/components/Form/components/FieldInput';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  email: Yup.string().email().required('This field is required'),
  password: Yup.string()
    .required('This field is required')
    .min(6, 'Password must be longer then six characters'),
  password_confirmation: Yup.string()
    .required('This field is required')
    .min(6, 'Password must be longer then six characters'),
});

export default function UserForm({ onSubmit }) {
  const {
    handleSubmit,
    watch,
    errors,
    register,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldInput
        name="name"
        type="text"
        labelText="Name"
        placeholder="Name"
        className="mb-10"
        value={watch('name')}
        register={register}
        errors={errors}
      />
      <FieldInput
        name="email"
        type="email"
        labelText="Email"
        placeholder="Email"
        className="mb-10"
        value={watch('email')}
        register={register}
        errors={errors}
      />
      <FieldInput
        name="password"
        type="password"
        labelText="Password"
        placeholder="Password"
        className="mb-10"
        value={watch('password')}
        register={register}
        errors={errors}
      />
      <FieldInput
        name="password_confirmation"
        type="password"
        labelText="Confirm password"
        placeholder="Confrim password"
        className="mb-10"
        value={watch('password_confirmation')}
        register={register}
        errors={errors}
      />

      <Button
        variant="primary"
        height="55"
        width="280"
        isLoading={isSubmitting}
        disabled={isSubmitting}
        className="w-full"
      >
        Create
      </Button>
    </form>
  );
}
