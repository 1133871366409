import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ChatModal from './ChatModal';
import ChatWindow from './ChatWindow';
import { userService } from 'src/common/services';
import { useQuery } from 'react-query';
import ChatConversations from './ChatTab/ChatConversations';
import ChatContacts from './ChatTab/ChatContacts';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/components/Button';
import { ReactComponent as SvgAdd } from 'src/assets/icons/icon-add.svg';
import { chatActions } from 'src/common/actions';
import { ChatEmptyState } from './ChatEmptyState';

const ChatContent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [modalOpened, setModalOpened] = useState(false);

  const dispatch = useDispatch();

  const handleSelect = (userIds, name = null) => {
    dispatch(
      chatActions.getConversation(
        typeof userIds === 'object' ? userIds : [userIds],
        name
      )
    );
    setActiveTab(0);
  };

  const conversation = useSelector((state) => state.chat.conversation);

  const {
    isLoading: isLoadingUsers,
    data: chatUsers = [],
  } = useQuery('chat-users', () => userService.getUsers());

  const handleNewChat = () => {
    setModalOpened(true);
    console.log(modalOpened, 'group chat');
  };

  return (
    <>
      <div className="flex">
        <h2 className="text-3xl font-bold  mr-20 text-black  border-l-0 border-t-0 border-b-0 pr-20 border-1 border-gray-400">
          Chat
        </h2>
        <div className="flex justify-end relative z-9 ml-20 ml-auto">
          <Button
            variant="secondary"
            height="44"
            width="220"
            className="text-sm "
            onClick={handleNewChat}
          >
            <SvgAdd className="mr-20 text-white" />
            New conversation
          </Button>
        </div>
      </div>
      <section
        className="flex justify-between"
        style={{ maxHeight: 'calc(100vh - 205px)' }}
      >
        <div className="w-half flex mt-40 mb-20 bg-white px-20 py-20 justify-center rounded-default overflow-y-scroll overflow-x-hidden">
          <Tabs
            className="w-full"
            onSelect={setActiveTab}
            selectedIndex={activeTab}
          >
            <TabList className="flex border-b-1 border-solid border-gray-300 mt-40">
              <Tab className="flex flex-col items-center justify-between pr-30 text-sm cursor-pointer outline-none">
                Latest chat
              </Tab>
              <Tab className="flex flex-col items-center justify-between pr-30 text-sm cursor-pointer outline-none">
                Contacts
              </Tab>
            </TabList>
            <TabPanel>
              <ChatConversations />
            </TabPanel>
            <TabPanel>
              {isLoadingUsers ? (
                'Loading...'
              ) : (
                <ChatContacts chatUsers={chatUsers} onSelect={handleSelect} />
              )}
            </TabPanel>
          </Tabs>
        </div>
        {Boolean(conversation) ? (
          <ChatWindow chatUsers={chatUsers} />
        ) : (
          <ChatEmptyState />
        )}
      </section>

      <ChatModal
        setOpened={setModalOpened}
        isOpened={modalOpened}
        contactsList={chatUsers}
        onSubmit={handleSelect}
      />
    </>
  );
};
export default ChatContent;
