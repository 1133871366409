import React from 'react';
import Select from './../../../../components/Select';

const ProductSelect = ({ onPrevious, onNext }) => {
  const setterOptions = [
    { value: 'User1', label: 'User 1' },
    { value: 'User2', label: 'User 2' },
  ];

  const closerOptions = [
    { value: 'User1', label: 'User 1' },
    { value: 'User2', label: 'User 2' },
  ];

  const productsOptions = [
    { value: 'Product1', label: 'Product 1' },
    { value: 'Product2', label: 'Product 2' },
  ];

  const paymentPlanOptions = [
    { value: 'PaymentPlan1', label: 'Payment plan 1' },
    { value: 'PaymentPlan2', label: 'Payment Plan 2' },
  ];

  return (
    <div className="flex flex-col rounded-default p-20 bg-white">
      <header className="flex justify-center mb-20">
        <div className="w-3/5">
          <h1 className="text-3xl font-bold text-gray-500 text-center">
            Select seller and product
          </h1>
          <p className="text-sm text-gray-400 text-center whitespace-nowrap">
            Please select an entry for each input drop-down list
          </p>
        </div>
      </header>
      <main className="flex justify-center flex-col mb-10">
        <h1 className="text-xl font-bold text-gray-500 text-center mb-10">
          User
        </h1>
        <div className="flex flex-col items-center justify-center">
          <Select
            labelText="Sales: Setter"
            name="sales_setter"
            placeholder=""
            defaultValue={{
              value: setterOptions[0].value,
              label: setterOptions[0].label,
            }}
            options={setterOptions}
            className="w-3/5"
          />
          <Select
            labelText="Sales: Closer"
            name="sales_closer"
            placeholder=""
            defaultValue={{
              value: closerOptions[0].value,
              label: closerOptions[0].label,
            }}
            options={closerOptions}
            className="w-3/5"
          />
        </div>
        <h1 className="text-xl font-bold text-gray-500 text-center mb-10">
          Products
        </h1>
        <div className="flex flex-col items-center justify-center">
          <Select
            labelText="Products"
            name="products"
            placeholder=""
            defaultValue={{
              value: productsOptions[0].value,
              label: productsOptions[0].label,
            }}
            options={productsOptions}
            className="w-3/5"
          />
          <Select
            labelText="Payment plan"
            name="payment_plan"
            placeholder=""
            defaultValue={{
              value: paymentPlanOptions[0].value,
              label: paymentPlanOptions[0].label,
            }}
            options={paymentPlanOptions}
            className="w-3/5"
          />
        </div>
      </main>
      <footer className="flex justify-between items-center mb-10">
        <button
          onClick={onPrevious}
          className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-50 outline-none transition duration-500"
        >
          Back
        </button>
        <button
          onClick={onNext}
          className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-50 outline-none transition duration-500"
        >
          Continue
        </button>
      </footer>
    </div>
  );
};

export default ProductSelect;
