import { API_ENDPOINT } from '../../utils/endpoints';
import { handleResponse, openFetch, authFetch, client } from '../helpers';

const getAllContacts = () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(`${API_ENDPOINT}/contacts`, requestOptions).then(
    handleResponse
  );
};

const getContact = (contactId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(
    `${API_ENDPOINT}/contacts/${contactId}`,
    requestOptions
  ).then(handleResponse);
};

const deleteContact = (contactId) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(
    `${API_ENDPOINT}/contacts/${contactId}`,
    requestOptions
  ).then(handleResponse);
};

const updateContact = (contactId, contactData) => {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(contactData),
  };

  return authFetch(
    `${API_ENDPOINT}/contacts/${contactId}`,
    requestOptions
  ).then(handleResponse);
};

const createNewContact = (contactData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(contactData),
  };

  return authFetch(`${API_ENDPOINT}/contacts`, requestOptions).then(
    handleResponse
  );
};

const updateContactImage = (id, image) => {
  const formData = new FormData();
  formData.append('file', image);
  const requestOptions = {
    method: 'POST',
    body: formData,
  };

  return authFetch(`${API_ENDPOINT}/contactsImage/${id}`, requestOptions).then(
    handleResponse
  );
};

const searchContacts = (query) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(
    `${API_ENDPOINT}/contacts/search/${query}`,
    requestOptions
  ).then(handleResponse);
};

// ruta za dohvatit kontakte s tagom  https://dev-api-crm.drsha.com/api/contacts-by?tags_array[0]=kristian
// ruta za vise tagova https://dev-api-crm.drsha.com/api/contacts-by?tags_array[0]=kristian&tags_array[2]=0
// ruta za zadnjih 2 tjedna
const getAllTags = () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(
    `${API_ENDPOINT}/tags?per_page=100&page=1&search=`,
    requestOptions
  ).then(handleResponse);
};

export const contactService = {
  getAllContacts,
  getContact,
  deleteContact,
  createNewContact,
  updateContact,
  updateContactImage,
  searchContacts,
  getAllTags,
};
