import React, { useEffect, useState } from 'react';
import FieldInput from 'src/components/Form/components/FieldInput';
import Button from 'src/components/Button';
import { useForm } from 'react-hook-form';
const TagsForm = ({ onSubmit, buttonText = 'Add tag', submitting = false }) => {
  const { register, handleSubmit, watch, errors, control, getValues } = useForm(
    {
      mode: 'onChange',
    }
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FieldInput
        autoFocus={true}
        className="mb-10"
        labelText="Tag name"
        register={register}
        name="name"
        defaultValue=""
        placeholder="Tag name"
        type="text"
        errors={errors}
        value={watch('name')}
      />

      <Button
        variant="primary"
        height="55"
        width="280"
        onClick={handleSubmit}
        isLoading={submitting}
        disabled={submitting}
        className="w-full"
      >
        {buttonText}
      </Button>
    </form>
  );
};

export default TagsForm;
