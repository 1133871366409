import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchInput from 'src/components/Form/components/SearchInput';
import Button from '../../../components/Button';
import Checkbox from 'src/components/Form/components/Checkbox';
import { ReactComponent as SvgArrowDown } from './../../../assets/icons/arrow-down-icon.svg';
import { ReactComponent as SvgSearch } from './../../../assets/icons/icon-search.svg';
import { ReactComponent as SvgRecycle } from './../../../assets/icons/recycle-icon.svg';
import { ReactComponent as SvgEdit } from './../../../assets/icons/icon-edit.svg';
import { ReactComponent as SvgNext } from './../../../assets/icons/icon-next.svg';
import { ReactComponent as SvgDelete } from './../../../assets/icons/icon-delete-campaign.svg';
import ServerlessPagination from '../../../components/commons/ServerlessPagination/ServerlessPagination';
import ProductsRow from './ProductRow';
import Select from './../../../components/Select/Select';

const ProductsContent = () => {
  const contactsList = useSelector((state) => state.contacts.contactsList);
  console.log(contactsList, 'mounta li se?');
  const [searchContactList, setSearchContactList] = useState('');
  const searchResults = useSelector(
    (state) => state.contacts.searchResultContactsList
  );
  const searching = searchContactList && searchContactList.length > 0;
  const [currentPage, setCurrentPage] = useState(0);
  const totalRows = searching ? searchResults.length : contactsList.length;
  const lastPage = Math.ceil(totalRows / 10);

  useEffect(() => {
    if (searchContactList && searchContactList.length > 0) {
      dispatch(contactActions.searchContacts(searchContactList));
    }

    if (currentPage !== 0) setCurrentPage(0);
  }, [searchContactList]);

  console.log('setSearchContacts', searchContactList);
  console.log('ovo su rezultati', searchResults);

  const [isCheckboxDropdownOpen, setIsCheckboxDropdownOpen] = useState(false);
  const toggleCheckboxDropdown = () => {
    setIsCheckboxDropdownOpen(!isCheckboxDropdownOpen);
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  const roleOptions = [
    { value: 'selectActivity', label: 'Select Activity' },
    { value: 'inactive', label: 'inactive' },
    { value: 'active', label: 'active' },
  ];

  const deleteRows = () => {
    if (isChecked) {
      return (
        <div className="self-center">
          <button className="bg-gray-100 text-black text-sm p-10 rounded-default  outline-none flex justify-center items-center mr-15 h-45">
            <SvgDelete />
            <SvgArrowDown />
          </button>
        </div>
      );
    } else {
      return <div className="d-none"></div>;
    }
  };

  const selectRows = () => {
    if (isChecked) {
      return (
        <div className="flex items-center">
          <Select
            showLabel={false}
            name="status"
            placeholder="Select action"
            className="mr-15 -mb-5 p-0 w-200"
            options={roleOptions}
            defaultValue={{
              value: roleOptions[0].value,
              label: roleOptions[0].label,
            }}
          />

          <button className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-45 outline-none transition duration-500 mr-15">
            <SvgNext />
          </button>
        </div>
      );
    } else {
      return <div className="d-none"></div>;
    }
  };

  return (
    <div>
      <div className="flex justify-between mb-20">
        <div>
          <SearchInput
            name="search"
            type="text"
            labelText="search"
            placeholder="Search"
          />
        </div>
        <div>
          <Button
            variant="link"
            height="50"
            width="220"
            className="text-sm "
            target="/add-product"
          >
            Create Product
          </Button>
        </div>
      </div>
      <div className="card">
        <div className="bg-white sm:rounded-t-lg flex justify-between py-8 px-2">
          <div>
            <button
              className={`${
                isCheckboxDropdownOpen ? 'shadow-lg' : 'shadow-none'
              } bg-white text-black text-sm rounded-default outline-none flex items-center px-12`}
              onClick={toggleCheckboxDropdown}
            >
              <Checkbox />
              <SvgArrowDown />
            </button>
            <div
              className={`${
                isCheckboxDropdownOpen
                  ? 'opacity-100 z-10'
                  : 'opacity-0 pointer-events-none'
              } transition-opacity duration-150 ease-in-out absolute rounded-md shadow-lg py-1 bg-white overflow-hidden mt-2`}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
            >
              <div className="flex px-20 text-sm text-gray-700 hover:bg-gray-100 items-center">
                <Checkbox onChange={handleChange} checked={isChecked} />
                Select All
              </div>
              <div className="flex px-20 text-sm text-gray-700 hover:bg-gray-100 items-center">
                <Checkbox onChange={handleChange} checked={isChecked} />
                Select All Suitable
              </div>
            </div>
          </div>
          <div className="flex items-center">
            {selectRows()}
            <button className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-45 outline-none transition duration-500 mr-15">
              <SvgEdit />
            </button>
            {deleteRows()}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-20 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-b-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-200">
                    <tr>
                      <th
                        scope="col"
                        className="px-15 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      ></th>
                      <th
                        scope="col"
                        className="px-15 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-15 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-15 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        URL
                      </th>
                      <th
                        scope="col"
                        className="px-15 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Duration in months
                      </th>
                      <th
                        scope="col"
                        className="px-15 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center"
                      >
                        Active
                      </th>
                      <th
                        scope="col"
                        className="px-15 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      ></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    <ProductsRow />
                    <ProductsRow />
                    <ProductsRow />
                  </tbody>
                </table>

                <ServerlessPagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  lastPage={lastPage}
                  total={totalRows}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsContent;
