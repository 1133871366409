import { notesActionTypes } from '../constants/notesConstants';

const initialState = {
  notesList: [],
  note: null,
  searchResultNotesList: [],
  areNotesLoading: false,
  areNotesLoaded: false,
  error: null,
};

export const notes = (state = initialState, action) => {
  switch (action.type) {
    case notesActionTypes.NOTES_GET_NOTES_REQUEST:
      return { ...state, areNotesLoading: true, areNotesLoaded: false };
    case notesActionTypes.NOTES_GET_NOTES_SUCCESS:
      return {
        ...state,
        notesList: action.payload.list,
        areNotesLoading: false,
        areNotesLoaded: true,
      };
    case notesActionTypes.NOTES_GET_NOTES_FAILURE:
      return { ...state, areNotesLoading: false, areNotesLoaded: false };
    case notesActionTypes.NOTES_ADD_NOTE_SUCCESS:
      return action.payload.note.contacts_id
        ? { ...state }
        : { ...state, notesList: [action.payload.note, ...state.notesList] };
    case notesActionTypes.NOTES_UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        notesList: [
          ...state.notesList.map((note) =>
            action.payload.note.id == note.id ? action.payload.note : note
          ),
        ],
      };
    case notesActionTypes.NOTES_DELETE_NOTE_REQUEST:
      return state;
    case notesActionTypes.NOTES_DELETE_NOTE_SUCCESS:
      return {
        ...state,
        notesList: state.notesList.filter(
          (item) => item.id !== action.payload.deletedNoteId
        ),
      };
    case notesActionTypes.NOTES_DELETE_NOTE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
