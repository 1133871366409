import React from 'react';
import { useSelector } from 'react-redux';
import { BarGraph } from '../../../components/Visualization/bar';
import { LineGraph } from '../../../components/Visualization/line';
import { PieGraph } from '../../../components/Visualization/pie';
const StatsContent = () => {
  const contactsList = useSelector((state) => state.contacts.contactsList);
  return (
    <div className="flex justify-between flex-wrap">
      <LineGraph
        title="Total visits"
        excerpt="This is the line graph example for total visits"
        className="w-half"
      />
      <BarGraph
        title="Total visits"
        excerpt="This is the line graph example for total visits"
        className="w-half"
      />
      <PieGraph
        title="Total visits"
        excerpt="This is the line graph example for total visits"
        className="w-half"
      />
    </div>
  );
};

export { StatsContent };
