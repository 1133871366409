import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../../components/Modal/Modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ReactComponent as SvgCheckCircle } from './.././../../assets/icons/icon-check-circle.svg';
import SearchInput from '../../../components/Form/components/SearchInput';
import FieldInput from '../../../components/Form/components/FieldInput';
import FavoritesAddButton from '../../../components/FavoritesAddButton/FavoritesAddButton';
import AddContactAudienceTable from '../../Campaigns/components/AddContactAudienceTable';
import { contactActions, mailCampaignsActions } from '../../../common/actions';
import Button from '../../../components/Button';
import { toast } from 'react-toastify';

const newAudienceSchema = yup.object().shape({
  username: yup
    .string()
    .min(8, 'Enter minimium 8 characters')
    .required('This field is required'),
});

function NewAudienceModal({ toggleNewAudience, isNewAudienceModalOpened }) {
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contacts.contactsList);

  const [audienceName, setAudienceName] = useState('');
  const [pickedContacts, setPickedContacts] = useState([]);

  const areContactsLoaded = useSelector(
    (state) => state.contacts.areContactsLoaded
  );

  const [searchContacts, setSearchContacts] = useState('');

  useEffect(() => {
    if (!isNewAudienceModalOpened)
      if (searchContacts && searchContacts.length > 0) setSearchContacts(null);
  });

  useEffect(() => {
    if (!areContactsLoaded) {
      dispatch(contactActions.getAllContacts());
    }
  }, [areContactsLoaded]);
  const { handleSubmit, watch, errors, register } = useForm({
    resolver: yupResolver(newAudienceSchema),
    mode: 'onChange',
  });

  const submitNewAudience = () => {
    if (audienceName.length > 0 && pickedContacts.length > 0) {
      let sendingArray = [];
      for (let i = 0; i < pickedContacts.length; i++) {
        let singleObject = {};
        singleObject = { contacts_id: pickedContacts[i] };
        sendingArray.push(singleObject);
      }
      dispatch(
        mailCampaignsActions.addAudience(
          { name: audienceName, contacts: sendingArray },
          toggleNewAudience
        )
      );
    } else {
      toast.warning('Please enter name and select at least one contact!', {
        className: 'text-white',
      });
    }
  };

  return (
    <Modal
      isOpen={isNewAudienceModalOpened}
      headerTitle="New Audience"
      className="modal--audience"
      onRequestClose={toggleNewAudience}
    >
      <div className="flex items-center mb-40 ">
        <h2 className={`text-2x font-bold text-black mr-20`}>New audience</h2>
      </div>
      <form noValidate className="flex flex-col sm:mb-50 md:mb-0 w-full">
        <div className="flex items-center">
          <FieldInput
            register={register}
            name="audience"
            defaultValue=""
            type="text"
            errors={errors}
            labelText="Audience Name"
            placeholder="Name your audience"
            value={watch('audience')}
            className="w-half mb-0 "
            onChange={(e) => {
              setAudienceName(e.target.value);
            }}
          />
          {/* <input
            type="text"
            onChange={(e) => {
              setAudienceName(e.target.value);
            }}
          /> */}
          <FavoritesAddButton className="ml-40" labelText="Add to favorites" />
        </div>
        <SearchInput
          name="search"
          type="text"
          errors={errors}
          labelText="search"
          placeholder="Search contacts..."
          className="mb-20"
          value={watch('search')}
          onChange={(e) => {
            setSearchContacts(e.target.value);
          }}
        />
        {areContactsLoaded && (
          <AddContactAudienceTable
            search={searchContacts}
            setSearchContacts={setSearchContacts}
            pickedContacts={pickedContacts}
            setPickedContacts={setPickedContacts}
            name="pickedContacts"
            value={pickedContacts}
          />
        )}
        <Button
          type="submit"
          className="ml-auto mt-20 flex text-center justify-center items-center rounded-default pl-0 px-30 py-10 text-sm text-white bg-primary hover:bg-secondary outline-none"
          role="menuitem"
          onClick={submitNewAudience}
        >
          <SvgCheckCircle className="ml-20 mr-20" />
          Confirm
        </Button>
      </form>
    </Modal>
  );
}

export { NewAudienceModal };
