import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { companiesActions } from 'src/common/actions';
import LayoutComponent from 'src/components/commons/LayoutComponent';
import CompaniesTable from './components/CompaniesTable';
import CompanyModal from './components/CompanyModal';

export default function Companies() {
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();
  const handleAddCompany = () => {
    setModalVisible(true);
  };
  const companies = useSelector(
    (state) => state.companiesReducer?.companies?.list?.data
  );
  useEffect(() => {
    console.log('get allcompanies');
    dispatch(companiesActions.getAllCompanies());
  }, []);

  return (
    <LayoutComponent
      title="Companies"
      addAction={{ title: 'New company', action: handleAddCompany }}
    >
      {companies && companies.length > 0 && (
        <CompaniesTable companies={companies} />
      )}
      <CompanyModal
        open={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </LayoutComponent>
  );
}
