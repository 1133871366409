import React from 'react';
// import styles from './ChatConversation.module.scss';
import { ReactComponent as SvgAvatar } from './../../../../assets/icons/icon-avatar-male.svg';
// import { ReactComponent as SvgAttachment } from './../../../../assets/icons/icon-attachment.svg';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { chatActions } from 'src/common/actions';

const ChatConversation = ({
  status = 'private',
  conversation: conversationData,
}) => {
  const dispatch = useDispatch();

  const selectUser = () => {
    dispatch(chatActions.getConversation(conversationData.id));
  };

  const lastMessage = conversationData.conversation.last_message;

  const message = String(lastMessage ? lastMessage.body : 'No messages...');
  const messageType = lastMessage ? lastMessage.type : null;

  const fromNow = moment(conversationData.updated_at).fromNow();
  const date = moment(conversationData.updated_at).format('dd/MM/YYYY HH:mm');

  return (
    <>
      <article className="flex flex-col cursor-pointer" onClick={selectUser}>
        <div className="flex">
          <SvgAvatar
            className="rounded-full object-cover object-center mr-20"
            style={{ minWidth: '44px', maxWidth: '44px', height: '44px' }}
          />

          <div className="flex flex-col text-sm text-gray-500 font-bold">
            {conversationData.name}
            <span className="text-gray-400 text-xxs font-normal mt-5">
              {date}
            </span>
          </div>
          <span className="flex ml-auto text-gray-400 text-xxs">{fromNow}</span>
        </div>
        <div className="flex items-center mt-20">
          <p className="text-xs text-gray-500">
            {messageType === 'upload'
              ? 'File...'
              : message.length > 50
              ? message.slice(0, 50) + '...'
              : message}
          </p>
          <div
            className={`flex items-center justify-center p-5 font-bold text-xxs text-white  ml-auto border-solid border-1 ${
              status === 'private' ? 'bg-red border-gray-500' : 'bg-primary'
            }`}
            style={{ width: '22px', height: '22px', borderRadius: '5px' }}
          >
            {conversationData.unread_messages}
          </div>
          {/* <SvgAttachment
            className="h-24 w-24"
            fill="text-red"
            style={{ minWidth: '24px', maxWidth: '24px' }}
          /> */}
        </div>

        <hr className="w-full border-gray-100 mt-15 mb-20" />
      </article>
    </>
  );
};
export default ChatConversation;
