export const dealsActionTypes = {
  DEALS_GET_ALL_REQUEST: 'DEALS_GET_ALL_REQUEST',
  DEALS_SET_ALL_REQUEST: 'DEALS_SET_ALL_REQUEST',
  DEALS_GET_ALL: 'DEALS_GET_ALL',
  DEALS_SET_ALL: 'DEALS_SET_ALL',
  DEALS_SET_SINGLE: 'DEALS_SET_SINGLE',
  DEALS_GET_SINGLE: 'DEALS_GET_SINGLE',
  DEALS_GET_COMMENTS_REQUEST: 'DEALS_GET_COMMENTS_REQUEST',
  DEALS_SET_COMMENTS_REQUEST: 'DEALS_SET_COMMENTS_REQUEST',
  DEALS_GET_COMMENTS: 'DEALS_GET_COMMENTS',
  DEALS_SET_COMMENTS: 'DEALS_SET_COMMENTS',
};
