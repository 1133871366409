import React from 'react';
import { ResponsiveLine } from '@nivo/line';

const initData = [
  {
    id: 'Viewers',
    color: 'hsl(208, 70%, 50%)',
    data: [
      {
        x: 'campaign',
        y: 121,
      },
      {
        x: 'contacts',
        y: 190,
      },
      {
        x: 'tasks',
        y: 113,
      },
      {
        x: 'notes',
        y: 257,
      },
    ],
  },
  {
    id: 'Moderators',
    color: 'hsl(292, 70%, 50%)',
    data: [
      {
        x: 'campaign',
        y: 108,
      },
      {
        x: 'contacts',
        y: 176,
      },
      {
        x: 'tasks',
        y: 181,
      },
      {
        x: 'notes',
        y: 189,
      },
    ],
  },
  {
    id: 'Admins',
    color: 'hsl(204, 70%, 50%)',
    data: [
      {
        x: 'campaign',
        y: 22,
      },
      {
        x: 'contacts',
        y: 8,
      },
      {
        x: 'tasks',
        y: 222,
      },
      {
        x: 'notes',
        y: 166,
      },
    ],
  },
];

const LineGraph = ({ title, excerpt, graphData, className }) => {
  let data;
  if (!graphData) {
    data = initData;
  } else {
    data = graphData;
  }
  return (
    <div
      className={`${className} flex flex-col bg-white py-40 px-30 rounded-default mt-40`}
      style={{ height: '500px' }}
    >
      <div className="flex  mb-40 flex-col">
        <h2 className={`text-xl font-bold text-gray-500`}>{title}</h2>
        <p className={`text-sm text-gray-400`}>{excerpt}</p>
      </div>
      <ResponsiveLine
        data={data}
        colors={{ datum: 'color' }}
        margin={{
          top: 0,
          right: 110,
          bottom: 50,
          left: 60,
        }}
        xScale={{
          type: 'point',
        }}
        yScale={{
          type: 'linear',
          stacked: true,
          min: 'auto',
          max: 'auto',
        }}
        minY="auto"
        maxY="auto"
        stacked={true}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'traffic',
          legendOffset: 40,
          legendPosition: 'middle',
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'count',
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        dotSize={10}
        dotColor="inherit:darker(0.3)"
        dotBorderWidth={2}
        dotBorderColor="#ffffff"
        enableDotLabel={true}
        dotLabel="y"
        dotLabelYOffset={-12}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export { LineGraph };
