import React, { useState } from 'react';
import FieldInput from 'src/components/Form/components/FieldInput';
import { ReactComponent as SvgNext } from './../../../../assets/icons/icon-next.svg';
import Modal from 'src/components/Modal';

function HubspotInput({ onNext }) {
  const hubspotFetched = false;
  const [isModalOpened, setIsModalOpened] = useState(false);
  const toggleModal = () => {
    setIsModalOpened(!isModalOpened);
  };

  return (
    <div className="flex flex-col rounded-default p-20 bg-white">
      <header className="flex justify-center mb-20">
        <div className="w-3/5">
          <h1 className="text-3xl font-bold text-gray-500 text-center">
            Hubspot
          </h1>
          <p className="text-sm text-gray-400 text-center whitespace-nowrap">
            Enter the Hubspot ID to get customer data automatically
          </p>
        </div>
      </header>
      <main className="flex justify-center mb-10">
        <div className="flex justify-between items-center w-3/5">
          <FieldInput
            labelText="Hubspot Deal ID"
            name=""
            defaultValue=""
            type="number"
            placeholder="1"
            className="w-full mr-5"
          />
          <button
            type="submit"
            className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-50 outline-none transition duration-500"
          >
            <SvgNext />
          </button>
        </div>
      </main>
      <footer className="flex justify-between items-center mb-10">
        <a
          href="https://help.hubspot.com/de/"
          rel="noreferrer"
          target="_blank"
          className="block border-2 p-5 px-15 rounded-full font-bold text-gray-400"
        >
          i
        </a>
        <button
          onClick={toggleModal}
          className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-50 outline-none transition duration-500"
        >
          Continue
        </button>
      </footer>
      <Modal
        isOpen={isModalOpened}
        onRequestClose={toggleModal}
        headerTitle="Are you sure?"
        className="modal--alert"
      >
        <h2 className={`w-full text-2x font-bold text-black mt-50 mb-20`}>
          Are you sure?
        </h2>
        <p className="my-20 mx-30">
          Offers may only be made in individual cases without a deal ID!
        </p>
        <div className="flex justify-between mt-20">
          <button
            className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-gray-500 hover:bg-gray-400"
            role="menuitem"
            onClick={toggleModal}
          >
            Cancel
          </button>
          <button
            className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-primary hover:bg-secondary whitespace-nowrap"
            role="menuitem"
            onClick={onNext}
          >
            There is no deal
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default HubspotInput;
