import React, { useState } from 'react';
import { Steps } from 'rsuite';
import HubspotInput from './steps/HubspotInput';
import DataInput from './steps/DataInput';
import ProductSelect from './steps/ProductSelect';
import PeriodInput from './steps/PeriodInput';
import CreditCheck from './steps/CreditCheck';
import OfferPreview from './steps/OfferPreview';
import EmailSend from './steps/EmailSend';
import Completed from './steps/Completed';
import { data } from 'autoprefixer';

const NewOfferContent = () => {
  const [step, setStep] = React.useState(0);

  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 7 ? 7 : nextStep);
  };

  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);

  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    company_name: '',
    street: '',
    house_number: '',
    postcode: '',
    city: '',
    country: '',
    taxId: '',
    phone_number: '',
    email: '',
  });

  return (
    <div>
      <div className="grid grid-cols-6">
        <div className="col-start-2 col-span-4">
          <Steps
            current={step}
            className="flex w-full h-10 bg-white mb-20 rounded-default"
          >
            <Steps.Item
              icon={<></>}
              className={`rounded-tl-default rounded-bl-default shadow-none flex flex-col whitespace-nowrap justify-center  ${
                step > 0 ? 'bg-primary' : ''
              }`}
            />
            <Steps.Item
              icon={<></>}
              className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
                step > 1 ? 'bg-primary' : ''
              } `}
            />
            <Steps.Item
              icon={<></>}
              className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
                step > 2 ? 'bg-primary' : ''
              }`}
            />
            <Steps.Item
              icon={<></>}
              className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
                step > 3 ? 'bg-primary' : ''
              }`}
            />
            <Steps.Item
              icon={<></>}
              className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
                step > 4 ? 'bg-primary' : ''
              }`}
            />
            <Steps.Item
              icon={<></>}
              className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
                step > 5 ? 'bg-primary' : ''
              }`}
            />
            <Steps.Item
              icon={<></>}
              className={`rounded-tr-default rounded-br-default shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
                step > 6 ? 'bg-primary' : ''
              }`}
            />
            <Steps.Item
              icon={<></>}
              className={`rounded-tr-default rounded-br-default shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
                step >= 7 ? 'bg-primary' : ''
              }`}
            />
          </Steps>
          <div>
            {step === 0 && <HubspotInput onNext={onNext} />}
            {step === 1 && (
              <DataInput onPrevious={onPrevious} onNext={onNext} data={data} />
            )}
            {step === 2 && (
              <ProductSelect onPrevious={onPrevious} onNext={onNext} />
            )}
            {step === 3 && (
              <PeriodInput
                onPrevious={onPrevious}
                onNext={onNext}
                data={data}
              />
            )}
            {step === 4 && (
              <CreditCheck
                onPrevious={onPrevious}
                onNext={onNext}
                data={data}
              />
            )}
            {step === 5 && (
              <OfferPreview
                onPrevious={onPrevious}
                onNext={onNext}
                data={data}
              />
            )}
            {step === 6 && <EmailSend onNext={onNext} />}
            {step === 7 && <Completed />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOfferContent;
