import React, { useState } from 'react';

const FieldTextareaBorder = ({
  register,
  errors,
  name,
  placeholder,
  labelText = name,
  touched = false,
  value,
  className,
  ...textareaProps
}) => {
  const [isHidden, setIsHidden] = useState(true);
  return (
    <div
      className={`${className} mb-25 flex flex-col 
			
				${errors && errors[name] ? 'isInvalid' : ''} 
			`}
    >
      <label className={`block text-xs mb-5`} htmlFor={name}>
        {labelText}
      </label>
      <div
        className={`flex w-full focus:shadow-outline relative placeholder-secondary flex-wrap `}
      >
        <textarea
          className={`flex items-center w-full h-full text-xs px-20 py-20 h-full rounded-default resize-none border-2 border-gray-200 ${
            errors && name && errors[name] ? 'bg-red bg-opacity-8' : 'bg-white'
          }  outline-none`}
          id={name}
          name={name}
          placeholder={placeholder}
          ref={register}
          defaultValue={value}
          //onBlur={() => setIsFocused(false)}
          {...textareaProps}
        />
      </div>
      {errors && name && errors[name] && (
        <p
          data-testid={`${name}-textarea-error`}
          className="text-xs mt-5 text-red text-right"
        >
          {errors[name].message}
        </p>
      )}
    </div>
  );
};
export default FieldTextareaBorder;
