import React from 'react';

const PaginationBar = (props) => {
  const paginationContainer = {
    display: 'flex',
    widht: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '40px 0 20px 0',
  };

  const numberStyle = {
    color: 'white',
    cursor: 'pointer',
    width: '30px',
    height: '30px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderRadius: '10px',
    backgroundColor: 'white',
    border: '1px solid #0395ff',
    color: '#0395ff',
  };

  const activeNumberStyle = {
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
    width: '30px',
    height: '30px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderRadius: '10px',
    backgroundColor: '#0395ff',
    border: '1px solid #0395ff',
    color: '#ffffff',
  };

  const pagFirst = {
    color: '#242F3C',
    fontSize: 'bold',
    cursor: 'pointer',
  };
  const pagFirstDisabled = {
    backgroundColor: '#E2E9F5',
    cursor: 'none',
  };
  const pagPrevious = {
    color: '#242F3C',
    fontSize: 'bold',
    cursor: 'pointer',
  };
  const pagPreviousDisabled = {
    color: '#E2E9F5',
    cursor: 'none',
    textDecoration: 'line-through',
  };
  const pagNext = {
    color: '#242F3C',
    fontSize: 'bold',
    cursor: 'pointer',
  };
  const pagNextDisabled = {
    color: '#E2E9F5',
    cursor: 'none',
    textDecoration: 'line-through',
  };
  const pagLast = {
    color: '#242F3C',
    fontSize: 'bold',
    cursor: 'pointer',
  };
  const pagLastDisabled = {
    color: 'gray',
    cursor: 'none',
  };

  const calculatePageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(props.numPag / props.perPage); i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const checkNumberStyle = (number) => {
    if (number === props.activepagenum) {
      return activeNumberStyle;
    }
    return numberStyle;
  };

  const renderPageNumbers = calculatePageNumbers().map((number) => {
    return (
      <li
        key={number}
        id={number}
        onClick={props.click}
        style={checkNumberStyle(number)}
      >
        {number}
      </li>
    );
  });
  //todo: kiki please do some styling here
  return (
    <ul style={paginationContainer}>
      {props.enableFirstLast === 'true' && (
        <li
          onClick={props.enabledFirst ? props.first : null}
          style={props.enabledFirst ? pagFirst : pagFirstDisabled}
        >
          first
        </li>
      )}
      <li
        onClick={props.disabledprevious ? null : props.previous}
        style={props.disabledprevious ? pagPreviousDisabled : pagPrevious}
      >
        previous
      </li>

      {renderPageNumbers}
      <li
        onClick={props.disablednext ? null : props.next}
        style={props.disablednext ? pagNextDisabled : pagNext}
      >
        next
      </li>
      {props.enableFirstLast === 'true' && (
        <li
          onClick={props.enabledLast ? props.last : null}
          style={props.enabledLast ? pagLast : pagLastDisabled}
        >
          last
        </li>
      )}
    </ul>
  );
};

export default PaginationBar;
