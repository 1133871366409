import React, { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { ReactComponent as SvgCalendar } from './../../../../../assets/icons/icon-calendar.svg';
import { ReactComponent as SvgDocument } from './../../../../../assets/icons/icon-document.svg';
import { ReactComponent as SvgEdit } from './../../../../../assets/icons/icon-edit.svg';
import FieldInput from '../../../../../components/Form/components/FieldInput';
import Select from '../../../../../components/Select/Select';
import styles from '../Automation.module.scss';
import Modal from '../../../../../components/Modal/Modal';
import ButtonBack from '../../../../../components/ButtonBack/ButtonBack';
import CampaignAutomationBuilder from '../../CampaignAutomationBuilder/CampaignAutomationBuilder';
import { Button, ClickAwayListener } from '@material-ui/core';

const frequency = [
  { name: 'frequency', value: 'Daily', label: 'Daily' },
  { name: 'frequency', value: 'Weekly', label: 'Weekly' },
  { name: 'frequency', value: 'Monthly', label: 'Monthly' },
  { name: 'frequency', value: 'Yearly', label: 'Yearly' },
  { name: 'frequency', value: 'Never', label: 'Never' },
];

const formatter = (u) => ({
  label: u.full_name,
  value: u.id,
});

function AutomationByNewContactsForm({
  mailCampaign,
  currentUser,
  automationLevels,
  setAutomationLevels,
  currentLevel,
  isValidating,
  contacts,
}) {
  const [values, setValues] = useState({
    title: mailCampaign.name,
    subject: mailCampaign.subject,
    from: currentUser.email,
    to: '',
    daysDelayed: 0,
    frequency: '',
    startDate: new Date(),
    minDate: new Date(),
    endDate: new Date(),
    html: '',
  });
  const [pickerStart, setPickerStart] = useState(false);
  const [pickerEnd, setPickerEnd] = useState(false);
  const handlePickerStart = () => {
    setPickerStart(!pickerStart);
  };
  const handlePickerEnd = () => {
    setPickerEnd(!pickerEnd);
  };

  //mail template
  const [mailTemplate, setMailTemplate] = useState(null);
  const [isMailBuilderModalOpened, setIsMailBuilderModalOpened] = useState(
    false
  );
  useEffect(() => {
    console.log('mailTemplate', mailTemplate);
  }, [mailTemplate]);

  const getValidationErrors = (validationValues) => {
    const getValidatonMessage = (filedName) => {
      switch (filedName) {
        case 'subject':
          return 'Subject field is required';
        default:
          return 'Field is required';
      }
    };

    let ret = {};
    const validationValuesKeys = Object.keys(validationValues);
    console.log('validationValues', validationValues, validationValuesKeys);
    for (let i = 0; i < validationValuesKeys.length; i++) {
      const currentKey = validationValuesKeys[i];
      const currentValue = validationValues[currentKey];
      if (
        !currentValue ||
        (Array.isArray(currentValue) && currentValue.length < 1) ||
        currentValue === ''
      ) {
        ret[currentKey] = {
          message: getValidatonMessage(currentKey),
        };
      }
    }
    return ret;
  };

  useEffect(() => {
    const automationLevelsUpdate = automationLevels.map((al) => {
      if (al.id !== currentLevel) return al;
      return {
        id: currentLevel,
        ...values,
        mailTemplate,
        errors: isValidating
          ? getValidationErrors({ ...values, mailTemplate })
          : null,
      };
    });
    setAutomationLevels(automationLevelsUpdate);
  }, [values, mailTemplate, isValidating]);

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChangeFrequencyValue = (e) => {
    if (e) {
      setValues((prevState) => ({
        ...prevState,
        frequency: e.value,
      }));
      console.log(e.label, e.value, 'ovo su vrjednosti eventa');
      console.log(e, 'ovo su vrjednosti eventa');
    }
  };

  const toggleMailBuilderModal = () => {
    setIsMailBuilderModalOpened(!isMailBuilderModalOpened);
  };
  const onMailBuilderClose = () => {
    setIsMailBuilderModalOpened(false);
  };

  const handleChangeSelectValue = (e) => {
    setValues((prevState) => ({
      ...prevState,
      [e.full_name]: e.value,
    }));
    console.log(e, 'tags event logger');
  };

  return (
    <>
      <form className="flex flex-wrap mt-30">
        <h3
          className={`text-gray-500 flex items-center text-sm font-bold mb-10 w-full`}
        >
          1. Email data
        </h3>
        <div
          className="flex items-center justify-center flex-col w-3/12 bg-gray-200 hover:bg-gray-300 rounded-default p-20 relative cursor-pointer"
          onClick={toggleMailBuilderModal}
        >
          <div className="flex items-center justify-center bg-white p-15 rounded-default ">
            <SvgDocument className="text-primary group-hover:text-primary transition duration-300 ease-in-out" />
          </div>
          <span
            style={{ textAlign: 'center' }}
            className={`text-gray-500 flex items-center text-xs font-medium mt-10`}
          >
            Edit Email Template
          </span>
          <SvgEdit className="absolute left-15 top-15 w-lg text-secondary" />
        </div>
        <div className="flex flex-col w-9/12 pl-20">
          <div className="flex">
            <div className="flex flex-col mr-20 w-full">
              <span className="text-xs font-medium text-gray-400 mb-10">
                Title
              </span>
              <FieldInput
                errors={
                  automationLevels.find((al) => al.id === currentLevel).errors
                }
                name="title"
                type="text"
                labelText=""
                placeholder=""
                className=""
                error
                value={values.title}
                onChange={handleChangeValue}
                //register={register}
                //errors={errors}
                //value={watch('title')}
              />
            </div>
            <div className="flex flex-col w-full">
              <span className="text-xs font-medium text-gray-400 mb-10">
                Subject
              </span>
              <FieldInput
                errors={
                  automationLevels.find((al) => al.id === currentLevel).errors
                }
                name="subject"
                type="text"
                labelText=""
                placeholder=""
                className=""
                value={values.subject}
                onChange={handleChangeValue}
                //register={register}
                //errors={errors}
                //value={watch('subject')}
              />
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col mr-20 w-full">
              <span className="text-xs font-medium text-gray-400">
                From sender
              </span>
              <FieldInput
                errors={
                  automationLevels.find((al) => al.id === currentLevel).errors
                }
                name="from"
                labelText=""
                type="text"
                placeholder={values.from}
                /* value={values.from} */
                onChange={handleChangeValue}
                className="disabled pointer-events-none opacity-50"
              />
            </div>
            <div className="flex flex-col w-full">
              <span className="text-xs font-medium text-gray-400">Send to</span>
              <FieldInput
                errors={
                  automationLevels.find((al) => al.id === currentLevel).errors
                }
                name="To"
                labelText=""
                type="text"
                placeholder="Contacts containting tag"
                className="disabled pointer-events-none opacity-50"
              />
            </div>
          </div>
        </div>

        <hr className="w-full border-gray-100 mt-15 mb-20" />
        <div className="flex flex-col w-full pl-20">
          {/* <h3
            className={`text-gray-500 flex items-center text-sm font-bold mb-10`}
          >
            2. Trigger
          </h3>
          <div className="flex">
            <div className="flex flex-col mr-20 w-full">
              <span className="text-xs font-medium text-gray-400 mb-10">
                Days delayed
              </span>
              <FieldInput
                errors={
                  automationLevels.find((al) => al.id === currentLevel).errors
                }
                name="daysDelayed"
                type="number"
                labelText=""
                placeholder="0"
                className=""
                value={{
                  value: values.daysDelayed ? values.daysDelayed : 'Non set',
                  label: values.daysDelayed ? values.daysDelayed : 'Non set',
                }}
                onChange={handleChangeValue}
              />
            </div>
          </div> */}
          <h3
            className={`text-gray-500 flex items-center text-sm font-bold mb-10`}
          >
            2. Shedule
          </h3>
          <div className="flex">
            <div className="flex flex-col mr-20 w-full">
              <span className="text-xs font-medium text-gray-400 mb-10">
                What time should email be sent?
              </span>
              <ClickAwayListener onClickAway={() => setPickerStart(false)}>
                <div
                  onClick={() => {
                    !pickerStart && handlePickerStart();
                  }}
                  className="bg-white flex items-center justify-between border-1 border-gray-300 rounded-default p-12 mt-5"
                >
                  <ReactDatePicker
                    calendarStartDay={values.startDate}
                    minDate={values.minDate}
                    maxDate={values.endDate}
                    open={pickerStart}
                    className="ReactDatePicker text-sm"
                    dateFormat="dd-MM-yyyy"
                    selected={values.startDate}
                    onChange={(date) => {
                      setValues((prevState) => ({
                        ...prevState,
                        startDate: date,
                      }));
                    }}
                  />
                  <Button
                    onClick={() => handlePickerStart()}
                    style={{ padding: 0, minWidth: 0 }}
                  >
                    <SvgCalendar className="w-sm h-sm min-w-sm text-primary right-0" />
                  </Button>
                </div>
              </ClickAwayListener>
            </div>
            <div className="flex flex-col mr-20 w-full">
              <span className="text-xs font-medium text-gray-400 mb-10">
                What time should automation end?
              </span>
              <ClickAwayListener onClickAway={() => setPickerEnd(false)}>
                <div
                  onClick={() => {
                    !pickerStart && handlePickerEnd();
                  }}
                  className="bg-white flex items-center justify-between border-1 border-gray-300 rounded-default p-12 mt-5"
                >
                  <ReactDatePicker
                    calendarStartDay={values.endDate}
                    minDate={values.startDate}
                    open={pickerEnd}
                    className="ReactDatePicker text-sm"
                    dateFormat="dd-MM-yyyy"
                    selected={values.endDate}
                    onChange={(date) => {
                      setValues((prevState) => ({
                        ...prevState,
                        endDate: date,
                      }));
                    }}
                  />
                  <Button style={{ padding: 0, minWidth: 0 }}>
                    <SvgCalendar className="w-sm h-sm min-w-sm text-primary right-0" />
                  </Button>
                </div>
              </ClickAwayListener>
            </div>
          </div>
          <div className="flex flex-col mr-20 mt-20 w-full">
            <div className="flex flex-col w-full">
              <span className="text-xs font-medium text-gray-400 mb-10">
                Should the mail repeat? (Max three times)
              </span>
              <Select
                labelText=""
                name="frequency"
                options={frequency}
                placeholder="Daily"
                className={`${styles.select}`}
                value={{
                  value: values.frequency ? values.frequency : 'Non set',
                  label: values.frequency ? values.frequency : 'Non set',
                }}
                onChange={(e) => handleChangeFrequencyValue(e)}
              />
            </div>
          </div>
        </div>
      </form>
      {isMailBuilderModalOpened && (
        <div className="flex flex col">
          <Modal
            isOpen={isMailBuilderModalOpened}
            headerTitle="Mail builder modal"
            className="modal--mail-builder"
            onRequestClose={toggleMailBuilderModal}
          >
            <div className="flex flex-col mb-20 ">
              <ButtonBack
                variant="click"
                onClick={toggleMailBuilderModal}
                className="mb-15"
              />
              <h2 className={`text-2xl font-bold text-black`}>Mail Builder</h2>
              <p className="text-xs text-gray-400">
                Design your own email content with builder, or pick a template{' '}
              </p>
            </div>
            <CampaignAutomationBuilder
              onMailBuilderClose={onMailBuilderClose}
              setValues={setMailTemplate}
            />
          </Modal>
        </div>
      )}
    </>
  );
}

export { AutomationByNewContactsForm };
