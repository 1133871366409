import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from 'src/common/actions';
import LayoutComponent from '../../components/commons/LayoutComponent';
import ChatContent from './components/ChatContent';

const Chat = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(chatActions.getConversations());
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      dispatch(chatActions.getConversations(false));
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <LayoutComponent title="">
      <ChatContent />
    </LayoutComponent>
  );
};

export { Chat };
