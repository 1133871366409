import React, { useEffect, useState } from 'react';
import LayoutComponent from '../../components/commons/LayoutComponent';
import { DealsTable } from './components/DealsTable';
import { CreateDeal } from './components/CreateDeal';
import { ReactComponent as SvgAdd } from '../../assets/icons/icon-add.svg';
import { Box, Typography, Dialog, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, contactActions, dealsActions } from 'src/common/actions';
import axios from 'axios';
import { API_ENDPOINT } from '../../utils/endpoints';
import { companiesActions } from 'src/common/actions/companiesAction';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button2: {
    padding: '18px 47px',
    maxWidth: 'unset',
    border: '1px solid #E1E1E1',
    borderRadius: '6px',
  },
  search: {
    minWidth: 441,
  },
  formControl: {
    width: 286,
    backgroundColor: '#FFFFFF',
    color: '#6A6565',
    marginRight: 20,
  },
  formControlSearch: {
    width: 441,
    backgroundColor: '#FFFFFF',
    color: '#6A6565',
    marginRight: 20,
    '& input::placeholder': {
      fontSize: '14px',
      paddingLeft: '5px',
    },
  },
  selectControl: {
    color: '#6A6565',
    fontStyle: 'normal',
    fontSize: '14px',
    '& .MuiInputLabel-root': {
      fontSize: '14px',
      paddingLeft: '8px',
      color: '#6A6565',
    },
    '& .MuiSelect-select': {
      fontSize: '14px',
      color: '#6A6565',
      marginLeft: 3,
    },
  },

  selectMultipleControl: {
    color: '#6A6565',
    fontStyle: 'normal',
    fontSize: '14px',
    paddingLeft: '5px',
    '& .MuiInputLabel-root': {
      fontSize: '14px',
      paddingLeft: '8px',
      color: '#6A6565',
    },
    '& .MuiSelect-select': {
      fontSize: '14px',
      color: '#6A6565',
      marginLeft: 3,
    },
  },
}));

const Outreach = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(dealsActions.getAllDeals());
    dispatch(contactActions.getAllContacts());
    dispatch(authActions.fetchUsers());
  }, []);
  const dealsList = useSelector((state) => state.dealsReducer?.deals?.list);
  const contactsList = useSelector((state) => state.contacts?.contactsList);
  const users = useSelector((state) => state.auth?.users);
  console.log('users', users);
  console.log(contactsList);
  console.log(dealsList);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleAlertOpen = () => {
    setAlertOpen(true);
  };
  const handleDataRefresh = () => {
    console.log('Deals Updated');
    dispatch(dealsActions.getAllDeals());
  };
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const createDeal = async (data) => {
    await axios
      .post(`${API_ENDPOINT}/deals`, data, {
        headers: {
          Authorization: 'Bearer' + localStorage.getItem('crm-client-token'),
          Accept: 'application/json',
        },
      })
      .then(() => {
        handleDataRefresh();
        handleClose();
        setAlertMessage('You have succesfully created a new deal');
        handleAlertOpen();
      })
      .catch((e) => {
        let errors = e.response.data.message + '\n';
        for (let error in e.response.data.errors) {
          errors += e.response.data.errors[error][0] + '\n';
        }
        // setAlertMessage(errors);
        // handleAlertOpen();
      });
  };

  return (
    <LayoutComponent>
      <div className="flex">
        <h2 className="text-3xl font-bold  mr-20 text-black  border-l-0 border-t-0 border-b-0 pr-20 border-1 border-gray-400">
          Deals
        </h2>
        <div className="flex justify-end relative z-9 ml-20 ml-auto">
          <button
            onClick={handleOpen}
            type="button"
            className="font-bold bg-primary hover:bg-secondary text-white p-15 px-20 text-sm rounded-default outline-none flex justify-center items-center normal-case"
          >
            <SvgAdd className="mr-20 text-white" />
            Create a deal
          </button>
        </div>
      </div>

      <DealsTable
        handleDataRefresh={handleDataRefresh}
        setAlertOpen={setAlertOpen}
        setAlertMessage={setAlertMessage}
        rows={dealsList}
      />
      <Snackbar
        open={alertOpen}
        onClose={handleAlertClose}
        autoHideDuration={3000}
      >
        <Alert
          onClose={handleAlertClose}
          variant="filled"
          severity={'success'}
          style={{
            whiteSpace: 'pre-line',
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={`classes.dialog right-0 w-half h-full`}
        style={{ left: 'initial !important' }}
        fullScreen
      >
        <Box display="flex" justifyContent="space-between" padding="24px 40px">
          <Box>
            <Typography
              style={{
                lineHeight: '25px',
                fontSize: '18px',
                fontWeight: 700,
                color: 'black',
              }}
            >
              Create Deal
            </Typography>
          </Box>

          <Box>
            <Typography variant="h5">
              <CloseIcon onClick={handleClose} />
            </Typography>
          </Box>
        </Box>
        <Box style={{ padding: '0 40px', height: '100%' }}>
          {/*<Divider />*/}
          <CreateDeal
            handleDataRefresh={handleDataRefresh}
            users={users}
            companies={[]}
            contacts={contactsList}
            onSubmit={createDeal}
            handleClose={handleClose}
          />
        </Box>
      </Dialog>
    </LayoutComponent>
  );
};

export default Outreach;
