import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LayoutComponent from '../../components/commons/LayoutComponent';
import { StatsContent } from './components';
import { contactActions } from '../../common/actions';

const Stats = () => {
  const dispatch = useDispatch();
  const areContactsLoaded = useSelector(
    (state) => state.contacts.areContactsLoaded
  );
  console.log('areContactsLoaded', areContactsLoaded);

  useEffect(() => {
    if (!areContactsLoaded) {
      dispatch(contactActions.getAllContacts());
    }
  }, []);

  return (
    <LayoutComponent title="Statistics">
      {areContactsLoaded && <StatsContent />}
    </LayoutComponent>
  );
};

export default Stats;
