import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as SvgNotif } from '../../../assets/icons/icon-notification.svg';
import { ReactComponent as SvgHamburger } from '../../../assets/icons/icon-hamburger.svg';
import { ReactComponent as SvgSearch } from '../../../assets/icons/icon-search.svg';
import { authActions } from '../../../common/actions';
import Notifications from './Notifications';
import { FaPlus } from 'react-icons/fa';
import Search from './Search';
import { API_SERVER_ENDPOINT } from 'src/utils/endpoints';
export default function Content({
  isSidebarVisible,
  setIsSidebarVisible,
  children,
  title,
  addAction,
  icon = null,
}) {
  const user = useSelector((state) => state.auth.user);
  const notifications = useSelector(
    (state) => state.notification.notifications
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const toggleSidebar = () => setIsSidebarVisible(!isSidebarVisible);

  const [isProfileDetailsOpened, setIsProfileDetailsOpened] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const toggleProfileDetails = () => {
    setIsProfileDetailsOpened(!isProfileDetailsOpened);
    setShowNotifications(false);
  };

  const toggleNotifications = () => {
    //if (notifications.length === 0) return;

    setShowNotifications(!showNotifications);
    setIsProfileDetailsOpened(false);
  };

  const onLogout = () => {
    dispatch(authActions.logoutUser(history));
  };

  const hasUnread = notifications.slice(0, 3).filter((i) => !i.read).length > 0;

  return (
    <div className="sm:w-full md:w-content sm:w-full p-30 md:pt-40 min-h-screen bg-gray-100">
      <div className="flex flex-col">
        {icon && (
          <button onClick={() => history.goBack()} className="mr-20">
            {icon}
          </button>
        )}
        <nav className="w-full mb-30">
          <div className="max-w-full mx-auto">
            <div className="relative flex items-center justify-between">
              <div className="relative flex items-center mr-auto">
                <button
                  className="inline-flex items-center justify-center p-20 rounded-default text-gray-400 hover:text-white bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  aria-expanded="false"
                  onClick={toggleSidebar}
                >
                  <span className="sr-only">Open main menu</span>
                  <SvgHamburger className="w-30 h-30" />
                </button>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <Search />
                <div className="ml-10 relative">
                  <button
                    className="relative p-10 rounded-full text-black hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-200 focus:ring-white"
                    onClick={toggleNotifications}
                  >
                    <span className="sr-only">View notifications</span>
                    <SvgNotif className="w-30 h-30" />
                    {hasUnread && (
                      <span className="flex w-10 h-10 rounded-full absolute right-0 top-0 bg-green-100 z-9" />
                    )}
                  </button>

                  <div
                    className={`${
                      showNotifications
                        ? 'opacity-100 z-10'
                        : 'opacity-0 pointer-events-none'
                    } transition-opacity duration-150 ease-in-out origin-top-right absolute right-0 mt-10 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 overflow-hidden w-200`}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <Notifications
                      onClose={() => setShowNotifications(false)}
                    />
                  </div>
                </div>

                <div className="ml-10 relative">
                  <div>
                    <button
                      className="flex items-center text-sm rounded-default border-l-2 border-solid ml-30 border-gray-300 focus:outline-none"
                      id="user-menu"
                      aria-haspopup="true"
                      onClick={toggleProfileDetails}
                    >
                      <span className="sr-only">Open user menu</span>
                      <span className="flex font-medium text-xs text-black px-10">
                        {user.name}
                      </span>
                      <img
                        className="w-50 h-50 rounded-default"
                        style={{ objectFit: 'cover' }}
                        src={
                          user && user.avatar
                            ? user.avatar
                            : 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixqx=Lbaonh13vO&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                        }
                        alt=""
                      />
                    </button>
                  </div>
                  <div
                    className={`${
                      isProfileDetailsOpened
                        ? 'opacity-100 z-10'
                        : 'opacity-0 pointer-events-none'
                    } transition-opacity duration-150 ease-in-out origin-top-right absolute right-0 mt-10 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 overflow-hidden`}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <a
                      href="/profile"
                      className="block px-20 py-10 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      Your Profile
                    </a>
                    <Link
                      className="block px-20 py-10 text-sm text-gray-700 hover:bg-gray-100"
                      to={{
                        pathname: '/settings',
                        state: {
                          state: user,
                        },
                      }}
                    >
                      Setting
                    </Link>
                    <a
                      href="#"
                      className="block px-20 py-10 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      onClick={onLogout}
                    >
                      Sign out
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {Boolean(title || addAction) && (
          <div className="flex justify-start mb-30">
            <h2 className="text-3xl font-bold mr-20 text-black pr-20">
              {title}
            </h2>

            {addAction && (
              <button
                className="flex text-center justify-center items-center
              rounded-default px-20 py-10 text-sm text-white bg-primary
              hover:bg-secondary outline-none ml-auto"
                onClick={addAction.action}
              >
                <FaPlus />
                &nbsp;{addAction.title}
              </button>
            )}
          </div>
        )}
      </div>
      {children}
    </div>
  );
}
