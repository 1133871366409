import { CHAT_ENDPOINTS } from 'src/utils/endpoints';
import { authFetch, client, handleResponse } from '../helpers';

/**
 * @param {number} from
 * @param {number} to
 */
const getConversations = (loading = true) => {
  return client().get(CHAT_ENDPOINTS, loading);
};

/**
 * @param {any} data
 * @param {number} from
 * @param {number} to
 */
const getConversation = (data, loading = true) => {
  return client().post(CHAT_ENDPOINTS, data, loading);
};

/**
 * @param {number} messageId
 */
const markAsSeen = (messageId) => {
  return client().post(`${CHAT_ENDPOINTS}/seen/${messageId}`);
};

/**
 * @param {number} conversationId
 * @param {any} data
 */
const sendMessage = async (conversationId, { fileRef, formRef, message }) => {
  const formData = new FormData(formRef.current);

  formData.append('message', message);

  return authFetch(
    `${CHAT_ENDPOINTS}/${conversationId}`,
    {
      headers: {
        Accept: 'application/json',
      },
      method: 'post',
      body: formData,
    },
    false
  ).then(handleResponse);
};

/**
 * @param {number} messageId
 * @param {string} message
 */
const editMessage = (messageId, message) => {
  return client().post(`${CHAT_ENDPOINTS}/${messageId}`, { message });
};

/**
 * @param {number} messageId
 */
const deleteMessage = (messageId) => {
  return client().delete(`${CHAT_ENDPOINTS}/message/${messageId}`);
};

/**
 * @param {number} conversationId
 * @param {any} data
 */
const updateConversation = (conversationId, data) => {
  return client().put(CHAT_ENDPOINTS + '/' + conversationId, data);
};

/**
 * @param {number} conversationId
 */
const deleteConversation = (conversationId) => {
  return client().delete(CHAT_ENDPOINTS + '/' + conversationId);
};
const deleteConversationMy = (conversationId) => {
  return client().delete(CHAT_ENDPOINTS + '/' + 'my' + '/' + conversationId);
};

export const chatService = {
  getConversations,
  getConversation,
  sendMessage,
  editMessage,
  deleteMessage,
  markAsSeen,
  updateConversation,
  deleteConversation,
  deleteConversationMy,
};
