import React, { useState } from 'react';
import AccountForm from '../../components/AccountForm/AccountForm';
import ButtonBack from '../../components/ButtonBack/ButtonBack';
import AuthLayout from '../../components/commons/AuthLayout';
import Modal from '../../components/Modal';
import styles from './AddAccount.module.scss';

export default function AddAcount() {
  const [
    IsChangeAddAccountModalOpened,
    setIsChangeAddAccountModalOpened,
  ] = useState(true);
  const toggleChangeAddressModal = () => {
    setIsChangeAddAccountModalOpened(!IsChangeAddAccountModalOpened);
    //setSidebarVisible(false);
  };
  return (
    <section className={`${styles.AddAccount}`}>
      <Modal
        isOpen={IsChangeAddAccountModalOpened}
        onRequestClose={toggleChangeAddressModal}
        headerTitle="CRM Soul Group"
        className="modal--login"
      >
        <ButtonBack className="absolute left-0 top-40 justify-end pr-30" />

        <h2 className={`w-full text-2x font-bold text-black mt-30 mb-20`}>
          Add new account
        </h2>
        <p className={`w-full text-sm text-gray-400 mb-40`}>
          Lorem ipsum dolor sit amet, latine viderer ex nec, te has iusto
          tritani legimus, ad pri alii summo blandit.
        </p>
        <AccountForm />
      </Modal>
    </section>
  );
}
