import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FieldInput from '../../Form/components/FieldInput';
import Button from '../../Button';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import * as Yup from 'yup';
import { routes } from '../../../common/constants';
import { authActions } from '../../../common/actions';
import { useDispatch } from 'react-redux';

const ResetSchema = Yup.object().shape({
  token: Yup.string().required('This field is required'),
  password: Yup.string()
    .min(8, 'Enter minimium 8 characters')
    .required('This field is required'),
  password_confirmation: Yup.string()
    .min(8, 'Enter minimium 8 characters')
    .required('This field is required'),
});

const ResetPasswordForm = () => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(ResetSchema),
    mode: 'onChange',
  });

  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();

  const email = query.get('email');
  const token = query.get('token');

  const onSubmit = (data) => {
    dispatch(authActions.resetPasswordAndLogin({ ...data, email }, history));
  };

  if (!email) {
    history.push(routes.LOGIN.path);
    return <>Loading...</>;
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col sm:mb-50 md:mb-0 w-full"
      autoComplete="off"
    >
      <FieldInput
        register={register}
        name="token"
        type="token"
        errors={errors}
        labelText="Token"
        placeholder="Token you received via email"
        value={watch('token')}
        defaultValue={token ? token : undefined}
        autoComplete="off"
      />
      <FieldInput
        register={register}
        name="password"
        type="password"
        errors={errors}
        labelText="Password"
        placeholder="Your password"
        value={watch('password')}
        autoComplete="off"
      />
      <FieldInput
        register={register}
        name="password_confirmation"
        type="password"
        errors={errors}
        labelText="Confirm password"
        placeholder="Confirm new password"
        value={watch('password_confirmation')}
        autoComplete="off"
      />

      <div className={`mt-20 sm:mb-30`}>
        <Button
          variant="primary"
          height="44"
          width=""
          isLoading={isSubmitting}
          className="m-auto w-full"
        >
          Reset password & Sign in
        </Button>
      </div>

      {/* <div className="flex justify-center w-full">
        <NavLink to="/add-account">
          Not registered yet?{' '}
          <strong className="text-primary text-sm font-bold">
            Create a new account
          </strong>
        </NavLink>
      </div> */}
    </form>
  );
};

export default ResetPasswordForm;
