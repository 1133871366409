import React, { useState } from 'react';
import FieldInput from 'src/components/Form/components/FieldInput';
import { Editor } from 'react-draft-wysiwyg';
import Checkbox from 'src/components/Form/components/Checkbox';

const ProductForm = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const toggleIsEditMode = () => setIsEditMode(!isEditMode);

  const [isChecked, setIsChecked] = useState(false);
  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <form>
      <div className="bg-white rounded-default shadow-md py-10 px-20 mb-20">
        <div className="flex border-b-1 border-gray-200 px-20 -mx-20 items-center">
          <div className="w-2/5 py-10">
            <h4 className="text-sm font-semibold">
              Name<span className="text-red ml-2">*</span>
            </h4>
          </div>
          <div className="w-3/5 py-10">
            <FieldInput
              name="firstName"
              type="text"
              placeholder="Name"
              className="w-4/5 -mb-5"
              labelText=""
            />
          </div>
        </div>
        <div className="flex border-b-1 border-gray-200 px-20 -mx-20 items-center">
          <div className="w-2/5 py-20 ">
            <h4 className="text-sm font-semibold">Description</h4>
          </div>
          <div className="w-3/5 py-20">
            <Editor
              toolbarClassName="border-0 border-b-2 border-gray-200"
              wrapperClassName="w-4/5 border-2 border-gray-200 rounded-default p-5"
              editorClassName="text-xs px-10"
              toolbar={{
                options: ['inline', 'list'],
                inline: {
                  options: ['bold', 'italic', 'underline'],
                },
                list: {
                  options: ['unordered', 'ordered'],
                },
              }}
            />
          </div>
        </div>
        <div className="flex border-b-1 border-gray-200 px-20 -mx-20 items-center">
          <div className="w-2/5 py-20 ">
            <h4 className="text-sm font-semibold">URL</h4>
          </div>
          <div className="w-3/5 py-20">
            <FieldInput
              name="URL"
              type="text"
              placeholder="https://example.com"
              className="w-4/5 -mb-5"
              labelText=""
            />
          </div>
        </div>
        <div className="flex border-b-1 border-gray-200 px-20 -mx-20 items-center">
          <div className="w-2/5 py-20 ">
            <h4 className="text-sm font-semibold">Duration in months</h4>
          </div>
          <div className="w-3/5 py-20">
            <FieldInput
              name="months"
              type="text"
              placeholder="numbers"
              className="w-4/5 -mb-5"
              labelText=""
            />
          </div>
        </div>
        <div className="flex px-20 -mx-20 items-center">
          <div className="w-2/5 py-20 ">
            <h4 className="text-sm font-semibold">Active</h4>
          </div>
          <div className="w-3/5 py-20">
            <Checkbox onChange={handleChange} checked={isChecked} />
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <button
          className="bg-gray-500 hover:bg-gray-400 text-white text-sm p-10 px-20 rounded-default h-45 outline-none transition duration-500 mr-15"
          onClick={toggleIsEditMode}
        >
          {' '}
          Cancel
        </button>
        <button className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-45 outline-none outline-none transition duration-500 mr-15">
          {' '}
          Update and continue editing
        </button>
        <button className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-45 outline-none outline-none transition duration-500">
          {' '}
          Update user
        </button>
      </div>
    </form>
  );
};

export default ProductForm;
