import React, { useState } from 'react';
import { ReactComponent as SvgSearch } from '../../../../assets/icons/icon-search.svg';

const SearchInput = ({
  register,
  errors,
  name,
  placeholder,
  labelText = name,
  touched = false,
  value,
  className,
  ...inputProps
}) => {
  const [isHidden, setIsHidden] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const toggleIsHidden = () => setIsHidden(!isHidden);
  return (
    <div
      className={` ${className} flex flex-col
			
				${errors && errors[name] ? 'isInvalid' : ''} 
			`}
    >
      <div
        className={` flex w-full focus:shadow-outline h-50 relative placeholder-secondary flex-wrap `}
      >
        <SvgSearch className="absolute left-10 top-0 w-40 h-full text-gray-400 px-10 text-secondary" />
        <input
          className={`flex items-center w-full h-full text-xs px-20 pl-50 h-full rounded-default ${
            errors && name && errors[name]
              ? 'border-1 border-red bg-red bg-opacity-8'
              : 'border-1 border-gray-200 bg-white'
          }  outline-none ${isFocused ? 'border-1 border-primary' : ''} `}
          id={name}
          type="search"
          name={name}
          placeholder={placeholder}
          ref={register}
          defaultValue={value}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          {...inputProps}
        />
      </div>
      {errors && name && errors[name] && (
        <p
          data-testid={`${name}-input-error`}
          className="text-xs mt-5 text-red text-right"
        >
          {errors[name].message}
        </p>
      )}
    </div>
  );
};
export default SearchInput;
