import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { mailCampaignsActions } from '../../../../common/actions';
import { ReactComponent as SvgDelete } from './../../../../assets/icons/icon-delete-campaign.svg';
import { ReactComponent as SvgEdit } from './../../../../assets/icons/icon-edit-campaign.svg';
import { ReactComponent as SvgGraph } from './../../../../assets/icons/icon-statistics.svg';

import Modal from '../../../../components/Modal';
import Moment from 'moment';

const CampaignRow = ({ mailCampaign }) => {
  Moment.locale('en');
  const dt = `{mailCampaign.updated_at}`;
  const [
    isDeleteCampaignModalOpened,
    setIsDeleteCampaignModalOpened,
  ] = useState(false);
  const toggleDeleteModal = () => {
    setIsDeleteCampaignModalOpened(!isDeleteCampaignModalOpened);
  };
  const dispatch = useDispatch();
  const handleDeleteCampaign = () => {
    dispatch(mailCampaignsActions.deleteMailCampaign(mailCampaign.id));
    setIsDeleteCampaignModalOpened(!isDeleteCampaignModalOpened);
  };

  return (
    <>
      <tr>
        <td className="px-20 py-20 whitespace-nowrap">
          <div className="flex items-center">
            <div className="text-sm text-gray-500">
              <Link
                className="text-gray-500 hover:text-black hover:text-secondary flex items-center relative "
                to={{
                  pathname: '/statistic-campaign',
                  state: {
                    campaignStats: mailCampaign,
                  },
                }}
              >
                {mailCampaign.name}
              </Link>
            </div>
          </div>
        </td>
        <td className="px-20 py-20 whitespace-nowrap">
          <div className="text-sm text-gray-500">{mailCampaign.from}</div>
        </td>
        <td className="px-20 py-20 whitespace-nowrap">
          <div className="text-sm text-gray-500">Hatchbuck</div>
        </td>
        <td className="px-20 py-20 whitespace-nowrap text-sm text-gray-500">
          <div className="text-sm text-gray-500">{mailCampaign.subject}</div>
        </td>
        <td className="px-20 py-20 whitespace-nowrap text-sm text-gray-500">
          <div className="text-sm text-gray-500">Custom</div>
        </td>
        {/* <td className="px-20 py-20 whitespace-nowrap text-sm text-gray-500 text-right">
          <div className="text-sm text-gray-500">
            {Moment({ dt }).format('L')}
          </div>
        </td> */}
        <td
          style={{
            height: '60px',
          }}
          className=" flex justify-end items-center px-20 py-10 whitespace-nowrap text-right text-sm font-medium"
        >
          <Link
            className="text-gray-500 hover:text-gray-300 flex items-center justify-center relative"
            to={{
              pathname: '/statistic-campaign',
              state: {
                campaignStats: mailCampaign,
              },
            }}
          >
            <SvgGraph
              className="text-primary"
              fill="#0395FF"
              style={{ width: '18px', height: '18px' }}
            />
          </Link>
          {/* <Link
            className="text-gray-500 hover:text-gray-300 flex items-center justify-center relative"
            to={{
              pathname: '/edit-campaign',
              state: {
                editCampaign: mailCampaign,
              },
            }}
          >
            <SvgEdit className="text-primary" />
          </Link> */}
          <button
            type="button"
            onClick={toggleDeleteModal}
            className="text-gray-500 ml-10 hover:text-gray-300 flex items-center justify-center relative outline-none"
          >
            <SvgDelete className="text-primary" />
          </button>
        </td>
      </tr>
      <Modal
        isOpen={isDeleteCampaignModalOpened}
        onRequestClose={toggleDeleteModal}
        headerTitle="Delete Campaign"
        className="modal--alert"
      >
        <h2 className={`w-full text-2x font-bold text-black mt-50 mb-20`}>
          Delete Campaign?
        </h2>
        <div className="flex justify-between">
          <a
            className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-primary hover:bg-secondary"
            role="menuitem"
            onClick={handleDeleteCampaign}
          >
            Yes
          </a>
          <a
            className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-gray-500 hover:bg-gray-400"
            role="menuitem"
            onClick={toggleDeleteModal}
          >
            Cancel
          </a>
        </div>
      </Modal>
    </>
  );
};

export default CampaignRow;
