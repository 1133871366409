import React, { useState } from 'react';
import LayoutComponent from '../../components/commons/LayoutComponent';
import { ReactComponent as SvgDelete } from './../../assets/icons/icon-delete-campaign.svg';
import { ReactComponent as SvgEdit } from './../../assets/icons/icon-edit-audience.svg';
import Select from '../../components/Select/Select';
import FieldInput from '../../components/Form/components/FieldInput';
import { useDispatch, useSelector } from 'react-redux';
import { contactActions } from '../../common/actions';
import Modal from '../../components/Modal';

const OfferReport = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const toggleIsEditMode = () => setIsEditMode(!isEditMode);
  const dispatch = useDispatch();
  const [isDeleteContactModalOpened, setIsDeleteContactModalOpened] = useState(
    false
  );
  const toggleDeleteModal = () => {
    setIsDeleteContactModalOpened(!isDeleteContactModalOpened);
  };
  const handleDeleteContact = () => {
    dispatch(contactActions.deleteContact(contact.id));
    setIsDeleteContactModalOpened(!isDeleteContactModalOpened);
  };

  // const contact = useSelector((state) => state.contact.contact);

  const roleOptions = [
    { value: 'Manager', label: 'Manager' },
    { value: 'Finances', label: 'Finances' },
    { value: 'Sales Manager', label: 'Sales Manager' },
    { value: 'Sales Setter', label: 'Sales Setter' },
    { value: 'Sales Closer', label: 'Sales Closer' },
  ];

  const name = 'Kristian';
  const contact = 'Kristian';

  const onImageChange = (event) => {
    if (event.target && event.target.files[0]) {
      dispatch(
        contactActions.updateContactImage(contact.id, event.target.files[0])
      );
    }
  };

  const renderProfileImage = () => {
    if (contact.image) {
      return (
        <div className="relative w-full h-full">
          <img
            className="block w-full rounded-sm object-center object-cover h-full"
            src={contact.image}
            alt=""
          />
          <div
            className="profileIconHolder"
            style={{
              height: '44px',
              bottom: '20px',
              right: '20px',
              position: 'absolute',
              zIndex: '2',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <div
              className="profileIconHolderInner"
              style={{
                height: '44px',
                width: '44px',
                backgroundColor: 'white',
                borderRadius: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                boxShadow: '0px 5px 15px rgb(176 190 197 / 20%)',
              }}
            >
              <input
                type="file"
                onChange={onImageChange}
                accept="image/*"
                className="hidden"
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex items-center">
          <span className="bg-primary hover:bg-navyBlue text-white px-8 py-5 text-sm rounded-default outline-none transition duration-500">
            Choose a file
          </span>
          <input
            className="hidden"
            style={{ width: '100%' }}
            type="file"
            accept="image/*"
            onChange={onImageChange}
          />
          <p className="text-sm uppercase text-primary text-bold px-30 text-center">
            No image provided!
          </p>
        </div>
      );
    }
  };

  const getContentByMode = () => {
    if (isEditMode) {
      return (
        <form>
          <div className="bg-white rounded-default shadow-md py-10 px-20 mb-20">
            <div className="flex border-b-1 border-gray-200 px-20 -mx-20 items-center">
              <div className="w-2/5 py-10">
                <h4 className="text-sm font-semibold">Profile Picture</h4>
              </div>
              <div className="w-3/5 py-10">
                <label className="cursor-pointer">{renderProfileImage()}</label>
              </div>
            </div>
            <div className="flex border-b-1 border-gray-200 px-20 -mx-20 items-center">
              <div className="w-2/5 py-20 ">
                <h4 className="text-sm font-semibold">Role</h4>
              </div>
              <div className="w-3/5 py-20">
                <Select
                  showLabel={false}
                  name="status"
                  options={roleOptions}
                  placeholder="role"
                  defaultValue={{
                    value: roleOptions[0].value,
                    label: roleOptions[0].label,
                  }}
                  className="w-4/5 p-0 -mb-5"
                />
              </div>
            </div>
            <div className="flex border-b-1 border-gray-200 px-20 -mx-20 items-center">
              <div className="w-2/5 py-20 ">
                <h4 className="text-sm font-semibold">
                  Name<span className="text-red ml-2">*</span>
                </h4>
              </div>
              <div className="w-3/5 py-20">
                <FieldInput
                  name="firstName"
                  type="text"
                  placeholder="Name"
                  className="w-4/5 -mb-5"
                  labelText=""
                />
              </div>
            </div>
            <div className="flex border-b-1 border-gray-200 px-20 -mx-20 items-center">
              <div className="w-2/5 py-20 ">
                <h4 className="text-sm font-semibold">
                  E-mail adress<span className="text-red ml-2">*</span>
                </h4>
              </div>
              <div className="w-3/5 py-20">
                <FieldInput
                  name="email"
                  type="text"
                  placeholder="email@email.com"
                  className="w-4/5 -mb-5"
                  labelText=""
                />
              </div>
            </div>
            <div className="flex border-b-1 border-gray-200 px-20 -mx-20 items-center">
              <div className="w-2/5 py-20 ">
                <h4 className="text-sm font-semibold">Mobile Number</h4>
              </div>
              <div className="w-3/5 py-20">
                <FieldInput
                  labelText=""
                  name="mobile_number"
                  placeholder="+391 0000 000 0"
                  type="text"
                  className="w-4/5 -mb-5"
                />
              </div>
            </div>
            <div className="flex border-b-1 border-gray-200 px-20 -mx-20 items-center">
              <div className="w-2/5 py-20 ">
                <h4 className="text-sm font-semibold">Hubspot Meeting Link </h4>
              </div>
              <div className="w-3/5 py-20">
                <FieldInput
                  name="hubspot_meeting_link"
                  type="text"
                  placeholder="Hubspot Meeting Link"
                  className="w-4/5 -mb-5"
                  labelText=""
                />
              </div>
            </div>
            <div className="flex border-b-1 border-gray-200 px-20 -mx-20 items-center">
              <div className="w-2/5 py-20 ">
                <h4 className="text-sm font-semibold">Stripe Account Id</h4>
              </div>
              <div className="w-3/5 py-20">
                <FieldInput
                  name="stripe_account_id"
                  type="text"
                  placeholder="Stripe Account Id"
                  className="w-4/5 -mb-5"
                  labelText=""
                />
              </div>
            </div>
            <div className="flex px-20 -mx-20 items-center">
              <div className="w-2/5 py-20 ">
                <h4 className="text-sm font-semibold">Password</h4>
              </div>
              <div className="w-3/5 py-20">
                <FieldInput
                  name="password"
                  type="password"
                  placeholder="Password"
                  className="w-4/5 -mb-5"
                  labelText=""
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              className="bg-gray-500 hover:bg-gray-400 text-white text-sm p-10 px-20 rounded-default h-45 outline-none transition duration-500 mr-15"
              onClick={toggleIsEditMode}
            >
              {' '}
              Cancel
            </button>
            <button className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-45 outline-none outline-none transition duration-500 mr-15">
              {' '}
              Update and continue editing
            </button>
            <button className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-45 outline-none outline-none transition duration-500">
              {' '}
              Update user
            </button>
          </div>
        </form>
      );
    } else {
      return (
        <div className="bg-white rounded-default shadow-md py-10 px-20 mb-20">
          <div className="flex border-b-1 border-gray-200 px-20 -mx-20 items-center">
            <div className="w-2/5 py-10">
              <h4 className="text-sm font-semibold">ID</h4>
            </div>
            <div className="w-3/5 py-10">
              <p className="text-sm font-semibold">1</p>
            </div>
          </div>
          <div className="flex border-b-1 border-gray-200 px-20 -mx-20 ">
            <div className="w-2/5 py-20 ">
              <h4 className="text-sm font-semibold">Profile Picture</h4>
            </div>
            <div className="w-3/5 py-20">
              <p className="text-sm font-semibold">-</p>
            </div>
          </div>
          <div className="flex border-b-1 border-gray-200 px-20 -mx-20 ">
            <div className="w-2/5 py-20 ">
              <h4 className="text-sm font-semibold">Role</h4>
            </div>
            <div className="w-3/5 py-20">
              <p className="text-sm font-semibold">Manager</p>
            </div>
          </div>
          <div className="flex border-b-1 border-gray-200 px-20 -mx-20 ">
            <div className="w-2/5 py-20 ">
              <h4 className="text-sm font-semibold">Name</h4>
            </div>
            <div className="w-3/5 py-20">
              <p className="text-sm font-semibold">Kristian</p>
            </div>
          </div>
          <div className="flex border-b-1 border-gray-200 px-20 -mx-20 ">
            <div className="w-2/5 py-20 ">
              <h4 className="text-sm font-semibold">E-mail adress</h4>
            </div>
            <div className="w-3/5 py-20">
              <p className="text-sm font-semibold">crm@gmail.com</p>
            </div>
          </div>
          <div className="flex border-b-1 border-gray-200 px-20 -mx-20 ">
            <div className="w-2/5 py-20 ">
              <h4 className="text-sm font-semibold">Mobile Number</h4>
            </div>
            <div className="w-3/5 py-20">
              <p className="text-sm font-semibold">-</p>
            </div>
          </div>
          <div className="flex border-b-1 border-gray-200 px-20 -mx-20 ">
            <div className="w-2/5 py-20 ">
              <h4 className="text-sm font-semibold">Hubspot Meeting Link</h4>
            </div>
            <div className="w-3/5 py-20">
              <p className="text-sm font-semibold">-</p>
            </div>
          </div>
          <div className="flex px-20 -mx-20">
            <div className="w-2/5 py-20 ">
              <h4 className="text-sm font-semibold">Stripe Account Id</h4>
            </div>
            <div className="w-3/5 py-20">
              <p className="text-sm font-semibold">acct_1IGRyjRJkg2VCyHV</p>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <LayoutComponent>
      <div>
        <div className="flex items-center justify-between mb-20">
          <h1 className="text-2xl font-bold ">Offer details: {name}</h1>
          <div
            className={`
          ${isEditMode ? 'hidden' : 'flex'}`}
          >
            <button
              className="bg-white hover:bg-gray-300 text-black text-sm p-10 px-20 rounded-default h-45 outline-none transition duration-500 mr-15"
              onClick={toggleDeleteModal}
            >
              <SvgDelete />
            </button>
            <button
              className="bg-primary hover:bg-secondary text-sm p-10 px-20 rounded-default h-45 outline-none outline-none transition duration-500"
              onClick={toggleIsEditMode}
            >
              <SvgEdit className="text-white" />
            </button>
          </div>
        </div>
        <div>{getContentByMode()}</div>
        <Modal
          isOpen={isDeleteContactModalOpened}
          onRequestClose={toggleDeleteModal}
          headerTitle="Delete contact"
          className="modal--alert"
        >
          <h2 className={`w-full text-2x font-bold text-black mt-50 mb-20`}>
            Delete offer?
          </h2>
          <div className="flex justify-between">
            <button
              className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-primary hover:bg-secondary"
              role="menuitem"
              onClick={handleDeleteContact}
            >
              Yes
            </button>
            <button
              className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-gray-500 hover:bg-gray-400"
              role="menuitem"
              onClick={toggleDeleteModal}
            >
              Cancel
            </button>
          </div>
        </Modal>
      </div>
    </LayoutComponent>
  );
};

export default OfferReport;
