import { client } from '../helpers';
import {
  SEND_PASSWORD_RESET_LINK,
  PASSWORD_RESET_LINK,
  API_ENDPOINT,
  NOTIFICATIONS_ENDPOINT,
  USERS_ENDPOINT,
} from 'src/utils/endpoints';

const getUsers = async () => {
  return client().get(USERS_ENDPOINT, false);
};

/**
 * @param {{name: string; email:string; password:string;}} data
 */
const createUser = async (data) => {
  return client().post(`${USERS_ENDPOINT}/create`, data);
};

/**
 * @param {{email: string; password: string;}} loginData
 */
const loginUser = async (loginData) => {
  return client(false).post(`${API_ENDPOINT}/login`, loginData);
};

/**
 * @param {number} userId
 */
const fetchUserInfo = async (userId) => {
  return client().get(`${API_ENDPOINT}/users/${userId}`);
};

/**
 */
const fetchUsersInfo = async () => {
  return client().get(`${API_ENDPOINT}/users`);
};

/**
 * @param {string} email
 */
const sendPasswordResetLink = async (email) => {
  return client(false).post(SEND_PASSWORD_RESET_LINK, { email });
};

/**
 *
 * @param {{token: string; password:string; password_confirmation:string;email:string}} data
 * @returns
 */
const resetPassword = async (data) => {
  return client(false).post(PASSWORD_RESET_LINK, data);
};

/**
 * Getting more notifications service
 * @param {number} page
 * @param {Date} fromTime This is used for ignoring main notifications, so we dont get duplicates in res
 */
const getNotifications = async (page = 0, fromTime = false) => {
  return client().get(
    NOTIFICATIONS_ENDPOINT + `?page=${page}&fromTime=${fromTime}`,
    false
  );
};
const getSearchResults = async (data) => {
  return client().get(API_ENDPOINT + `/search?search=${data}`, false);
};

/**
 * @param {string} notificationId
 */
const readNotification = async (notificationId) => {
  return client().get(`${NOTIFICATIONS_ENDPOINT}/read/${notificationId}`);
};

const readAllNotification = async () => {
  return client().get(`${NOTIFICATIONS_ENDPOINT}/read-all`, false);
};
const deactivateUser = async () => {
  return client().patch(`${API_ENDPOINT}/me/deactivate`, false);
};
const reactivateUser = async (id) => {
  return client().patch(`${API_ENDPOINT}/recover/${id}`, false);
};

/**
 * @param {number[]} notificationIds
 */
const deleteNotifications = async (notificationIds) => {
  return client().delete(NOTIFICATIONS_ENDPOINT, { notificationIds }, false);
};

export const userService = {
  getUsers,
  getSearchResults,
  createUser,
  loginUser,
  deactivateUser,
  reactivateUser,
  fetchUserInfo,
  fetchUsersInfo,
  sendPasswordResetLink,
  resetPassword,
  getNotifications,
  readNotification,
  readAllNotification,
  deleteNotifications,
};
