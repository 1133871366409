import React, { useState } from 'react';
import ButtonBack from '../../components/ButtonBack/ButtonBack';
import LayoutComponent from '../../components/commons/LayoutComponent';
import ProductForm from '../Product/components/ProductForm';

export default function AddProduct() {
  return (
    <LayoutComponent title="Add new contact">
      <ButtonBack className="justify-end -mt-30" />
      <div className="flex flex-col mt-50">
        <ProductForm className="mt-50" />
      </div>
    </LayoutComponent>
  );
}
