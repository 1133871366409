import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, companiesActions } from 'src/common/actions';
import LayoutComponent from 'src/components/commons/LayoutComponent';
import UserModal from './components/UserModal';
import UserTable from './components/UserTable';

export default function Users() {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const users = useSelector((state) => state.auth.users);
  const companies = useSelector(
    (state) => state.companiesReducer?.companies?.list?.data
  );
  useEffect(() => {
    dispatch(authActions.fetchUsers());
    dispatch(companiesActions.getAllCompanies());
  }, []);

  const handleAddUser = () => {
    setModalVisible(true);
  };

  return (
    <LayoutComponent
      title="Users"
      pageTitle="Users"
      addAction={{ title: 'New user', action: handleAddUser }}
    >
      {users && companies && users.length > 0 && (
        <UserTable companies={companies} users={users} />
      )}
      <UserModal open={modalVisible} onClose={() => setModalVisible(false)} />
    </LayoutComponent>
  );
}
