import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { routes } from '../../common/constants';
import { tokenHelper } from '../../common/helpers';
import { authActions } from '../../common/actions';
import { notificationActions } from '../../common/actions/notificationActions';

function PrivateRoute({ component, ...rest }) {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);
  const isTokenExpired = tokenHelper.isTokenExpired();

  const handleLoadNotifications = () => {
    if (isUserLoggedIn) dispatch(notificationActions.getNotifications());
  };

  const handlePingNotifications = () => {
    if (isUserLoggedIn) dispatch(notificationActions.pingNotifications());
  };

  useEffect(() => {
    if (!isUserLoggedIn && !isTokenExpired) {
      dispatch(authActions.fetchUser());
    }

    handleLoadNotifications();

    const interval = setInterval(() => {
      handlePingNotifications();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [isUserLoggedIn]);

  const delegate = () => {
    if (isTokenExpired) {
      return <Redirect to={{ pathname: routes.LOGIN.path }} />;
    }
    if (isUserLoggedIn) {
      return <Route {...rest} component={component} />;
    }
    return null;
  };

  return delegate();
}

// PrivateRoute.propTypes = {
//   component: PropTypes.func.isRequired,
// };

export { PrivateRoute };
