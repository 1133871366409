export const notesActionTypes = {
  NOTES_GET_NOTES_REQUEST: 'NOTES_GET_NOTES_REQUEST',
  NOTES_GET_NOTES_SUCCESS: 'NOTES_GET_NOTES_SUCCESS',
  NOTES_GET_NOTES_FAILURE: 'NOTES_GET_NOTES_FAILURE',

  //TODO move this to notes constants
  NOTES_ADD_NOTE_REQUEST: 'NOTES_ADD_NOTE_REQUEST',
  NOTES_ADD_NOTE_SUCCESS: 'NOTES_ADD_NOTE_SUCCESS',
  NOTES_ADD_NOTE_FAILURE: 'NOTES_ADD_NOTE_FAILURE',

  NOTES_UPDATE_NOTE_REQUEST: 'NOTES_UPDATE_NOTE_REQUEST',
  NOTES_UPDATE_NOTE_SUCCESS: 'NOTES_UPDATE_NOTE_SUCCESS',
  NOTES_UPDATE_NOTE_FAILURE: 'NOTES_UPDATE_NOTE_FAILURE',

  NOTES_DELETE_NOTE_REQUEST: 'NOTES_DELETE_NOTE_REQUEST',
  NOTES_DELETE_NOTE_SUCCESS: 'NOTES_DELETE_NOTE_SUCCESS',
  NOTES_DELETE_NOTE_FAILURE: 'NOTES_DELETE_NOTE_FAILURE',
};

export const notesToastMessages = {
  [notesActionTypes.NOTES_ADD_NOTE_SUCCESS]: {
    message: 'Note added',
  },
  [notesActionTypes.NOTES_DELETE_NOTE_SUCCESS]: {
    message: 'Note deleted',
  },
  [notesActionTypes.NOTES_UPDATE_NOTE_SUCCESS]: {
    message: 'Note updated',
  },
};
