import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { contactActions } from '../../../../common/actions';
import Checkbox from '../../../../components/Form/components/Checkbox';
import { ReactComponent as SvgDelete } from './../../../../assets/icons/icon-delete-campaign.svg';
import { ReactComponent as SvgEdit } from './../../../../assets/icons/icon-edit-campaign.svg';
import { ReactComponent as SvgDocument } from './../../../../assets/icons/icon-document.svg';
import { ReactComponent as SvgCheck } from './../../../../assets/icons/check-icon.svg';
import { ReactComponent as SvgExit } from './../../../../assets/icons/exit-icon.svg';
import Modal from '../../../../components/Modal';
import { routes } from '../../../../common/constants';
import { useHistory } from 'react-router';
import Button from '../../../../components/Button';

const ProductsRow = ({ search, setSearchOffers, setIsEditMode }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isDeleteOfferModalOpened, setIsDeleteOfferModalOpened] = useState(
    false
  );
  const toggleDeleteModal = () => {
    setIsDeleteOfferModalOpened(!isDeleteOfferModalOpened);
  };

  const handleDeleteOffer = () => {
    dispatch(contactActions.deleteContact(data.offer_id));
    setIsDeleteOfferModalOpened(!isDeleteOfferModalOpened);
  };

  const data = {
    product_id: '1',
    product_name: 'Product1',
    product_URL: 'https://crm.com/',
    product_duration: '5',
    product_active: true,
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <tr>
        <td className="px-15 py-10 whitespace-nowrap">
          <div className="">
            <Checkbox onChange={handleChange} checked={isChecked} />
          </div>
        </td>
        <td className="px-15 py-10 whitespace-nowrap">
          <a className="text-sm text-blue-400 hover:text-navyBlue font-semibold">
            {data.product_id}
          </a>
        </td>
        <td className="px-15 py-10 whitespace-nowrap">
          <div className="text-sm text-gray-900">{data.product_name}</div>
        </td>
        <td className="px-15 py-10 whitespace-nowrap">
          <a
            href="#"
            className="whitespace-nowrap text-sm text-blue-400 hover:text-navyBlue font-semibold"
          >
            {data.product_URL}
          </a>
        </td>
        <td className="px-15 py-10 whitespace-nowrap">
          <div className="text-sm text-gray-900">{data.product_duration}</div>
        </td>
        <td className="px-15 py-10 whitespace-nowrap text-sm text-gray-500">
          <div className="flex justify-center items-center">
            {data.product_active === false ? (
              <div className="border-2 border-red rounded-full p-5">
                <SvgExit className="text-red" />
              </div>
            ) : (
              <div className="border-2 border-green-100 rounded-full p-3">
                <SvgCheck className="text-green-100" />
              </div>
            )}
          </div>
        </td>
        <td className="px-15 py-10 whitespace-nowrap text-sm font-medium h-full">
          <div className="flex justify-between">
            <a
              className="flex items-center justify-center relative focus:outline-none"
              href="/product"
            >
              <SvgDocument className="text-gray-500" />
            </a>

            <a
              className="flex items-center justify-center relative focus:outline-none"
              href="/product"
            >
              <SvgEdit />
            </a>

            <button
              type="button"
              onClick={toggleDeleteModal}
              className="text-gray-500 hover:text-gray-300 flex items-center justify-center relative outline-none"
            >
              <SvgDelete fill="#0395FF" />
            </button>
          </div>
        </td>
      </tr>

      <Modal
        isOpen={isDeleteOfferModalOpened}
        onRequestClose={toggleDeleteModal}
        headerTitle="Delete offer"
        className="modal--alert"
      >
        <h2 className={`w-full text-2x font-bold text-black mt-50 mb-20`}>
          Delete product?
        </h2>
        <div className="flex justify-between">
          <button
            className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-primary hover:bg-secondary"
            role="menuitem"
            onClick={handleDeleteOffer}
          >
            Yes
          </button>
          <button
            className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-gray-500 hover:bg-gray-400"
            role="menuitem"
            onClick={toggleDeleteModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ProductsRow;
