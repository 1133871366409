import React from 'react';
import LayoutComponent from '../../components/commons/LayoutComponent';
import OfferContent from './components/OffersContent';

const Offers = ({ value }) => {
  return (
    <LayoutComponent>
      <OfferContent />
    </LayoutComponent>
  );
};

export default Offers;
