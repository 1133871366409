import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import Button from 'src/components/Button';
import FieldInput from 'src/components/Form/components/FieldInput';
import Select from 'src/components/Select';
import Title from 'src/components/Tailwind/Title';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  // Company
  company_name: Yup.string().required('This field is required'),
  company_type: Yup.string().required('This field is required'),
  company_email: Yup.string().email().required('This field is required'),
  company_phone: Yup.string().required('This field is required'),

  // User name
  user_name: Yup.string().required('This field is required'),
  user_email: Yup.string().email().required('This field is required'),
  user_password: Yup.string()
    .required('This field is required')
    .min(6, 'Password must be longer then six characters'),
  user_password_confirmation: Yup.string()
    .required('This field is required')
    .min(6, 'Password must be longer then six characters'),
});

const companyTypes = [
  { value: 'super admin', label: 'super admin' },
  { value: 'admin', label: 'admin' },
  { value: 'user', label: 'user' },
];

export default function CompanyForm({ onSubmit }) {
  const {
    handleSubmit,
    watch,
    errors,
    register,
    control,
    getValues,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const formatSubmit = () => {
    const data = getValues();

    return onSubmit({
      ...data,
      company_type: data.company_type.value,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(formatSubmit)}>
        <Select
          name="company_type"
          labelText="Type"
          placeholder="Type"
          options={companyTypes}
          defaultValue={companyTypes[0]}
          // register={register}
          control={control}
          errors={errors}
          value={watch('company_type')}
        />
        <FieldInput
          name="company_name"
          type="text"
          labelText="Name"
          placeholder="Name"
          className="mb-10"
          value={watch('company_name')}
          register={register}
          errors={errors}
        />
        <FieldInput
          name="company_address"
          type="text"
          labelText="Address"
          placeholder="Address"
          className="mb-10"
          value={watch('company_address')}
          register={register}
          errors={errors}
        />
        <FieldInput
          name="company_zip_code"
          type="text"
          labelText="Zip code"
          placeholder="Zip code"
          className="mb-10"
          value={watch('company_zip_code')}
          register={register}
          errors={errors}
        />
        <FieldInput
          name="company_city"
          type="text"
          labelText="City"
          placeholder="City"
          className="mb-10"
          value={watch('company_city')}
          register={register}
          errors={errors}
        />
        <FieldInput
          name="company_country"
          type="text"
          labelText="Country"
          placeholder="Country"
          className="mb-10"
          value={watch('company_country')}
          register={register}
          errors={errors}
        />
        <FieldInput
          name="company_phone"
          type="phone"
          labelText="Phone"
          placeholder="Phone"
          className="mb-10"
          value={watch('company_phone')}
          register={register}
          errors={errors}
        />
        <FieldInput
          name="company_email"
          type="email"
          labelText="Email"
          placeholder="Email"
          className="mb-10"
          value={watch('company_email')}
          register={register}
          errors={errors}
        />

        <Title>User data</Title>

        <FieldInput
          name="user_name"
          type="text"
          labelText="Name"
          placeholder="Name"
          className="mb-10"
          value={watch('user_name')}
          register={register}
          errors={errors}
        />
        <FieldInput
          name="user_email"
          type="email"
          labelText="Email"
          placeholder="Email"
          className="mb-10"
          value={watch('user_email')}
          register={register}
          errors={errors}
        />
        <FieldInput
          name="user_password"
          type="password"
          labelText="Password"
          placeholder="Password"
          className="mb-10"
          value={watch('user_password')}
          register={register}
          errors={errors}
        />
        <FieldInput
          name="user_password_confirmation"
          type="password"
          labelText="Confirm password"
          placeholder="Confrim password"
          className="mb-10"
          value={watch('user_password_confirmation')}
          register={register}
          errors={errors}
        />

        <Button
          variant="primary"
          height="55"
          width="280"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          className="w-full"
        >
          Save Company
        </Button>
      </form>
    </>
  );
}
