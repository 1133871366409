import React, { memo, useState } from 'react';
import { ReactComponent as SvgDocument } from 'src/assets/icons/icon-document.svg';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { chatActions } from 'src/common/actions';
import ActionsMenu from 'src/components/Misc/ActionsMenu';
import { FileIcon } from 'react-file-icon';

const ChatMessageBubble = ({ isMy, message, last }) => {
  const [isMessageActionOpened, setMessageActionOpened] = useState(false);
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(chatActions.deleteMessage(message.id));
    setMessageActionOpened(false);
  };

  const direction = isMy ? 'outgoing' : 'incoming';

  const renderMessage = () => {
    if (message.type == 'upload') {
      const body = JSON.parse(message.body);

      let size, sizeString;

      if (body.size < 1000000) {
        size = Math.round(body.size / 100) / 10;
        sizeString = `${size} KB`;
      } else {
        size = Math.round(body.size / 100000) / 10;
        sizeString = `${size} MB`;
      }

      return (
        <a target="_blank" href={body.url} rel="noreferrer">
          <div className="flex">
            <div className="flex items-center justify-center bg-white p-15 rounded-default mr-20">
              <div style={{ width: 30 }}>
                <FileIcon extension={body.extension} />
              </div>
            </div>
            <div className="flex flex-col my-auto">
              <h3 className="text-black flex items-center w-full text-sm font-medium group-hover:text-white transition duration-300 ease-in-out">
                {body.name}
              </h3>

              <span className="text-gray-500 flex text-xxs font-normal">
                {sizeString}
              </span>
            </div>
          </div>
        </a>
      );
    }
    return <p>{message.body}</p>;
  };
  return (
    <div
      className={`${
        direction == 'outgoing' ? 'justify-end' : 'justify-start'
      } flex mb-10`}
    >
      <div>
        {!isMy && (!last || last.sender.id != message.sender.id) && (
          <h3 className="text-xxs font-medium mb-5">{message.sender.name}</h3>
        )}
        <div
          className={`${
            direction == 'outgoing'
              ? 'rounded-br-none bg-white border-1 border-gray-300'
              : 'bg-gray-300 rounded-tl-none'
          } flex flex-col text-xxs text-gray-500 rounded-default px-15 py-10 `}
        >
          {renderMessage()}
          <span className="text-gray-400 text-xxs ml-auto">
            {moment(message.created_at).format('HH:mm')}
          </span>
        </div>
      </div>
      {isMy && (
        <ActionsMenu
          className={direction == 'outgoing' ? 'order-first' : 'order-last'}
          visible={isMessageActionOpened}
          setVisible={setMessageActionOpened}
          actions={[{ label: 'Delete', action: handleDelete }]}
        />
      )}
    </div>
  );
};
export default memo(ChatMessageBubble);
