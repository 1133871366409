import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Checkbox from '../../../../components/Form/components/Checkbox/Checkbox';
import AudienceRow from '../AudienceRow/AudienceRow';
import PaginationBar from '../../../../components/commons/PaginationBar/PaginationBar';

const PickAudienceTable = ({
  search,
  pickedAudiences,
  setPickedAudiences,
  handleEditAudience,
  audiences,
}) => {
  const [active, setActive] = useState(false);
  const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
  const [disabledNext, setDisabledNext] = useState(false);
  const [disabledPrevious, setDisabledPrevious] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(7);

  const handleChange = (e, index) => {
    setActive(index);
  };

  const updatePickedAudiences = (audience, isChecked) => {
    if (isChecked) {
      setPickedAudiences([...pickedAudiences, audience]);
    } else {
      setPickedAudiences(
        pickedAudiences.filter(
          (audienceInState) => audienceInState.id !== audience.id
        )
      );
    }
  };

  const getAudiencesRenderList = () => {
    const ret = [];
    const pickedAudiencesIdStack = [];
    for (let i = 0; i < pickedAudiences.length; i++) {
      pickedAudiencesIdStack.push(pickedAudiences[i].id);
    }

    const searchNameLowerCase = search.toLowerCase();

    for (let i = 0; i < audiences.length; i++) {
      const currentAudience = audiences[i];
      const audienceNameLowerCase = currentAudience.name.toLowerCase();

      if (audienceNameLowerCase.indexOf(searchNameLowerCase) === -1) continue;

      ret.push({
        ...currentAudience,
        isSelected: pickedAudiencesIdStack.indexOf(currentAudience.id) !== -1,
      });
    }

    if (search.length > 0 && currentPaginationPage !== 1) {
      if (currentPaginationPage > Math.ceil(ret.length / itemsPerPage)) {
        setCurrentPaginationPage(1);
      }
    }

    return ret;
  };

  const handlePaginationPrevious = () => {
    const current = currentPaginationPage - 1;
    setCurrentPaginationPage(current);
    setDisabledNext(false);

    if (current === 1) {
      setDisabledPrevious(true);
    }
  };

  const handlePaginationNext = () => {
    const data = getAudiencesRenderList();
    const current = currentPaginationPage + 1;
    setCurrentPaginationPage(current);
    setDisabledPrevious(false);

    if (current === Math.ceil(data.length / itemsPerPage)) {
      setDisabledNext(true);
    }
  };

  const handlePaginationClick = (e) => {
    const data = getAudiencesRenderList();
    const clicked = Number(e.target.id);
    setCurrentPaginationPage(clicked);

    if (clicked === 1) {
      setDisabledPrevious(true);
      setDisabledNext(false);
    } else if (clicked === Math.ceil(data.length / itemsPerPage)) {
      setDisabledNext(true);
      setDisabledPrevious(false);
    } else {
      setDisabledNext(false);
      setDisabledPrevious(false);
    }
  };

  const calculateNumPage = () => {
    let data = getAudiencesRenderList();
    return data.length;
  };

  const checkPaginationDisplay = () => {
    let numberOfItems = calculateNumPage();
    if (numberOfItems > itemsPerPage) {
      return (
        <PaginationBar
          previous={handlePaginationPrevious}
          next={handlePaginationNext}
          click={handlePaginationClick}
          perPage={itemsPerPage}
          numPag={calculateNumPage()}
          activepagenum={currentPaginationPage}
          disabledprevious={disabledPrevious}
          disablednext={disabledNext}
          enableFirstLast="false"
        />
      );
    } else {
      return null;
    }
  };

  const handleSelectAll = () => {
    if (audiences.length === pickedAudiences.length) {
      setPickedAudiences([]);
    } else if (audiences.length > pickedAudiences.length) {
      let newArray = [];
      for (let i = 0; i < audiences.length; i++) {
        newArray.push(audiences[i]);
      }
      setPickedAudiences(newArray);
    }
  };

  const checkSelectAll = () => {
    if (audiences.length === pickedAudiences.length) {
      return true;
    } else {
      return false;
    }
  };

  const updateEditAudience = (audience) => {
    handleEditAudience(audience);
  };

  const renderTableContent = () => {
    const data = getAudiencesRenderList();

    const indexOfLastItem = currentPaginationPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentAudienceData = data.slice(indexOfFirstItem, indexOfLastItem);

    return (
      <tbody className="bg-white divide-y divide-gray-200">
        {currentAudienceData.map((audience, index) => {
          return (
            <AudienceRow
              audience={audience}
              updatePickedAudiences={updatePickedAudiences}
              isPreselected={audience.isSelected}
              handleEditAudience={updateEditAudience}
              key={index}
            />
          );
        })}
      </tbody>
    );
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-20 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xxs font-medium text-gray-400  tracking-wider"
                  >
                    <Checkbox
                      name="1"
                      labelText=""
                      onChange={handleSelectAll}
                      checked={checkSelectAll()}
                    />
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xxs font-medium text-gray-400  tracking-wider"
                  >
                    Group name
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xxs font-medium text-gray-400  tracking-wider"
                  >
                    People in group
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xxs font-medium text-gray-400  tracking-wider"
                  >
                    Average open rate
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xxs font-medium text-gray-400  tracking-wider"
                  >
                    Average click rate
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xxs font-medium text-gray-400  tracking-wider"
                  >
                    Favourite
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xxs font-medium text-gray-400  tracking-wider"
                  >
                    Created at
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xxs font-medium text-gray-400  tracking-wider"
                  >
                    Options
                  </th>
                </tr>
              </thead>
              {renderTableContent()}
            </table>
            {checkPaginationDisplay()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickAudienceTable;
