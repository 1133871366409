import React, { useEffect, useState } from 'react';

import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import { useSelector } from 'react-redux';
import CalendarItemModal from './CalendarItemModal';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

moment.locale('en', { week: { dow: 1 } });
const localizer = momentLocalizer(moment);

const rangeFormat = (range) =>
  `${moment(range.start).format('HH:mm')} - ${moment(range.end).format(
    'HH:mm'
  )}`;

const CalendarComponent = ({ view = 'month', setView }) => {
  const calendar = useSelector((state) => state.calendar);
  const [eventId, setEventId] = useState(null);

  const { state } = useLocation();

  const handleSelect = (event) => {
    setEventId(event.id);
  };

  const events = React.useMemo(
    () =>
      calendar.calendarItems.map((item) => ({
        ...item,
        start: new Date(item.start),
        end: new Date(item.end),
      })),
    [calendar.calendarItems]
  );

  useEffect(() => {
    if (state && state.eventId) setEventId(state.eventId);
  }, [state]);

  return (
    <>
      <div className="overflow-y-auto">
        <div style={{ height: 1050, minWidth: 800 }}>
          <BigCalendar
            className="ccal"
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={(data) => ({
              style: { ...data.style, '--borderColor': data.style.color },
            })}
            formats={{
              timeGutterFormat: 'HH:mm',
              agendaTimeFormat: 'HH:mm',
              weekdayFormat: 'dddd',
              agendaTimeRangeFormat: rangeFormat,
              eventTimeRangeFormat: rangeFormat,
            }}
            onSelectEvent={handleSelect}
            view={view}
            onView={setView}
          />
        </div>
      </div>
      {Boolean(eventId) && (
        <CalendarItemModal
          open={Boolean(eventId)}
          setOpen={() => setEventId(null)}
          eventId={eventId}
        />
      )}
    </>
  );
};

export default CalendarComponent;
