import React from 'react';

const Grid = ({ cols = 1, gap = 4, children, className }) => {
  return (
    <div
      className={`grid grid-cols-${cols} ${className ? className : ''}`}
      style={{ gap: 20 }}
    >
      {children}
    </div>
  );
};

export default Grid;
