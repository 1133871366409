export const mailCampaignsActionTypes = {
  MAIL_CAMPAIGNS_GET_ALL_MAIL_CAMPAIGNS_REQUEST:
    'MAIL_CAMPAIGNS_GET_ALL_MAIL_CAMPAIGNS_REQUEST',
  MAIL_CAMPAIGNS_GET_ALL_MAIL_CAMPAIGNS_SUCCESS:
    'MAIL_CAMPAIGNS_GET_ALL_MAIL_CAMPAIGNS_SUCCESS',
  MAIL_CAMPAIGNS_GET_ALL_MAIL_CAMPAIGNS_FAILURE:
    'MAIL_CAMPAIGNS_GET_ALL_MAIL_CAMPAIGNS_FAILURE',
  MAIL_CAMPAIGNS_GET_SINGLE_MAIL_CAMPAIGNS_REQUEST:
    'MAIL_CAMPAIGNS_GET_SINGLE_MAIL_CAMPAIGNS_REQUEST',
  MAIL_CAMPAIGNS_GET_SINGLE_MAIL_CAMPAIGNS_SUCCESS:
    'MAIL_CAMPAIGNS_GET_SINGLE_MAIL_CAMPAIGNS_SUCCESS',
  MAIL_CAMPAIGNS_GET_SINGLE_MAIL_CAMPAIGNS_FAILURE:
    'MAIL_CAMPAIGNS_GET_SINGLE_MAIL_CAMPAIGNS_FAILURE',
  MAIL_CAMPAIGNS_GET_MAIL_CAMPAIGN_REQUEST:
    'MAIL_CAMPAIGNS_GET_MAIL_CAMPAIGN_REQUEST',
  MAIL_CAMPAIGNS_GET_MAIL_CAMPAIGN_SUCCESS:
    'MAIL_CAMPAIGNS_GET_MAIL_CAMPAIGN_SUCCESS',
  MAIL_CAMPAIGNS_GET_MAIL_CAMPAIGN_FAILURE:
    'MAIL_CAMPAIGNS_GET_MAIL_CAMPAIGN_FAILURE',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_AUTOMATION_REQUEST:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_AUTOMATION_REQUEST',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_REQUEST:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_REQUEST',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_AUTOMATION_SUCCESS:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_AUTOMATION_SUCCESS',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SUCCESS:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SUCCESS',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_FAILURE:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_FAILURE',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_AUTOMATION_FAILURE:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_AUTOMATION_FAILURE',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_FAILURE:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_FAILURE',
  MAIL_CAMPAIGNS_EDIT_MAIL_CAMPAIGN_REQUEST:
    'MAIL_CAMPAIGNS_EDIT_MAIL_CAMPAIGN_REQUEST',
  MAIL_CAMPAIGNS_EDIT_MAIL_CAMPAIGN_SUCCESS:
    'MAIL_CAMPAIGNS_EDIT_MAIL_CAMPAIGN_SUCCESS',
  MAIL_CAMPAIGNS_EDIT_MAIL_CAMPAIGN_FAILURE:
    'MAIL_CAMPAIGNS_EDIT_MAIL_CAMPAIGN_FAILURE',
  MAIL_CAMPAIGNS_DELETE_MAIL_CAMPAIGN_REQUEST:
    'MAIL_CAMPAIGNS_DELETE_MAIL_CAMPAIGN_REQUEST',
  MAIL_CAMPAIGNS_DELETE_MAIL_CAMPAIGN_SUCCESS:
    'MAIL_CAMPAIGNS_DELETE_MAIL_CAMPAIGN_SUCCESS',
  MAIL_CAMPAIGNS_DELETE_MAIL_CAMPAIGN_FAILURE:
    'MAIL_CAMPAIGNS_DELETE_MAIL_CAMPAIGN_FAILURE',
  MAIL_CAMPAIGNS_GET_ALL_AUDIENCE_REQUEST:
    'MAIL_CAMPAIGNS_GET_ALL_AUDIENCE_REQUEST',
  MAIL_CAMPAIGNS_GET_ALL_AUDIENCE_SUCCESS:
    'MAIL_CAMPAIGNS_GET_ALL_AUDIENCE_SUCCESS',
  MAIL_CAMPAIGNS_GET_ALL_AUDIENCE_FAILURE:
    'MAIL_CAMPAIGNS_GET_ALL_AUDIENCE_FAILURE',
  MAIL_CAMPAIGNS_AUDIENCE_CLEAR_STATE: 'MAIL_CAMPAIGNS_AUDIENCE_CLEAR_STATE',
  MAIL_CAMPAIGNS_AUDIENCE_NEW_AUDIENCE_REQUEST:
    'MAIL_CAMPAIGNS_AUDIENCE_NEW_AUDIENCE_REQUEST',
  MAIL_CAMPAIGNS_AUDIENCE_NEW_AUDIENCE_SUCCESS:
    'MAIL_CAMPAIGNS_AUDIENCE_NEW_AUDIENCE_SUCCESS',
  MAIL_CAMPAIGNS_AUDIENCE_NEW_AUDIENCE_FAILURE:
    'MAIL_CAMPAIGNS_AUDIENCE_NEW_AUDIENCE_FAILURE',
  MAIL_CAMPAIGNS_AUDIENCE_NEW_AUDIENCE_CLEAR_STATE:
    'MAIL_CAMPAIGNS_AUDIENCE_NEW_AUDIENCE_CLEAR_STATE',
  MAIL_CAMPAIGNS_AUDIENCE_EDIT_AUDIENCE_REQUEST:
    'MAIL_CAMPAIGNS_AUDIENCE_EDIT_AUDIENCE_REQUEST',
  MAIL_CAMPAIGNS_AUDIENCE_EDIT_AUDIENCE_SUCCESS:
    'MAIL_CAMPAIGNS_AUDIENCE_EDIT_AUDIENCE_SUCCESS',
  MAIL_CAMPAIGNS_AUDIENCE_EDIT_AUDIENCE_FAILURE:
    'MAIL_CAMPAIGNS_AUDIENCE_EDIT_AUDIENCE_FAILURE',
  MAIL_CAMPAIGNS_AUDIENCE_EDIT_AUDIENCE_CLEAR_STATE:
    'MAIL_CAMPAIGNS_AUDIENCE_EDIT_AUDIENCE_CLEAR_STATE',

  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_AUDIENCE:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_AUDIENCE',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_CLEAR_AUDIENCE:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_CLEAR_AUDIENCE',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_FROM:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_FROM',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_CLEAR_FROM:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_CLEAR_FROM',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_SUBJECT:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_SUBJECT',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_CLEAR_SUBJECT:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_CLEAR_SUBJECT',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_TEMPLATE:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_TEMPLATE',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_TEMPLATE_AUTOMATIZATION:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_TEMPLATE_AUTOMATIZATION',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_CLEAR_TEMPLATE:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_CLEAR_TEMPLATE',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_BODY:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_BODY',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_CLEAR_BODY:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_CLEAR_BODY',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_SCHEDULE_DATE:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_SCHEDULE_DATE',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_CLEAR_SCHEDULE_DATE:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_CLEAR_SCHEDULE_DATE',
  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_CLEAR_STATE:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_CLEAR_STATE',

  MAIL_CAMPAIGNS_GET_ALL_MAIL_TEMPLATES_REQUEST:
    'MAIL_CAMPAIGNS_GET_ALL_MAIL_TEMPLATES_REQUEST',
  MAIL_CAMPAIGNS_GET_ALL_MAIL_TEMPLATES_SUCCESS:
    'MAIL_CAMPAIGNS_GET_ALL_MAIL_TEMPLATES_SUCCESS',
  MAIL_CAMPAIGNS_GET_ALL_MAIL_TEMPLATES_FAILURE:
    'MAIL_CAMPAIGNS_GET_ALL_MAIL_TEMPLATES_FAILURE',

  MAIL_CAMPAIGNS_GET_MAIL_TEMPLATE_REQUEST:
    'MAIL_CAMPAIGNS_GET_MAIL_TEMPLATE_REQUEST',
  MAIL_CAMPAIGNS_GET_MAIL_TEMPLATE_SUCCESS:
    'MAIL_CAMPAIGNS_GET_MAIL_TEMPLATE_SUCCESS',
  MAIL_CAMPAIGNS_GET_MAIL_TEMPLATE_FAILURE:
    'MAIL_CAMPAIGNS_GET_MAIL_TEMPLATE_FAILURE',

  MAIL_CAMPAIGNS_PREVIEW_EMAIL_REQUEST: 'MAIL_CAMPAIGNS_PREVIEW_EMAIL_REQUEST',
  MAIL_CAMPAIGNS_PREVIEW_EMAIL_SUCCESS: 'MAIL_CAMPAIGNS_PREVIEW_EMAIL_SUCCESS',
  MAIL_CAMPAIGNS_PREVIEW_EMAIL_FAILURE: 'MAIL_CAMPAIGNS_PREVIEW_EMAIL_FAILURE',

  MAIL_CAMPAIGNS_NEW_MAIL_TEMPLATE_REQUEST:
    'MAIL_CAMPAIGNS_NEW_MAIL_TEMPLATE_REQUEST',
  MAIL_CAMPAIGNS_NEW_MAIL_TEMPLATE_SUCCESS:
    'MAIL_CAMPAIGNS_NEW_MAIL_TEMPLATE_SUCCESS',
  MAIL_CAMPAIGNS_NEW_MAIL_TEMPLATE_FAILURE:
    'MAIL_CAMPAIGNS_NEW_MAIL_TEMPLATE_FAILURE',

  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_TEMPLATE:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_TEMPLATE',

  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_TEMPLATE_AUTOMATIZATION:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_TEMPLATE_AUTOMATIZATION',

  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_SCHEDULED_TIME:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_SCHEDULED_TIME',

  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_SUBJECT:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_SUBJECT',

  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_FROM:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_FROM',

  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_NAME:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_NAME',

  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_MESSAGE:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_MESSAGE',

  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_MESSAGE_AUTOMATIZATION:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_MESSAGE_AUTOMATIZATION',

  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_DESIGN_AUTOMATIZATION:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_DESIGN_AUTOMATIZATION',

  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_DESIGN:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_DESIGN',

  MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_INACTIVITY:
    'MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_INACTIVITY',

  MAIL_CAMPAIGNS_CLEAR_TEMPLATES_STATE: 'MAIL_CAMPAIGN_CLEAR_TEMPLATES_STATE',
  MAIL_CAMPAIGNS_GET_ALL_PRODUCTS_REQUEST:
    'MAIL_CAMPAIGNS_GET_ALL_PRODUCTS_REQUEST',
  MAIL_CAMPAIGNS_GET_ALL_PRODUCTS_SUCCESS:
    'MAIL_CAMPAIGNS_GET_ALL_PRODUCTS_SUCCESS',
  MAIL_CAMPAIGNS_GET_ALL_PRODUCTS_FAILURE:
    'MAIL_CAMPAIGNS_GET_ALL_PRODUCTS_FAILURE',
  MAIL_CAMPAIGNS_SEARCH_PRODUCTS_REQUEST:
    'MAIL_CAMPAIGNS_SEARCH_PRODUCTS_REQUEST',
  MAIL_CAMPAIGNS_SEARCH_PRODUCTS_SUCCESS:
    'MAIL_CAMPAIGNS_SEARCH_PRODUCTS_SUCCESS',
  MAIL_CAMPAIGNS_SEARCH_PRODUCTS_FAILURE:
    'MAIL_CAMPAIGNS_SEARCH_PRODUCTS_FAILURE',
  MAIL_CAMPAIGNS_AUDIENCE_ASSIGN_REQUEST:
    'MAIL_CAMPAIGNS_AUDIENCE_ASSIGN_REQUEST',
  MAIL_CAMPAIGNS_AUDIENCE_ASSIGN_SUCCESS:
    'MAIL_CAMPAIGNS_AUDIENCE_ASSIGN_SUCCESS',
  MAIL_CAMPAIGNS_AUDIENCE_ASSIGN_FAILURE:
    'MAIL_CAMPAIGNS_AUDIENCE_ASSIGN_FAILURE',
};

export const mailCampaignToastMessages = {
  [mailCampaignsActionTypes.MAIL_CAMPAIGNS_PREVIEW_EMAIL_SUCCESS]: {
    message: 'Preview sent',
  },
  [mailCampaignsActionTypes.MAIL_CAMPAIGNS_PREVIEW_EMAIL_FAILURE]: {
    message: 'Preview sent failure',
  },
  [mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SUCCESS]: {
    message: 'Campaign created',
  },
  [mailCampaignsActionTypes.MAIL_CAMPAIGNS_EDIT_MAIL_CAMPAIGN_SUCCESS]: {
    message: 'Campaign updated',
  },
  [mailCampaignsActionTypes.CONTACTS_EDIT_CONTACT_UPDATE]: {
    message: 'Campaign updated',
  },
  [mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_FAILURE]: {
    message: 'Campaign not created',
  },
  [mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_AUTOMATION_SUCCESS]: {
    message: 'Campaign automation created',
  },
  [mailCampaignsActionTypes.MAIL_CAMPAIGNS_EDIT_MAIL_CAMPAIGN_AUTOMATION_SUCCESS]: {
    message: 'Campaign automation updated',
  },
  [mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_AUTOMATION_FAILURE]: {
    message: 'Campaign automation not created',
  },
};
