import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as SvgSend } from '../../../../assets/icons/icon-send.svg';
import FieldTextarea from '../../../Form/components/FieldTextarea';
import CommentStatusButton from '../CommentStatusButton/CommentStatusButton';
import styles from './CommentForm.module.scss';
import { CirclePicker } from 'react-color';

const CommentForm = ({ onSubmit, sendingComment }) => {
  const { register, watch, errors, formState, reset, getValues } = useForm({
    mode: 'onChange',
  });

  const [isStatusActive, setStatusActive] = useState(false);
  const [commentColor, setCommentColor] = useState('#ffffff');

  const comment = watch('message');
  const commentTrimmed = String(comment || '').trim();

  const buttonDisabled = sendingComment || !commentTrimmed;

  const colors = ['#2ecc71', '#3498db', '#ff6b6b'];

  const handleCommentSubmit = (status = 'public') => {
    onSubmit(
      { ...getValues(), background_color: commentColor, status },
      { formState, reset }
    );
  };

  const handleChangeColor = (color) => {
    setCommentColor(color.hex);
  };

  return (
    <form
      noValidate
      onSubmit={handleCommentSubmit}
      className="flex w-full items-center border-t-1 border-gray-400 mt-20"
    >
      <p className="hidden"></p>
      <FieldTextarea
        register={register}
        name="message"
        defaultValue=""
        type="text"
        errors={errors}
        labelText=""
        placeholder="Type a comment here..."
        value={comment}
        className={`${styles.input} w-full`}
        disabled={sendingComment}
      />

      <CirclePicker
        onChangeComplete={handleChangeColor}
        colors={colors}
        color={commentColor}
      />

      <CommentStatusButton
        icon={<SvgSend />}
        onClick={handleCommentSubmit}
        loading={sendingComment}
        disabled={buttonDisabled}
        active={isStatusActive}
        setActive={setStatusActive}
        className="flex items-center justify-center px-10 bg-primary rounded relative w-50 h-50 rounded-full outline-none"
      />
    </form>
  );
};

export default CommentForm;
