import React from 'react';
import { Button, Grid, Box, InputLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { Alert } from '@material-ui/lab';
import { Divider } from 'rsuite';
import axios from 'axios';
import { API_ENDPOINT } from 'src/utils/endpoints';

export const EditComment = (props) => {
  const { name, handleClose, comment, handleRefreshComments } = props;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Formik
            initialValues={{ comment: comment.comment }}
            onSubmit={(
              values,
              { setSubmitting, resetForm },
              uri = `/deal-comments/${comment.id}`
            ) => {
              //let image = URL.createObjectURL(images[0].file);

              const data = {
                comment: values.comment,
              };
              axios
                .patch(`${API_ENDPOINT}${uri}`, data, {
                  headers: {
                    Authorization:
                      'Bearer' + localStorage.getItem('crm-client-token'),
                    Accept: 'application/json',
                  },
                })
                .then((response) => {
                  handleRefreshComments();
                  handleClose();
                  resetForm();
                })
                .catch((e) => {
                  console.log(e);
                });
              // alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validate={(values) => {
              const errors = {};
              if (!values.comment) {
                errors.comment = (
                  <Alert severity="error">Comment is required!</Alert>
                );
              }
              return errors;
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              resetForm,
            }) => (
              <form id="comment" onSubmit={handleSubmit}>
                <Divider style={{ marginTop: 5 }} />
                <Box className="text-xs text-gray-500 mb-8 mt-20">
                  Update comment
                </Box>
                <TextField
                  fullWidth
                  variant="standard"
                  id="comment"
                  value={values.comment}
                  onChange={handleChange}
                  placeholder="Comment"
                  className=" mb-20 overflow-hidden"
                  // InputProps={{
                  //   endAdornment: (
                  //     <Button
                  //       variant="contained"
                  //       color="primary"
                  //       className={'bg-primary'}
                  //       type="submit"
                  //       for="comment"
                  //       onClick={handleSubmit}
                  //       disabled={isSubmitting}
                  //     >
                  //       Send
                  //     </Button>
                  //   ),
                  // }}
                />
                <Box marginBottom="20px">
                  {errors.comment && touched.comment && errors.comment}
                </Box>
                <Grid
                  item
                  container
                  xs={12}
                  direction="column"
                  style={{
                    marginTop: 25,
                  }}
                >
                  <Grid item>
                    <Box display="flex" justifyContent="center">
                      <Box p={1}>
                        <Button
                          onClick={handleClose}
                          variant="outlined"
                          size="large"
                          type="button"
                          color="secondary"
                          style={{
                            backgroundColor: 'white',
                            marginRight: '10px',
                            color: '#FF5F52',
                            border: '1px solid #FF5F52',
                            boxShadow: 'none',
                          }}
                        >
                          Cancel
                        </Button>
                      </Box>
                      <Box p={1}>
                        <Button
                          color="primary"
                          size="large"
                          type="submit"
                          variant="contained"
                          onClick={() => handleSubmit()}
                          style={{
                            boxShadow: 'none',
                          }}
                        >
                          Confirm
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};
