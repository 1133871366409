export const API_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT || 'https://dev-api-crm.drsha.com/api'; // This should be set with env

export const API_SERVER_ENDPOINT =
  process.env.REACT_APP_API_SERVER || 'https://dev-api-crm.drsha.com'; // This should be set with env

export const USERS_ENDPOINT = API_ENDPOINT + '/users';
export const COMPANIES_ENDPOINT = API_ENDPOINT + '/companies';

export const SEND_PASSWORD_RESET_LINK = API_ENDPOINT + '/password/email';
export const PASSWORD_RESET_LINK = API_ENDPOINT + '/password/reset';

export const NOTES_ENDPOINT = API_ENDPOINT + '/notes';
export const NOTES_COMMENTS_ENDPOINT = API_ENDPOINT + '/note-comments';
export const NOTES_CATEGORIES_ENDPOINT =
  API_ENDPOINT + '/categories?type=notes';

export const CALENDAR_ITEM_ENDPOINT = API_ENDPOINT + '/calendar-items';
export const CALENDAR_POSSIBLE_ATTENDEES =
  API_ENDPOINT + '/calendar-possible-attendees';

export const NOTIFICATIONS_ENDPOINT = API_ENDPOINT + '/notifications';

export const CHAT_ENDPOINTS = API_ENDPOINT + '/conversations';
