import React, { useState } from 'react';
import { ReactComponent as SvgAvatars } from './../../../../assets/icons/icon-avatars.svg';
import { ReactComponent as SvgFrom } from './../../../../assets/icons/icon-mail-from.svg';
import { ReactComponent as SvgSubject } from './../../../../assets/icons/icon-mail-subject.svg';
import { ReactComponent as SvgEdit } from './../../../../assets/icons/icon-edit.svg';
import { BsCursor } from 'react-icons/bs';

const MailInput = ({
  register,
  errors,
  name,
  placeholder,
  type = 'text',
  labelText = name,
  touched = false,
  value,
  className,
  disableEdit,
  cursor = '',
  ...inputProps
}) => {
  const [isHidden, setIsHidden] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const toggleIsHidden = () => setIsHidden(!isHidden);
  return (
    <div
      className={`${className} mb-25 flex flex-col relative
			
				${errors && errors[name] ? 'isInvalid' : ''}  
			`}
    >
      <label
        className={`absolute flex left-40 top-15 text-gray-500 font-medium text-xs z-10`}
        htmlFor={name}
      >
        {labelText}
      </label>
      <div
        className={` flex w-full focus:shadow-outline h-50 relative placeholder-secondary`}
      >
        <input
          className={`flex items-center w-full h-full text-xs px-20 pl-120 h-full rounded-default ${
            errors && name && errors[name]
              ? 'border-2 border-red bg-red bg-opacity-8'
              : 'border-2 border-gray-200 bg-white'
          }outline-none ${isFocused ? 'border-2 border-gray-500' : ''} ${
            'cursor-' + cursor
          }`}
          id={name}
          type={type === 'password' ? (isHidden && 'password') || 'text' : type}
          name={name}
          placeholder={placeholder}
          ref={register}
          disabled={disableEdit ? 'disabled' : null}
          defaultValue={value}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          {...inputProps}
        />
        {name === 'audience' && (
          <SvgAvatars className="absolute left-15 top-15 w-lg text-secondary" />
        )}
        {name === 'from' && (
          <SvgFrom className="absolute left-15 top-15 w-lg text-secondary" />
        )}
        {name === 'name' && (
          <SvgEdit className="absolute left-15 top-15 w-lg text-secondary" />
        )}
        {name === 'subject' && (
          <SvgSubject className="absolute left-15 top-20 w-lg text-secondary" />
        )}
      </div>
      {errors && name && errors[name] && (
        <p
          data-testid={`${name}-input-error`}
          className="text-xs mt-5 text-red text-right"
        >
          {errors[name].message}
        </p>
      )}
    </div>
  );
};
export default MailInput;
