import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { calendarActions } from '../../common/actions/calendarActions';
import LayoutComponent from '../../components/commons/LayoutComponent';
import CalendarComponent from './components/CalendarComponent';
import CreateCalendarItemModal from './components/CreateCalendarItemModal';

const viewTypes = [
  {
    label: 'Month',
    value: 'month',
  },
  {
    label: 'Week',
    value: 'week',
  },
  {
    label: 'Day',
    value: 'day',
  },
  {
    label: 'Agenda',
    value: 'agenda',
  },
];

const buttonClassName =
  'flex text-center justify-center items-center rounded-default px-20 py-10 text-sm text-white border-1 border-transparent bg-primary hover:bg-secondary outline-none mr-10';

const buttonActiveClassName =
  'flex text-center justify-center items-center rounded-default px-20 py-10 text-sm text-dark border-1 border-primary  hover:bg-secondary hover:text-white outline-none mr-10';

const Calendar = () => {
  const dispatch = useDispatch();
  const isLoaded = useSelector((state) => state.contacts.isLoaded);

  const [isModalOpen, setModalOpen] = useState(false);
  const [view, setView] = useState('month');

  const handleAddCalendarItem = () => {
    setModalOpen(true);
  };

  const firstDate = moment().startOf('month').format('yyyy-MM-DD');
  const lastDate = moment().endOf('month').format('yyyy-MM-DD');

  useEffect(() => {
    if (!isLoaded)
      dispatch(calendarActions.getCalendarItems(firstDate, lastDate));
  }, []);

  return (
    <LayoutComponent>
      <div className="flex justify-start mb-30">
        <h2 className="text-3xl font-bold mr-20 text-black pr-20 border-r-1 border-gray-400">
          Calendar
        </h2>

        <div className="flex">
          {viewTypes.map((viewType) => (
            <button
              className={
                view === viewType.value
                  ? buttonClassName
                  : buttonActiveClassName
              }
              key={viewType.value}
              onClick={() => setView(viewType.value)}
            >
              {viewType.label}
            </button>
          ))}
        </div>
        <button
          className="flex text-center justify-center items-center
              rounded-default px-20 py-10 text-sm text-white bg-primary
              hover:bg-secondary outline-none ml-auto"
          onClick={handleAddCalendarItem}
        >
          <FaPlus />
          &nbsp;New item
        </button>
      </div>
      <CalendarComponent view={view} setView={setView} />
      <CreateCalendarItemModal open={isModalOpen} setOpen={setModalOpen} />
    </LayoutComponent>
  );
};

export default Calendar;
