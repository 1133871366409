import React from 'react';

const Toggler = ({
  errors,
  name,
  labelText,
  subtitleText = '',
  register,
  labelComponent,
  checked,
  className,
  ...inputProps
}) => {
  return (
    <div className={` ${className} relative flex flex-row items-end`}>
      {labelComponent ? (
        labelComponent
      ) : (
        <label
          className="flex items-center text-primary text-sm font-bold"
          htmlFor={name}
        >
          {labelText}
        </label>
      )}
      <div
        className={`${
          checked
            ? 'bg-primary border-primary justify-end'
            : 'bg-gray-200 border-gray-200'
        } cursor-pointer rounded-full border-2 w-50 h-50 flex flex-shrink-0  items-center`}
        style={{ maxHeight: '28px', height: '28px' }}
      >
        <input
          type="radio"
          className="opacity-0 absolute h-full w-full left-0 top-0 cursor-pointer "
          ref={register}
          name={name}
          id={name}
          {...inputProps}
          checked={checked}
        />
        <svg
          className={`rounded-full min-w-sm w-sm h-sm bg-white text-white scale-0 transition-all pointer-events-none`}
          viewBox="0 0 20 20"
        ></svg>
      </div>
    </div>
  );
};

export default Toggler;
