import React, { memo } from 'react';
import NotificationBlock from './NotificationBlock/NotificationBlock';

const NotificationGroup = ({ group, date, selected, setSelected }) => {
  const prettyDate = new Date(date).toDateString();

  return (
    <div className="py-30 px-15">
      <h3 className="font-bold text-xs tracking-wide text-gray-500 mb-25">
        {prettyDate}
      </h3>

      {group.map((notification) => (
        <NotificationBlock
          notification={notification}
          key={notification.id}
          {...{ selected, setSelected }}
        />
      ))}
    </div>
  );
};

export default memo(NotificationGroup);
