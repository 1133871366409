import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LayoutComponent from '../../components/commons/LayoutComponent';
import { CampaignContent } from './components/CampaignContent';
import { mailCampaignsActions } from '../../common/actions';

const Campaigns = () => {
  const dispatch = useDispatch();
  const areMailCampaignsLoaded = useSelector(
    (state) => state.mailCampaigns.areMailCampaignsLoaded
  );

  useEffect(() => {
    console.log('mount', areMailCampaignsLoaded);
    if (!areMailCampaignsLoaded) {
      dispatch(mailCampaignsActions.getAllMailCampaigns());
    }
  }, []);

  return (
    <LayoutComponent title="">
      {areMailCampaignsLoaded && <CampaignContent />}
    </LayoutComponent>
  );
};

export default Campaigns;
