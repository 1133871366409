import { API_ENDPOINT } from '../../utils/endpoints';
import { handleResponse, authFetch } from '../helpers';

const getAllMailCampaigns = () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(`${API_ENDPOINT}/campaign`, requestOptions).then(
    handleResponse
  );
};

const getSingleMailCampaigns = (id) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(`${API_ENDPOINT}/campaign/${id}`, requestOptions).then(
    handleResponse
  );
};

const getAllMailCampaignsFilter = (startDate = null, endDate = null) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let url = `${API_ENDPOINT}/campaignFilter`;
  if (startDate && endDate) url = url + '/' + startDate + '/' + endDate;
  return authFetch(url, requestOptions).then(handleResponse);
};

const getMailCampaign = (mailCampaignId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(
    `${API_ENDPOINT}/campaign/${mailCampaignId}`,
    requestOptions
  ).then(handleResponse);
};

const deleteMailCampaign = (mailCampaignId) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // TODO: IMPLEMENT
  return authFetch(
    `${API_ENDPOINT}/campaign/${mailCampaignId}`,
    requestOptions
  ).then(handleResponse);
};

const updateMailCampaign = (mailCampaign, mailCampaignId) => {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(mailCampaign),
  };

  // TODO: IMPLEMENT
  return authFetch(
    `${API_ENDPOINT}/campaign/${mailCampaignId}`,
    requestOptions
  ).then(handleResponse);
};

const createNewMailCampaign = (mailCampaignData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(mailCampaignData),
  };

  return authFetch(`${API_ENDPOINT}/campaign`, requestOptions).then(
    handleResponse
  );
};

const createNewMailCampaignAutomation = (mailCampaignData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(mailCampaignData),
  };

  return authFetch(`${API_ENDPOINT}/automation`, requestOptions).then(
    handleResponse
  );
};

const getAllAudiences = () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(`${API_ENDPOINT}/audience/`, requestOptions).then(
    handleResponse
  );
};

const postAudience = (audienceData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(audienceData),
  };

  return authFetch(`${API_ENDPOINT}/audience/`, requestOptions).then(
    handleResponse
  );
};

const putAudience = (audienceId, audienceData) => {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(audienceData),
  };

  return authFetch(
    `${API_ENDPOINT}/audience/${audienceId}`,
    requestOptions
  ).then(handleResponse);
};

const getAudience = (audienceId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(
    `${API_ENDPOINT}/audience/${audienceId}`,
    requestOptions
  ).then(handleResponse);
};

const addAudience = (audienceData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(audienceData),
  };

  return authFetch(`${API_ENDPOINT}/audience/`, requestOptions).then(
    handleResponse
  );
};

//TEMPLATES
const getAllMailTemplates = () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(`${API_ENDPOINT}/mail-templates`, requestOptions).then(
    handleResponse
  );
};

const getMailTemplate = (mailTemplateId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(
    `${API_ENDPOINT}/mail-templates/${mailTemplateId}`,
    requestOptions
  ).then(handleResponse);
};

const createNewMailTemplate = (mailTemplateData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(mailTemplateData),
  };

  return authFetch(`${API_ENDPOINT}/mail-templates`, requestOptions).then(
    handleResponse
  );
};

const previewEmailCampaign = (previewData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(previewData),
  };

  return authFetch(`${API_ENDPOINT}/campaign-preview`, requestOptions).then(
    handleResponse
  );
};

const deleteMailTemplate = (mailTemplateId) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // TODO: IMPLEMENT
  return authFetch(
    `${API_ENDPOINT}/mail-templates/${mailTemplateId}`,
    requestOptions
  ).then(handleResponse);
};
//PRODUCT TYPES

const getAllProducts = () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(`${API_ENDPOINT}/products/`, requestOptions).then(
    handleResponse
  );
};

const searchProducts = (query) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(
    `${API_ENDPOINT}/products/search/${query}`,
    requestOptions
  ).then(handleResponse);
};

const audienceAssign = (audienceData, id) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(audienceData),
  };

  return authFetch(`${API_ENDPOINT}/audienceAssign/${id}`, requestOptions).then(
    handleResponse
  );
};

export const mailCampaignsService = {
  getSingleMailCampaigns,
  getAllMailCampaigns,
  getAllMailCampaignsFilter,
  getMailCampaign,
  deleteMailCampaign,
  updateMailCampaign,
  createNewMailCampaign,
  createNewMailCampaignAutomation,
  getAllAudiences,
  postAudience,
  putAudience,
  getAudience,
  addAudience,
  getAllMailTemplates,
  getMailTemplate,
  createNewMailTemplate,
  previewEmailCampaign,
  deleteMailTemplate,
  getAllProducts,
  searchProducts,
  audienceAssign,
};
