import React from 'react';
import { calendarService } from '../../../common/services';
import Select from '../../../components/Select/Select';
import { useQuery } from 'react-query';

const formatter = (u) => ({
  label: u.fullName,
  value: u.id,
});

const CalendarItemAttendees = ({ control, register, attendees }) => {
  const {
    isLoading: isLoading,
    isSuccess: isSuccess,
    data: users = [],
  } = useQuery('users', () => calendarService.getPossibleAttendees());

  const usersMapped = React.useMemo(() => users.map(formatter));
  const defaultValueAssignedTo = (attendees || []).map(formatter);

  return (
    <>
      <Select
        labelText="Attendees"
        name="attendees"
        placeholder="Attendees"
        control={control}
        register={register}
        isMulti
        disabled={isLoading || !isSuccess}
        options={usersMapped}
        defaultValue={defaultValueAssignedTo}
      />
    </>
  );
};

export default CalendarItemAttendees;
