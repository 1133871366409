import { actionCreator } from '../helpers';
import { chatService } from '../services/chatService';

export const chatActionTypes = {
  // All conversations
  CHAT_GET_CONVERSATIONS_REQUEST: 'CHAT_GET_CONVERSATIONS_REQUEST',
  CHAT_GET_CONVERSATIONS_SUCCESS: 'CHAT_GET_CONVERSATIONS_SUCCESS',
  CHAT_GET_CONVERSATIONS_FAILURE: 'CHAT_GET_CONVERSATIONS_FAILURE',

  // Single conversation
  CHAT_GET_SINGLE_CONVERSATION_REQUEST: 'CHAT_GET_SINGLE_CONVERSATION_REQUEST',
  CHAT_GET_SINGLE_CONVERSATION_SUCCESS: 'CHAT_GET_SINGLE_CONVERSATION_SUCCESS',
  CHAT_GET_SINGLE_CONVERSATION_FAILURE: 'CHAT_GET_SINGLE_CONVERSATION_FAILURE',

  // Send message
  CHAT_SEND_MESSAGE_REQUEST: 'CHAT_SEND_MESSAGE_REQUEST',
  CHAT_SEND_MESSAGE_SUCCESS: 'CHAT_SEND_MESSAGE_SUCCESS',
  CHAT_SEND_MESSAGE_FAILURE: 'CHAT_SEND_MESSAGE_FAILURE',

  // Delete message
  CHAT_DELETE_MESSAGE_REQUEST: 'CHAT_DELETE_MESSAGE_REQUEST',
  CHAT_DELETE_MESSAGE_SUCCESS: 'CHAT_DELETE_MESSAGE_SUCCESS',
  CHAT_DELETE_MESSAGE_FAILURE: 'CHAT_DELETE_MESSAGE_FAILURE',

  // Ping conversation
  CHAT_PING_SINGLE_CONVERSATION_REQUEST:
    'CHAT_PING_SINGLE_CONVERSATION_REQUEST',
  CHAT_PING_SINGLE_CONVERSATION_SUCCESS:
    'CHAT_PING_SINGLE_CONVERSATION_SUCCESS',
  CHAT_PING_SINGLE_CONVERSATION_FAILURE:
    'CHAT_PING_SINGLE_CONVERSATION_FAILURE',

  // Update conversation
  CHAT_UPDATE_CONVERSATION_REQUEST: 'CHAT_UPDATE_CONVERSATION_REQUEST',
  CHAT_UPDATE_CONVERSATION_SUCCESS: 'CHAT_UPDATE_CONVERSATION_SUCCESS',
  CHAT_UPDATE_CONVERSATION_FAILURE: 'CHAT_UPDATE_CONVERSATION_FAILURE',

  // Delete conversation
  CHAT_DELETE_CONVERSATION_REQUEST: 'CHAT_DELETE_CONVERSATION_REQUEST',
  CHAT_DELETE_CONVERSATION_SUCCESS: 'CHAT_DELETE_CONVERSATION_SUCCESS',
  CHAT_DELETE_CONVERSATION_FAILURE: 'CHAT_DELETE_CONVERSATION_FAILURE',
};

const getConversation = (ids, name = null) => (dispatch) => {
  dispatch(actionCreator(chatActionTypes.CHAT_GET_SINGLE_CONVERSATION_REQUEST));

  chatService
    .getConversation({ name, ids }, true)
    .then((res) => {
      dispatch(
        actionCreator(chatActionTypes.CHAT_GET_SINGLE_CONVERSATION_SUCCESS, res)
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(chatActionTypes.CHAT_GET_SINGLE_CONVERSATION_FAILURE, {
          error: err,
        })
      );
    });
};

const pingConversation = (conversationId) => (dispatch) => {
  dispatch(
    actionCreator(chatActionTypes.CHAT_PING_SINGLE_CONVERSATION_REQUEST)
  );

  chatService
    .getConversation({ ids: conversationId }, false)
    .then((res) => {
      dispatch(
        actionCreator(
          chatActionTypes.CHAT_PING_SINGLE_CONVERSATION_SUCCESS,
          res
        )
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(chatActionTypes.CHAT_PING_SINGLE_CONVERSATION_FAILURE, {
          error: err,
        })
      );
    });
};

const getConversations = (loading = true) => (dispatch) => {
  dispatch(actionCreator(chatActionTypes.CHAT_GET_CONVERSATIONS_REQUEST));

  chatService
    .getConversations(loading)
    .then((res) => {
      dispatch(
        actionCreator(chatActionTypes.CHAT_GET_CONVERSATIONS_SUCCESS, res)
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(chatActionTypes.CHAT_GET_CONVERSATIONS_FAILURE, {
          error: err,
        })
      );
    });
};

const sendMessage = (data, onSuccess, onFail) => (dispatch) => {
  const { conversationId, ...other } = data;

  dispatch(actionCreator(chatActionTypes.CHAT_SEND_MESSAGE_REQUEST));

  chatService
    .sendMessage(conversationId, other)
    .then((res) => {
      dispatch(actionCreator(chatActionTypes.CHAT_SEND_MESSAGE_SUCCESS, res));
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      dispatch(
        actionCreator(chatActionTypes.CHAT_SEND_MESSAGE_FAILURE, {
          error: err,
        })
      );
      if (onFail) onFail(err);
    });
};

const deleteMessage = (messageId, onSuccess, onFail) => (dispatch) => {
  dispatch(actionCreator(chatActionTypes.CHAT_DELETE_MESSAGE_REQUEST));

  chatService
    .deleteMessage(messageId)
    .then(() => {
      dispatch(
        actionCreator(chatActionTypes.CHAT_DELETE_MESSAGE_SUCCESS, messageId)
      );
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      dispatch(
        actionCreator(chatActionTypes.CHAT_DELETE_MESSAGE_FAILURE, {
          error: err,
        })
      );
      if (onFail) onFail(err);
    });
};

const updateConversation = (conversationId, data, onSuccess, onFail) => (
  dispatch
) => {
  dispatch(actionCreator(chatActionTypes.CHAT_UPDATE_CONVERSATION_REQUEST));

  chatService
    .updateConversation(conversationId, data)
    .then((res) => {
      dispatch(
        actionCreator(chatActionTypes.CHAT_UPDATE_CONVERSATION_SUCCESS, res)
      );
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      dispatch(
        actionCreator(chatActionTypes.CHAT_UPDATE_CONVERSATION_FAILURE, {
          error: err,
        })
      );

      if (onFail) onFail(err);
    });
};

const deleteConversation = (conversationId, onSuccess, onFail) => (
  dispatch
) => {
  dispatch(actionCreator(chatActionTypes.CHAT_DELETE_CONVERSATION_REQUEST));

  chatService
    .deleteConversation(conversationId)
    .then(() => {
      dispatch(
        actionCreator(
          chatActionTypes.CHAT_DELETE_CONVERSATION_SUCCESS,
          conversationId
        )
      );
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      dispatch(
        actionCreator(chatActionTypes.CHAT_DELETE_CONVERSATION_FAILURE, {
          error: err,
        })
      );
      if (onFail) onFail();
    });
};
const deleteConversationMy = (conversationId, onSuccess, onFail) => (
  dispatch
) => {
  dispatch(actionCreator(chatActionTypes.CHAT_DELETE_CONVERSATION_REQUEST));

  chatService
    .deleteConversationMy(conversationId)
    .then(() => {
      dispatch(
        actionCreator(
          chatActionTypes.CHAT_DELETE_CONVERSATION_SUCCESS,
          conversationId
        )
      );
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      dispatch(
        actionCreator(chatActionTypes.CHAT_DELETE_CONVERSATION_FAILURE, {
          error: err,
        })
      );
      if (onFail) onFail();
    });
};

export const chatActions = {
  getConversation,
  pingConversation,
  getConversations,
  sendMessage,
  deleteMessage,
  updateConversation,
  deleteConversation,
  deleteConversationMy,
};
