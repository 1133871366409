import React from 'react';
import { Children } from 'react';

const SettingsBox = ({ title, children, className, subtitle }) => {
  return (
    <div
      className={`${className} bg-white rounded-default shadow-md p-20 flex-none`}
    >
      <h3 className="text-md font-bold">{title}</h3>
      <h5 className="text-xsm text-gray-400 mb-20">{subtitle}</h5>
      <div className="mb-50">{children}</div>
    </div>
  );
};

export default SettingsBox;
