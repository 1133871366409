import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
const initData = [
  {
    service: 'Mails',
    mailing: 450,
    mailingColor: 'hsl(208, 70%, 50%)',
  },
  {
    service: 'Tasks',
    mailing: 78,
    mailingColor: 'hsl(208, 70%, 50%)',
    contacts: 145,
    contactsColor: 'hsl(208, 71%, 55%)',
    tasks: 101,
    tasksColor: 'hsl(208, 69%, 63%)',
  },
  {
    service: 'Campaigns',
    mailing: 170,
    mailingColor: 'hsl(208, 70%, 50%)',
    contacts: 154,
    contactsColor: 'hsl(208, 71%, 55%)',
    tasks: 8,
    tasksColor: 'hsl(208, 69%, 63%)',
  },
];

const BarGraph = ({
  title,
  excerpt,
  graphData,
  keysData,
  fillData,
  legendTitleBottom,
  legendTitleLeft,
  className,
}) => {
  let keys;
  let data;
  let fill;
  let legendBottom;
  let legendLeft;

  if (legendTitleLeft) legendLeft = legendTitleLeft;
  else legendLeft = 'food';

  if (legendTitleBottom) legendBottom = legendTitleBottom;
  else legendBottom = 'Services';

  if (keysData) keys = keysData;
  else keys = ['mailing', 'contacts', 'tasks'];

  if (!graphData) data = initData;
  else data = graphData;

  if (fillData) fill = fillData;
  else
    fill = [
      {
        match: {
          id: 'notes',
        },
        id: 'dots',
      },
      {
        match: {
          id: 'contacts',
        },
        id: 'lines',
      },
    ];
  return (
    <div
      className={`${className} flex flex-col bg-white py-40 px-30 rounded-default mt-50`}
      style={{ height: '500px' }}
    >
      <div className="flex  mb-40 flex-col">
        <h2 className={`text-xl font-bold text-gray-500`}>{title}</h2>
        <p className={`text-sm text-gray-400`}>{excerpt}</p>
      </div>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy="service"
        margin={{
          top: 10,
          right: 10,
          bottom: 50,
          left: 10,
        }}
        padding={0.3}
        colors={({ id, data }) => data[`${id}Color`]}
        colorBy="id"
        fill={fillData}
        borderColor="inherit:darker(1.6)"
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: legendBottom,
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: legendTitleLeft,
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="inherit:darker(1.6)"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  );
};

export { BarGraph };
