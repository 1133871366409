import React from 'react';
import FieldInput from '../Form/components/FieldInput';
import Button from '../Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import Select from '../Select/Select';

const AccountSchema = Yup.object().shape({
  username: Yup.string()
    .required('This field is required')
    .min(2, 'Username must be longer then two characters'),
  password: Yup.string()
    .required('This field is required')
    .min(6, 'Password must be longer then sixt characters'),
  email: Yup.string().email().required('This field is required'),
  phone: Yup.string().required('This field is required'),
  gender: Yup.object().required('Obavezno polje'),
  address: Yup.string().required('This field is required'),
  terms: Yup.string().required('Obavezno polje'),
});

const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Other', label: 'Other' },
];

const AccountForm = (className) => {
  const { register, handleSubmit, watch, errors, control } = useForm({
    resolver: yupResolver(AccountSchema),
    mode: 'onChange',
  });
  const history = useHistory();
  //const { user } = useUser();

  const onSubmit = (data) => {
    history.push('/');
  };
  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} className={`pb-50`}>
      <FieldInput
        labelText="Username"
        register={register}
        name="username"
        defaultValue=""
        type="text"
        errors={errors}
        value={watch('username')}
      />
      <FieldInput
        labelText="Password"
        register={register}
        name="password"
        defaultValue=""
        type="text"
        errors={errors}
        value={watch('password')}
      />
      <FieldInput
        labelText="Email"
        register={register}
        name="email"
        defaultValue=""
        type="text"
        errors={errors}
        value={watch('email')}
      />
      <FieldInput
        className="mb-40"
        labelText="Phone number"
        register={register}
        name="phone"
        defaultValue=""
        type="phone"
        errors={errors}
        value={watch('phone')}
      />
      <FieldInput
        labelText="Address"
        register={register}
        name="address"
        defaultValue=""
        type="text"
        errors={errors}
        value={watch('address')}
      />
      <Select
        labelText="Gender"
        name="gender"
        options={genderOptions}
        placeholder="Gender"
        register={register}
        control={control}
        value={watch('gender')}
      />
      <Button variant="primary" height="55" width="240" onClick={handleSubmit}>
        Add account
      </Button>
    </form>
  );
};
export default AccountForm;
