import React from 'react';
import LayoutComponent from '../../components/commons/LayoutComponent';
import NotificationContent from './Components';

const NotificationList = () => {
  return (
    <LayoutComponent title="Notification List">
      <NotificationContent />
    </LayoutComponent>
  );
};

export default NotificationList;
