import React, { useState } from 'react';
import SearchInput from 'src/components/Form/components/SearchInput';
import ChatContact from '../ChatContact';
import styles from '../ChatContent.module.scss';

export default function ChatContacts({ chatUsers, onSelect }) {
  const [search, setSearch] = useState('');

  const filtered = search
    ? chatUsers.filter(
        (i) =>
          String(i.name)
            .toLocaleLowerCase()
            .search(search.toLocaleLowerCase()) !== -1
      )
    : chatUsers;

  return (
    <>
      <SearchInput
        name="search"
        type="text"
        labelText="search"
        placeholder="Search..."
        className={`${styles.search} mt-20 mb-30`}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {filtered.map((contact, index) => {
        return (
          <ChatContact contact={contact} key={index} onSelect={onSelect} />
        );
      })}
    </>
  );
}
