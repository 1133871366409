import { authActionTypes } from '../constants';

const initialState = {
  user: {},
  users: [],
  isUserLoggingIn: false,
  isUserLoggedIn: false,
  error: null,
};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case authActionTypes.AUTH_LOGIN_USER_REQUEST:
      return {
        ...state,
        isUserLoggedIn: false,
        isUserLoggingIn: true,
      };
    case authActionTypes.AUTH_LOGIN_USER_SUCCESS:
      return {
        ...state,
        isUserLoggedIn: true,
        isUserLoggingIn: false,
        user: action.payload.user,
      };
    case authActionTypes.AUTH_LOGIN_USER_FAILURE:
      return {
        ...state,
        isUserLoggedIn: false,
        isUserLoggingIn: false,
        error: action.payload.error,
      };
    case authActionTypes.AUTH_FETCH_USER_INFO_REQUEST:
      return {
        ...state,
        isUserLoggedIn: false,
        isUserLoggingIn: true,
      };
    case authActionTypes.AUTH_FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        isUserLoggedIn: true,
        isUserLoggingIn: false,
      };
    case authActionTypes.AUTH_FETCH_USERS_INFO_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
      };
    case authActionTypes.AUTH_FETCH_USER_INFO_FAILURE:
      return {
        ...state,
        isUserLoggingIn: false,
        isUserLoggedIn: false,
      };
    case authActionTypes.AUTH_CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
