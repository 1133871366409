import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { contactActions } from '../../.././../common/actions';
import { ReactComponent as SvgDelete } from './../../../../assets/icons/icon-delete-campaign.svg';
import { ReactComponent as SvgEdit } from './../../../../assets/icons/icon-edit-campaign.svg';
import Modal from '../../../../components/Modal';
import { routes } from '../../../../common/constants';
import { API_ENDPOINT } from 'src/utils/endpoints';
import { useHistory } from 'react-router';
import moment from 'moment';
import axios from 'axios';
import { Input } from '@material-ui/core';

const TagsRow = ({
  tag,
  search,
  setSearchTags,
  handleEdit,
  edit,
  editId,
  setEditId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [inputValue, setInputValue] = useState(tag.name);
  const [isDeleteTagModalOpened, setIsDeleteTagModalOpened] = useState(false);
  const toggleDeleteModal = (id) => {
    setIsDeleteTagModalOpened(!isDeleteTagModalOpened);
    if (id) {
      setEditId(id);
    }
  };
  const handleSetInput = (event) => {
    if (event.target.value != '') {
      setInputValue(event.target.value);
    }
  };
  const handleDeleteTag = async () => {
    await axios
      .delete(`${API_ENDPOINT}/tags/${editId}`, {
        headers: {
          Authorization: 'Bearer' + localStorage.getItem('crm-client-token'),
          Accept: 'application/json',
        },
      })
      .then(() => {
        setIsDeleteTagModalOpened(false);
        dispatch(contactActions.getAllTags());
      })
      .catch((e) => {
        let errors = e.response.data.message + '\n';
        for (let error in e.response.data.errors) {
          errors += e.response.data.errors[error][0] + '\n';
        }
        // setAlertMessage(errors);
        // handleAlertOpen();
      });
  };

  return (
    <>
      <tr>
        <td
          colSpan="4"
          className="px-20 py-10 whitespace-nowrap cursor-pointer"
        >
          <div className="flex items-center">
            <div className="text-sm font-medium text-gray-900">
              {edit && editId == tag.id ? (
                <Input
                  autoFocus
                  onChange={(event) => handleSetInput(event)}
                  value={inputValue}
                  className="text-sm font-medium text-gray-900"
                />
              ) : (
                '#' + tag.name
              )}
            </div>
          </div>
        </td>
        <td className="px-20 py-10 whitespace-nowrap">
          <span className="px-20 py-10 whitespace-nowrap text-sm text-gray-500">
            {moment(tag.created_at).format('DD.MM.YYYY. hh:mm a')}
          </span>
        </td>
        <td className="px-20 py-10 whitespace-nowrap text-sm font-medium h-full">
          <div className="flex justify-end">
            <button
              className="text-gray-500 hover:text-gray-300 flex items-center justify-center relative mr-10"
              onClick={() => {
                if (edit) {
                  handleEdit(tag.id, inputValue);
                } else {
                  handleEdit(tag.id);
                }
              }}
            >
              <SvgEdit className="text-primary w-sm" />
            </button>
            <button
              type="button"
              onClick={() => toggleDeleteModal(tag.id)}
              className="text-gray-500 hover:text-gray-300 flex items-center justify-center relative outline-none"
            >
              <SvgDelete className="text-primary w-sm" />
            </button>
          </div>
        </td>
      </tr>

      <Modal
        isOpen={isDeleteTagModalOpened}
        onRequestClose={toggleDeleteModal}
        headerTitle="Delete tag"
        className="modal--alert"
      >
        <h2 className={`w-full text-2x font-bold text-black mt-50 mb-20`}>
          Delete the tag?
        </h2>
        <div className="flex justify-between">
          <button
            className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-primary hover:bg-secondary"
            role="menuitem"
            onClick={handleDeleteTag}
          >
            Yes
          </button>
          <button
            className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-gray-500 hover:bg-gray-400"
            role="menuitem"
            onClick={toggleDeleteModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default TagsRow;
