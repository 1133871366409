import { useHistory } from 'react-router-dom';
import { routes } from '../common/constants';

export const useNotificationData = (item) => {
  const history = useHistory();

  switch (item.action) {
    case 'invitation':
      return [
        `${item.author} invited you to ${item.name}`,
        () => {
          history.push({
            pathname: routes.CALENDAR.path,
            state: { eventId: item.id },
          });
        },
      ];
    default:
      return ['', () => null];
  }
};
