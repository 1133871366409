import React, { useEffect, useState } from 'react';
import Button from '../../../components/Button';
import FieldInput from '../../../components/Form/components/FieldInput';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FieldTextarea from '../../../components/Form/components/FieldTextarea';
import { ReactComponent as SvgCalendar } from './../../../assets/icons/icon-calendar.svg';
import ReactDatePicker from 'react-datepicker';
import Checkbox from '../../../components/Form/components/Checkbox/Checkbox';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import en_GB from 'date-fns/locale/en-GB';
// import { ImageUpload } from '../../../components/Misc/ImageUpload';
import Grid from '../../../components/Tailwind/Grid/Grid';
import Select from '../../../components/Select/Select';
import { calendarService } from '../../../common/services';
import CalendarItemAttendees from './CalendarItemAttendees';
import FieldTextareaBorder from 'src/components/Form/components/FieldTextarea/FieldTextareaBorder';

registerLocale('en_GB', en_GB);
setDefaultLocale('en_GB');

const PasswordSchema = Yup.object().shape({
  title: Yup.string().required('This field is required'),
});

const repeatOnFields = {
  repeating_weekly_monday: false,
  repeating_weekly_tuesday: false,
  repeating_weekly_wednesday: false,
  repeating_weekly_thursday: false,
  repeating_weekly_friday: false,
  repeating_weekly_saturday: false,
  repeating_weekly_sunday: false,
};

const now = new Date();

const dateToLocaleTimeString = (date) =>
  date.toLocaleTimeString('en', { hour12: false });

const CalendarItemForm = ({ onSubmit, defaultValues = {} }) => {
  const { start_date, end_date, start_time, end_time } = defaultValues;

  const [startDate, setStartDate] = useState(
    start_date ? new Date(start_date) : now
  );
  const [endDate, setEndDate] = useState(end_date ? new Date(end_date) : now);
  const [startTime, setStartTime] = useState(
    start_time ? new Date(`01.01.2021 ${start_time}`) : now
  );
  const [endTime, setEndTime] = useState(
    end_time ? new Date(`01.01.2021 ${end_time}`) : now
  );

  const [allDay, setAllDay] = useState(defaultValues.is_all_day || false);
  const [repeat, setRepeat] = useState(defaultValues.repeat || false);

  const fixedRepeatOnFields = React.useMemo(() => {
    const newItems = { ...repeatOnFields };

    for (const item of Object.keys(repeatOnFields))
      newItems[item] = defaultValues[item] || false;

    return newItems;
  }, []);

  const [repeatOn, setRepeatOn] = useState(fixedRepeatOnFields);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    formState: { isSubmitting },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(PasswordSchema),
    mode: 'onChange',
  });

  const toggleAllDay = () => {
    setAllDay(!allDay);
  };

  const toggleRepeat = () => {
    setRepeat(!repeat);
  };

  const toggleRepeatOn = (e) => {
    setRepeatOn({
      ...repeatOn,
      [e.target.name]: !Boolean(repeatOn[e.target.name]),
    });
  };

  const formatSubmit = (data) => {
    return onSubmit({
      ...data,
      is_all_day: allDay,
      start_date: startDate.toISOString(),
      start_time: dateToLocaleTimeString(startTime),
      end_date: endDate.toISOString(),
      end_time: dateToLocaleTimeString(endTime),
      type: data.type ? data.type.value : null,
      repeat,
      attendees: data.attendees ? data.attendees.map((u) => u.value) : [],
      // files,
      ...repeatOn,
    });
  };

  const calendarItemTypes = Object.values(calendarService.calendarItemTypes);

  const repeatDisabled =
    startDate.getTime() > endDate.getTime() ||
    startDate.getTime() === endDate.getTime();

  useEffect(() => {
    if (startDate.getTime() > endDate.getTime()) {
      setEndDate(startDate);
    }
  }, [startDate, endDate]);

  return (
    <>
      <form
        noValidate
        onSubmit={handleSubmit(formatSubmit)}
        className="flex flex-col w-full"
      >
        <Select
          className="w-full"
          labelText="Item type"
          name="type"
          control={control}
          placeholder="Item type"
          register={register}
          watch={watch}
          setValue={setValue}
          options={calendarItemTypes}
          defaultValue={defaultValues.type || calendarItemTypes[0]}
        />

        <FieldInput
          register={register}
          name="title"
          type="text"
          errors={errors}
          labelText="Item title *"
          placeholder="Your item title"
          value={watch('title')}
          defaultValue={defaultValues.title || ''}
        />

        <FieldTextareaBorder
          labelText="Description"
          placeholder="Description"
          register={register}
          name="description"
          defaultValue={defaultValues.description || ''}
          type="text"
          errors={errors}
          value={watch('description')}
        />
        <CalendarItemAttendees
          control={control}
          register={register}
          attendees={defaultValues.attendees}
        />

        <FieldInput
          register={register}
          name="url"
          type="text"
          errors={errors}
          labelText="Meeting url"
          placeholder="Meeting url"
          value={watch('url')}
          defaultValue={defaultValues.url || ''}
        />

        <>
          <>
            <Grid cols={2} className="mb-20">
              <div className="flex flex-col relative">
                <span className="text-xs font-medium text-gray-400 mb-10">
                  Start date
                </span>
                <div className="flex items-center justify-between border-1 border-gray-300 rounded-default p-10">
                  <ReactDatePicker
                    className="ReactDatePicker text-sm"
                    dateFormat={'dd-MM-yyyy'}
                    withPortal
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                  />
                  <SvgCalendar className="w-sm h-sm min-w-sm text-primary right-0" />
                </div>
              </div>

              <div className="flex flex-col relative">
                <span className="text-xs font-medium text-gray-400 mb-10">
                  End date
                </span>
                <div className="flex items-center justify-between border-1 border-gray-300 rounded-default p-10">
                  <ReactDatePicker
                    className="ReactDatePicker text-sm"
                    dateFormat={'dd-MM-yyyy'}
                    withPortal
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                    }}
                    p
                  />
                  <SvgCalendar className="w-sm h-sm min-w-sm text-primary right-0" />
                </div>
              </div>
            </Grid>

            <Grid cols={4}>
              <div className="inline-flex -mt-15 mb-5">
                <Checkbox
                  name="all_day"
                  labelText="All day ?"
                  checked={allDay}
                  onChange={toggleAllDay}
                />
              </div>
              <div
                className={`inline-flex -mt-15 mb-5${
                  repeatDisabled ? ' opacity-50' : ''
                }`}
              >
                <Checkbox
                  name="repeat"
                  labelText="Repeat ?"
                  checked={repeat && !repeatDisabled}
                  onChange={toggleRepeat}
                  disabled={repeatDisabled}
                />
              </div>
            </Grid>

            {!Boolean(allDay) && (
              <Grid cols={2} className="mb-15">
                <div className="flex flex-col relative">
                  <span className="text-xs font-medium text-gray-400 mb-10">
                    Start time
                  </span>

                  <div className="flex items-center justify-between border-1 border-gray-300 rounded-default p-10 overflow-hidden">
                    <ReactDatePicker
                      className="ReactDatePicker text-sm"
                      selected={startTime}
                      onChange={(date) => setStartTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      withPortal
                    />
                  </div>
                </div>
                <div className="flex flex-col relative">
                  <span className="text-xs font-medium text-gray-400 mb-10">
                    End time
                  </span>

                  <div className="flex items-center justify-between border-1 border-gray-300 rounded-default p-10 overflow-hidden">
                    <ReactDatePicker
                      className="ReactDatePicker text-sm"
                      selected={endTime}
                      onChange={(date) => setEndTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="HH:mm"
                      withPortal
                    />
                  </div>
                </div>
              </Grid>
            )}

            {Boolean(repeat && !repeatDisabled) && (
              <div className="mb-15">
                {Object.keys(repeatOnFields).map((field) => (
                  <div key={field}>
                    <div className="inline-flex capitalize">
                      <Checkbox
                        name={field}
                        labelText={field.split('_').join(' ')}
                        checked={Boolean(repeatOn[field])}
                        onChange={toggleRepeatOn}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        </>

        {/* <ImageUpload value={files} onChange={setFiles} /> */}

        <div className="flex w-full">
          <Button
            variant="primary"
            height="44"
            isLoading={isSubmitting}
            className="ml-auto w-auto"
          >
            Save
          </Button>
        </div>
      </form>
    </>
  );
};

export default CalendarItemForm;
