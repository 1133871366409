export * from './actionHelper';
export * from './serviceHelper';
export * from './historyHelper';
export * from './formatHelper';
export * from './tokenHelper';
export * from './mailCampaignsHelper';

export function findDefaultValue(options, name, defaultValues) {
  return defaultValues[name]
    ? options.find((i) => i.value === defaultValues[name])
    : options[0];
}

export const groupByDate = (data, key = 'created_at', includeHours = false) => {
  var groups = {};

  data.forEach(function (val) {
    var date = val[key].split(includeHours ? ':' : 'T')[0];
    if (date in groups) {
      groups[date].push(val);
    } else {
      groups[date] = new Array(val);
    }
  });

  return groups;
};
