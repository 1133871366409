import { API_ENDPOINT } from '../../utils/endpoints';
import { handleResponse, openFetch, authFetch, client } from '../helpers';

const getAllCompanies = () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(`${API_ENDPOINT}/companies`, requestOptions).then(
    handleResponse
  );
};

export const companiesService = {
  getAllCompanies,
};
