import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { calendarActions } from '../../../common/actions/calendarActions';
import { calendarService } from '../../../common/services';
import ModalHeaderActions from '../../../components/Misc/ModalHeaderActions';
import Modal from '../../../components/Modal';
import CalendarItemForm from './CalendarItemForm';
import { toast } from 'react-toastify';
import CalendarItemDetails from './CalendarItemDetails';

const CalendarItemModal = ({ eventId, open, setOpen }) => {
  const [calendarItem, setCalendarItem] = useState(null);
  const [editing, setEditing] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleSubmit = (data) => {
    dispatch(
      calendarActions.updateCalendarItem(
        eventId,
        data,
        () => {
          toast.success('Calendar item updated', { className: 'text-white' });
          setOpen(false);
        },
        (errors) => {
          toast.warning(errors, { className: 'text-white' });
        }
      )
    );
  };

  const handleDelete = () => {
    if (!calendarItem) return;

    dispatch(calendarActions.deleteCalendarItem(eventId));
    setEditing(false);
    setOpen(false);
  };

  const handleCloseModal = () => {
    if (editing) {
      setEditing(false);
    } else {
      setOpen(false);
    }
  };

  const fetchCalendarItem = async (loading = true) => {
    return calendarService.getCalendarItem(eventId, loading).then((res) => {
      setCalendarItem(res);
    });
  };

  useEffect(() => {
    fetchCalendarItem();
  }, [eventId]);

  if (!calendarItem) return <></>;

  return (
    <Modal
      isOpen={open}
      // onRequestClose={() => setOpen(false)}
      headerTitle=""
      className="modal--notepopup"
      style={{
        position: 'relative',
      }}
    >
      <ModalHeaderActions
        name={calendarItem.title}
        onClose={handleCloseModal}
        onDelete={handleDelete}
        canEdit={calendarItem.user_id === user.id}
        onEdit={() => setEditing(true)}
        editing={editing}
      />

      {editing ? (
        <CalendarItemForm
          defaultValues={calendarItem}
          onSubmit={handleSubmit}
        />
      ) : (
        <CalendarItemDetails
          calendarItem={calendarItem}
          onUpdate={fetchCalendarItem}
        />
      )}
    </Modal>
  );
};

export default CalendarItemModal;
