import React, { memo } from 'react';
import ReactPaginate from 'react-paginate';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const linkClassName = `ServerlessPagination-link block w-40 h-40 bg-gray-300 rounded-full flex justify-center items-center mr-15`;

const ServerlessPagination = ({
  currentPage,
  setCurrentPage,
  lastPage,
  scrollTo,
  total,
}) => {
  const handleChange = (page) => {
    if (scrollTo) {
      document.querySelector(scrollTo).scrollIntoView({
        behavior: 'smooth',
      });
    }

    setCurrentPage(page.selected);
  };

  const showFrom = currentPage * 10 + 1;
  const showTo = (currentPage + 1) * 10;

  return (
    <nav
      style={{ userSelect: 'none' }}
      className="ServerlessPagination bg-gray-200 p-20 flex items-center justify-between"
      role="navigation"
      aria-label="ServerlessPagination"
    >
      {total > 10 ? (
        <ReactPaginate
          containerClassName={`ServerlessPagination-list flex`}
          //
          pageLinkClassName={linkClassName}
          previousLinkClassName={linkClassName}
          nextLinkClassName={linkClassName}
          //
          activeLinkClassName={`bg-gray-400 text-white`}
          //
          breakLinkClassName={`ServerlessPagination-link`}
          disabledClassName={`opacity-50`}
          previousLabel={<FaChevronLeft />}
          nextLabel={<FaChevronRight />}
          pageCount={lastPage}
          onPageChange={handleChange}
          disableInitialCallback={true}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
        />
      ) : null}

      <p>
        Showing {showFrom} to {showTo > total ? total : showTo} of {total}
      </p>
    </nav>
  );
};

export default memo(ServerlessPagination);
