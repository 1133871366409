import React from 'react';
import FieldInput from 'src/components/Form/components/FieldInput';
import Select from './../../../../components/Select/Select';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { NavLink, useHistory } from 'react-router-dom';
import { authActions } from './../../../../common/actions';

const validationSchema = Yup.object().shape({
  salutaiton: Yup.string().required('This field is required'),
  first_name: Yup.string().required('This field is required'),
  last_name: Yup.string().required('This field is required'),
  company_name: Yup.string().required('This field is required'),
  street: Yup.string().required('This field is required'),
  house_number: Yup.string().required('This field is required'),
  postcode: Yup.string().required('This field is required'),
  city: Yup.string().required('This field is required'),
  country: Yup.string().required('This field is required'),
  phone_number: Yup.string().required('This field is required'),
  email: Yup.string().required('This field is required'),
});

const DataInput = ({ onPrevious, onNext, data }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    handleSubmit,
    watch,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    dispatch(authActions.data(data, history));
  };

  const salutationOptions = [
    { value: 'Mr', label: 'Mr' },
    { value: 'Mrs', label: 'Mrs' },
  ];

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col rounded-default p-20 bg-white">
          <header className="flex justify-center mb-20">
            <div className="w-3/5">
              <h1 className="text-3xl font-bold text-gray-500 text-center">
                Customer data
              </h1>
              <p className="text-sm text-gray-400 text-center whitespace-nowrap">
                All fields are required, please fill out
              </p>
            </div>
          </header>
          <main className="flex flex-col justify-center mb-10">
            <h1 className="text-xl font-bold text-gray-500 text-center mb-10">
              Contact person
            </h1>
            <div className="flex items-center mb-20">
              <Select
                labelText="Salutation"
                name="salutation"
                options={salutationOptions}
                placeholder="Salutation"
                defaultValue={{
                  value: salutationOptions[0].value,
                  label: salutationOptions[0].label,
                }}
                className="w-1/5 mr-10"
              />
              <FieldInput
                labelText="First name"
                name="first_name"
                placeholder="First Name"
                type="text"
                errors={errors}
                value={watch('first_name')}
                className="w-2/5 mr-10"
              />
              <FieldInput
                labelText="Last Name"
                name="last_name"
                placeholder="Last Name"
                type="text"
                errors={errors}
                value={watch('last_name')}
                className="w-2/5"
              />
            </div>
            <h1 className="text-xl font-bold text-gray-500 text-center mb-10">
              Address
            </h1>
            <div>
              <FieldInput
                labelText="Company name"
                name="company_name"
                placeholder="Company Name"
                type="text"
                errors={errors}
                value={watch('company_name')}
                className="w-full"
              />
            </div>
            <div className="flex items-center mb-20">
              <FieldInput
                labelText="Street"
                name="street"
                placeholder="Street"
                type="text"
                errors={errors}
                value={watch('street')}
                className="w-2/4 mr-10"
              />
              <FieldInput
                labelText="House number"
                name="house_number"
                placeholder="House number"
                type="text"
                errors={errors}
                value={watch('house_number')}
                className="w-1/4 mr-10"
              />
              <FieldInput
                labelText="Postcode"
                name="postcode"
                placeholder="Postcode"
                type="text"
                errors={errors}
                value={watch('postcode')}
                className="w-1/4"
              />
            </div>
            <div className="flex items-center mb-20">
              <FieldInput
                labelText="City"
                name="city"
                placeholder="City"
                type="text"
                errors={errors}
                value={watch('city')}
                className="w-2/5 mr-10"
              />
              <FieldInput
                labelText="Country"
                name="country"
                placeholder="country"
                type="text"
                errors={errors}
                value={watch('country')}
                className="w-2/5 mr-10"
              />
              <FieldInput
                labelText="Tax ID"
                name="taxId"
                placeholder="ID"
                type="text"
                errors={errors}
                value={watch('taxId')}
                className="w-1/5"
              />
            </div>
            <h1 className="text-xl font-bold text-gray-500 text-center mb-10">
              Contact details
            </h1>
            <div className="flex items-center mb-20">
              <FieldInput
                labelText="Phone number"
                name="phone_number"
                placeholder="Phone number"
                type="text"
                errors={errors}
                value={watch('phone_number')}
                className="w-2/4 mr-10"
              />
              <FieldInput
                labelText="E-mail adress"
                name="email"
                placeholder="email@email.com"
                type="text"
                errors={errors}
                value={watch('email')}
                className="w-2/4"
              />
            </div>
          </main>
          <footer className="flex justify-between items-center mb-10">
            <button
              onClick={onPrevious}
              className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-50 outline-none transition duration-500"
            >
              Back
            </button>
            <button
              onClick={onNext}
              className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-50 outline-none transition duration-500"
            >
              Continue
            </button>
          </footer>
        </div>
      </form>
    </>
  );
};

export default DataInput;
