import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SearchInput from 'src/components/Form/components/SearchInput';
import ChatConversation from '../ChatConversation';
import styles from '../ChatContent.module.scss';

export default function ChatConversations() {
  const [search, setSearch] = useState('');
  const { conversations } = useSelector((state) => state.chat);
  console.log('haris', conversations);
  const filtered = search
    ? conversations.filter(
        (i) =>
          String(i.name)
            .toLocaleLowerCase()
            .search(search.toLocaleLowerCase()) !== -1
      )
    : conversations;

  return (
    <>
      <SearchInput
        name="search"
        type="text"
        labelText="search"
        placeholder="Search..."
        className={`${styles.search} mt-20 mb-30`}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {filtered.map((conversation) =>
        conversation?.conversation?.last_message ? (
          <ChatConversation key={conversation.id} conversation={conversation} />
        ) : null
      )}
    </>
  );
}
