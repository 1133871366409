// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

const removeTokenFromLocalStorage = () => {
  window.localStorage.removeItem('crm-client-token');
};

const getTokenFromLocalStorage = () => {
  const token = window.localStorage.getItem('crm-client-token');
  return token;
};

const setTokenToLocalStorage = (token) => {
  window.localStorage.setItem('crm-client-token', token);
};

const isTokenExpired = (token) => {
  if (!token) {
    token = getTokenFromLocalStorage();
  }

  if (!token || token === '') return true;
  let decodedToken;
  try {
    decodedToken = jwt_decode(token);
  } catch (err) {
    removeTokenFromLocalStorage();
  }
  if (!decodedToken || decodedToken === {}) return true;

  const tokenExpirationDate = decodedToken.exp * 1000;

  if (tokenExpirationDate <= Date.now()) return true;

  return false;
};

const getUserIdFromToken = () => {
  const token = getTokenFromLocalStorage();

  if (!token || token === '') return null;
  let decodedToken;
  try {
    decodedToken = jwt_decode(token);
  } catch (err) {
    removeTokenFromLocalStorage();
  }
  if (!decodedToken || decodedToken === {}) return null;

  return decodedToken.sub;
};

export const tokenHelper = {
  isTokenExpired,
  removeTokenFromLocalStorage,
  getTokenFromLocalStorage,
  setTokenToLocalStorage,
  getUserIdFromToken,
};
