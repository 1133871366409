import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { contactActions } from '../../../common/actions';
import ContactRow from './ContactRow';
import ServerlessPagination from '../../../components/commons/ServerlessPagination/ServerlessPagination';

const ContactsWidget = () => {
  const dispatch = useDispatch();
  const contactsList = useSelector((state) => state.contacts.contactsList);
  const [searchContactList] = useState('');
  const searchResults = useSelector(
    (state) => state.contacts.searchResultContactsList
  );
  const searching = searchContactList && searchContactList.length > 0;
  const [currentPage, setCurrentPage] = useState(0);
  const totalRows =
    searching && searchResults && searchResults.length
      ? searchResults.length
      : contactsList && contactsList.length && contactsList.length;
  const lastPage = Math.ceil(totalRows / 10);
  const renderContacts = () => {
    return searching ? searchResults : contactsList ? contactsList : [];
  };

  useEffect(() => {
    if (searchContactList && searchContactList.length > 0) {
      dispatch(contactActions.searchContacts(searchContactList));
    }
    if (currentPage !== 0) setCurrentPage(0);
  }, [searchContactList]);

  return (
    <>
      <div className="flex flex-col">
        <div className="">
          <div className="py-2 align-middle inline-block min-w-full sm:px-20 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      User
                    </th>
                    <th
                      scope="col"
                      className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Company
                    </th>
                    <th
                      scope="col"
                      className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Created at
                    </th>
                    <th
                      scope="col"
                      className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Phone
                    </th>
                    <th
                      scope="col"
                      className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Socials
                    </th>
                    <th
                      scope="col"
                      className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {renderContacts()
                    .slice(currentPage * 10, (currentPage + 1) * 10)
                    .map((contact, index) => {
                      return <ContactRow contact={contact} key={index} />;
                    })}
                </tbody>
              </table>

              <ServerlessPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                lastPage={lastPage}
                total={totalRows}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ContactsWidget };
