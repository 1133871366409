import React, { useEffect } from 'react';
import LayoutComponent from '../../components/commons/LayoutComponent';
import { useDispatch, useSelector } from 'react-redux';
import SearchContent from './components/SearchContent';
import { getQueryStringParam } from 'src/utils/getQueryParams';
import { notificationActions } from 'src/common/actions/notificationActions';

const Search = () => {
  const dispatch = useDispatch();
  const search = getQueryStringParam('search');
  useEffect(() => {
    dispatch(notificationActions.getSearchResults(search));
  }, [search]);
  const searchResults = useSelector(
    (state) => state.notification.searchResults
  );
  return (
    <LayoutComponent title="">
      {searchResults && (
        <SearchContent search={search} searchResults={searchResults} />
      )}
    </LayoutComponent>
  );
};

export default Search;
