import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { contactActions } from '../../.././../common/actions';
import { ReactComponent as SvgFacebook } from './../../../../assets/icons/icon-fb.svg';
import { ReactComponent as SvgInstagram } from './../../../../assets/icons/icon-ig.svg';
import { ReactComponent as SvgTwitter } from './../../../../assets/icons/icon-tw.svg';
import { ReactComponent as SvgAction } from './../../../../assets/icons/icon-action.svg';
import { ReactComponent as SvgAvatar } from './../../../../assets/icons/icon-avatar-male.svg';
import { ReactComponent as SvgDelete } from './../../../../assets/icons/icon-delete-campaign.svg';
import { ReactComponent as SvgEdit } from './../../../../assets/icons/icon-edit-campaign.svg';
import Modal from '../../../../components/Modal';
import { routes } from '../../../../common/constants';
import { useHistory } from 'react-router';
import moment from 'moment';

const getSocialNetworkIcon = (network) => {
  switch (network) {
    case 'Twitter':
      return <SvgFacebook />;
    case 'Instagram':
      return <SvgInstagram />;
    case 'Facebook':
      return <SvgTwitter />;
    default:
      return null;
  }
};

const ContactRow = ({ contact, search, setSearchContacts }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isContactActionOpened, setIsContactActionOpened] = useState(false);
  const openContactAction = () => {
    setIsContactActionOpened(true);
  };
  const closeContactAction = () => {
    setIsContactActionOpened(false);
  };

  const [isDeleteContactModalOpened, setIsDeleteContactModalOpened] = useState(
    false
  );
  const toggleDeleteModal = () => {
    setIsDeleteContactModalOpened(!isDeleteContactModalOpened);
  };

  const handleDeleteContact = () => {
    dispatch(contactActions.deleteContact(contact.id));
    setIsDeleteContactModalOpened(!isDeleteContactModalOpened);
  };

  const renderProfileImage = () => {
    if (contact.image) {
      return (
        <img
          className="h-24 w-24 rounded-full object-cover object-center"
          style={{ minWidth: '24px', maxWidth: '24px' }}
          src={contact.image}
          alt=""
        />
      );
    } else {
      return (
        <SvgAvatar
          className="h-24 w-24 rounded-full object-cover object-center"
          style={{ minWidth: '24px', maxWidth: '24px' }}
        />
      );
    }
  };

  return (
    <>
      <tr>
        <td className="px-20 py-10 whitespace-nowrap cursor-pointer">
          <div
            className="flex items-center"
            onClick={() => {
              history.push(`${routes.CONTACT.path}?contactId=${contact.id}`);
            }}
          >
            <div className="flex-shrink-0 h-24 w-24">
              {renderProfileImage()}
            </div>
            <div className="ml-15">
              <div className="text-sm font-medium text-gray-900">
                {contact.first_name} {contact.last_name}
              </div>
              <div className="text-sm text-gray-500">{contact.email}</div>
            </div>
          </div>
        </td>
        <td className="px-20 py-10 whitespace-nowrap">
          <div className="text-sm text-gray-900">{contact.company}</div>
        </td>
        <td className="px-20 py-10 whitespace-nowrap">
          <span className="px-20 py-10 whitespace-nowrap text-sm text-gray-500">
            {moment(contact.created_at).format('DD.MM.YYYY.')}
          </span>
        </td>
        <td className="px-20 py-10 whitespace-nowrap">
          <div className="text-sm text-gray-900">{contact.phone}</div>
        </td>
        <td className="px-20 py-10 whitespace-nowrap text-sm text-gray-500">
          {contact.social_links && (
            <ul className="flex items-center">
              {contact.social_links.map((network, index) => {
                return (
                  <li className="flex mx-10" key={index}>
                    <a href={network.url} taret="_blank">
                      {getSocialNetworkIcon(network.name)}
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </td>
        <td className="px-20 py-10 whitespace-nowrap text-sm font-medium h-full">
          <div className="flex justify-end">
            <button
              className="text-gray-500 hover:text-gray-300 flex items-center justify-center relative mr-10"
              onClick={() => {
                history.push(`${routes.CONTACT.path}?contactId=${contact.id}`);
                closeContactAction();
              }}
            >
              <SvgEdit className="text-primary w-sm" />
            </button>
            <button
              type="button"
              onClick={toggleDeleteModal}
              className="text-gray-500 hover:text-gray-300 flex items-center justify-center relative outline-none"
            >
              <SvgDelete className="text-primary w-sm" />
            </button>
          </div>
        </td>
      </tr>

      <Modal
        isOpen={isDeleteContactModalOpened}
        onRequestClose={toggleDeleteModal}
        headerTitle="Delete contact"
        className="modal--alert"
      >
        <h2 className={`w-full text-2x font-bold text-black mt-50 mb-20`}>
          Delete contact?
        </h2>
        <div className="flex justify-between">
          <button
            className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-primary hover:bg-secondary"
            role="menuitem"
            onClick={handleDeleteContact}
          >
            Yes
          </button>
          <button
            className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-gray-500 hover:bg-gray-400"
            role="menuitem"
            onClick={toggleDeleteModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ContactRow;
