import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { notesActions } from '../../../common/actions';
import NoteForm from '../NoteCreate/NoteForm';

import NoteComments from './components/NoteComments';
import ModalHeaderActions from '../../Misc/ModalHeaderActions';
import ButtonBack from 'src/components/ButtonBack';

const NotePopup = ({ className, note, setIsNotePopupModalOpened, contact }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const [editing, setEditing] = useState(false);

  const handleEditNote = () => {
    setEditing(true);
  };

  const handleDeleteNote = () => {
    dispatch(notesActions.deleteNote(note.id));
    setEditing(false);
    setIsNotePopupModalOpened(false);
  };

  const handleCloseModal = () => {
    if (editing) {
      setEditing(false);
    } else {
      setIsNotePopupModalOpened(false);
    }
  };

  const handeUpdateNote = (data) => {
    return dispatch(notesActions.updateNote(note.id, data, setEditing));
  };

  const noteCategories = React.useMemo(
    () => note.categories.map((c) => c.name).join(', '),
    [note]
  );

  const isConsultation =
    Array.isArray(note.categories) &&
    note.categories.length === 1 &&
    note.categories[0].name === 'Consultation';

  const clearAssignedTo = note.assigned_to.filter(Boolean);

  return (
    <div className={`${className} flex-col items-center`}>
      <ButtonBack
        variant="click"
        onClick={handleCloseModal}
        className="mb-15"
      />
      <ModalHeaderActions
        name={note.name}
        canEdit={user.id === note.created_by}
        editing={editing}
        setEditing={setEditing}
        onEdit={handleEditNote}
        onDelete={handleDeleteNote}
        onClose={handleCloseModal}
      />
      {editing ? (
        <NoteForm
          buttonText="Save changes"
          onSubmit={handeUpdateNote}
          defaultValues={note}
          contact={contact}
        />
      ) : (
        <>
          <ul className="flex flex-col">
            <li className="text-gray-400 font-semibold mb-2 text-xs tracking-wide">
              Categories:{' '}
              <strong className="text-primary font-semibold">
                {noteCategories ? noteCategories : '/'}
              </strong>
            </li>
            {!isConsultation && (
              <li className="text-gray-400 font-semibold mb-2 text-xs tracking-wide">
                Follow up type:{' '}
                <strong className="text-primary font-semibold">
                  {note.group_name ? note.group_name : '/'}
                </strong>
              </li>
            )}
            <li className="text-gray-400 font-semibold mb-2 text-xs tracking-wide">
              Status:{' '}
              <strong className="text-primary font-semibold capitalize">
                {note.status_name}
              </strong>
            </li>
            <li className="text-gray-400 font-semibold mb-2 text-xs tracking-wide">
              Created by{' '}
              <strong className="text-primary font-semibold">
                {note.created_by_data.name}
              </strong>{' '}
              on {note.created_at ? moment(note.created_at).format('L') : '-'}
            </li>
            {Boolean(clearAssignedTo && clearAssignedTo.length) && (
              <li className="text-gray-400 font-semibold mb-2 text-xs tracking-wide">
                Assigned to:{' '}
                <strong className="text-primary font-semibold">
                  {clearAssignedTo.map((i) => i.first_name).join(', ')}
                </strong>
              </li>
            )}
          </ul>
          <p className="mt-20 mb-20 flex text-sm text-gray-400 my-10 leading-snug overflow-hidden w-full">
            {note.message}
          </p>

          <NoteComments note={note} />
        </>
      )}
    </div>
  );
};

export default NotePopup;
