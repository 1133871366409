import React from 'react';
import { useQuery } from 'react-query';

import CommentDisplay from '../../Comment/CommentDisplay/CommentDisplay';
import CommentForm from '../../Comment/CommentInput';
import { notesService } from '../../../../common/services/notesService';
import Loader from '../../../Loader';
import { useState } from 'react';
import { toast } from 'react-toastify';

const NoteComments = ({ note }) => {
  const {
    isLoading,
    isSuccess,
    data: comments,

    refetch: reloadComments,
  } = useQuery('note-comments-' + note.id, () =>
    notesService.getComments(note.id)
  );

  const [sendingComment, setSendingComment] = useState(false);

  const handleComment = (commentData, formActions) => {
    if (!String(commentData.message).trim()) {
      toast.warning('Please enter your comment !', {
        className: 'text-white',
      });
    } else {
      setSendingComment(true);

      notesService
        .createComment({ ...commentData, note_id: note.id })
        .then(() => {
          reloadComments().then(() => {
            setSendingComment(false);
            formActions.reset();
          });
        });
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <h3 className="font-bold text-lg mb-15">Comments</h3>

        {isLoading ? (
          <div className="bg-primary mx-auto p-10 rounded-full">
            <Loader />
          </div>
        ) : (
          <>
            {isSuccess && (
              <>
                {comments.data.map((comment, index) => {
                  return (
                    <CommentDisplay
                      key={index}
                      comment={comment}
                      reload={reloadComments}
                    />
                  );
                })}
              </>
            )}
            <CommentForm
              onSubmit={handleComment}
              sendingComment={sendingComment}
            />
          </>
        )}
      </div>
    </>
  );
};

export default NoteComments;
