import { React, useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import DashboardItems from '../components/DashboardItems';
import { DashboardTabs } from '../components/DashboardTabs';
import { useDispatch, useSelector } from 'react-redux';
import LayoutComponent from 'src/components/commons/LayoutComponent';
import { contactActions } from 'src/common/actions';

export const Dashboard = () => {
  const dispatch = useDispatch();
  const areContactsLoaded = useSelector(
    (state) => state.contacts.areContactsLoaded
  );

  useEffect(() => {
    return () => {
      dispatch(contactActions.clearContactsState());
    };
  }, []);

  useEffect(() => {
    if (!areContactsLoaded) {
      dispatch(contactActions.getAllContacts());
    }
  }, [areContactsLoaded]);

  const stats = {
    opened_offers: 1,
    signed_offers: 4,
    personal_revenue: 187319.01,
    provision: 0,
    personal_call_time: '00:00',
    number_of_phone_calls: 0,
    number_of_meetings: 0,
    meetings_positive: 0,
    best_employees: 0,
    customer_satisfaction: '0 / 0',
  };

  const handleDataRefresh = () => {
    /* axios
      .get(`${process.env.REACT_APP_API_URL}/statistic`, {
        headers: {
          Authorization: localStorage.getItem('token'),
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setStats(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      }); */
  };

  useEffect(() => {
    handleDataRefresh();
  }, []);

  return (
    <LayoutComponent title="">
      {areContactsLoaded && (
        <>
          <Box padding={3}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Box>
                <Typography
                  style={{
                    fontWeight: '700',
                    fontSize: '24px',
                    lineHeight: '34px',
                    color: '#000000',
                  }}
                >
                  Dashboard
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box padding={3} paddingTop={0}>
            <DashboardItems filteredList={'2150'} stats={stats} />
          </Box>

          <Box padding={3} paddingTop={0}>
            <DashboardTabs />
          </Box>
        </>
      )}
    </LayoutComponent>
  );
};
