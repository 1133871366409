import React from 'react';
import FieldInput from 'src/components/Form/components/FieldInput';
import Select from 'src/components/Select';

const EmailSend = ({ onNext }) => {
  const emailTemplateOptions = [
    { value: 'Email_template_1', label: 'Email template 1' },
    { value: 'Email_template_2', label: 'Email template 2' },
  ];

  return (
    <div className="flex flex-col rounded-default p-20 bg-white">
      <header className="flex justify-center mb-20">
        <div className="w-3/5">
          <h1 className="text-3xl font-bold text-gray-500 text-center">
            Send offer
          </h1>
          <p className="text-sm text-gray-400 text-center whitespace-nowrap">
            Enter an email address and select an email template
          </p>
        </div>
      </header>
      <main className="flex flex-col justify-center items-center mb-10">
        <div className="flex justify-between items-center w-3/5">
          <FieldInput
            labelText="Link to the offer"
            name="link_to_offer"
            placeholder="https://devla.dev.com"
            value="https://devla.dev.com"
            type="text"
            className="w-full mr-5"
          />
          <button className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-50 outline-none transition duration-500">
            Copy
          </button>
        </div>
        <div className="flex flex-col justify-between items-center w-3/5">
          <FieldInput
            labelText="E-mail addresses"
            name="email_addresses"
            placeholder="e-mail"
            type="text"
            className="w-full mr-5"
          />
          <div className="self-end">
            <button className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-50 outline-none transition duration-500">
              +
            </button>
          </div>
        </div>
        <div className="flex justify-between items-center w-3/5">
          <FieldInput
            labelText="Email subject"
            name="email_subject"
            placeholder="Subject"
            type="text"
            className="w-full"
          />
        </div>
        <div className="flex justify-between items-center w-3/5">
          <Select
            labelText="Email template"
            name="email_template"
            options={emailTemplateOptions}
            defaultValue={{
              value: emailTemplateOptions[0].value,
              label: emailTemplateOptions[0].label,
            }}
            className="w-full"
          />
        </div>
      </main>
      <footer className="flex justify-end mb-10">
        <button
          onClick={onNext}
          className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-50 outline-none transition duration-500"
        >
          Send offer now
        </button>
      </footer>
    </div>
  );
};

export default EmailSend;
