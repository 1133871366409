import React from 'react';
import {
  Box,
  Button,
  Grid,
  Avatar,
  Dialog,
  Chip,
  Typography,
  Tooltip,
  Divider,
  Menu,
  MenuItem,
  IconButton,
  Popover,
  Snackbar,
  TextField,
} from '@material-ui/core';
import { animations } from 'react-animation';
import { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MoreVert from '@material-ui/icons/MoreVert';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import UrlIcon from '@material-ui/icons/Link';
import OpenInNew from '@material-ui/icons/OpenInBrowser';
import Launch from '@material-ui/icons/Launch';
import AddBox from '@material-ui/icons/AddBox';
import { DeleteDeal } from './DeleteDeal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { API_ENDPOINT } from 'src/utils/endpoints';
import axios from 'axios';
import { Alert } from '@material-ui/lab';
import { EditDeal } from './EditDeal';
import { authActions, contactActions, dealsActions } from 'src/common/actions';
import { ReactComponent as SvgAdd } from '../../../assets/icons/icon-add.svg';
import { Formik } from 'formik';
import { ReactComponent as SvgDelete } from '../../../assets/icons/icon-delete-campaign.svg';
import { ReactComponent as SvgEdit } from '../../../assets/icons/icon-edit.svg';
import { EditComment } from './EditComment';

const useStyles = makeStyles(() => ({
  p10: {
    padding: '0 10px 0 0',
  },
  mt20: {
    marginTop: '20px',
  },
  button: {
    padding: '18px 47px',
    maxWidth: 'unset',
    border: '1px solid #E1E1E1',
    borderRadius: '6px',
  },
  divider: {
    marginTop: '32px',
    marginBottom: '24px',
  },
  bottom: {
    position: 'fixed',
    bottom: '20px',
    right: '40px',
  },
  deleteButton: {
    padding: '16px 106px 22px 24px',
    textAlign: 'left',
    borderRadius: '6px',
    color: 'black',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important',
  },
  removeShadow: {
    boxShadow: 'none',
  },
}));

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'EUR',
});

export const DealDetails = (props) => {
  const { push } = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    dealValues,
    handleClose,
    updateDealStage,
    handleDataRefresh,
    setAlertOpen,
    setAlertMessage,
  } = props;
  const [values, setValues] = useState(dealValues);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElMore, setAnchorElMore] = useState(null);
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const [dealStage, setDealStage] = useState(values.stage);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [offerOpen, setOfferOpen] = useState(false);
  const [alertOpenEdit, setAlertOpenEdit] = useState(false);
  const [alertMessageEdit, setAlertMessageEdit] = useState('');
  const [comments, setComments] = useState([]);
  const [openCreateComment, setOpenCreateComment] = useState(false);
  const [commentId, setCommentId] = useState();
  const [comment, setComment] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [alertOpenDelete, setAlertOpenDelete] = useState(false);
  const [alertMessageDelete, setAlertMessageDelete] = useState('');
  console.log(comments);

  const handleAlertOpen = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const handleAlertCloseDelete = () => {
    setAlertOpenDelete(false);
  };
  const handleOpenCreateComment = () => {
    setOpenCreateComment(true);
  };

  const handleCloseCreateComment = () => {
    setOpenCreateComment(false);
  };
  const handleAlertOpenEdit = () => {
    setAlertOpenEdit(true);
  };

  const handleAlertCloseEdit = () => {
    setAlertOpenEdit(false);
  };
  const handleDeleteComment = (id) => {
    setCommentId(id);
    setOpenDelete(true);
  };
  const handleEditComment = (item) => {
    setComment(item);
    setOpenEdit(true);
  };
  const handleEditCommentClose = () => {
    setOpenEdit(false);
  };
  const handleDeleteCommentClose = () => {
    setOpenDelete(false);
  };
  console.log('detaljiii: ', dealValues);

  useEffect(() => {
    dispatch(authActions.fetchUsers());
    dispatch(contactActions.getAllContacts());
    // dispatch(getContactsAction('/contacts'));*/
  }, []);
  const users = useSelector((state) => state.auth?.users);
  const contactsList = useSelector((state) => state.contacts?.contactsList);
  const handleOpenStageMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  useEffect(() => {
    axios
      .get(`${API_ENDPOINT}/deal-comments?deal_id=${values.id}`, {
        headers: {
          Authorization: 'Bearer' + localStorage.getItem('crm-client-token'),
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setComments(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleRefreshComments = () => {
    axios
      .get(`${API_ENDPOINT}/deal-comments?deal_id=${values.id}`, {
        headers: {
          Authorization: 'Bearer' + localStorage.getItem('crm-client-token'),
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setComments(response.data.data);
        setAlertMessageEdit(
          openEdit ? 'Comment updated succesfully' : 'Comment added succesfully'
        );
        setAlertOpenEdit(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSelectStageMenu = (stage) => {
    setDealStage(stage);
    handleCloseStageMenu();
    updateDealStage(values.id, stage, true);
  };

  const handleCloseStageMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = (e) => {
    setAnchorMenuEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorMenuEl(null);
  };

  const handleDeleteClose = () => {
    handleCloseMenu();
    setDeleteOpen(false);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleEditClose = () => {
    handleCloseMenu();
    setEditOpen(false);
  };
  const handleClick = (event) => {
    setAnchorElMore(event.currentTarget);
  };

  const handleCloseMore = () => {
    setAnchorElMore(null);
  };
  function nameToInitials(fullName) {
    const namesArray = fullName.trim().split(' ');
    if (namesArray?.length === 1) return `${namesArray[0].charAt(0)}`;
    else
      return `${namesArray[0].charAt(0)}${namesArray[
        namesArray?.length - 1
      ].charAt(0)}`;
  }

  const handleClickCompany = (values) => {
    /*if (values.company) {
      push({
        pathname: pathBuilder(ROUTES.SINGLE_COMPANY, ':id', values.company.id),
      });
    }*/
  };
  const openMore = Boolean(anchorElMore);
  const id = openMore ? 'simple-popover' : undefined;

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const editDeal = async (data) => {
    let url = `${API_ENDPOINT}/deals/${values.id}`;

    await axios
      .patch(url, data, {
        headers: {
          Authorization: 'Bearer' + localStorage.getItem('crm-client-token'),
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setAlertMessageEdit('You have succesfully updated deal values');
        handleAlertOpenEdit();

        if (response) {
          setValues(response.data.data);
          handleDataRefresh();
        }

        handleEditClose();
      })
      .catch((e) => {
        let errors = e.response.data.message + '\n';
        for (let error in e.response.data.errors) {
          errors += e.response.data.errors[error][0] + '\n';
        }
        setAlertMessage(errors);
        handleAlertOpen();
      });
  };

  const handleOfferClose = () => {
    setOfferOpen(false);
  };

  const handleOfferOpen = () => {
    setOfferOpen(true);
  };

  const handleOfferAdd = async (dealId, offerId) => {
    /*await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/offers/${offerId}`,
        { deal_id: dealId },
        {
          headers: {
            Authorization: localStorage.getItem('token'),
            Accept: 'application/json',
          },
        }
      )
      .then((response) => {
        handleAlertClose();

        if (response) {
          let data = response.data.data;
          setValues({
            ...values,
            offer: {
              id: data.id,
              name: data.name,
              status: data.status,
            },
          });
          handleDataRefresh();
        }
        handleOfferClose();
      })
      .catch((e) => {
        let errors = e.response.data.message + '\n';
        for (let error in e.response.data.errors) {
          errors += e.response.data.errors[error][0] + '\n';
        }
        setAlertMessage(errors);
        handleAlertOpen();
      });*/
  };

  const handleDeleteConfirm = async () => {
    let bodyFormData = new FormData();

    bodyFormData.append('deal_ids[]', values.id);
    bodyFormData.append('archive', 1);

    await axios({
      method: 'post',
      url: `${API_ENDPOINT}/deals/archive`,
      data: bodyFormData,
      headers: {
        Authorization: 'Bearer' + localStorage.getItem('crm-client-token'),
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(() => {
        // handleDeleteClose();
        handleClose();
        setAlertMessage('Succesfully archived deal');
        setAlertOpen(true);
        handleDataRefresh();
      })
      .catch((e) => {
        let errors = e.response?.data?.message + '\n';
        for (let error in e.response?.data?.errors) {
          errors += e.response?.data?.errors[error][0] + '\n';
        }
        // setAlertMessage(errors);
        // handleAlertOpen();
      });
  };
  const handleDeleteCommentConfirm = async () => {
    await axios({
      method: 'delete',
      url: `${API_ENDPOINT}/deal-comments/${commentId}`,
      headers: {
        Authorization: 'Bearer' + localStorage.getItem('crm-client-token'),
        Accept: 'application/json',
      },
    })
      .then(() => {
        // handleDeleteClose();
        handleDeleteCommentClose();
        setAlertMessageDelete('Succesfully deleted comment');
        setAlertOpenDelete(true);
        handleRefreshComments();
      })
      .catch((e) => {
        let errors = e.response?.data?.message + '\n';
        for (let error in e.response?.data?.errors) {
          errors += e.response?.data?.errors[error][0] + '\n';
        }
        // setAlertMessage(errors);
        // handleAlertOpen();
      });
  };

  const offerStatus = (offer) => {
    let status = offer && offer.status ? offer.status : 'Open';
    let color = '#000000';
    let backgroundColor = 'rgba(0, 0, 0, 0.1)';

    if (status === 'Open') {
      color = '#F3AD26';
      backgroundColor = 'rgba(243, 173, 38, 0.1)';
    } else if (status === 'Signed') {
      color = '#26CA36';
      backgroundColor = 'rgba(38, 202, 54, 0.1)';
    } else if (status === 'Lost') {
      color = '#EE1B2D';
      backgroundColor = 'rgba(238, 27, 45, 0.1)';
    } else if (status === 'Draft') {
      color = '#000000';
      backgroundColor = 'rgba(0, 0, 0, 0.1)';
    }

    const StatusChip = withStyles({
      root: {
        width: 'auto',
        padding: '0 10px',

        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '11px',
        lineHeight: '16px',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '0.5px',
        textTransform: 'uppercase',
        color: color,
        marginBottom: '5px',
        background: backgroundColor,
      },
      label: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
      },
    })(Chip);
    return (
      <>
        <Box>
          <span
            style={{
              fontWeight: '300',
              fontSize: '14px',
              color: '#6A6565',
              marginTop: '5px',
              lineHeight: '19.6px',
            }}
          >
            {offer && offer.first_name ? offer.first_name : ''}
          </span>
          <span>
            <StatusChip
              label={status}
              classes={{
                root: classes.root,
                label: classes.label,
              }}
              style={{ marginLeft: '8px' }}
            />
          </span>
        </Box>
        <Box style={{ marginLeft: '80px', marginTop: '4px' }}>
          <IconButton
            edge="end"
            style={{
              height: '26px',
              width: '26px',
              marginRight: '10px',
              marginTop: '1px',
            }}
            onClick={() => {
              navigator.clipboard.writeText(offer.public_link);
            }}
          >
            {' '}
            <FileCopyIcon />
          </IconButton>
          <IconButton
            edge="end"
            style={{
              height: '26px',
              width: '26px',
              marginRight: '10px',
            }}
            onClick={() => {
              window.open(offer.public_link);
            }}
          >
            <OpenInNew />
          </IconButton>
          <Tooltip title="More options">
            <IconButton
              aria-label="more"
              aria-haspopup="true"
              style={{ height: '26px', width: '26px' }}
              onClick={handleClick}
            >
              <MoreVert />
            </IconButton>
          </Tooltip>
          <Popover
            id={id}
            open={openMore}
            anchorEl={anchorElMore}
            onClose={handleCloseMore}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Button className={classes.deleteButton}>Delete Offer</Button>
          </Popover>
        </Box>
      </>
    );
  };

  return (
    <>
      <Box>
        <Snackbar
          open={alertOpenEdit}
          onClose={handleAlertCloseEdit}
          autoHideDuration={3000}
        >
          <Alert
            onClose={handleAlertCloseEdit}
            variant="filled"
            severity={'success'}
            style={{
              whiteSpace: 'pre-line',
            }}
          >
            {alertMessageEdit}
          </Alert>
        </Snackbar>
        <Snackbar
          open={alertOpenDelete}
          onClose={handleAlertCloseDelete}
          autoHideDuration={3000}
        >
          <Alert
            onClose={handleAlertCloseDelete}
            variant="filled"
            severity={'success'}
            style={{
              whiteSpace: 'pre-line',
            }}
          >
            {alertMessageDelete}
          </Alert>
        </Snackbar>
        <Grid
          container
          style={{
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={11}>
            <Box
              style={{
                fontSize: '16px',
                fontWeight: '700',
                fontStyle: 'normal',
                color: '#000000',

                lineHeight: '22.4px',
              }}
            >
              {values.name}
            </Box>
          </Grid>

          <Grid
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingTop: '10px',
            }}
            item
            xs={1}
          >
            <IconButton
              aria-label="close-dialog"
              style={{
                marginTop: '-10px',
                color: 'black',
              }}
            >
              <CloseIcon
                style={{ height: '24px', width: '24px' }}
                onClick={handleClose}
              />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          style={{
            marginTop: '32px',
            marginBottom: '32px',
          }}
        >
          <Grid item xs={11}>
            <Box
              style={{
                fontSize: '18px',
                fontWeight: '700',
                fontStyle: 'normal',

                lineHeight: '25.2px',
                color: '#000000',
              }}
            >
              {values.name}
            </Box>
            <Box
              fontWeight={300}
              fontSize="16px"
              color="#4A4A4A"
              marginTop="5px"
            >
              Betrag:{' '}
              <span style={{ fontWeight: 'bold', color: 'black' }}>
                {formatter.format(values.amount)}
              </span>
            </Box>
            <Box
              style={{
                fontSize: '14px',
                fontWeight: '300',
                fontStyle: 'normal',

                lineHeight: '19.6px',
                color: '#6A6565',
                marginTop: '11px',
              }}
            >
              Abschlussdatum: {moment(values.closing_date).format('DD.MM.YYYY')}
            </Box>
            <Box
              fontSize="14px"
              color="#6A6565"
              marginTop="10px"
              fontWeight={300}
            >
              Deal stage:{' '}
              <span
                onClick={handleOpenStageMenu}
                style={{ marginLeft: '5px', cursor: 'pointer' }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    color: 'black',
                  }}
                >
                  {dealStage}
                </span>
                <ExpandMore style={{ verticalalign: 'middle' }} />
              </span>
            </Box>

            <Menu
              id="stage-menu"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseStageMenu}
            >
              <MenuItem
                onClick={() => {
                  handleSelectStageMenu('Market Positioning');
                }}
              >
                Market Positioning
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleSelectStageMenu('Develop Plan');
                }}
              >
                Develop Plan
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleSelectStageMenu('Initiate contacts');
                }}
              >
                Initiate Contacts
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleSelectStageMenu('Prepare presentation');
                }}
              >
                Prepare presentation
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleSelectStageMenu('Deliver presentation');
                }}
              >
                Deliver presentation
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleSelectStageMenu('Present offer');
                }}
              >
                Present offer
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleSelectStageMenu('Follow up');
                }}
              >
                Follow up
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleSelectStageMenu('Subsequent meetings');
                }}
              >
                Subsequent meetings
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleSelectStageMenu('Sign contract');
                }}
              >
                Sign contract
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleSelectStageMenu('Implement contract');
                }}
              >
                Implement contract
              </MenuItem>
            </Menu>
          </Grid>

          <Grid item xs={1} style={{ marginTop: '-5px' }}>
            <IconButton
              aria-label="more"
              onClick={handleOpenMenu}
              style={{
                marginTop: '-10px',
              }}
            >
              <MoreVert />
            </IconButton>

            <Menu
              style={{ marginRight: '-10px' }}
              id="menu"
              anchorEl={anchorMenuEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              open={Boolean(anchorMenuEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem
                style={{
                  borderBottom: '1px solid #E7E7E7',
                  padding: '14.7px 120.6px 14.7px 24px',
                }}
                onClick={() => {
                  handleEditOpen();
                }}
              >
                Edit deal
              </MenuItem>
              <MenuItem
                style={{ padding: '14.7px 94.2px 14.7px 24px' }}
                onClick={() => {
                  handleDeleteOpen();
                }}
              >
                Archive deal
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          style={{
            marginTop: '10px',
            marginBottom: '20px',
          }}
        >
          <Grid item xs={12}>
            <Box fontSize="14px" color="#6A6565" marginTop="8px">
              Company
            </Box>
            <Box
              onClick={() => handleClickCompany(values)}
              style={{
                backgroundColor: 'white',
                border: '1px solid #E1E1E1',
                boxSizing: 'border-box',
                padding: '20px',
                boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.01)',
                borderRadius: '6px',
                marginTop: '12px',
              }}
            >
              <Box className="text-xs text-gray-500">
                Company name:{' '}
                <strong className="font-bold">{values.company.name}</strong>
              </Box>
              <Button
                disableRipple
                className="bg-primary hover:bg-secondary text-white mt-5 px-10"
                style={{ textTransform: 'capitalize' }}
                startIcon={<UrlIcon style={{ marginTop: '2px' }} />}
                endIcon={<Launch style={{ marginTop: '2px' }} />}
                onClick={() => {
                  window.open(
                    /contacts/ + '?contactId=' + values.contact.id,
                    '_blank'
                  );
                }}
              >
                {values.company.url ? values.company.url : 'Visit company'}
              </Button>
            </Box>

            <Box
              style={{
                fontSize: '14px',
                color: '#6A6A6A',
                fontWeight: '400',
                fontStyle: 'normal',

                lineHeight: '19.6px',
                marginTop: '20px',
              }}
            >
              Contact Person
            </Box>
            <Box marginTop="10px" marginBottom="10px">
              {/*<ContactCard
                id={values?.contact?.id}
                name={values?.contact?.name}
                email={values?.contact?.email}
                phone={values?.contact?.phone}
                category={dealValues?.contact.category}
                backgroundColor="white"
              />*/}
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          style={{
            marginTop: '10px',
            marginBottom: '20px',
          }}
        >
          <Grid item xs={12}>
            <Box
              style={{
                fontSize: '14px',
                color: '#6A6A6A',
                fontWeight: '400',
                fontStyle: 'normal',

                lineHeight: '19.6px',
                marginTop: '8px',
              }}
            >
              Responsible Deal employee
            </Box>
            <Box display="flex" marginTop="16px">
              <Box>
                <Avatar className="bg-primary w-lg h-lg text-sm">
                  {nameToInitials(values?.resp_deal_employee?.name)}
                </Avatar>
              </Box>
              <Box mt={1} ml={1}>
                <Typography variant="body2">
                  {values?.resp_deal_employee?.name}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container className="mb-20">
          <Box width="100%" maxHeight="200px" overflow="auto">
            {comments.length > 0 && (
              <div>
                <Divider />
                <Box className="mb-10 mt-20">Comments</Box>
                {comments
                  .slice(0)
                  .reverse()
                  .map((item) => {
                    return (
                      <Grid
                        style={{ animation: animations.popIn }}
                        className="w-full mb-15 bg-gray-100 p-10 rounded-default"
                        item
                        xs={12}
                        key={item.id}
                      >
                        <Box display="flex" alignItems="center">
                          <Avatar
                            className="bg-secondary text-sm"
                            style={{
                              width: '30px',
                              height: '30px',
                            }}
                          >
                            {nameToInitials(item?.user?.name)}
                          </Avatar>
                          <Box className="flex justify-between pr-15 w-full">
                            <Typography
                              variant="body1"
                              className="pl-10 font-medium"
                            >
                              {item?.user?.name}
                            </Typography>
                            <Box display="flex">
                              <button
                                className="appearance-none flex ml-10"
                                onClick={() => handleEditComment(item)}
                              >
                                <SvgEdit
                                  fill="text-gray-400"
                                  className="flex"
                                ></SvgEdit>
                              </button>
                              <button
                                className="appearance-none flex ml-10"
                                onClick={() => handleDeleteComment(item.id)}
                              >
                                <SvgDelete
                                  fill="text-gray-400"
                                  className="flex"
                                ></SvgDelete>
                              </button>
                            </Box>
                          </Box>
                        </Box>
                        <Typography
                          style={{
                            color: '#282828',
                            fontSize: '14px',
                            marginTop: 4,
                            wordWrap: 'break-word',
                          }}
                        >
                          {item.comment}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '13px',
                            color: '#6A6A6A',
                            fontWeight: '400',
                            fontStyle: 'normal',

                            lineHeight: '19.6px',
                            marginTop: '8px',
                          }}
                        >
                          {moment(item.created_at).format(
                            'MMMM Do YYYY, h:mm:ss a'
                          )}
                        </Typography>
                      </Grid>
                    );
                  })}
              </div>
            )}
          </Box>
          <Grid item xs={12}>
            <Formik
              initialValues={{ comment: '' }}
              onSubmit={(
                values,
                { setSubmitting, resetForm },
                uri = '/deal-comments'
              ) => {
                //let image = URL.createObjectURL(images[0].file);

                var formData = new FormData();
                formData.append('comment', values.comment);
                formData.append('deal_id', dealValues.id);
                axios
                  .post(`${API_ENDPOINT}${uri}`, formData, {
                    headers: {
                      Authorization:
                        'Bearer' + localStorage.getItem('crm-client-token'),
                      Accept: 'application/json',
                      'Content-Type': 'multipart/form-data',
                    },
                  })
                  .then((response) => {
                    handleRefreshComments();
                    resetForm();
                  })
                  .catch((e) => {
                    let errorsPost = e.response?.data?.message + '\n';
                    for (let error in e.response?.data?.errorsPost) {
                      errorsPost +=
                        e.response?.data?.errorsPost[error][0] + '\n';
                    }
                    console.log(erorrsPost);
                  });
                // alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
              }}
              validateOnChange={false}
              validateOnBlur={false}
              validate={(values) => {
                const errors = {};
                if (!values.comment) {
                  errors.comment = (
                    <Alert severity="error">Comment is required!</Alert>
                  );
                }
                return errors;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                resetForm,
              }) => (
                <form id="comment" onSubmit={handleSubmit}>
                  <Divider style={{ marginTop: 5 }} />
                  <Box className="text-xs text-gray-500 mb-8 mt-20">
                    Leave a comment
                  </Box>
                  <TextField
                    fullWidth
                    variant="standard"
                    id="comment"
                    value={values.comment}
                    onChange={handleChange}
                    placeholder="Comment"
                    className=" mb-20 overflow-hidden"
                    InputProps={{
                      endAdornment: (
                        <Button
                          variant="contained"
                          color="primary"
                          className={'bg-primary'}
                          type="submit"
                          for="comment"
                          onClick={handleSubmit}
                          disabled={isSubmitting}
                        >
                          Send
                        </Button>
                      ),
                    }}
                  />
                  <Box marginBottom="20px">
                    {errors.comment && touched.comment && errors.comment}
                  </Box>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>

      <Dialog onClose={handleDeleteClose} open={deleteOpen}>
        <Box
          style={{ animation: animations.popIn }}
          display="flex"
          justifyContent="space-between"
          padding="10px 40px"
        >
          {
            <DeleteDeal
              name={values.name}
              handleClose={handleDeleteClose}
              handleConfirm={handleDeleteConfirm}
            />
          }
        </Box>
      </Dialog>
      <Dialog onClose={handleDeleteCommentClose} open={openDelete}>
        <Box
          style={{ animation: animations.popIn }}
          display="flex"
          justifyContent="space-between"
          padding="10px 40px"
        >
          {
            <DeleteDeal
              openDelete={openDelete}
              name={'Comment'}
              handleClose={handleDeleteCommentClose}
              handleConfirm={handleDeleteCommentConfirm}
            />
          }
        </Box>
      </Dialog>
      <Dialog
        style={{ margin: 50 }}
        onClose={handleEditCommentClose}
        open={openEdit}
      >
        <Box
          style={{ animation: animations.popIn }}
          display="flex"
          justifyContent="space-between"
          padding="15px 50px"
        >
          {
            <EditComment
              comment={comment}
              handleRefreshComments={handleRefreshComments}
              name={'Comment'}
              handleClose={handleEditCommentClose}
            />
          }
        </Box>
      </Dialog>

      <Dialog
        onClose={handleEditClose}
        aria-labelledby="edit-deal-dialog"
        open={editOpen}
        fullScreen
        style={{ left: 'auto' }}
      >
        <Box display="flex" justifyContent="space-between" padding="10px 40px">
          <Box>
            <Typography variant="h5">Edit Deal</Typography>
          </Box>

          <Box>
            <Typography variant="h5">
              <CloseIcon onClick={handleEditClose} />
            </Typography>
          </Box>
        </Box>
        <Box style={{ padding: '0 40px', height: '100%' }}>
          <Divider />
          <EditDeal
            values={values}
            users={users}
            contacts={contactsList}
            onSubmit={editDeal}
            handleClose={handleEditClose}
          />
        </Box>
      </Dialog>

      {/*<Dialog onClose={handleOfferClose} open={offerOpen}>
        <Box display="flex" justifyContent="space-between" padding="10px 40px">
          <DealOffer
            deal={values}
            handleClose={handleOfferClose}
            handleAdd={handleOfferAdd}
          />
        </Box>
        </Dialog>*/}
    </>
  );
};
