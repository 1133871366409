import React from 'react';

const RadioButton = ({
  errors,
  name,
  labelText,
  subtitleText = '',
  register,
  labelComponent,
  checked = false,
  className,
  ...inputProps
}) => {
  return (
    <div
      className={` ${className} relative flex flex-row-reverse items-center`}
    >
      {labelComponent ? (
        labelComponent
      ) : (
        <label
          className="flex items-center text-gray-500 text-xs ml-10"
          htmlFor={name}
        >
          {labelText}
        </label>
      )}
      <div
        className="bg-white cursor-pointer rounded-full border-1 border-gray-200 w-sm h-sm flex flex-shrink-0 justify-center items-center"
        style={{ maxHeight: '24px', height: '24px' }}
      >
        <input
          type="radio"
          className="opacity-0 absolute h-full w-full left-0 top-0 cursor-pointer "
          ref={register}
          name={name}
          id={name}
          {...inputProps}
          checked={checked}
        />
        <svg
          className={` ${
            checked ? 'block' : 'hidden'
          } rounded-full min-w-18 w-18 h-18 bg-primary text-white scale-0 transition-all pointer-events-none`}
          viewBox="0 0 20 20"
        ></svg>
      </div>
    </div>
  );
};

export default RadioButton;
