import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { contactActions } from 'src/common/actions';
import Button from 'src/components/Button';
import ButtonBack from 'src/components/ButtonBack';
import Modal from 'src/components/Modal/Modal';
import TagsForm from 'src/pages/Contacts/components/TagsModal/TagsForm';
import { API_ENDPOINT } from 'src/utils/endpoints';

const TagsModal = ({ setIsTagModalOpened, isTagModalOpened, tags }) => {
  const dispatch = useDispatch();
  /* const areTagsLoaded = useSelector((state) => state.contacts.areTagsLoaded);

  useEffect(() => {
    if (!areTagsLoaded) {
      dispatch(contactActions.getAllTags());
    }
  }, [areTagsLoaded]); */

  const handleCreateTags = async (data) => {
    let name = data.name;
    if (data) {
      await axios
        .post(
          `${API_ENDPOINT}/tags`,
          { name },
          {
            headers: {
              Authorization:
                'Bearer' + localStorage.getItem('crm-client-token'),
              Accept: 'application/json',
            },
          }
        )
        .then(() => {
          dispatch(contactActions.getAllTags());
          setIsTagModalOpened(false);
        })
        .catch((e) => {
          let errors = e.response.data.message + '\n';
          for (let error in e.response.data.errors) {
            errors += e.response.data.errors[error][0] + '\n';
          }
          // setAlertMessage(errors);
          // handleAlertOpen();
        });
    }
  };

  const handleCloseModal = () => {
    setIsTagModalOpened(false);
  };

  return (
    <Modal
      isOpen={isTagModalOpened}
      onRequestClose={() => setIsTagModalOpened(false)}
      headerTitle=""
      className="modal--password"
      style={{
        position: 'relative',
      }}
    >
      <ButtonBack
        variant="click"
        className="mb-15"
        onClick={handleCloseModal}
      />

      <h2 className={`text-2xl font-bold text-black mb-20`}>Add tag </h2>
      <TagsForm /* contact={contact} */ onSubmit={handleCreateTags} />
    </Modal>
  );
};

export { TagsModal };
