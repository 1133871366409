import React from 'react';
import Loader from '../../../Loader';

const CommentStatusButton = ({
  icon,
  onClick,
  disabled,
  loading,
  active,
  setActive,
  className,
}) => {
  const handleActivate = () => {
    setActive(!active);
  };

  const handleClick = (status) => {
    setActive(false);

    onClick(status);
  };

  return (
    <div className="relative">
      <button
        className={`${className}${disabled ? ' cursor-not-allowed' : ''}`}
        disabled={disabled}
        type="button"
        onClick={handleActivate}
      >
        {loading ? <Loader /> : icon}
      </button>
      {Boolean(active) && (
        <ul className="absolute" style={{ top: -120, right: 0 }}>
          <li
            className="text-white px-20 py-5 bg-primary rounded-xs mb-5 cursor-pointer"
            onClick={() => handleClick('public')}
          >
            Public
          </li>
          <li
            className="text-white px-20 py-5 bg-primary rounded-xs mb-5 cursor-pointer"
            onClick={() => handleClick('private')}
          >
            Private
          </li>
          <li
            className="text-white px-20 py-5 bg-primary rounded-xs mb-5 cursor-pointer"
            onClick={() => handleClick('center level')}
          >
            Center
          </li>
        </ul>
      )}
    </div>
  );
};

export default CommentStatusButton;
