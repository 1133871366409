import { contactActionTypes } from '../constants';

const initialState = {
  contact: null,
  isContactLoaded: false,
  isContactLoading: false,
  error: null,
};

export const contact = (state = initialState, action) => {
  switch (action.type) {
    case contactActionTypes.CONTACT_GET_CONTACT_REQUEST:
      return {
        ...state,
        isContactLoading: true,
        isContactLoaded: false,
      };
    case contactActionTypes.CONTACT_GET_CONTACT_SUCCESS:
      return {
        ...state,
        isContactLoading: false,
        isContactLoaded: true,
        contact: action.payload.contact,
      };
    case contactActionTypes.CONTACT_GET_CONTACT_FAILURE:
      return {
        ...state,
        isContactLoading: false,
        isContactLoaded: false,
      };
    case contactActionTypes.CONTACTS_REMOVE_NOTE_SUCCESS: {
      if (!state.contact) return { ...state };

      return {
        ...state,
        contact: {
          ...state.contact,
          notes: state.contact.notes.filter(
            (note) => note.id !== action.payload.deletedContactNoteId
          ),
        },
      };
    }
    case contactActionTypes.CONTACTS_ADD_NOTE_SUCCESS:
      if (!state.contact) return { ...state };

      return {
        ...state,
        contact: {
          ...state.contact,
          notes: [action.payload.note, ...state.contact.notes],
        },
      };
    case contactActionTypes.CONTACTS_UPDATE_NOTE_SUCCESS:
      if (!state.contact) return { ...state };

      return {
        ...state,
        contact: {
          ...state.contact,
          notes: state.contact.notes.map((i) => {
            if (i.id === action.payload.note.id) return action.payload.note;

            return i;
          }),
        },
      };
    case contactActionTypes.CONTACT_UPDATE_CONTACT_IMAGE_SUCCESS:
    case contactActionTypes.CONTACT_EDIT_CONTACT_SUCCESS:
    case contactActionTypes.CONTACT_CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};
