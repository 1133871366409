import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { styles } from './CampaignPreviewEmailModal.module.scss';
import Modal from '../../../components/Modal/Modal';
import { ReactComponent as SvgCheckCircle } from './.././../../assets/icons/icon-check-circle.svg';
import { ReactComponent as SvgBack } from './.././../../assets/icons/back-arrow.svg';
import { mailCampaignsActions } from '../../../common/actions';
import moment from 'moment';
import { toast } from 'react-toastify';
import ButtonBack from '../../../components/ButtonBack/ButtonBack';
import FieldInput from '../../../components/Form/components/FieldInput';

function CampaignPreviewEmailModal({
  toggleCampaignPreviewEmailModal,
  isCampaignPreviewEmailModalOpened,
}) {
  moment.locale('en');
  const dispatch = useDispatch();
  const [previewEmails, setPreviewEmails] = useState(null);

  const message = useSelector((state) =>
    state.mailCampaigns.mailCampaignState.message
      ? state.mailCampaigns.mailCampaignState.message
      : ''
  );

  const subject = useSelector((state) =>
    state.mailCampaigns.mailCampaignState.subject
      ? state.mailCampaigns.mailCampaignState.subject
      : ''
  );

  const onChangePreviewEmails = (e) => {
    const name = e.target.value;
    setPreviewEmails(name);
    //toast.success('Templated uploaded', { className: 'text-white' });
  };
  const submitCampaignPreview = () => {
    if (
      message &&
      message.length > 5 &&
      subject &&
      subject.length > 0 &&
      previewEmails &&
      previewEmails.length > 5
    ) {
      let previewData = {
        message: message,
        emails: previewEmails,
        subject: subject,
      };
      dispatch(mailCampaignsActions.previewEmailCampaign(previewData));
      toggleCampaignPreviewEmailModal();
    } else {
      toast.warning('Please check the campaign data!', {
        className: 'text-white',
      });
    }
  };

  /* const clearCampaignScheduleData = () => {
    dispatch(mailCampaignsActions.setScheduledTime(null));
    toggleCampaignPreviewEmailModal();
  }; */

  return (
    <Modal
      isOpen={isCampaignPreviewEmailModalOpened}
      headerTitle="Preview campaign"
      className="modal--schedule"
      onRequestClose={toggleCampaignPreviewEmailModal}
    >
      <div className="flex items-center mb-40 flex-col">
        <ButtonBack
          onClick={toggleCampaignPreviewEmailModal}
          variant="click"
          className="mb-15"
        />
        <h2 className={`text-2x font-bold text-black mr-20`}>
          Preview campaign
        </h2>
      </div>
      <div className="flex flex-col sm:mb-50 md:mb-0 w-full">
        <FieldInput
          name="template"
          defaultValue=""
          type="text"
          labelText="Email"
          placeholder="Enter emails comma separated"
          className="w-full mb-0 "
          onChange={onChangePreviewEmails}
        />

        <div className="flex flex-between">
          <button
            className="ml-auto bg-gray-300 mt-20 flex text-center justify-center items-center rounded-default pl-0 px-30 py-10 text-sm text-white bg-primary hover:bg-secondary outline-none"
            role="menuitem"
            onClick={toggleCampaignPreviewEmailModal}
            variant="primary"
          >
            <SvgBack className="ml-20 mr-20" />
            Cancel
          </button>
          <button
            type="submit"
            className="ml-auto mt-20 flex text-center justify-center items-center rounded-default pl-0 px-30 py-10 text-sm text-white bg-primary hover:bg-secondary outline-none"
            role="menuitem"
            onClick={submitCampaignPreview}
          >
            <SvgCheckCircle className="ml-20 mr-20" />
            Preview
          </button>
        </div>
      </div>
    </Modal>
  );
}

export { CampaignPreviewEmailModal };
