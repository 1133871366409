import React from 'react';
import InvoicePreview from '../InvoicePreview.js/InvoicePreview';

const OfferPreview = ({ onPrevious, onNext }) => {
  return (
    <div className="flex flex-col rounded-default p-20 bg-white">
      <header className="flex justify-center mb-20">
        <div className="w-3/5">
          <h1 className="text-3xl font-bold text-gray-500 text-center">
            Offer preview
          </h1>
          <p className="text-sm text-gray-400 text-center whitespace-nowrap">
            Please check all data for accuracy
          </p>
        </div>
      </header>
      <main>
        <div className="border-2 border-gray-300 mb-20  p-10">
          <InvoicePreview />
        </div>
      </main>
      <footer className="flex justify-between items-center mb-10">
        <button
          onClick={onPrevious}
          className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-50 outline-none transition duration-500"
        >
          Back
        </button>
        <button
          onClick={onNext}
          className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-50 outline-none transition duration-500"
        >
          Continue
        </button>
      </footer>
    </div>
  );
};

export default OfferPreview;
