import React from 'react';
import LayoutComponent from '../../components/commons/LayoutComponent';
import ProductContent from './components';

const Product = () => {
  return (
    <LayoutComponent>
      <ProductContent />
    </LayoutComponent>
  );
};

export default Product;
