import parsePhoneNumber from 'libphonenumber-js';
import moment from 'moment';

export const formatPhoneNumberInternational = (phoneNumber) => {
  var hasPlusPrefix = phoneNumber.charAt(0) === '+';
  // return parsePhoneNumber(
  //   `${hasPlusPrefix ? '' : '+'}${phoneNumber}`
  // ).formatInternational();
};

export const formatFullTimeToHHMM = (time) => {
  return moment(time, 'HH:mm:ss').format('HH:mm');
};
