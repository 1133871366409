import React from 'react';
import { useSelector } from 'react-redux';
import { usePromiseTracker } from 'react-promise-tracker';
import styles from './Loader.module.scss';

export default function Loader() {
  const { promiseInProgress } = usePromiseTracker();

  return (
    <>
      {promiseInProgress && (
        <div
          className={`flex flex-col w-full h-full bg-gray-500 bg-opacity-50 overflow-y-hidden justify-center items-end md:items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none m-auto`}
        >
          <div className={`${styles.loader}`}>
            <div></div>
            <div></div>
          </div>
          <span className="flex mt-30 text-white font-bold text-md tracking-tight">
            Loading...
          </span>
        </div>
      )}
    </>
  );
}

export { Loader };
