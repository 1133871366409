import React, { useEffect, useState } from 'react';

import styles from './NoteForm.module.scss';
import Select from '../../Select/Select';
import FieldInput from '../../Form/components/FieldInput';
import Button from '../../Button';
import FieldTextarea from '../../Form/components/FieldTextarea';
import { useForm } from 'react-hook-form';
import { findDefaultValue } from '../../../common/helpers';
import { useSelector } from 'react-redux';
import { notesService } from '../../../common/services/notesService';
import { useQuery } from 'react-query';

const NoteForm = ({
  onSubmit,
  buttonText = 'Add note',
  defaultValues = {},
  contact,
  submitting = false,
}) => {
  const {
    isLoading: isLoadingCategories,
    isSuccess: isSuccessCategories,
    data: categories = [],
  } = useQuery('note-categories', () => notesService.getCategories());
  const [isActivated] = useState(false);

  const { contactsList } = useSelector((state) => state.contacts);

  const contactsMapped = React.useMemo(() =>
    contactsList.map((c) => ({
      label: `${c.first_name} ${c.last_name}`,
      value: c.id,
    }))
  );

  const categoriesMapped = React.useMemo(() => {
    return (categories.data || []).map((category) => {
      const item = { name: category.name, id: category.id };

      return {
        label: category.name,
        options: [item, ...category.children].map((child) => ({
          label: child.name,
          value: child.id,
        })),
      };
    });
  }, [categories]);

  const groupOptions = [
    { value: 'Master Sha Event', label: 'Master Sha Event' },
    { value: 'Global Event', label: 'Global Event' },
    { value: 'Centre Event', label: 'Centre Event' },
    { value: 'LPH Event', label: 'LPH Event' },
    { value: 'Tao Research', label: 'Tao Research' },
  ];

  const typeOptions = [
    { value: 'Consultation', label: 'Consultation' },
    { value: 'Follow-up', label: 'Follow-up' },
    { value: 'General', label: 'General' },
  ];

  const statusOptions = [
    { value: 'public', label: 'Public' },
    { value: 'private', label: 'Private' },
    { value: 'center level', label: 'Center Level' },
  ];

  const activityOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];

  const { register, handleSubmit, watch, errors, control, getValues } = useForm(
    {
      mode: 'onChange',
    }
  );

  const defaultValueGroup = findDefaultValue(
    groupOptions,
    'group_name',
    defaultValues
  );

  const defaultValueType = findDefaultValue(typeOptions, 'type', defaultValues);

  const defaultValueStatus = findDefaultValue(
    statusOptions,
    'status_name',
    defaultValues
  );

  const defaultValueCategories = React.useMemo(() => {
    return defaultValues.categories
      ? defaultValues.categories.map((category) => ({
          label: category.name,
          value: category.id,
        }))
      : [];
  }, [defaultValues]);

  const defaultValueAssignedTo = React.useMemo(() => {
    const assignedTo = defaultValues.assigned_to
      ? defaultValues.assigned_to.filter(Boolean)
      : [];

    if (contact && !assignedTo.find((single) => single.id === contact.id))
      assignedTo.push(contact);

    return assignedTo.map((assignee) => ({
      label: `${assignee.first_name} ${assignee.last_name}`,
      value: assignee.id,
    }));
  }, [defaultValues]);

  const watchCat = watch('category');

  const isConsultation =
    Array.isArray(watchCat) &&
    watchCat.length === 1 &&
    watchCat[0].label === 'Consultation';

  const watchType = watch('type');
  const selectedFollowUp =
    watchType &&
    watchType.hasOwnProperty('value') &&
    watchType.value === 'Follow-up';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between mt-30">
        <Select
          className="w-full"
          labelText="Category"
          name="category"
          options={categoriesMapped}
          placeholder="Category"
          register={register}
          control={control}
          defaultValue={defaultValueCategories}
          isMulti
          value={watchCat}
          disabled={isLoadingCategories || !isSuccessCategories}
        />
      </div>
      {!isActivated && (
        <Select
          className="w-full"
          labelText="Activity"
          name="activity"
          options={activityOptions}
          placeholder="Activity"
          register={register}
          control={control}
          value={watch('activity')}
          defaultValue={{
            value: activityOptions[0].value,
            label: activityOptions[0].label,
          }}
        />
      )}

      <Select
        className="w-full"
        labelText="Type"
        name="type"
        options={typeOptions}
        placeholder="Type"
        register={register}
        control={control}
        value={watch('type')}
        defaultValue={defaultValueType}
      />

      <div className="flex justify-between">
        {selectedFollowUp && (
          <Select
            className="w-half"
            labelText="Follow-up Type"
            name="group"
            options={groupOptions}
            placeholder="Follow-up Type"
            register={register}
            control={control}
            value={watch('group')}
            defaultValue={defaultValueGroup}
          />
        )}
        <Select
          className={selectedFollowUp ? 'w-half' : 'w-full'}
          labelText="Status"
          name="status"
          options={statusOptions}
          placeholder="Status"
          //register={register}
          control={control}
          defaultValue={defaultValueStatus}
        />
      </div>

      <Select
        labelText="Assigned to"
        name="assigned_to"
        options={contactsMapped}
        placeholder="Assigned to"
        //register={register}
        control={control}
        defaultValue={defaultValueAssignedTo}
        isMulti
      />

      <FieldInput
        className="mb-10"
        labelText="Title"
        register={register}
        name="title"
        defaultValue={defaultValues.name || ''}
        placeholder="Enter your note title"
        type="text"
        errors={errors}
        value={watch('title')}
      />

      <FieldTextarea
        className={`${styles.textarea} mb-10`}
        labelText="Message"
        placeholder="This is your note example"
        register={register}
        name="message"
        defaultValue={defaultValues.message || ''}
        type="text"
        errors={errors}
        value={watch('message')}
      />

      <Button
        variant="primary"
        height="55"
        width="280"
        onClick={handleSubmit}
        isLoading={submitting}
        disabled={submitting}
        className="w-full"
      >
        {buttonText}
      </Button>
    </form>
  );
};

export default NoteForm;
