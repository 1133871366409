import { combineReducers } from 'redux';
import { auth } from './authReducer';
import { contacts } from './contactsReducer';
import { mailCampaigns } from './mailCampaignsReducer';
import { contact } from './contactReducer';
import { notes } from './notesReducer';
import { calendar } from './calendarReducer';
import { notification } from './notificationReducer';
import { chat } from './chatReducer';
import { dealsReducer } from './dealsReducer';
import { companiesReducer } from './companiesReducer';

export const appReducer = combineReducers({
  auth,
  contacts,
  mailCampaigns,
  contact,
  notes,
  calendar,
  notification,
  chat,
  dealsReducer,
  companiesReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'AUTH_LOGOUT_USER') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
