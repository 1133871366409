import React from 'react';
import ChatMessageBubble from '../ChatMessageBubble';
import moment from 'moment';

const ChatMessage = ({ date, messages, myId }) => {
  const fromNow = moment(date).fromNow();

  return (
    <>
      <div
        className={`flex items-center justify-center w-full border-t-1 border-gray-300 border-solid mb-20 mt-20`}
      >
        <span
          className="flex text-xxs text-gray-400 align-center bg-white px-5"
          style={{ marginTop: '-10px' }}
        >
          {fromNow}
        </span>
      </div>
      <div className="mb-10 w-full">
        {messages.map((message, i) => (
          <div key={i} className="flex flex-col">
            <ChatMessageBubble
              isMy={myId == message.sender.id}
              message={message}
              last={messages[i - 1] || undefined}
            />
          </div>
        ))}
      </div>
    </>
  );
};
export default ChatMessage;
