import { toast } from 'react-toastify';
import { contactActionTypes } from '../constants';
import {
  notesActionTypes,
  notesToastMessages,
} from '../constants/notesConstants';
import { actionCreator } from '../helpers';
import { notesService } from '../services/notesService';

const getAllNotes = (track = true) => (dispatch) => {
  dispatch(actionCreator(notesActionTypes.NOTES_GET_NOTES_REQUEST));
  notesService
    .getAllNotes(track)
    .then((res) => {
      dispatch(
        actionCreator(notesActionTypes.NOTES_GET_NOTES_SUCCESS, {
          list: res.data,
        })
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(notesActionTypes.NOTES_GET_NOTES_FAILURE, {
          error: err,
        })
      );
    });
};

const deleteNote = (noteId) => (dispatch) => {
  dispatch(actionCreator(notesActionTypes.NOTES_DELETE_NOTE_REQUEST));
  notesService
    .deleteNote(noteId)
    .then((res) => {
      toast(
        notesToastMessages[notesActionTypes.NOTES_DELETE_NOTE_SUCCESS].message
      );
      dispatch(
        actionCreator(notesActionTypes.NOTES_DELETE_NOTE_SUCCESS, {
          deletedNoteId: noteId,
        })
      );
      dispatch(
        actionCreator(contactActionTypes.CONTACTS_REMOVE_NOTE_SUCCESS, {
          deletedContactNoteId: noteId,
        })
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(notesActionTypes.NOTES_DELETE_NOTE_FAILURE, {
          error: err,
        })
      );
    });
};

const addNote = (data, setIsProfileNoteModalOpened) => (dispatch) => {
  const noteData = validateNote(data);

  if (!noteData) return false;

  dispatch(actionCreator(notesActionTypes.NOTES_ADD_NOTE_REQUEST));
  notesService
    .addNote(noteData)
    .then((res) => {
      toast(
        notesToastMessages[notesActionTypes.NOTES_ADD_NOTE_SUCCESS].message
      );
      setIsProfileNoteModalOpened(false);
      dispatch(
        actionCreator(notesActionTypes.NOTES_ADD_NOTE_SUCCESS, {
          note: res,
        })
      );
      dispatch(
        actionCreator(contactActionTypes.CONTACTS_ADD_NOTE_SUCCESS, {
          note: res,
        })
      );
      //dispatch(contactActions.getAllContacts());
    })
    .catch((err) => {
      dispatch(
        actionCreator(notesActionTypes.NOTES_ADD_NOTE_FAILURE, {
          error: err,
        })
      );
    });
};

const updateNote = (noteId, data, setEditing) => (dispatch) => {
  const noteData = validateNote(data);

  if (!noteData) return false;

  dispatch(actionCreator(notesActionTypes.NOTES_UPDATE_NOTE_REQUEST));
  notesService
    .updateNote(noteId, noteData)
    .then((res) => {
      toast(
        notesToastMessages[notesActionTypes.NOTES_UPDATE_NOTE_SUCCESS].message
      );
      setEditing(false);
      dispatch(
        actionCreator(notesActionTypes.NOTES_UPDATE_NOTE_SUCCESS, {
          note: res,
        })
      );
      dispatch(
        actionCreator(contactActionTypes.CONTACTS_UPDATE_NOTE_SUCCESS, {
          note: res,
        })
      );
      //dispatch(contactActions.getAllContacts());
    })
    .catch((err) => {
      dispatch(
        actionCreator(notesActionTypes.NOTES_UPDATE_NOTE_FAILURE, {
          error: err,
        })
      );
    });
};

const validateNote = (data) => {
  if (
    data.title &&
    data.message &&
    data.status &&
    data.status.value &&
    // data.group &&
    // data.group.value &&
    data.type &&
    data.type.value &&
    data.category &&
    data.category.length
  ) {
    let validated = {
      name: data.title,
      message: data.message,
      status_name: data.status.value,
      group_name: data.group ? data.group.value : '',
      categories: data.category.map((c) => c.value),
      type: data.type ? data.type.value : '',
    };

    if (data.contacts_id) validated.contacts_id = data.contacts_id;

    if (Array.isArray(data.assigned_to))
      validated.assigned_to = data.assigned_to.map((i) => i.value);

    return validated;
  } else {
    toast.warning('Please fill all fields for note!', {
      className: 'text-white',
    });

    return false;
  }
};

export const notesActions = {
  getAllNotes,
  addNote,
  deleteNote,
  updateNote,
};
