import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import moment from 'moment';
import ButtonBack from 'src/components/ButtonBack';
import { useHistory } from 'react-router-dom';
import { routes } from 'src/common/constants';
const SearchContent = ({ searchResults, search }) => {
  const history = useHistory();
  const renderResult = (item, name, index, type) => {
    return (
      <Grid
        onClick={() =>
          type != 'users'
            ? history.push(
                type && type == 'contact'
                  ? `${routes.CONTACT.path}?contactId=${item.contact_id}`
                  : type == 'campaign'
                  ? `${routes.STATISTICS_CAMPAIGN.path}?campaignId=${item.campaign_id}`
                  : type == 'notes'
                  ? `${routes.NOTES.path}`
                  : null
              )
            : null
        }
        key={index}
        item
        xs={2}
      >
        <Box
          style={{
            cursor: 'pointer',
            padding: 15,
            backgroundColor: '#BDDBEF',
            borderRadius: '7px',
          }}
        >
          <div
            style={{
              fontWeight: 550,
              fontSize: 17,
            }}
          >
            {name}
          </div>
          <Box width="100%" display="flex" justifyContent="space-between">
            <div style={{ fontSize: 13 }}>
              {moment(item && item.created_at && item.created_at).format(
                'DD/MM/YY'
              )}
            </div>
          </Box>
        </Box>
      </Grid>
    );
  };
  return (
    <>
      <div style={{ alignItems: 'center' }} className="flex">
        <h2 className="text-3xl font-bold  mr-20 text-black  border-l-0 border-t-0 border-b-0 pr-20 border-1 border-gray-400">
          Search result
        </h2>
        <h2 className="text-md font-bold  mr-20 text-black  border-l-0 border-t-0 border-b-0">
          {search}
        </h2>
      </div>
      <ButtonBack className="justify-end -mt-50" />
      <Grid container spacing={3} className="mt-20">
        {searchResults && searchResults.contacts ? (
          <>
            <h2
              style={{ width: '100%' }}
              className="text-2xl font-bold  ml-10 text-black pr-20"
            >
              Contacts:
            </h2>
            {searchResults.contacts.map((item, index) => {
              let name = item.contact_first_name + item.contact_last_name;
              const type = 'contact';
              return renderResult(item, name, index, type);
            })}{' '}
          </>
        ) : null}
        {searchResults && searchResults.campaign ? (
          <>
            <h2
              style={{ width: '100%' }}
              className="text-2xl font-bold  ml-10 text-black pr-20"
            >
              Campaign:
            </h2>
            {searchResults.campaign.map((item, index) => {
              let name = item.campaign_name;
              const type = 'campaign';
              return renderResult(item, name, index, type);
            })}{' '}
          </>
        ) : null}
        {searchResults && searchResults.notes ? (
          <>
            <h2
              style={{ width: '100%' }}
              className="text-2xl font-bold  ml-10 text-black pr-20"
            >
              Notes:
            </h2>
            {searchResults.notes.map((item, index) => {
              let name = item.note_name;
              const type = 'notes';
              return renderResult(item, name, index, type);
            })}{' '}
          </>
        ) : null}

        {searchResults && searchResults.users ? (
          <>
            <h2
              style={{ width: '100%' }}
              className="text-2xl font-bold  ml-10 text-black pr-20"
            >
              Users:
            </h2>
            {searchResults.users.map((item, index) => {
              let name = item.user_name;
              const type = 'users';
              return renderResult(item, name, index, type);
            })}{' '}
          </>
        ) : null}
      </Grid>
    </>
  );
};

export default SearchContent;
