import React, { useState } from 'react';
import { useHistory } from 'react-router';
import MemoTwoEnvelopes from '../../assets/ComponentIcons/TwoEnvelopes';
import { routes } from '../../common/constants';
import ForgotPasswordForm from '../../components/Auth/ForgotPasswordForm';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Title from '../../components/Tailwind/Title';
import styles from './Login.module.scss';

const ForgotPassword = () => {
  const [sentTo, setSentTo] = useState(false);
  const history = useHistory();

  const handleRedirectLogin = () => {
    history.push(routes.LOGIN.path);
  };

  const handleEnterCode = () => {
    history.push(routes.RESET_PASSWORD.path + '?email=' + sentTo);
  };

  return (
    <section className={`${styles.login}`}>
      <Modal
        isOpen={true}
        onRequestClose={handleRedirectLogin}
        headerTitle="CRM Soul Group"
        className="modal--password"
        closeContent={
          <span className="text-primary font-bold underline pt-5">Sign in</span>
        }
      >
        {sentTo ? (
          <div className="flex flex-col items-center text-center">
            <MemoTwoEnvelopes className="mt-50" />
            <Title className="mt-25 mb-15">Email Sent!</Title>

            <p style={{ maxWidth: 350 }} className="text-gray-400 mb-25">
              We have sent you an email on{' '}
              <span className="text-primary">{sentTo}</span> check your email!
            </p>

            <Button
              variant="primary"
              height="44"
              width=""
              className="m-auto w-full"
              onClick={handleEnterCode}
            >
              Enter code
            </Button>
          </div>
        ) : (
          <ResetAction {...{ sentTo, setSentTo }} />
        )}
        <div className="mb-100" />
        <hr className="border-gray-100 my-20 sm:hidden md:block" />
        <p className={`w-full text-xs text-center text-gray-400 mb-0`}>
          © 2021 CRM Soul Group. All rights reserved.
        </p>
      </Modal>
    </section>
  );
};

const ResetAction = ({ setSentTo }) => (
  <>
    <Title className="mt-50 mb-20">Forgot Password?</Title>
    <p className={`w-full text-sm text-gray-400 mb-40`}>
      Enter your email address so we will send you reset link on the address you
      provided.
    </p>
    <ForgotPasswordForm {...{ setSentTo }} />
  </>
);

export default ForgotPassword;
