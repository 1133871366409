import React, { memo } from 'react';
import moment from 'moment';
import CommentDisplayActions from './ComentDisplayActions';
import { useSelector } from 'react-redux';

const CommentDisplay = ({ className, comment, reload }) => {
  moment.locale('en');

  const { user } = useSelector((state) => state.auth);

  let color = comment?.background_color ? comment.background_color : '#ffffff';

  return (
    <>
      <div
        className={`${className ? `${className} ` : ''}flex mb-20`}
        style={{
          padding: '10px',
          borderRadius: '6px',
          border: `1px solid ${color}`,
          backgroundColor: `${color}35`,
        }}
      >
        <img
          className="h-24 w-24 rounded-full"
          src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=256&amp;h=256&amp;q=60"
          alt=""
        />
        <div className="pl-15 flex-1">
          <div className="flex justify-between">
            <h5 className="text-xs font-bold">{comment.user.name}</h5>
            {user.id == comment.user.id && (
              <div className="actions flex">
                <CommentDisplayActions comment={comment} reload={reload} />
              </div>
            )}
          </div>
          <p className="text-xs text-gray-400 py-5 leading-tight">
            {comment.message}
          </p>
          <p className="text-xxs text-gray-400 ">
            {comment.created_at ? moment(comment.created_at).format('L') : '-'}
          </p>
        </div>
      </div>
    </>
  );
};

export default memo(CommentDisplay);
