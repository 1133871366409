import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from 'src/common/actions';
import { groupByDate } from 'src/common/helpers';
import ChatInput from '../ChatInput';
import ChatMessage from '../ChatMessage';
import ActionsMenu from 'src/components/Misc/ActionsMenu';
import ChatModal from '../ChatModal';
import { toast } from 'react-toastify';

export default function ChatWindow({ chatUsers }) {
  const { chat } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [showActions, setShowActions] = useState(false);
  const [editing, setEditing] = useState(false);

  const chatWindowRef = useRef(null);
  const user = useSelector((state) => state.auth.user);

  const currentChat = chat.conversation;
  const conversationData = currentChat.conversationData;

  const created_by = conversationData.data?.created_by;

  const isGroupChat = conversationData.is_group;

  const fromNow = moment(conversationData.updated_at).fromNow();

  const groups = React.useMemo(() => {
    const sortedMessages = currentChat.messages
      .sort((a, b) => a.id - b.id)
      .filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj['id']).indexOf(obj['id']) === pos;
      });

    return groupByDate(sortedMessages, 'created_at', true);
  }, [currentChat]);

  const handleEdit = () => {
    setEditing(true);
    setShowActions(false);
  };

  const onEdit = (selected, name) => {
    dispatch(
      chatActions.updateConversation(
        conversationData.id,
        { selected, name },
        () => {
          toast.success('Conversation updated', {
            className: 'text-white',
          });
        },
        (e) => {
          toast.warning(e, {
            className: 'text-white',
          });
        }
      )
    );
  };

  const handleDelete = () => {
    const sure = confirm('Are you sure ?');

    if (sure) {
      dispatch(
        chatActions.deleteConversation(
          conversationData.id,
          () => {
            toast.success('Conversation deleted', { className: 'text-white' });
          },
          () => {
            toast.warning('There was error !', {
              className: 'text-white',
            });
          }
        )
      );
    }

    setShowActions(false);
  };
  const handleDeleteMy = () => {
    const sure = confirm('Are you sure ?');

    if (sure) {
      dispatch(
        chatActions.deleteConversationMy(
          conversationData.id,
          () => {
            toast.success('Conversation deleted local', {
              className: 'text-white',
            });
          },
          () => {
            toast.warning('There was error !', {
              className: 'text-white',
            });
          }
        )
      );
    }

    setShowActions(false);
  };

  useEffect(() => {
    if (chatWindowRef.current)
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
  }, [currentChat]);

  useEffect(() => {
    let interval = setInterval(() => {
      dispatch(chatActions.pingConversation(conversationData.id));
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [conversationData.id, chat.lastPing]);

  return (
    <>
      <div className="w-half flex flex-col justify-start mt-40 mb-20 bg-white p-0 rounded-default">
        <div className="flex flex-col bg-gray-300 w-full p-15 rounded-default rounded-b-none mb-20">
          <div className="flex">
            {/* <SvgAvatar
            className="rounded-full object-cover object-center mr-20"
            style={{ minWidth: '44px', maxWidth: '44px', height: '44px' }}
          /> */}
            <div className="flex flex-col text-sm text-gray-500 font-bold">
              {conversationData.name}{' '}
              {isGroupChat &&
                conversationData.name !== conversationData.names && (
                  <i>({conversationData.names})</i>
                )}
              <span className="text-gray-400 text-xxs font-normal mt-5">
                {fromNow}
              </span>
            </div>
            <div className="ml-auto my-auto">
              {
                <ActionsMenu
                  visible={showActions}
                  setVisible={setShowActions}
                  actions={
                    created_by == user.id && isGroupChat
                      ? [
                          { label: 'Edit for everyone', action: handleEdit },
                          {
                            label: 'Delete for everyone',
                            action: handleDelete,
                          },
                          { label: 'Delete for me', action: handleDeleteMy },
                        ]
                      : created_by == user.id
                      ? [
                          {
                            label: 'Delete for everyone',
                            action: handleDelete,
                          },
                          { label: 'Delete for me', action: handleDeleteMy },
                        ]
                      : [{ label: 'Delete for me', action: handleDeleteMy }]
                  }
                />
              }
            </div>
          </div>
        </div>
        <div
          className="flex flex-col px-20 overflow-y-scroll overflow-x-hidden flex-1"
          ref={chatWindowRef}
        >
          {Object.keys(groups).length
            ? Object.keys(groups).map((key) => (
                <ChatMessage
                  key={key}
                  messages={groups[key]}
                  date={groups[key][groups[key].length - 1].created_at}
                  myId={user.id}
                  // direction={user.id === message.send_by ? 'outgoing' : 'incoming'}
                />
              ))
            : 'Start chat by writing first message.'}
        </div>
        <ChatInput />
      </div>

      {editing && (
        <ChatModal
          editing={true}
          setOpened={setEditing}
          isOpened={editing}
          contactsList={chatUsers}
          onSubmit={onEdit}
          defaultValues={{
            name: conversationData.name,
            selected: conversationData.ids,
          }}
        />
      )}
    </>
  );
}
