import { calendarActionTypes } from '../actions/calendarActions';

const initialState = {
  calendarItems: [],
  isLoading: false,
  isLoaded: false,
};

export const calendar = (state = initialState, action) => {
  switch (action.type) {
    case calendarActionTypes.CALENDAR_GET_CALENDAR_ITEM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case calendarActionTypes.CALENDAR_GET_CALENDAR_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        calendarItems: action.payload,
      };
    case calendarActionTypes.CALENDAR_GET_CALENDAR_ITEM_FAILURE:
      return {
        ...initialState,
      };

    case calendarActionTypes.CALENDAR_CREATE_CALENDAR_ITEM_SUCCESS:
      return {
        ...state,
        calendarItems: [...state.calendarItems, ...action.payload],
      };

    case calendarActionTypes.CALENDAR_UPDATE_CALENDAR_ITEM_SUCCESS:
      return {
        ...state,
        calendarItems: [
          ...state.calendarItems.filter((i) => i.id !== action.payload.id),
          ...action.payload.res,
        ],
      };

    case calendarActionTypes.CALENDAR_DELETE_CALENDAR_ITEM_SUCCESS:
      return {
        ...state,
        calendarItems: state.calendarItems.filter(
          (i) => i.id !== action.payload.id
        ),
      };

    default:
      return state;
  }
};
