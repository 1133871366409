import React from 'react';

const BlockWithTitle = ({ title, content }) => {
  if (!content.length) return <></>;

  return (
    <div className="mb-25">
      <h3 className="text-md font-semibold text-black mb-10">{title}</h3>
      {content}
    </div>
  );
};

export default BlockWithTitle;
