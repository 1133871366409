import React from 'react';
import { toast } from 'react-toastify';
import { companyService } from 'src/common/services/companyService';
import ModalHeaderActions from 'src/components/Misc/ModalHeaderActions';
import Modal from 'src/components/Modal';
import CompanyForm from './CompanyForm';

export default function CompanyModal({ open, onClose }) {
  const handleSubmit = async (data) => {
    return companyService
      .createCompany(data)
      .then(() => {
        toast.success('Company created', { className: 'text-white' });
        onClose();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <Modal isOpen={open} className="modal--notepopup">
      <ModalHeaderActions name="Add company" onClose={onClose} />
      <CompanyForm onSubmit={handleSubmit} />
    </Modal>
  );
}
