import React from 'react';
import { NavLink } from 'react-router-dom';
const primaryColor = '#ffffff';

const SidebarItem = ({
  icon,
  title,
  isActive,
  handleNav = undefined,
  handleOnClick = undefined,
  objectItem,
}) => {
  const Icon = icon;

  if (handleOnClick) {
    return (
      <div
        className={` ${
          isActive ? 'bg-primary' : 'bg-yellow'
        } py-20 px-20 text-xs"`}
      >
        <button onClick={handleOnClick} className="flex items-center relative">
          <Icon fill="text-white" className="mr-20 min-w-xs w-xs" />

          <h1 className="text-left text-white">{title}</h1>
        </button>
      </div>
    );
  }

  return (
    <div
      className={` ${
        isActive ? 'bg-primary' : 'bg-yellow'
      } px-20 py-20 text-xs"`}
    >
      <NavLink to={handleNav} className={`flex items-center relative `}>
        <Icon fill={'#fff'} className="mr-20 min-w-xs w-xs" />
        <div>
          <h1 className="text-white">{title}</h1>
        </div>
      </NavLink>
    </div>
  );
};

export default SidebarItem;
