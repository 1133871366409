export const routes = {
  HOME: {
    path: '/',
  },
  DASHBOARD: {
    path: '/dashboard',
  },
  CALENDAR: {
    path: '/calendar',
  },
  OUTREACH: {
    path: '/outreach',
  },
  STATS: {
    path: '/stats',
  },
  MAIL_CAMPAIGNS: {
    path: '/mail-campaigns',
  },
  ADD_CAMPAIGN: {
    path: '/add-campaign',
  },
  EDIT_CAMPAIGN: {
    path: '/edit-campaign',
  },
  STATISTICS_CAMPAIGN: {
    path: '/statistic-campaign',
  },
  CONTACTS: {
    path: '/contacts',
  },
  CONTACT: {
    path: '/contact',
  },
  ADD_CONTACT: {
    path: '/add-contact',
  },
  LOGIN: {
    path: '/login',
  },
  FORGOT_PASSWORD: {
    path: '/forgot-password',
  },
  RESET_PASSWORD: {
    path: '/reset-password',
  },
  ADD_ACCOUNT: {
    path: '/add-account',
  },
  NOTES: {
    path: '/notes',
  },
  PROFILE: {
    path: '/profile',
  },
  CHAT: {
    path: '/chat',
  },
  NOTIFICATIONS: {
    path: '/notifications',
  },
  USERS: {
    path: '/admin/users',
  },
  COMPANIES: {
    path: '/admin/companies',
  },
  SETTINGS: {
    path: '/settings',
  },
  OFFERS: {
    path: '/offers',
  },
  OFFER: {
    path: '/offer',
  },
  PRODUCTS: {
    path: '/products',
  },
  PRODUCT: {
    path: '/product',
  },
  ADDPRODUCT: {
    path: '/add-product',
  },
  NEWOFFER: {
    path: '/new-offer',
  },
  SEARCH: {
    path: '/search',
  },
};

export const perPage = 10;
