import React, { useState } from 'react';
import { ReactComponent as SvgDelete } from '../../assets/icons/icon-delete-campaign.svg';
import { ReactComponent as SvgEdit } from '../../assets/icons/icon-edit.svg';
import { ReactComponent as SvgClose } from '../../assets/icons/close-icon.svg';
import Modal from '../Modal';
import ButtonBack from '../ButtonBack';

const ModalHeaderActions = ({
  name,
  editing,
  setEditing,
  canEdit = false,
  onEdit,
  onDelete,
  onClose,
}) => {
  const [isDeleteNoteModalOpened, setDeleteNoteModalOpened] = useState(false);

  const toggleDeleteNoteModal = () => {
    setDeleteNoteModalOpened(!isDeleteNoteModalOpened);
  };

  const handleDelete = (data) => {
    setDeleteNoteModalOpened(false);

    if (onDelete) onDelete(data);
  };

  return (
    <>
      <div className="flex justify-between items-center w-full mb-20">
        <h3 className="text-3xl font-bold text-black">
          {editing ? <>Edit: {name}</> : name}
        </h3>

        <div className="flex items-center">
          {canEdit && (
            <>
              {!editing && (
                <button className="appearance-none flex ml-10" onClick={onEdit}>
                  <SvgEdit className="flex text-gray-500"></SvgEdit>
                </button>
              )}
              <button
                className="appearance-none flex ml-10"
                onClick={toggleDeleteNoteModal}
              >
                <SvgDelete fill="text-gray-400" className="flex"></SvgDelete>
              </button>
            </>
          )}
          <button className="appearance-none flex ml-10" onClick={onClose}>
            <SvgClose fill="text-gray-400" className="flex"></SvgClose>
          </button>
        </div>
      </div>
      <Modal
        isOpen={isDeleteNoteModalOpened}
        onRequestClose={toggleDeleteNoteModal}
        headerTitle="Delete item"
        className="modal--alert"
      >
        <h2 className={`w-full text-2x font-bold text-black mt-50 mb-20`}>
          Delete item?
        </h2>
        <div className="flex justify-between">
          <a
            className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-primary hover:bg-secondary"
            role="menuitem"
            onClick={handleDelete}
          >
            Yes
          </a>
          <a
            className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-gray-500 hover:bg-gray-400"
            role="menuitem"
            onClick={toggleDeleteNoteModal}
          >
            Cancel
          </a>
        </div>
      </Modal>
    </>
  );
};

export default ModalHeaderActions;
