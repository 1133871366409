import React from 'react';
import { BsThreeDots } from 'react-icons/bs';

export default function ActionsMenu({
  className = '',
  visible = false,
  setVisible = () => null,
  actions = [],
}) {
  const toggleVisible = () => {
    setVisible(!visible);
  };

  return (
    <div className={`${className} ml-10 relative`}>
      <button
        className="flex items-center focus:outline-none font-medium text-xs text-black px-10"
        id="user-menu"
        aria-haspopup="true"
        onClick={toggleVisible}
      >
        <BsThreeDots />
      </button>
      {visible && (
        <div
          className={`transition-opacity duration-150 ease-in-out origin-top-right absolute mt-10 rounded-default shadow-lg py-1 bg-white overflow-hidden`}
          style={{ left: '-30px', top: '40px', width: '100px' }}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          {actions.map((i) => (
            <a
              className="block px-10 py-10 text-xxs text-gray-500 hover:bg-gray-100 w-full"
              role="menuitem"
              onClick={i.action}
              key={i.label}
            >
              {i.label}
            </a>
          ))}
        </div>
      )}
    </div>
  );
}
