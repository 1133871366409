import React from 'react';

const Checkbox = ({
  errors,
  name,
  labelText,
  subtitleText = '',
  // register,
  labelComponent,
  checked = false,
  variant,
  className,
  ...inputProps
}) => {
  if (variant == 'checkblock') {
    return (
      <div
        className={` ${
          checked ? 'bg-primary' : 'bg-gray-300'
        } ${className} w-full relative checkbox cursor-pointer flex flex-shrink-0 justify-center items-center rounded-default p-30`}
      >
        <input
          type="checkbox"
          className="opacity-0 absolute h-full w-full left-0 top-0 cursor-pointer"
          // ref={register}
          name={name}
          id={name}
          {...inputProps}
        />
        {labelComponent ? (
          labelComponent
        ) : (
          <div className="flex-col flex items-center justify-center">
            <label
              className={`block text-xs mb-10 font-bold l ${
                checked ? 'text-white' : 'text-gray-500'
              }`}
              htmlFor={name}
            >
              {labelText}
            </label>
            <p
              className={`block text-xxs text-center ${
                checked ? 'text-gray-100' : 'text-gray-400'
              }`}
            >
              {subtitleText}
            </p>
          </div>
        )}
        {/* {errors && errors[name] && (
        <p className="text-sm text-teal-800">{errors[name].message}</p>
      )} */}
      </div>
    );
  }
  return (
    <div className="checkbox flex py-15 justify-between items-center relative">
      <div
        className={` ${
          checked ? 'border-primary' : 'border-gray-200'
        } bg-white cursor-pointer border-2 w-20 h-20 flex flex-shrink-0 justify-center items-center`}
        style={{ borderRadius: '4px' }}
      >
        <input
          type="checkbox"
          className="opacity-0 absolute h-full w-full left-0 top-0 cursor-pointer"
          // ref={register}
          name={name}
          id={name}
          {...inputProps}
        />
        <svg
          className={` ${
            checked ? 'block' : 'hidden'
          } bg-primary text-white scale-0 transition-all pointer-events-none`}
          viewBox="0 0 20 20"
          style={{ width: '12px', height: '12px', borderRadius: '1px' }}
        ></svg>
      </div>
      {labelComponent ? (
        labelComponent
      ) : (
        <label className="block text-gray-400 text-xs ml-10" htmlFor={name}>
          {labelText}
          <p className="text-xxs text-gray-300">{subtitleText}</p>
        </label>
      )}

      {/* {errors && errors[name] && (
        <p className="text-sm text-teal-800">{errors[name].message}</p>
      )} */}
    </div>
  );
};

export default Checkbox;
