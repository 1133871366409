import React from 'react';
import { ReactComponent as SvgAvatar } from './../../../../assets/icons/icon-avatar-male.svg';
import Checkbox from '../../../../components/Form/components/Checkbox/Checkbox';

const ChatContact = ({ contact, clickable, checked, onSelect }) => {
  const selectUser = () => {
    onSelect(contact.id);
  };

  // const getStatus = (status) => {
  //   switch (status) {
  //     case 'Visitor':
  //       return (
  //         <span
  //           className="flex text-xxs font-medium px-10 py-2 border-1 rounded-default"
  //           style={{
  //             color: '#54CB60',
  //             backgroundColor: ' #E0F5E2',
  //             borderColor: '#54CB60',
  //           }}
  //         >
  //           Visitor
  //         </span>
  //       );
  //     case 'Customer':
  //       return (
  //         <span
  //           className="flex text-xxs font-medium px-10 py-2 border-1 rounded-default"
  //           style={{
  //             color: '#A479FF',
  //             backgroundColor: '#EAE0FF',
  //             borderColor: '#A479FF',
  //           }}
  //         >
  //           Customer
  //         </span>
  //       );
  //     case 'Lead':
  //       return (
  //         <span
  //           className="flex text-xxs font-medium px-10 py-2 border-1 rounded-default"
  //           style={{
  //             color: '#A479FF',
  //             backgroundColor: '#EAE0FF',
  //             borderColor: '#A479FF',
  //           }}
  //         >
  //           Lead
  //         </span>
  //       );
  //     default:
  //       return (
  //         <span
  //           className="flex text-xxs font-medium px-10 py-2 border-1 rounded-default"
  //           style={{
  //             color: '#FFA879',
  //             backgroundColor: '#FFEBE0',
  //             borderColor: '#FFA879',
  //           }}
  //         >
  //           ?
  //         </span>
  //       );
  //   }
  // };

  const renderProfileImage = () => {
    if (contact.image) {
      return (
        <img
          className="h-24 w-24 rounded-full object-cover object-center"
          style={{ minWidth: '24px', maxWidth: '24px' }}
          src={contact.image}
          alt=""
        />
      );
    } else {
      return (
        <SvgAvatar
          className="h-24 w-24 rounded-full object-cover object-center"
          style={{ minWidth: '24px', maxWidth: '24px' }}
        />
      );
    }
  };

  return (
    <>
      <div className="flex px-20 py-20 whitespace-nowrap cursor-pointer whitespace-nowrap border-t-1 border-solid border-gray-300">
        {clickable && (
          <div className="flex items-center">
            <Checkbox
              name="1"
              labelText=""
              onClick={selectUser}
              checked={checked}
            />
          </div>
        )}
        <div className="flex items-center w-full" onClick={selectUser}>
          <div className="flex-shrink-0 h-24 w-24">{renderProfileImage()}</div>
          <div className="ml-15 flex justify-between w-full">
            <span className="flex text-sm font-medium text-gray-900">
              {contact.name}
            </span>
            {/* <span
              className={` ${
                contact.status == ''
              }flex text-sm font-medium text-gray-900`}
            >
              {getStatus(contact.status)}
            </span> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatContact;
