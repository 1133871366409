import React, { useState } from 'react';
import ServerlessPagination from 'src/components/commons/ServerlessPagination/ServerlessPagination';
import CampaignRow from '../CampaignRow/CampaignRow';
import ArrowDownAZ from '../../../../assets/icons/sort-az-down.svg';
import ArrowDownAZActive from '../../../../assets/icons/sort-az-down-active.svg';
import ArrowUpAZActive from '../../../../assets/icons/sort-za-down-active.svg';
import { Box } from '@material-ui/core';

const CampaignTable = ({ campaigns, children, value, title }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [sortBy, setSortBy] = useState('CreatedAt');
  const totalRows = campaigns.length;
  const lastPage = Math.ceil(totalRows / 10);
  console.log('Lista kampananja', campaigns);
  const handleSort = (item) => {
    if (sortBy === 'Campaign' && item === 'Campaign') {
      setSortBy('CampaignReversed');
    } else if (sortBy === 'From' && item === 'From') {
      setSortBy('FromReversed');
    } else if (sortBy === 'To' && item === 'To') {
      setSortBy('ToReversed');
    } else if (sortBy === 'Subject' && item === 'Subject') {
      setSortBy('SubjectReversed');
    } else if (sortBy === 'Design' && item === 'Design') {
      setSortBy('DesignReversed');
    } else {
      setSortBy(item);
    }
  };
  return (
    <div className="flex flex-col mt-50">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-20 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ cursor: 'pointer', userSelect: 'none' }}
                    onClick={() => handleSort('Campaign')}
                  >
                    <Box display="flex" alignItems="center" style={{ gap: 10 }}>
                      Campaign Name{' '}
                      <img
                        src={
                          sortBy == 'CampaignReversed'
                            ? ArrowUpAZActive
                            : sortBy == 'Campaign'
                            ? ArrowDownAZActive
                            : ArrowDownAZ
                        }
                      />
                    </Box>
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ cursor: 'pointer', userSelect: 'none' }}
                    onClick={() => handleSort('From')}
                  >
                    <Box display="flex" alignItems="center" style={{ gap: 10 }}>
                      From{' '}
                      <img
                        src={
                          sortBy == 'FromReversed'
                            ? ArrowUpAZActive
                            : sortBy == 'From'
                            ? ArrowDownAZActive
                            : ArrowDownAZ
                        }
                      />
                    </Box>
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    To
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ cursor: 'pointer', userSelect: 'none' }}
                    onClick={() => handleSort('Subject')}
                  >
                    <Box display="flex" alignItems="center" style={{ gap: 10 }}>
                      Subject{' '}
                      <img
                        src={
                          sortBy == 'SubjectReversed'
                            ? ArrowUpAZActive
                            : sortBy == 'Subject'
                            ? ArrowDownAZActive
                            : ArrowDownAZ
                        }
                      />
                    </Box>
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    // style={{ cursor: 'pointer', userSelect: 'none' }}
                    // onClick={() => handleSort('Design')}
                  >
                    {/* <Box display="flex" alignItems="center" style={{ gap: 10 }}> */}
                    Design{' '}
                    {/* <img
                        src={
                          sortBy == 'DesignReversed'
                            ? ArrowUpAZActive
                            : sortBy == 'Design'
                            ? ArrowDownAZActive
                            : ArrowDownAZ
                        }
                      />
                    </Box> */}
                  </th>
                  {/* <th
                    scope="col"
                    className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                  >
                    Recent activity
                  </th> */}
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {campaigns
                  .sort((a, b) =>
                    sortBy == 'Campaign'
                      ? a.name.localeCompare(b.name)
                      : sortBy == 'CampaignReversed'
                      ? b.name.localeCompare(a.name)
                      : sortBy == 'From'
                      ? a.from.localeCompare(b.from)
                      : sortBy == 'FromReversed'
                      ? b.from.localeCompare(a.from)
                      : sortBy == 'To'
                      ? a.to.localeCompare(b.to)
                      : sortBy == 'ToReversed'
                      ? b.to.localeCompare(a.to)
                      : sortBy == 'Subject'
                      ? a.subject.localeCompare(b.subject)
                      : sortBy == 'SubjectReversed'
                      ? b.subject.localeCompare(a.subject)
                      : sortBy == 'Design'
                      ? a.design.localeCompare(b.design)
                      : sortBy == 'DesignReversed'
                      ? b.design.localeCompare(a.design)
                      : null
                  )
                  .slice(currentPage * 10, (currentPage + 1) * 10)
                  .map((mailCampaign, index) => {
                    return (
                      <>
                        <CampaignRow mailCampaign={mailCampaign} key={index} />
                      </>
                    );
                  })}
              </tbody>
            </table>
            <ServerlessPagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              lastPage={lastPage}
              total={totalRows}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignTable;
