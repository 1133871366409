import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../../../components/Modal/Modal';
import { ReactComponent as SvgCheckCircle } from './../../../../assets/icons/icon-check-circle.svg';
import FieldInput from '../../../../components/Form/components/FieldInput';
import { mailCampaignsActions } from 'src/common/actions';
import { toast } from 'react-toastify';

function CampaignEditTemplateModal({
  toggleCampaignEditTemplateModal,
  isCampaignEditTemplateModalOpened,
}) {
  const dispatch = useDispatch();
  const [templateName, setTemplateName] = useState(null);
  const [templateContent, setTemplateContent] = useState(null);
  const message = useSelector((state) =>
    state.mailCampaigns.mailCampaignAutomatizationState.message
      ? state.mailCampaigns.mailCampaignAutomatizationState.message
      : ''
  );
  /* const submitCampaignDate = () => {
    dispatch(
      mailCampaignsActions.addAudience(
        { name: audienceName },
        toggleCampaignEditTemplateModal
      )
    );
  }; */

  const onChangeTemplateName = (e) => {
    const name = e.target.value;
    setTemplateName(name);
    //toast.success('Templated uploaded', { className: 'text-white' });
  };

  const submitTemplate = () => {
    if (templateName && message.length > 10) {
      dispatch(
        mailCampaignsActions.newTemplate({
          name: templateName,
          content: message,
        })
      );
      toggleCampaignEditTemplateModal();
      toast.success('Templated added', { className: 'text-white' });
    } else {
      toast.warning('Please fill the fields!', { className: 'text-white' });
    }
  };

  return (
    <Modal
      isOpen={isCampaignEditTemplateModalOpened}
      onRequestClose={toggleCampaignEditTemplateModal}
      headerTitle="Add new template"
      className="modal--newTemplate"
    >
      <div className="flex items-center mb-40 ">
        <h2 className={`text-lg font-bold text-black mr-20`}>
          Save campaing content as new template
        </h2>
      </div>
      <form
        //onSubmit={handleSubmit(onSubmit)}
        className={`flex flex-wrap`}
      >
        <FieldInput
          /* register={register} */
          name="template"
          defaultValue=""
          type="text"
          labelText="Template name"
          placeholder="custom-template"
          className="w-full mb-0 "
          onChange={onChangeTemplateName}
        />
        <textarea
          className="w-full bg-gray-300 min-h-quarter"
          value={message}
        />
      </form>

      <button
        className="ml-auto mt-20 flex text-center justify-center items-center rounded-default pl-0 px-30 py-10 text-sm text-white bg-primary hover:bg-secondary outline-none"
        role="submit"
        onClick={submitTemplate}
      >
        <SvgCheckCircle className="ml-20 mr-20" />
        Confirm
      </button>
    </Modal>
  );
}

export default CampaignEditTemplateModal;
