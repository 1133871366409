import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from '../../../components/Modal/Modal';
import { ReactComponent as SvgCheckCircle } from './.././../../assets/icons/icon-check-circle.svg';
import { mailCampaignsActions } from '../../../common/actions';
import moment from 'moment';
import { toast } from 'react-toastify';
import ButtonBack from '../../../components/ButtonBack/ButtonBack';

function CampaignDatepickerModal({
  toggleCampaignDatepickerModal,
  isCampaignDatepickerModalOpened,
}) {
  moment.locale('en');
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());

  const submitCampaignDate = () => {
    let today = new Date();

    if (startDate && startDate >= today) {
      dispatch(mailCampaignsActions.setScheduledTime(startDate));
      toggleCampaignDatepickerModal();
      toast.success('Campaign scheduled', { className: 'text-white' });
    } else {
      toast.success(
        'Please check the date and time, date and time must be greather then current!',
        { className: 'text-white' }
      );
    }
  };

  /* const clearCampaignScheduleData = () => {
    dispatch(mailCampaignsActions.setScheduledTime(null));
    toggleCampaignDatepickerModal();
  }; */

  return (
    <Modal
      isOpen={isCampaignDatepickerModalOpened}
      headerTitle="Schedule campaign"
      className="modal--schedule"
      onRequestClose={toggleCampaignDatepickerModal}
    >
      <div className="flex items-center mb-40 flex-col">
        <ButtonBack variant="small" className="mb-15" />
        <h2 className={`text-2x font-bold text-black mr-20`}>
          Schedule campaign
        </h2>
      </div>
      <div className="flex flex-col sm:mb-50 md:mb-0 w-full">
        <ReactDatePicker
          className="ReactDatePicker"
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={60}
          timeCaption=""
          dateFormat="YYYY-MM-DD"
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
          }}
          inline
        />

        <div className="flex flex-between">
          <button
            type="submit"
            className="ml-auto mt-20 flex text-center justify-center items-center rounded-default pl-0 px-30 py-10 text-sm text-white bg-primary hover:bg-secondary outline-none"
            role="menuitem"
            onClick={submitCampaignDate}
          >
            <SvgCheckCircle className="ml-20 mr-20" />
            Schedule time
          </button>

          {/* <button
            className="ml-auto mt-20 flex text-center justify-center items-center rounded-default pl-0 px-30 py-10 text-sm text-white bg-primary hover:bg-secondary outline-none"
            role="menuitem"
            onClick={clearCampaignScheduleData}
            variant="primary"
          >
            <SvgCheckCircle className="ml-20 mr-20" />
            Clear
          </button> */}
        </div>
      </div>
    </Modal>
  );
}

export { CampaignDatepickerModal };
