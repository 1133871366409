import { trackPromise } from 'react-promise-tracker';
import { history } from '../history';
import { routes } from '../constants';
import { tokenHelper } from '../helpers';

export const handleResponse = (response) =>
  response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      let error;

      if (data.errors)
        error = Array.isArray(data.errors)
          ? data.errors.join('\n')
          : Object.values(data.errors)
              .map((i) => i.join('\n'))
              .join('\n');
      else
        error = (data && (data.error || data.message)) || response.statusText;

      return Promise.reject(error);
    }
    return data;
  });

export const authFetch = async (input, init, track = true) => {
  const token = tokenHelper.getTokenFromLocalStorage();

  if (tokenHelper.isTokenExpired(token)) {
    history.push(routes.LOGIN.path);
  }

  const fetchInit = init || {};

  fetchInit.headers = {
    ...(init ? init.headers : {}),
    Authorization: `Bearer ${token}`,
  };

  return track
    ? trackPromise(fetch(input, fetchInit))
    : fetch(input, fetchInit);
};

export const openFetch = async (input, init) => {
  const fetchInit = init || {};

  return trackPromise(fetch(input, fetchInit));
};

export const client = (authorized = true) => {
  const requestOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const fetcher = authorized ? authFetch : openFetch;

  return {
    get: (endpoint, track) =>
      fetcher(
        endpoint,
        {
          ...requestOptions,
          method: 'GET',
        },
        track
      ).then(handleResponse),
    put: (endpoint, data, track) =>
      fetcher(
        endpoint,
        {
          ...requestOptions,
          method: 'PUT',
          body: JSON.stringify(data),
        },
        track
      ).then(handleResponse),
    patch: (endpoint, data, track) =>
      fetcher(
        endpoint,
        {
          ...requestOptions,
          method: 'PATCH',
          body: JSON.stringify(data),
        },
        track
      ).then(handleResponse),
    post: (endpoint, data, track) =>
      fetcher(
        endpoint,
        {
          ...requestOptions,
          method: 'POST',
          body: JSON.stringify(data),
        },
        track
      ).then(handleResponse),
    delete: (endpoint, data, track) =>
      fetcher(
        endpoint,
        {
          ...requestOptions,
          method: 'DELETE',
          body: JSON.stringify(data),
        },
        track
      ).then(handleResponse),
  };
};
