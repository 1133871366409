import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '../../../../components/Form/components/Checkbox/Checkbox';
import { mailCampaignsActions } from '../../../../common/actions';
import AudienceProductTyepesRow from '../AudienceProductTypesRow';
import PaginationBar from '../../../../components/commons/PaginationBar/PaginationBar';

const EditAudienceProductType = ({
  search,
  setSearchProducts,
  pickedProducts,
  setPickedProducts,
  audienceForEdit,
}) => {
  const [selectedName, setSelectedName] = useState([]);
  const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
  const [disabledNext, setDisabledNext] = useState(false);
  const [disabledPrevious, setDisabledPrevious] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(7);
  const dispatch = useDispatch();

  const updatePickedProducts = (contact) => {
    let id = contact.id;
    if (selectedName.includes(id)) {
      let newArray = selectedName.filter((item) => item !== id);
      setSelectedName(newArray);
    } else {
      let newArray = selectedName.concat(id);
      setSelectedName(newArray);
    }
  };

  useEffect(() => {
    if (selectedName.length === 0) {
      let selectedContacts = [];
      for (let i = 0; i < audienceForEdit.products_list.length; i++) {
        selectedContacts.push(audienceForEdit.products_list[i].id);
      }
      setSelectedName(selectedContacts);
    }
  }, []);

  useEffect(() => {
    setPickedProducts(selectedName);
  }, [selectedName]);

  useEffect(() => {
    if (search && search.length > 0) {
      dispatch(mailCampaignsActions.searchProducts(search));

      if (search.length > 0 && currentPaginationPage !== 1) {
        let data = renderContacts();
        if (currentPaginationPage > Math.ceil(data.length / itemsPerPage)) {
          setCurrentPaginationPage(1);
          setDisabledPrevious(true);
          setDisabledNext(false);
        }
      }
    }
  }, [search]);

  useEffect(() => {
    console.log(pickedProducts);
  }, [pickedProducts]);

  const products = useSelector(
    (state) => state.mailCampaigns.products.productsList
  );
  const searchResults = useSelector(
    (state) => state.mailCampaigns.products.searchProductsList
  );

  const handlePaginationPrevious = () => {
    const current = currentPaginationPage - 1;
    setCurrentPaginationPage(current);
    setDisabledNext(false);

    if (current === 1) {
      setDisabledPrevious(true);
    }
  };

  const handlePaginationNext = () => {
    const data = renderContacts();
    const current = currentPaginationPage + 1;
    setCurrentPaginationPage(current);
    setDisabledPrevious(false);

    if (current === Math.ceil(data.length / itemsPerPage)) {
      setDisabledNext(true);
    }
  };

  const handlePaginationClick = (e) => {
    const data = renderContacts();
    const clicked = Number(e.target.id);
    setCurrentPaginationPage(clicked);

    if (clicked === 1) {
      setDisabledPrevious(true);
      setDisabledNext(false);
    } else if (clicked === Math.ceil(data.length / itemsPerPage)) {
      setDisabledNext(true);
      setDisabledPrevious(false);
    } else {
      setDisabledNext(false);
      setDisabledPrevious(false);
    }
  };

  const calculateNumPage = () => {
    let data = renderContacts();
    return data.length;
  };

  const checkPaginationDisplay = () => {
    let numberOfItems = calculateNumPage();
    if (numberOfItems > itemsPerPage) {
      return (
        <PaginationBar
          previous={handlePaginationPrevious}
          next={handlePaginationNext}
          click={handlePaginationClick}
          perPage={itemsPerPage}
          numPag={calculateNumPage()}
          activepagenum={currentPaginationPage}
          disabledprevious={disabledPrevious}
          disablednext={disabledNext}
          enableFirstLast="false"
        />
      );
    } else {
      return null;
    }
  };

  const handleSelectAll = () => {
    if (pickedProducts.length === products.length) {
      setSelectedName([]);
    } else if (pickedProducts.length < products.length) {
      let newArray = [];
      for (let i = 0; i < products.length; i++) {
        newArray.push(products[i].id);
      }
      setSelectedName(newArray);
    }
  };

  const checkSelectAll = () => {
    if (pickedProducts.length === products.length) {
      return true;
    } else {
      return false;
    }
  };

  const renderContacts = () => {
    if (search && search.length > 0) {
      return searchResults;
    } else {
      let newProducts = [];
      let editableProducts = [];
      let selectedProducts = [];
      for (let i = 0; i < audienceForEdit.products_list.length; i++) {
        newProducts.push(audienceForEdit.products_list[i]);
        selectedProducts.push(audienceForEdit.products_list[i].id);
      }
      products.map((product) => {
        if (!selectedProducts.includes(product.id)) {
          editableProducts.push(product);
        }
      });

      let fullEditContactlist = newProducts.concat(editableProducts);

      return fullEditContactlist;
    }
  };

  const renderTableContent = () => {
    const data = renderContacts();

    const indexOfLastItem = currentPaginationPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentAudienceData = data.slice(indexOfFirstItem, indexOfLastItem);

    return (
      <tbody className="bg-white divide-y divide-gray-200">
        {currentAudienceData.map((product, index) => {
          let isContactSelected = false;
          if (selectedName.includes(product.id)) {
            isContactSelected = true;
          }
          return (
            <AudienceProductTyepesRow
              product={product}
              updatePickedProducts={updatePickedProducts}
              isPreselected={isContactSelected}
              pickedProducts={selectedName}
              key={index}
            />
          );
        })}
      </tbody>
    );
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-20 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xxs font-medium text-gray-400  tracking-wider"
                  >
                    <Checkbox
                      name="1"
                      labelText=""
                      onChange={handleSelectAll}
                      checked={checkSelectAll()}
                    />
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xxs font-medium text-gray-400  tracking-wider"
                  >
                    Product Name
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xxs font-medium text-gray-400  tracking-wider"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xxs font-medium text-gray-400  tracking-wider"
                  >
                    Company
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xxs font-medium text-gray-400  tracking-wider"
                  >
                    Created at
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-xxs font-medium text-gray-400 tracking-wider text-right"
                  >
                    Method of payment
                  </th>
                </tr>
              </thead>
              {renderTableContent()}
            </table>
            {checkPaginationDisplay()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAudienceProductType;
