import React from 'react';
import { ResponsivePie } from '@nivo/pie';

const data = [
  {
    id: 'contacts',
    label: 'Contacts',
    value: 402,
    color: '#0395FF',
  },
  {
    id: 'mails',
    label: 'Mails',
    value: 215,
    color: '#0395FF',
  },
  {
    id: 'notes',
    label: 'Notes',
    value: 49,
    color: '#5779FF',
  },
  {
    id: 'tasks',
    label: 'Tasks',
    value: 130,
    color: 'hsl(292, 70%, 50%)',
  },
];
const PieGraph = ({ title, excerpt, className }) => {
  return (
    <div
      className={`${className} flex flex-col bg-white py-40 px-30 rounded-default mt-50`}
      style={{ height: '500px' }}
    >
      <div className="flex  mb-40 flex-col">
        <h2 className={`text-xl font-bold text-gray-500`}>{title}</h2>
        <p className={`text-sm text-gray-400`}>{excerpt}</p>
      </div>
      <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.55}
        padAngle={1}
        cornerRadius={3}
        colors={{ datum: 'data.color' }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', '0.3']] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextColor="#000"
        radialLabelsLinkColor={{ from: 'color', modifiers: [] }}
        sliceLabelsSkipAngle={10}
        sliceLabelsTextColor="#000"
        defs={[
          {
            id: 'dots',
            type: 'patternLines',
            background: 'inherit',
            color: 'hsla(205, 100%, 51%, 1)',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 56,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#242F3C',
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export { PieGraph };
