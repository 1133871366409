import { dealsActionTypes } from '../constants/dealsConstants';

const initialState = {
  deals: [],
  single_deal: {},
  deal_comments: [],
};

export const dealsReducer = (state = initialState, action) => {
  switch (action.type) {
    case dealsActionTypes?.DEALS_GET_ALL: {
      return {
        deals: action.payload,
      };
    }
    case dealsActionTypes.DEALS_SET_ALL: {
      return {
        ...state,
        deals: action.payload,
      };
    }
    case dealsActionTypes.DEALS_GET_ALL_REQUEST: {
      return {
        deals: action.payload,
      };
    }
    case dealsActionTypes.DEALS_SET_ALL_REQUEST: {
      return {
        ...state,
        deals: action.payload,
      };
    }
    case dealsActionTypes.DEALS_GET_SINGLE: {
      return {
        single_deal: action.payload,
      };
    }
    case dealsActionTypes.DEALS_SET_SINGLE: {
      return {
        ...state,
        single_deal: action.payload,
      };
    }
    case dealsActionTypes.DEALS_GET_COMMENTS: {
      return {
        deal_comments: action.payload,
      };
    }
    case dealsActionTypes.DEALS_SET_COMMENTS: {
      return {
        ...state,
        deal_comments: action.payload,
      };
    }
    case dealsActionTypes.DEALS_GET_COMMENTS_REQUEST: {
      return {
        deal_comments: action.payload,
      };
    }
    case dealsActionTypes.DEALS_SET_COMMENTS_REQUEST: {
      return {
        ...state,
        deal_comments: action.payload,
      };
    }
    default:
      return state;
  }
};
