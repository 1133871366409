import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import MailInput from '../../components/Form/components/MailInput/MailInput';
import { ReactComponent as SvgNext } from 'src/assets/icons/icon-next.svg';

import { mailCampaignsActions } from '../../common/actions';
import { prepareMailCampaingPutModel } from '../../common/helpers';
import { toast } from 'react-toastify';
import CampaignEditor from '../../components/CampaignForm/CampaignEditor';
import EmailEditor from 'react-email-editor';

const EditCampaignForm = ({ editableMailCampaign }) => {
  const dispatch = useDispatch();
  const [editCampaign, setEditCampaign] = useState(editableMailCampaign);
  const mailCampaignMessage = useSelector((state) =>
    state.mailCampaigns.mailCampaignState
      ? state.mailCampaigns.mailCampaignState.message
      : null
  );

  const history = useHistory();

  const editorRef = useRef(null);

  const onSubmit = (data) => {
    // history.push(routes.HOME.path);
  };

  const handleOnSave = () => {
    let editCampaignObject = {};
    editCampaignObject = {
      ...editCampaign,
      message: mailCampaignMessage,
    };
    if (
      editCampaignObject.name.trim().length > 0 &&
      // editCampaignObject.audience_list.length > 0 &&
      editCampaignObject.subject.trim().length > 0 &&
      editCampaignObject.message.trim().length > 0
    ) {
      dispatch(
        mailCampaignsActions.updateMailCampaign(
          prepareMailCampaingPutModel(editCampaignObject),
          editCampaignObject.id,
          history
        )
      );
    } else {
      toast.warning('Please enter all campaign informations!', {
        className: 'text-white',
      });
    }
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setEditCampaign((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const onEditorLoad = () => {
    let data = JSON.parse(editCampaign.design);
    editorRef.current.editor.loadDesign(data);
  };

  return (
    <form noValidate className={`flex flex-wrap pb-50`}>
      <MailInput
        labelText="Name:"
        name="name"
        type="text"
        value={editCampaign.name}
        className="sm:w-full"
        onChange={(e) => onChangeInput(e)}
        disableEdit={true}
      />
      <MailInput
        labelText={`Audiences: ${(editCampaign.audience_list || []).map(
          (audience) => ` ${audience.name}`
        )}`}
        name="audience"
        type="text"
        className="sm:w-full"
        disableEdit={true}
      />
      <MailInput
        labelText="Subject:"
        name="subject"
        type="text"
        value={editCampaign.subject}
        className="sm:w-full"
        onChange={(e) => onChangeInput(e)}
        disableEdit={true}
      />

      <EmailEditor ref={editorRef} onLoad={onEditorLoad} />

      {/*<CampaignEditor template={editCampaign.design} />*/}
      {/* <div className="flex justify-end mt-50 w-full">
        <Button
          variant="primary"
          width="220"
          height="44"
          onClick={handleOnSave}
        >
          <SvgNext className="mr-20" />
          Save
        </Button>
      </div> */}
    </form>
  );
};
export default EditCampaignForm;
