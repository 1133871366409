import { companiesActionTypes } from '../constants/companiesConstants';

const initialState = {
  companies: [],
  single_company: {},
};

export const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case companiesActionTypes?.COMPANIES_GET_ALL: {
      return {
        companies: action.payload,
      };
    }
    case companiesActionTypes.COMPANIES_SET_ALL: {
      return {
        ...state,
        companies: action.payload,
      };
    }
    case companiesActionTypes.COMPANIES_GET_ALL_REQUEST: {
      return {
        companies: action.payload,
      };
    }
    case companiesActionTypes.COMPANIES_SET_ALL_REQUEST: {
      return {
        ...state,
        companies: action.payload,
      };
    }
    case companiesActionTypes.COMPANIES_GET_SINGLE: {
      return {
        single_company: action.payload,
      };
    }
    case companiesActionTypes.COMPANIES_SET_SINGLE: {
      return {
        ...state,
        single_company: action.payload,
      };
    }
    default:
      return state;
  }
};
