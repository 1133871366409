import React from 'react';

const InvoicePreview = () => {
  return (
    <div id="invoice" className="p-5 overflow-auto">
      <div className="flex justify-end">
        <img src="" alt="CRM" className="block w-1/4" />
      </div>
      <div className="mt-3 mb-5">
        <div>devla</div>
        <div>Kristian Radic</div>
        <div>Hercegovacka 32</div>
        <div>21000 Split</div>
      </div>
      <div className="grid grid-cols-12 lg:grid-cols-6 border-b-2 border-gray-300 pb-5">
        <div className="col-span-2 text-xl flex">
          <div className="self-end">Offer</div>
        </div>
        <div className="text-right">
          Offer no.:
          <div>1</div>
        </div>
        <div className="text-right">
          Customer no.:
          <div>1</div>
        </div>
        <div className="text-right">
          Date:
          <div>07/08/2021</div>
        </div>
        <div className="text-right">
          Expire date:
          <div>07/22/2021</div>
        </div>
      </div>
      <p className="my-10">With pleasure we offer you:</p>
      <table className="table">
        <thead>
          <tr className="bg-gray-100 border-b-2 border-gray-300">
            <th
              scope="col"
              className="uppercase text-gray-400 font-semibold px-10 py-10"
              style={{ width: '1%' }}
            >
              Item
            </th>
            <th
              scope="col"
              className="text-left uppercase text-gray-400 font-semibold px-10 py-10"
              style={{ width: '35%' }}
            >
              Description
            </th>
            <th
              scope="col"
              className="text-right uppercase text-gray-400 font-semibold px-10 py-10"
              style={{ width: '1%' }}
            >
              Quantity
            </th>
            <th
              scope="col"
              className="text-right uppercase text-gray-400 font-semibold px-10 py-10"
              style={{ width: '5%' }}
            >
              Singles
            </th>
            <th
              scope="col"
              className="text-right uppercase text-gray-400 font-semibold px-10 py-10"
              style={{ width: '5%' }}
            >
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="invoice-item">
            <td className="text-center px-10 py-15">1</td>
            <td className="px-10 py-15">
              <span className="font-bold">Product 1</span>
            </td>
            <td className="text-right px-10 py-15">1</td>
            <td className="text-right px-10 py-15">1,000.00€</td>
            <td className="text-right px-10 py-15">1,000.00€</td>
          </tr>

          <tr className="invoice-description mb-2">
            <td></td>
            <td className="px-10 py-5">
              This a description for product 1 .<br />
              Here is a list
              <ul>
                <li>item 1</li>
                <li>item 2</li>
                <li>item 3</li>
              </ul>
              Bye!
            </td>
          </tr>

          <tr className="border-t-2 border-gray-300">
            <td></td>
            <td></td>
            <th
              colSpan="2"
              className="uppercase text-gray-400 font-semibold py-10 text-right"
            >
              Subtotal
            </th>
            <td className="text-right">1,000.00€</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <th
              colSpan="2"
              className="uppercase text-gray-400 font-semibold py-10 text-right"
            >
              VAT. 0%
            </th>
            <td className="text-right">0.00€</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <th
              colSpan="2"
              className="uppercase text-gray-400 font-semibold py-10 text-right"
            >
              Total
            </th>
            <td className="text-right">1,000.00€</td>
          </tr>
        </tbody>
      </table>

      <div className="mt-5">
        The payment in question is € 1,000.00 plus VAT, due immediately after
        invoicing. The terms and conditions of Life Building Education GmbH
        apply: https://consulting.karl-ess.com/agb Many thanks for the good
        cooperation.
      </div>
    </div>
  );
};

export default InvoicePreview;
