import React from 'react';
import PostIcon from '../../../assets/icons/post-icon.svg';

export const ChatEmptyState = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
      }}
    >
      <img src={PostIcon} alt="post" style={{ marginBottom: '30px' }} />
      <div
        style={{
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '34.75px',
          color: '#0B2559',
        }}
      >
        There is no opened chat
      </div>
      <div
        style={{
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '300',
          lineHeight: '20.27px',
          color: '#040F25',
        }}
      >
        Start by selecting user.
      </div>
    </div>
  );
};
