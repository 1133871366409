import React, { memo } from 'react';
import Checkbox from '../../../../components/Form/components/Checkbox/Checkbox';
import { ReactComponent as SvgAvatar } from './../../../../assets/icons/icon-avatar-male.svg';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { notificationActions } from '../../../../common/actions/notificationActions';
import { useNotificationData } from '../../../../hooks/useNotification';

const NotificationBlock = ({ notification, selected, setSelected }) => {
  const dispatch = useDispatch();
  const [content, action] = useNotificationData(notification.data);

  const isSelected = selected.indexOf(notification.id) !== -1;

  const handleChange = () => {
    if (isSelected) {
      setSelected(selected.filter((i) => i != notification.id));
    } else {
      setSelected([...selected, notification.id]);
    }
  };

  const handleAction = () => {
    if (!notification.read)
      dispatch(notificationActions.readNotification(notification.id, action));
    else action();
  };

  const prettyTime = moment(notification.created_at).format('HH:mm');

  return (
    <div className="flex py-15 items-center">
      <div className="flex items-center">
        <Checkbox
          name="1"
          labelText=""
          onChange={handleChange}
          checked={isSelected}
        />
        <SvgAvatar
          className="rounded-full object-cover object-center mr-15 ml-15"
          style={{ minWidth: '36px', maxWidth: '36px', height: '36px' }}
        />
      </div>
      <p
        className="text-xs text-gray-400 leading-snug tracking-wide px-10 cursor-pointer"
        onClick={handleAction}
      >
        {content}
      </p>
      <p className="whitespace-nowrap text-xs text-gray-400 ml-auto">
        {prettyTime}
      </p>
    </div>
  );
};

export default memo(NotificationBlock);
