import React, { useState, useEffect } from 'react';
import LayoutComponent from '../../components/commons/LayoutComponent';
import SettingsContent from './components/SettingsContent';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'src/common/actions';

const Settings = ({ value }) => {
  // const dispatch = useDispatch();
  // React.useEffect(() => {
  //   dispatch(authActions.fetchUser());
  // }, []);
  // const user = useSelector((state) => state.auth?.users);
  return (
    <LayoutComponent title="">
      <SettingsContent />
    </LayoutComponent>
  );
};

export default Settings;
