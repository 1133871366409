import React from 'react';
import { Form, Formik } from 'formik';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  ThemeProvider,
  createTheme,
  InputAdornment,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as SvgAdd } from '../../../assets/icons/icon-add.svg';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { CirclePicker } from 'react-color';

const useStyles = makeStyles(() => ({
  p10: {},
  mt20: {
    flexWrap: 'nowrap',
    gap: '24px',
    marginTop: '24px',
  },
  rootSelect: {
    paddingLeft: '20px',
  },
}));

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        backgroundColor: '#fafafa',
        border: '1px solid #E1E1E1',
        borderRadius: '6px',
        fontSize: '14px',
        color: '#6a6a6a',
      },
    },
    MuiTypography: {
      body2: {
        color: '#6a6a6a',
        fontSize: '14px',
        marginBottom: '6px',
      },
    },
    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: '0',
      },
      adornedStart: {
        paddingLeft: '0',
      },
    },
  },
});

export const CreateDeal = ({
  onSubmit,
  handleClose,
  closeModal,
  users,
  contacts,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState([]);
  const [amount, setAmount] = React.useState('');
  const [name, setName] = React.useState('');
  const [stage, setStage] = React.useState('Market Positioning');
  const [closingDate, setClosingDate] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [restDeal, setRespDeal] = React.useState();
  const [respDeals, setRespDeals] = React.useState([]);
  const [color, setColor] = React.useState('');
  const colors = ['#2ecc71', '#3498db', '#ff6b6b'];
  const [commentColor, setCommentColor] = React.useState('#ffffff');
  const handleButtonClose = () => {
    console.log('sakrji 4');
    handleClose();
  };
  const handleChangeColor = (color) => {
    setColor(color.hex);
  };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          name: name,
          stage: stage,
          amount: amount,
          closing_date: closingDate,
          resp_deal_employee_id: restDeal?.id,
          company_id: restDeal?.company_id,
          contacts: value,
          color: color,
          resp_deal_employees: respDeals,
        }}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props) => {
          const {
            values,
            handleBlur,
            handleChange,
            isSubmitting,
            submitForm,
            handleClose,
          } = props;
          return (
            <ThemeProvider theme={theme}>
              <Form className="flex h-full flex-col">
                <Grid container className={classes.mt20}>
                  <Grid item xs={12} md={12}>
                    <Typography className="tet-gray-500 text-xs mb-5">
                      Deal Name
                    </Typography>
                    <TextField
                      className={classes.p10}
                      placeholder="Enter deal name"
                      fullWidth
                      variant="outlined"
                      required
                      id="name"
                      name="name"
                      onChange={(event) => setName(event.target.value)}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                  </Grid>
                </Grid>

                <Grid container className={classes.mt20}>
                  <Grid item xs={12} md={6}>
                    <Typography className="tet-gray-500 text-xs mb-5">
                      Deal Stage
                    </Typography>
                    <Select
                      classes={{
                        root: classes.rootSelect,
                      }}
                      className={classes.p10}
                      id="stage"
                      name="stage"
                      variant="outlined"
                      displayEmpty
                      onChange={(event) => setStage(event.target.value)}
                      value={values.stage}
                      onBlur={handleBlur}
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        Choose deal stage
                      </MenuItem>
                      <MenuItem value="Market Positioning">
                        Market Positioning
                      </MenuItem>
                      <MenuItem value="Develop Plan">Develop Plan</MenuItem>
                      <MenuItem value="Initiate contacts">
                        Initiate contacts
                      </MenuItem>
                      <MenuItem value="Prepare presentation">
                        Prepare presentation
                      </MenuItem>
                      <MenuItem value="Deliver presentation">
                        Deliver presentation
                      </MenuItem>
                      <MenuItem value="Present offer">Present offer</MenuItem>
                      <MenuItem value="Follow up">Follow up</MenuItem>
                      <MenuItem value="Subsequent meetings">
                        Subsequent meetings
                      </MenuItem>
                      <MenuItem value="Sign contract">Sign contract</MenuItem>
                      <MenuItem value="Implement contract">
                        Implement contract
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="tet-gray-500 text-xs mb-5">
                      Amount
                    </Typography>

                    <TextField
                      className={classes.p10}
                      placeholder="Enter amount"
                      fullWidth
                      variant="outlined"
                      required
                      id="amount"
                      name="amount"
                      onChange={(event) => setAmount(event.target.value)}
                      onBlur={handleBlur}
                      value={values.amount}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              position: 'absolute',
                              right: '20px',
                            }}
                          >
                            {/*<img src={EIcon} />*/}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container className={classes.mt20}>
                  <Grid item xs={12} md={6}>
                    <Typography className="tet-gray-500 text-xs mb-5">
                      Closing Date
                    </Typography>
                    <TextField
                      className={classes.p10}
                      placeholder="Choose date when the Deal will be closed"
                      fullWidth
                      variant="outlined"
                      required
                      id="closing_date"
                      name="closing_date"
                      type="date"
                      onChange={(event) => setClosingDate(event.target.value)}
                      onBlur={handleBlur}
                      value={values.closing_date}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="tet-gray-500 text-xs mb-5">
                      Responsible Deal Employee
                    </Typography>
                    <Select
                      classes={{
                        root: classes.rootSelect,
                      }}
                      id="resp_deal_employee_id"
                      name="resp_deal_employee_id"
                      variant="outlined"
                      className={classes.p10}
                      displayEmpty
                      onChange={(event) => {
                        setRespDeal(event.target.value);
                      }}
                      onBlur={handleBlur}
                      value={values.resp_deal_employee_id}
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        Choose employee
                      </MenuItem>
                      {users?.map((user, index) => {
                        return (
                          <MenuItem
                            value={user}
                            key={index}
                          >{`${user.name}`}</MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid item xs={12} md={12} className="mt-20">
                    <Typography className="tet-gray-500 text-xs mb-5">
                      Responsible Deal Employees
                    </Typography>
                    <Autocomplete
                      className={classes.p10}
                      multiple
                      id="contacts"
                      options={users}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            className="mr-10 text-primary"
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      onChange={(event, users) =>
                        setRespDeals(
                          users.map((item) => {
                            return item?.id;
                          })
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          placeholder="Choose employees"
                          className="text-gray-500 relative"
                          {...params}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid container className="mt-20">
                  <Grid item xs={12} md={12}>
                    <Typography className="tet-gray-500 text-xs mb-5">
                      Contact
                    </Typography>
                    <Autocomplete
                      className="order-first flex w-full mb-10"
                      multiple
                      id="contacts"
                      options={contacts}
                      disableCloseOnSelect
                      getOptionLabel={(option) =>
                        option.first_name + option.last_name
                      }
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            className="mr-10 text-primary"
                            checked={selected}
                          />
                          {option.first_name + option.last_name}
                        </React.Fragment>
                      )}
                      onChange={(event, contacts) =>
                        setValue(
                          contacts.map((item) => {
                            return item?.id;
                          })
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          placeholder="Choose contact"
                          className="text-gray-500"
                          {...params}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={12}>
                    <Typography className="tet-gray-500 text-xs mb-5 relative">
                      Company
                    </Typography>
                    <Select
                      classes={{
                        root: classes.rootSelect,
                      }}
                      id="company_id"
                      name="company_id"
                      variant="outlined"
                      className="p-10 bg-gray-300"
                      displayEmpty
                      placeholder="Choose company"
                      onChange={(event) => setCompany(event.target.value)}
                      onBlur={handleBlur}
                      value={values.company_id}
                      fullWidth
                      disabled
                    ></Select>
                    <span
                      className="absolute"
                      style={{ left: '100px', top: '10px' }}
                    >
                      {restDeal ? (
                        <> {users.find((user) => user.id === restDeal).id}</>
                      ) : (
                        'Choose a responsible employee'
                      )}
                    </span>
                  </Grid> */}
                </Grid>
                {console.log('users', users)}

                <Grid
                  item
                  xs={12}
                  className="flex flex-col w-full border-t border-gray-300 pt-10 w-full"
                >
                  <Box
                    className="flex mt-auto justify-end align-end"
                    style={{ paddingRight: 10, paddingBottom: 10 }}
                  >
                    <Typography
                      style={{
                        marginTop: 4,
                        color: '#6A6A6A',
                        paddingRight: 10,
                        fontWeight: 600,
                        fontSize: 13,
                      }}
                    >
                      Choose deal color:
                    </Typography>
                    <CirclePicker
                      onChangeComplete={handleChangeColor}
                      colors={colors}
                      width="130px"
                      color={commentColor}
                    />
                  </Box>
                  <Box className="flex justify-end align-end">
                    <Box
                      display="flex"
                      paddingTop="12px"
                      justifyContent="flex-end"
                      width="100%"
                      borderTop="1px solid #DEDEDE"
                    >
                      <Box p={1}>
                        <Button
                          disabled={isSubmitting}
                          onClick={() => handleButtonClose()}
                          variant="outlined"
                          className="font-medium bg-red hover:bg-darkRed text-white p-10 px-20 text-sm rounded-default outline-none border-none flex justify-center items-center normal-case"
                        >
                          Cancel
                        </Button>
                      </Box>
                      <Box p={1}>
                        <Button
                          disabled={isSubmitting}
                          onClick={() => {
                            submitForm();
                            console.log(values.contacts);
                          }}
                          variant="outlined"
                          className="font-medium bg-primary hover:bg-secondary text-white p-10 px-20 text-sm rounded-default outline-none flex border-none justify-center items-center normal-case"
                        >
                          <SvgAdd className="mr-20 text-white" />
                          Create Deal
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Form>
            </ThemeProvider>
          );
        }}
      </Formik>
    </>
  );
};
