import React from 'react';

const Title = ({ children, className }) => {
  return (
    <h2
      className={`w-full text-2x font-bold text-black${
        className ? ` ${className}` : ''
      }`}
    >
      {children}
    </h2>
  );
};

export default Title;
