import { mailCampaignsActionTypes } from '../constants';
import { sortMailCampaignsByDate } from '../helpers';

const initialState = {
  mailCampaignsList: {
    allMailCampaignsList: [],
    singleMailCampaign: [],
    recentMailCampaignsList: [],
    scheduledMailCampaignsList: [],
    automatedMailCampaignsList: [],
  },
  areMailCampaignLoading: false,
  areMailCampaignsLoaded: false,
  error: null,
  audiencesState: {
    areAudiencesLoading: false,
    areAudiencesLoaded: false,
    audiences: [],
    error: null,
    newAudienceState: {
      audience: null,
    },
    editAudienceState: {
      audience: null,
    },
  },
  mailCampaignState: {
    name: null,
    audience: null,
    template: null,
    from: null,
    subject: null,
    body: null,
    scheduleCampaignDate: null,
    message: null,
    design: null,
    inactivityDays: null,
  },
  mailCampaignAutomatizationState: {
    template: null,
    message: null,
    design: null,
  },
  mailTemplateState: {
    templates: [],
    areMailTemplatesLoading: false,
    areMailTemplatesLoaded: false,
    error: null,
  },
  products: {
    productsList: [],
    searchProductsList: [],
  },
};

export const mailCampaigns = (state = initialState, action) => {
  switch (action.type) {
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_MAIL_CAMPAIGNS_REQUEST:
      return {
        ...state,
        areMailCampaignLoading: true,
        areMailCampaignsLoaded: false,
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_MAIL_CAMPAIGNS_SUCCESS: {
      const mailCampaigns = action.payload.list;
      return {
        ...state,
        areMailCampaignLoading: false,
        areMailCampaignsLoaded: true,
        mailCampaignsList: {
          allMailCampaignsList: mailCampaigns,
          scheduledMailCampaignsList: sortMailCampaignsByDate(
            mailCampaigns.filter(
              (mailCampaign) => mailCampaign.time_scheduled_at === null
            )
          ),
          recentMailCampaignsList: sortMailCampaignsByDate(
            mailCampaigns.filter(
              (mailCampaign) => mailCampaign.time_scheduled_at !== null
            )
          ),
          automatedMailCampaignsList: sortMailCampaignsByDate(
            mailCampaigns.filter(
              // TODO: Check if this variable automation exist when automated campaign is created
              (mailCampaign) => mailCampaign.automation === true
            )
          ),
        },
      };
    }
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_MAIL_CAMPAIGNS_FAILURE:
      return {
        ...state,
        areMailCampaignLoading: false,
        areMailCampaignsLoaded: false,
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_SINGLE_MAIL_CAMPAIGNS_REQUEST:
      return {
        ...state,
        areMailCampaignLoading: true,
        areMailCampaignsLoaded: false,
        mailCampaignsList: {
          singleMailCampaign: {},
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_SINGLE_MAIL_CAMPAIGNS_SUCCESS: {
      const mailCampaigns = action.payload.list;
      return {
        ...state,
        areMailCampaignLoading: false,
        areMailCampaignsLoaded: true,
        mailCampaignsList: {
          singleMailCampaign: mailCampaigns,
        },
      };
    }
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_MAIL_CAMPAIGNS_FAILURE:
      return {
        ...state,
        areMailCampaignLoading: false,
        areMailCampaignsLoaded: false,
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_DELETE_MAIL_CAMPAIGN_REQUEST:
      return state;
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_DELETE_MAIL_CAMPAIGN_SUCCESS:
      return {
        ...state,
        mailCampaignsList: {
          allMailCampaignsList: state.mailCampaignsList.allMailCampaignsList.filter(
            (item) => item.id !== action.payload.deletedMailCampaignId
          ),
          recentMailCampaignsList: state.mailCampaignsList.recentMailCampaignsList.filter(
            (item) => item.id !== action.payload.deletedMailCampaignId
          ),
          scheduledMailCampaignsList: state.mailCampaignsList.scheduledMailCampaignsList.filter(
            (item) => item.id !== action.payload.deletedMailCampaignId
          ),
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_DELETE_MAIL_CAMPAIGN_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_EDIT_MAIL_CAMPAIGN_REQUEST:
      return {
        ...state,
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_EDIT_MAIL_CAMPAIGN_SUCCESS:
      return {
        ...state,
        areMailCampaignsLoaded: false,
        mailCampaignsList: {
          ...state.mailCampaignsList,
          allMailCampaignsList: state.mailCampaignsList.allMailCampaignsList.map(
            (mailCampaign) => {
              if (mailCampaign.id === action.payload.editedMailCampaign.id) {
                return action.payload.editedMailCampaign;
              }
              return mailCampaign;
            }
          ),

          recentMailCampaignsList: state.mailCampaignsList.recentMailCampaignsList.map(
            (mailCampaign) => {
              if (mailCampaign.id === action.payload.editedMailCampaign.id) {
                return action.payload.editedMailCampaign;
              }
              return mailCampaign;
            }
          ),
          scheduledMailCampaignsList: state.mailCampaignsList.scheduledMailCampaignsList.map(
            (mailCampaign) => {
              if (mailCampaign.id === action.payload.editedMailCampaign.id) {
                return action.payload.editedMailCampaign;
              }
              return mailCampaign;
            }
          ),
          automatedMailCampaignsList: state.mailCampaignsList.automatedMailCampaignsList.map(
            (mailCampaign) => {
              if (mailCampaign.id === action.payload.editedMailCampaign.id) {
                return action.payload.editedMailCampaign;
              }
              return mailCampaign;
            }
          ),
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_EDIT_MAIL_CAMPAIGN_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_AUDIENCE_REQUEST:
      return {
        ...state,
        audiencesState: {
          ...state.audiencesState,
          areAudiencesLoading: true,
          areAudiencesLoaded: false,
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_AUDIENCE_SUCCESS:
      return {
        ...state,
        audiencesState: {
          ...state.audiencesState,
          areAudiencesLoading: false,
          areAudiencesLoaded: true,
          audiences: action.payload.audiences,
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_AUDIENCE_FAILURE:
      return {
        ...state,
        audiencesState: {
          ...state.audiencesState,
          areAudiencesLoading: false,
          areAudiencesLoaded: false,
          error: action.payload.error,
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_NEW_AUDIENCE_REQUEST:
      return state;
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_NEW_AUDIENCE_SUCCESS:
      return {
        ...state,
        audiencesState: {
          ...state.audiencesState,
          areAudiencesLoading: false,
          areAudiencesLoaded: true,
          audiences: [
            action.payload.audience,
            ...state.audiencesState.audiences,
          ],
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_NEW_AUDIENCE_FAILURE:
      return state;
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_EDIT_AUDIENCE_REQUEST:
      return state;
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_EDIT_AUDIENCE_SUCCESS:
      return {
        ...state,
        audiencesState: {
          ...state.audiencesState,
          areAudiencesLoading: false,
          areAudiencesLoaded: true,
          audiences: state.audiencesState.audiences.map((audience) => {
            if (audience.id !== action.payload.audience.id) return audience;
            return action.payload.audience;
          }),
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_EDIT_AUDIENCE_FAILURE:
      return state;
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_CLEAR_STATE:
      return {
        ...state,
        audiencesState: {
          ...initialState.audiencesState,
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_AUDIENCE: {
      return {
        ...state,
        mailCampaignState: {
          ...state.mailCampaignState,
          audiences: action.payload.audiences,
        },
      };
    }
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_CLEAR_STATE:
      return initialState;

    //templates
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_MAIL_TEMPLATES_REQUEST:
      return {
        ...state,
        mailTemplateState: {
          ...state.mailTemplateState,
          areMailTemplatesLoading: true,
          areMailTemplatesLoaded: false,
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_MAIL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        mailTemplateState: {
          ...state.mailTemplateState,
          areMailTemplatesLoading: false,
          areMailTemplatesLoaded: true,
          templates: action.payload.templates,
        },
      };
    }
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_MAIL_TEMPLATES_FAILURE:
      return {
        ...state,
        mailTemplateState: {
          ...state.mailTemplateState,
          areMailTemplatesLoading: false,
          areMailTemplatesLoaded: false,
          error: action.payload.error,
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_TEMPLATE:
      return {
        ...state,
        mailCampaignState: {
          ...state.mailCampaignState,
          template: state.mailTemplateState.templates.find(
            (template) => template.id === action.payload.selectedTemplateId
          ),
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_TEMPLATE_AUTOMATIZATION:
      return {
        ...state,
        mailCampaignAutomatizationState: {
          ...state.mailCampaignAutomatizationState,
          template: state.mailTemplateState.templates.find(
            (template) => template.id === action.payload.selectedTemplateId
          ),
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_SCHEDULED_TIME:
      return {
        ...state,
        mailCampaignState: {
          ...state.mailCampaignState,
          scheduledTime: action.payload.scheduledTime,
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_NAME:
      return {
        ...state,
        mailCampaignState: {
          ...state.mailCampaignState,
          name: action.payload.name,
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_FROM:
      return {
        ...state,
        mailCampaignState: {
          ...state.mailCampaignState,
          from: action.payload.from,
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_SUBJECT:
      return {
        ...state,
        mailCampaignState: {
          ...state.mailCampaignState,
          subject: action.payload.subject,
        },
      };

    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_MESSAGE:
      return {
        ...state,
        mailCampaignState: {
          ...state.mailCampaignState,
          message: action.payload.message,
        },
      };

    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_DESIGN:
      return {
        ...state,
        mailCampaignState: {
          ...state.mailCampaignState,
          design: action.payload.design,
        },
      };

    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_MESSAGE_AUTOMATIZATION:
      return {
        ...state,
        mailCampaignAutomatizationState: {
          ...state.mailCampaignAutomatizationState,
          message: action.payload.message,
        },
      };

    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_DESIGN_AUTOMATIZATION:
      return {
        ...state,
        mailCampaignAutomatizationState: {
          ...state.mailCampaignAutomatizationState,
          design: action.payload.design,
        },
      };

    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_INACTIVITY:
      return {
        ...state,
        mailCampaignState: {
          ...state.mailCampaignState,
          inactivityDays: action.payload.inactivityDays,
        },
      };

    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_PREVIEW_EMAIL_REQUEST:
      return state;
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_PREVIEW_EMAIL_SUCCESS:
      return state;
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_PREVIEW_EMAIL_FAILURE:
      return state;

    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_TEMPLATE_REQUEST:
      return state;
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        mailTemplateState: {
          ...state.mailTemplateState,
          areMailTemplatesLoading: false,
          areMailCampaignsLoaded: true,
          templates: [
            action.payload.template,
            ...state.mailTemplateState.templates,
          ],
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_TEMPLATE_FAILURE:
      return state;

    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_PRODUCTS_REQUEST:
      return state;

    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          productsList: action.payload.products,
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_PRODUCTS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_SEARCH_PRODUCTS_REQUEST:
      return state;
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_SEARCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          searchProductsList: action.payload.searchedProducts,
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_SEARCH_PRODUCTS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_ASSIGN_REQUEST:
      return {
        ...state,
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_ASSIGN_SUCCESS:
      return {
        ...state,
        audiencesState: {
          ...state.audiencesState,
          areAudiencesLoaded: false,
        },
      };
    case mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_ASSIGN_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
