import { React, useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import {
  Grid,
  Dialog,
  Box,
  Typography,
  Card,
  Chip,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Avatar,
  Divider,
  rgbToHex,
} from '@material-ui/core';

import AddBox from '@material-ui/icons/AddBox';
import OpenInNew from '@material-ui/icons/OpenInNew';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LinkIcon from '@material-ui/icons/Link';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { API_ENDPOINT } from '../../../utils/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import { DealDetails } from './DealDetails';
//import axios from 'axios';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'EUR',
});

const useStyles = makeStyles(() => ({
  gridWrapper: {
    border: '1px solid grey',
    display: 'grid',
    backgroundColor: 'grey',
    gridRowGap: 1,
    gridColumnGap: 1,
  },
  paper: {
    padding: '0',
  },
  bottomRight: {
    justifyContent: 'flex-end',
  },
  columnContainer: {
    height: '80vh',
    overflow: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar-track': {
      border: '0px solid black',
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: 'rgba(248, 248, 248, 1)',
      border: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(196, 196, 196, 0.4)',
      opacity: '0.4',
      borderRadius: '25px',
    },
  },
  centerColumn: {
    borderBottom: '1px solid transparent',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  dialog: {
    height: '100vh',
    width: '485px',
    right: '0',
  },
  cardStatus: {
    maxWidth: '90px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const DealsTable = (props) => {
  const classes = useStyles();
  const rows = props.rows;
  const [columns, setColumns] = useState({});
  const [open, setOpen] = useState(false);
  const [offerOpen, setOfferOpen] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState({});
  const [openItemFromSearch, setOpenItemFromSearch] = useState(false);
  // const [alertOpen, setAlertOpen] = useState(false);
  // const [alertMessage, setAlertMessage] = useState('');

  // const handleAlertOpen = () => {
  //   setAlertOpen(true);
  // };

  // const handleAlertClose = () => {
  //   setAlertOpen(false);
  // };
  function hexToRgb(hex, opacity) {
    return (
      'rgba(' +
      (hex = hex.replace('#', ''))
        .match(new RegExp('(.{' + hex.length / 3 + '})', 'g'))
        .map(function (l) {
          return parseInt(hex.length % 2 ? l + l : l, 16);
        })
        .concat(isFinite(opacity) ? opacity : 1)
        .join(',') +
      ')'
    );
  }
  //toast
  const [isToastOpen, setIsToastOpen] = useState(false);

  const handleOpenToast = () => {
    setIsToastOpen(true);
  };

  /*const handleCloseToast = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsToastOpen(false);
  };*/

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOfferClose = () => {
    setOfferOpen(false);
  };

  const handleOfferOpen = (data) => {
    setSelectedItemData(data);
    setOfferOpen(true);
  };

  const handleOfferAdd = async (dealId, offerId) => {
    /*await axioss
      .patch(
        `${process.env.REACT_APP_API_URL}/offers/${offerId}`,
        { deal_id: dealId },
        {
          headers: {
            Authorization: localStorage.getItem('token'),
            Accept: 'application/json',
          },
        }
      )
      .then(() => {
        handleAlertClose();
        handleOfferClose();
        props.handleDataRefresh();
      })
      .catch((e) => {
        let errors = e.response.data.message + '\n';
        for (let error in e.response.data.errors) {
          errors += e.response.data.errors[error][0] + '\n';
        }
        setAlertMessage(errors);
        handleAlertOpen();
      });*/
  };

  let definedColumns = {
    'Market Positioning': {
      name: 'Market Positioning',
      items: [],
    },
    'Develop Plan': {
      name: 'Develop Plan',
      items: [],
    },
    'Initiate contacts': {
      name: 'Initiate contacts',
      items: [],
    },
    'Prepare presentation': {
      name: 'Prepare presentation',
      items: [],
    },
    'Deliver presentation': {
      name: 'Deliver presentation',
      items: [],
    },
    'Present offer': {
      name: 'Present offer',
      items: [],
    },
    'Follow up': {
      name: 'Follow up',
      items: [],
    },
    'Subsequent meetings': {
      name: 'Subsequent meetings',
      items: [],
    },
    'Sign contract': {
      name: 'Sign contract',
      items: [],
    },
    'Implement contract': {
      name: 'Implement contract',
      items: [],
    },
  };

  const calculateTotals = (items) => {
    let total = 0;

    for (let item of items) {
      total += item.data.amount;
    }

    return total;
  };

  useEffect(() => {
    let openItem = {};

    if (rows?.data) {
      for (let row of rows?.data) {
        if (!definedColumns[row.stage]) {
          row.stage = 'Market Positioning';
        }

        console.log('here');
        let item = {
          id: `${row.id}`,
          data: row,
        };

        let column = definedColumns[row.stage];
        let items = column && column.items ? column.items : null;

        column && column.items ? (column.items = [...items, item]) : null;

        if (props.id) {
          if (props.id == row.id) {
            openItem = row;
          }
        }
      }
    }

    console.log(definedColumns);

    setColumns(definedColumns);

    // If used search, open specific card
    // Prevent from openning again after using filter
    if (openItem.id && !openItemFromSearch) {
      handleCardClick(openItem);
      setOpenItemFromSearch(true);
    }
  }, [rows]);

  const updateDealStage = async (id, stage, refresh = false) => {
    await axios
      .post(
        `${API_ENDPOINT}/deals/${id}/update-stage`,
        { stage: stage },
        {
          headers: {
            Authorization: 'Bearer' + localStorage.getItem('crm-client-token'),
            Accept: 'application/json',
          },
        }
      )
      .then(() => {
        // handleAlertClose();

        if (refresh) {
          props.handleDataRefresh();
        } else {
          for (let row of rows.data) {
            if (row.id == id) {
              row.stage = stage;
              break;
            }
          }
        }
      })
      .catch((e) => {
        let errors = e.response.data.message + '\n';
        for (let error in e.response.data.errors) {
          errors += e.response.data.errors[error][0] + '\n';
        }
        // setAlertMessage(errors);
        // handleAlertOpen();
      });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });

      updateDealStage(removed.id, destColumn.name);
    } else {
      const { source, destination } = result;
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const handleCardClick = (data) => {
    setSelectedItemData(data);
    handleOpen();
  };

  const offerStatus = (offer) => {
    let status = offer && offer.status ? offer.status : 'Open';
    let color = '#000000';
    let backgroundColor = 'rgba(0, 0, 0, 0.1)';

    if (status === 'Open') {
      color = '#F3AD26';
      backgroundColor = 'rgba(243, 173, 38, 0.1)';
    } else if (status === 'Signed') {
      color = '#26CA36';
      backgroundColor = 'rgba(38, 202, 54, 0.1)';
    } else if (status === 'Lost') {
      color = '#EE1B2D';
      backgroundColor = 'rgba(238, 27, 45, 0.1)';
    } else if (status === 'Draft') {
      color = '#000000';
      backgroundColor = 'rgba(0, 0, 0, 0.1)';
    }

    const StatusChip = withStyles({
      root: {
        width: 'auto',
        padding: '0 10px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '11px',
        lineHeight: '16px',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '0.5px',
        textTransform: 'uppercase',
        color: color,
        background: backgroundColor,
      },
      label: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
      },
    })(Chip);

    return (
      <div className="flex flex-col">
        <span className="font-medium text-xs my-10 text-primary underline uppercase">
          {offer && offer.first_name ? offer.first_name : ''}
        </span>
        <Box className="flex justify-between">
          <StatusChip
            label={status}
            classes={{
              root: classes.root,
              label: classes.label,
            }}
          />
          <Box className="flex ml-auto">
            <IconButton
              style={{
                padding: 0,
                marginRight: 15,
                marginTop: 1,
              }}
              onClick={() => {
                navigator.clipboard.writeText(offer.public_link);
                handleOpenToast();
              }}
            >
              <FileCopyIcon />
            </IconButton>
            <IconButton
              style={{
                padding: 0,
              }}
              onClick={() => {
                window.open(offer.public_link);
              }}
            >
              <LinkIcon />
            </IconButton>
          </Box>
        </Box>
      </div>
    );
  };

  return (
    <>
      <div
        className="deals__table mt-30 max-w-full"
        style={{
          overflowX: 'scroll',
          maxWidth: '100%',
          display: 'flex',
        }}
      >
        <div>
          <Grid container style={{ flexWrap: 'nowrap', maxWidth: '0px' }}>
            <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
              {Object.entries(columns).map(([id, column]) => {
                return (
                  <Grid
                    style={{ minWidth: '18vw' }}
                    container
                    direction="column"
                    item
                    xs
                    align="center"
                    key={id}
                  >
                    <Grid
                      item
                      align="left"
                      className={`px-10 py-20 deals__th bg-white border-1 border-t-0 border-b-0 border-gray-100`}
                    >
                      <Typography
                        variant="body2"
                        className="flex justify-between text-black "
                      >
                        <strong>{column?.name} </strong>
                        <strong>{column?.items?.length}</strong>
                      </Typography>
                    </Grid>
                    <Box
                      className={`deals__table-column ${classes.columnContainer}`}
                    >
                      <Droppable droppableId={id} key={id}>
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className={classes.outerColumn}
                              style={{
                                backgroundColor: snapshot.isDraggingOver
                                  ? 'rgb(209 218 224)'
                                  : '#F8F8F8',
                                padding: 4,
                                height: '100%',
                              }}
                            >
                              {column.items.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                    style={{
                                      backgroundColor: `rgba(${
                                        hexToRgb('#F48225').g
                                      }, 0.8) !important`,
                                    }}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <Card
                                          variant="outlined"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className="text-left my-10 border-1 border-solid border-gray-200 text-black"
                                          style={{
                                            userSelect: 'none',
                                            background: snapshot.isDragging
                                              ? '#E6F6FF'
                                              : item &&
                                                item.data &&
                                                item.data.color
                                              ? `${hexToRgb(
                                                  item.data?.color,
                                                  0.3
                                                )}`
                                              : 'white',
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          <CardContent>
                                            <div
                                              onClick={() => {
                                                handleCardClick(item.data);
                                              }}
                                            >
                                              <Typography className="font-bold text-xs mb-2 text-black">
                                                {item.data.name}
                                              </Typography>

                                              <Typography
                                                className="text-black mb-8 font-normal text-xs"
                                                component="p"
                                              >
                                                Betrag:{' '}
                                                <span className="font-bold">
                                                  {formatter.format(
                                                    item.data.amount
                                                  )}
                                                </span>
                                              </Typography>
                                              <Box className="flex mb-10 items-center">
                                                <Avatar
                                                  className="bg-secondary text-sm"
                                                  style={{
                                                    width: '30px',
                                                    height: '30px',
                                                  }}
                                                >
                                                  {item.data?.resp_deal_employee?.name?.charAt(
                                                    0
                                                  )}
                                                </Avatar>
                                                <Typography className="ml-10 text-gray-500 text-xs font-medium">
                                                  {
                                                    item.data
                                                      ?.resp_deal_employee?.name
                                                  }
                                                </Typography>
                                              </Box>
                                              {/* <Divider /> */}
                                            </div>

                                            {item.data?.offers?.length ? (
                                              <Box className="flex flex-col">
                                                {offerStatus(
                                                  item.data?.offers[0]
                                                )}
                                              </Box>
                                            ) : (
                                              <div className="mt-10">
                                                {/* <Grid
                                                  container
                                                  className="justify-between"
                                                >
                                                  <Grid item xs={6}>
                                                    <Box className="text-xs leading-tight text-gray-500 h-full flex items-center text-primary">
                                                      No offer yet
                                                    </Box>
                                                  </Grid>
                                                  <Grid item xs={6}>
                                                    <Button
                                                      onClick={() => {
                                                        handleOfferOpen(
                                                          item.data
                                                        );
                                                      }}
                                                      style={{
                                                        textTransform: 'none',
                                                      }}
                                                      startIcon={
                                                        <AddBox className="text-primary ml-10" />
                                                      }
                                                      className="flex ml-auto p-0 pr-15 border-primary border-1 border-solid text-primary"
                                                    >
                                                      Add
                                                    </Button>
                                                  </Grid>
                                                </Grid> */}
                                              </div>
                                            )}
                                          </CardContent>
                                        </Card>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </Box>
                    <Grid
                      item
                      className={` ${classes.outerColumnBottom} bg-white p-20`}
                    >
                      <Typography variant="body2">
                        <strong>
                          Gesamt:{' '}
                          {formatter.format(calculateTotals(column.items))}
                        </strong>
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </DragDropContext>
          </Grid>
        </div>
      </div>

      <Dialog
        onClose={handleClose}
        aria-labelledby="deals-info"
        open={open}
        className={classes.dialog}
        fullScreen
        style={{ left: 'auto' }}
      >
        <Box style={{ padding: '0 40px', height: '100%' }}>
          <DealDetails
            dealValues={selectedItemData}
            handleClose={handleClose}
            updateDealStage={updateDealStage}
            handleDataRefresh={props.handleDataRefresh}
            setAlertMessage={props.setAlertMessage}
            setAlertOpen={props.setAlertOpen}
          />
        </Box>
      </Dialog>
    </>
  );
};
