import { React } from 'react';
import { Grid, Box, Paper, Typography } from '@material-ui/core';

const DashboardItem = (props) => {
  return (
    <>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <Paper
          style={{
            minHeight: '240px',
            background: '#FFFFFF',
            border: '1px solid #f2f5f9',
            padding: '30px 30px',
            display: 'flex',
            flexDirection: 'column',
          }}
          square
          elevation={0}
        >
          <Typography
            style={{
              fontWeight: '900',
              fontSize: '2em',
              lineHeight: '54px',
              marginBottom: '5px',

              color: 'black',
            }}
          >
            {props.value}
          </Typography>
          <Typography
            gutterBottom
            style={{
              fontWeight: '400',
              fontSize: '16px',
              color: '#6A6A6A',
            }}
          >
            {props.title}
          </Typography>
          <Box
            style={{
              marginTop: 'auto',
            }}
          >
            <img
              style={{
                fontSize: '40px',
              }}
              src={props.icon}
            />
          </Box>
        </Paper>
      </Grid>
    </>
  );
};

export default DashboardItem;
