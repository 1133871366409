import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import { ReactComponent as SvgCalendar } from './../../../assets/icons/icon-calendar.svg';
import { ReactComponent as SvgSearch } from './../../../assets/icons/icon-search.svg';
import { ReactComponent as SvgArrowDown } from './../../../assets/icons/arrow-down-icon.svg';
import { ReactComponent as SvgDelete } from './../../../assets/icons/icon-delete-campaign.svg';
import { ReactComponent as SvgAdd } from '../../../assets/icons/icon-add.svg';
import { mailCampaignsActions } from '../../../common/actions';
import * as moment from 'moment';
import OfferStats from './OfferStats';
import SearchInput from '../../../components/Form/components/SearchInput';
import ServerlessPagination from '../../../components/commons/ServerlessPagination/ServerlessPagination';
import { contactActions } from '../../../common/actions';
import OfferRow from './OfferRow';
import Checkbox from 'src/components/Form/components/Checkbox';
import { check } from 'prettier';
import Button from 'src/components/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const OffersContent = () => {
  const dispatch = useDispatch();
  const [viewAllSection, setViewAllSection] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [startDate, setStartDate] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const setFilterData = () => {
    let dateFrom = null;
    let dateTo = null;
    //if (startDate) dateFrom = startDate.getTime();
    //if (endDate) dateTo = endDate.getTime();

    if (startDate) dateFrom = moment(startDate).format('YYYY-MM-DD');
    if (endDate) dateTo = moment(endDate).format('YYYY-MM-DD');
    dispatch(mailCampaignsActions.getAllMailCampaignsFilter(dateFrom, dateTo));
    console.log('filtriraj');
  };

  const renderDateSelection = () => {
    return (
      <div>
        <div className="flex justify-between py-15">
          <div className="flex flex-col relative sm:mb-50 md:mb-0 w-half ">
            <span className="text-xs font-medium text-gray-400 mb-10">
              Start date
            </span>
            <div className="flex items-center justify-between border-1 border-gray-300 rounded-default p-10 bg-white mr-10">
              <ReactDatePicker
                className="ReactDatePicker text-sm outline-none"
                dateFormat="dd-MM-yyyy"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
              />
              <SvgCalendar className="w-sm h-sm min-w-sm text-primary right-0" />
            </div>
          </div>
          <div className="flex flex-col relative sm:mb-50 md:mb-0 w-half">
            <span className="text-xs font-medium text-gray-400 mb-10">
              End date
            </span>
            <div className="flex items-center justify-between border-1 border-gray-300 rounded-default p-10 bg-white">
              <ReactDatePicker
                className="ReactDatePicker text-sm outline-none"
                dateFormat="dd-MM-yyyy"
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);
                }}
              />
              <SvgCalendar className="w-sm h-sm min-w-sm text-primary right-0" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const contactsList = useSelector((state) => state.contacts.contactsList);
  const [searchContactList, setSearchContactList] = useState('');
  const searchResults = useSelector(
    (state) => state.contacts.searchResultContactsList
  );
  const searching = searchContactList && searchContactList.length > 0;
  const [currentPage, setCurrentPage] = useState(0);
  const totalRows = searching ? searchResults.length : contactsList.length;
  const lastPage = Math.ceil(totalRows / 10);

  useEffect(() => {
    if (searchContactList && searchContactList.length > 0) {
      dispatch(contactActions.searchContacts(searchContactList));
    }

    if (currentPage !== 0) setCurrentPage(0);
  }, [searchContactList]);

  console.log('setSearchContacts', searchContactList);
  console.log('ovo su rezultati', searchResults);

  const [isCheckboxDropdownOpen, setIsCheckboxDropdownOpen] = useState(false);
  const toggleCheckboxDropdown = () => {
    setIsCheckboxDropdownOpen(!isCheckboxDropdownOpen);
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  const deleteRows = () => {
    if (isChecked) {
      return (
        <div className="self-center">
          <button className="bg-gray-100 text-black text-sm p-10 rounded-default  outline-none flex justify-center items-center mr-15">
            <SvgDelete />
            <SvgArrowDown />
          </button>
        </div>
      );
    } else {
      return <div className="d-none"></div>;
    }
  };

  const history = useHistory();
  const handleNewOffer = () => {
    let path = `new-offer`;
    history.push(path);
  };

  return (
    <div>
      <div className="container mx-auto p-5">
        <div className="flex flex-wrap mb-15">
          <div className="flex w-full">
            <h2 className="text-3xl font-bold  mr-20 text-black  border-l-0 border-t-0 border-b-0 pr-20 border-1 border-gray-400">
              Offers
            </h2>
            <SearchInput
              name="search"
              type="text"
              //errors={errors}
              labelText="search"
              placeholder="Search notes..."
              /* onChange={(e) => {
              setSearch(e.target.value);
            }} */
            />
            <div className="flex justify-end relative z-9 ml-auto ">
              <Button
                variant="primary"
                height="44"
                width="220"
                className="text-sm "
                onClick={handleNewOffer}
              >
                <SvgAdd className="mr-20 text-white" />
                Add New Offer
              </Button>
            </div>
          </div>

          <div className="mt-20 mb-20 w-full">
            <div className=" bg-white border-default rounded-default shadow-md h-auto">
              <div className="scroll-wrap flex flex-wrap">
                <div className="w-2/3 px-20 py-15">{renderDateSelection()}</div>
              </div>
            </div>
          </div>
          <div className="pr-8 mb-20 w-1/3">
            <OfferStats title="Open offers" value="0" data="No data" />
          </div>
          <div className="px-8 mb-20 w-1/3">
            <OfferStats title="Open offers" value="0" data="No data" />
          </div>
          <div className="pl-8 mb-20 w-1/3">
            <OfferStats title="Open offers" value="0" data="No data" />
          </div>
        </div>

        <h1 className="mb-8 text-90 font-semibold text-2xl">Deals</h1>
        <div className="flex mb-20">
          <div className="relative h-9 flex-no-shrink mb-8 w-1/3">
            <SearchInput
              name="search"
              type="text"
              //errors={errors}
              labelText="search"
              placeholder="Search"
            />
          </div>
          <div className="w-full flex items-center mb-8">
            <div className="flex w-full justify-end items-center"></div>
          </div>
        </div>
        <div className="card">
          <div className="bg-white sm:rounded-t-lg flex justify-between py-8 px-2">
            <div>
              <button
                className={`${
                  isCheckboxDropdownOpen ? 'shadow-lg' : 'shadow-none'
                } bg-white text-black text-sm rounded-default outline-none flex items-center px-12`}
                onClick={toggleCheckboxDropdown}
              >
                <Checkbox />
                <SvgArrowDown />
              </button>
              <div
                className={`${
                  isCheckboxDropdownOpen
                    ? 'opacity-100 z-10'
                    : 'opacity-0 pointer-events-none'
                } transition-opacity duration-150 ease-in-out absolute rounded-md shadow-lg py-1 bg-white overflow-hidden mt-2`}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu"
              >
                <div className="flex px-20 text-sm text-gray-700 hover:bg-gray-100 items-center">
                  <Checkbox onChange={handleChange} checked={isChecked} />
                  Select All
                </div>
                <div className="flex px-20 text-sm text-gray-700 hover:bg-gray-100 items-center">
                  <Checkbox onChange={handleChange} checked={isChecked} />
                  Select All Suitable
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="self-center ">
                <button className="bg-gray-100 text-black text-sm p-10 rounded-default  outline-none flex items-center mr-15">
                  <SvgSearch />
                  <SvgArrowDown />
                </button>
              </div>
              {deleteRows()}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-20 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-b-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-200">
                      <tr>
                        <th
                          scope="col"
                          className="px-15 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        ></th>
                        <th
                          scope="col"
                          className="px-15 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          ID
                        </th>
                        <th
                          scope="col"
                          className="px-15 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="px-15 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Offer Maker
                        </th>
                        <th
                          scope="col"
                          className="px-15 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-15 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Total Price Net
                        </th>
                        <th
                          scope="col"
                          className="px-15 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Company Name
                        </th>
                        <th
                          scope="col"
                          className="px-15 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      <OfferRow />
                      <OfferRow />
                      <OfferRow />
                      <OfferRow />
                    </tbody>
                  </table>

                  <ServerlessPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    lastPage={lastPage}
                    total={totalRows}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OffersContent;
