import { actionCreator } from '../helpers';
import { userService } from '../services';

export const notificationActionTypes = {
  NOTIFICATIONS_PING: 'NOTIFICATIONS_PING',

  NOTIFICATION_GET_NOTIFICATIONS_REQUEST:
    'NOTIFICATION_GET_NOTIFICATIONS_REQUEST',
  NOTIFICATION_GET_NOTIFICATIONS_SUCCESS:
    'NOTIFICATION_GET_NOTIFICATIONS_SUCCESS',
  NOTIFICATION_GET_NOTIFICATIONS_FAILURE:
    'NOTIFICATION_GET_NOTIFICATIONS_FAILURE',

  NOTIFICATION_READ_NOTIFICATIONS_REQUEST:
    'NOTIFICATION_READ_NOTIFICATIONS_REQUEST',
  NOTIFICATION_READ_NOTIFICATIONS_SUCCESS:
    'NOTIFICATION_READ_NOTIFICATIONS_SUCCESS',
  NOTIFICATION_READ_NOTIFICATIONS_FAILURE:
    'NOTIFICATION_READ_NOTIFICATIONS_FAILURE',

  NOTIFICATION_READ_ALL_NOTIFICATIONS_REQUEST:
    'NOTIFICATION_READ_ALL_NOTIFICATIONS_REQUEST',
  NOTIFICATION_READ_ALL_NOTIFICATIONS_SUCCESS:
    'NOTIFICATION_READ_ALL_NOTIFICATIONS_SUCCESS',
  NOTIFICATION_READ_ALL_NOTIFICATIONS_FAILURE:
    'NOTIFICATION_READ_ALL_NOTIFICATIONS_FAILURE',

  NOTIFICATION_DELETE_NOTIFICATIONS_REQUEST:
    'NOTIFICATION_DELETE_NOTIFICATIONS_REQUEST',
  NOTIFICATION_DELETE_NOTIFICATIONS_SUCCESS:
    'NOTIFICATION_DELETE_NOTIFICATIONS_SUCCESS',
  NOTIFICATION_DELETE_NOTIFICATIONS_FAILURE:
    'NOTIFICATION_DELETE_NOTIFICATIONS_FAILURE',
  SEARCH_RESULTS_REQUEST: 'SEARCH_RESULTS_REQUEST',
  SEARCH_RESULTS_SUCCESS: 'SEARCH_RESULTS_SUCCESS',
  SEARCH_RESULTS_FAILURE: 'SEARCH_RESULTS_FAILURE',
};

/**
 * Gets notifications from past 60 seconds
 * @returns void
 */
const pingNotifications = () => (dispatch) => {
  userService
    .getNotifications(0, new Date(Date.now() - 60000).toISOString())
    .then((res) => {
      dispatch(actionCreator(notificationActionTypes.NOTIFICATIONS_PING, res));
    });
};

const getNotifications = (page = 0) => (dispatch) => {
  dispatch(
    actionCreator(
      notificationActionTypes.NOTIFICATION_GET_NOTIFICATIONS_REQUEST
    )
  );
  userService
    .getNotifications(page, false)
    .then((res) => {
      dispatch(
        actionCreator(
          notificationActionTypes.NOTIFICATION_GET_NOTIFICATIONS_SUCCESS,
          res
        )
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          notificationActionTypes.NOTIFICATION_GET_NOTIFICATIONS_FAILURE,
          {
            error: err,
          }
        )
      );
    });
};
const getSearchResults = (data) => (dispatch) => {
  dispatch(actionCreator(notificationActionTypes.SEARCH_RESULTS_REQUEST));
  userService
    .getSearchResults(data)
    .then((res) => {
      dispatch(
        actionCreator(notificationActionTypes.SEARCH_RESULTS_SUCCESS, res)
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(notificationActionTypes.SEARCH_RESULTS_FAILURE, {
          error: err,
        })
      );
    });
};

/**
 *
 * @param {number} notificationId
 * @param {Function} onRead
 * @returns
 */
const readNotification = (notificationId, onRead) => (dispatch) => {
  dispatch(
    actionCreator(
      notificationActionTypes.NOTIFICATION_READ_NOTIFICATIONS_REQUEST
    )
  );
  userService
    .readNotification(notificationId)
    .then(() => {
      dispatch(
        actionCreator(
          notificationActionTypes.NOTIFICATION_READ_NOTIFICATIONS_SUCCESS,
          notificationId
        )
      );

      if (onRead) onRead();
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          notificationActionTypes.NOTIFICATION_READ_NOTIFICATIONS_FAILURE,
          {
            error: err,
          }
        )
      );
    });
};

/**
 *
 * @param {Function} onRead
 */
const readAllNotification = (onRead) => (dispatch) => {
  dispatch(
    actionCreator(
      notificationActionTypes.NOTIFICATION_READ_ALL_NOTIFICATIONS_REQUEST
    )
  );
  userService
    .readAllNotification()
    .then(() => {
      dispatch(
        actionCreator(
          notificationActionTypes.NOTIFICATION_READ_ALL_NOTIFICATIONS_SUCCESS
        )
      );

      if (onRead) onRead();
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          notificationActionTypes.NOTIFICATION_READ_ALL_NOTIFICATIONS_FAILURE,
          {
            error: err,
          }
        )
      );
    });
};

/**
 *
 * @param {number[]} notificationIds
 * @param {Function} onDelete
 * @param {Function} onFail
 */
const deleteNotifications = (notificationIds, onDelete, onFail) => (
  dispatch
) => {
  dispatch(
    actionCreator(
      notificationActionTypes.NOTIFICATION_DELETE_NOTIFICATIONS_REQUEST
    )
  );
  userService
    .deleteNotifications(notificationIds)
    .then(() => {
      dispatch(
        actionCreator(
          notificationActionTypes.NOTIFICATION_DELETE_NOTIFICATIONS_SUCCESS,
          notificationIds
        )
      );

      if (onDelete) onDelete();
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          notificationActionTypes.NOTIFICATION_DELETE_NOTIFICATIONS_FAILURE,
          {
            error: err,
          }
        )
      );

      if (onFail) onFail();
    });
};

export const notificationActions = {
  pingNotifications,
  getNotifications,
  readNotification,
  readAllNotification,
  deleteNotifications,
  getSearchResults,
};
