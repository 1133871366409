import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { ReactComponent as SvgCalendar } from './../../../../assets/icons/icon-calendar.svg';

const PeriodInput = ({ onPrevious, onNext }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [startDate, setStartDate] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const setFilterData = () => {
    let dateFrom = null;
    let dateTo = null;
    //if (startDate) dateFrom = startDate.getTime();
    //if (endDate) dateTo = endDate.getTime();

    if (startDate) dateFrom = moment(startDate).format('YYYY-MM-DD');
    if (endDate) dateTo = moment(endDate).format('YYYY-MM-DD');
  };
  return (
    <div className="flex flex-col rounded-default p-20 bg-white">
      <header className="flex justify-center mb-20">
        <div>
          <h1 className="text-3xl font-bold text-gray-500 text-center">
            Date picker
          </h1>
          <p className="text-sm text-gray-400 text-center whitespace-nowrap">
            Enter the expiration date of the offer and the start date of the
            service
          </p>
        </div>
      </header>
      <main className="flex justify-center mb-10">
        <div className="flex justify-between flex-col items-center w-3/5">
          <div className="flex flex-col relative w-full mb-20 ">
            <span className="text-xs font-medium text-gray-400">
              Expiry date of the offer
            </span>
            <div className="flex items-center justify-between border-1 border-gray-300 rounded-default p-10 bg-white">
              <ReactDatePicker
                className="ReactDatePicker text-sm outline-none"
                dateFormat="dd-MM-yyyy"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
              />
              <SvgCalendar className="w-sm h-sm min-w-sm text-primary right-0" />
            </div>
          </div>
          <div className="flex flex-col relative w-full mb-20">
            <span className="text-xs font-medium text-gray-400">
              Product service start date
            </span>
            <div className="flex items-center justify-between border-1 border-gray-300 rounded-default p-10 bg-white">
              <ReactDatePicker
                className="ReactDatePicker text-sm outline-none"
                dateFormat="dd-MM-yyyy"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
              />
              <SvgCalendar className="w-sm h-sm min-w-sm text-primary right-0" />
            </div>
          </div>
        </div>
      </main>
      <footer className="flex justify-between items-center mb-10">
        <button
          onClick={onPrevious}
          className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-50 outline-none transition duration-500"
        >
          Back
        </button>
        <button
          onClick={onNext}
          className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-50 outline-none transition duration-500"
        >
          Continue
        </button>
      </footer>
    </div>
  );
};

export default PeriodInput;
