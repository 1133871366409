import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Select from '../../../../../components/Select/Select';
import FieldInput from '../../../../../components/Form/components/FieldInput';
import Button from '../../../../../components/Button';
import { useForm } from 'react-hook-form';
import { contactActions } from '../../../../../common/actions';
import { toast } from 'react-toastify';

const MetasShema = Yup.object().shape({
  fieldName: Yup.string().required('This field is required'),
  fieldValue: Yup.string().required('This field is required'),
});

const ProfileMetasForm = ({ contact }) => {
  const dispatch = useDispatch();

  const { register, handleSubmit, watch, errors, control } = useForm({
    mode: 'onChange',
    // resolver: yupResolver(MetasShema),
  });

  const onSubmitMeta = (data) => {
    console.log(data);
    if (data.fieldName && data.fieldValue) {
      const contactPostData = {
        ...contact,
        metas: [
          ...(contact.metas || []),
          {
            label: data.fieldName,
            value: data.fieldValue,
          },
        ],
      };

      dispatch(
        contactActions.updateContact(
          contact.id,
          contactPostData
          // setIsProfileSidebarOpened
        )
      );
    } else {
      toast.warning('Please fill all fields for creating a post meta!', {
        className: 'text-white',
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitMeta)}
      className="bg-gray-300 mt-20 mb-20 py-20 px-30 mx-60 rounded-default"
      style={{
        width: 'calc(100% + 120px) !important',
        marginLeft: '-60px !important',
      }}
    >
      <h3 className="font-bold text-md mt-10 mb-20">
        Add a contact <br />
        meta field
      </h3>
      <FieldInput
        labelText="Field name label (name)"
        register={register}
        name="fieldName"
        defaultValue=""
        type="text"
        errors={errors}
        value={watch('fieldName')}
        className="mb-0"
      />

      <FieldInput
        className="mb-10"
        labelText="Field value"
        register={register}
        name="fieldValue"
        defaultValue=""
        type="text"
        errors={errors}
        value={watch('fieldValue')}
      />

      <Button variant="primary" height="55" width="280" onClick={handleSubmit}>
        Add
      </Button>
      <section>
        <div className="bg-gray-300 pt-20 w-full rounded-default flex flex-col mb-40">
          <h3 className="font-bold text-md mt-10 mb-20 px-30">Meta fields </h3>
          <div className="flex items-center py-10 px-30 bg-gray-100">
            <div className="flex flex-col">
              <p className="w-full text-xs text-gray-400 mb-0">Meta fields</p>
              <p className="w-full text-sm text-gray-500 mb-0">Meta value</p>
            </div>
            <button
              type="button"
              //onClick={toggleDeleteModal}
              className="ml-auto text-gray-500 hover:text-gray-300 flex items-center justify-center relative"
            >
              {/* <SvgDelete /> */}
            </button>
          </div>
          <div className="flex items-center py-10 px-30">
            <div className="flex flex-col">
              <p className="w-full text-xs text-gray-400 mb-0">Meta fields</p>
              <p className="w-full text-sm text-gray-500 mb-0">Meta value</p>
            </div>
            <button
              type="button"
              //onClick={toggleDeleteModal}
              className="ml-auto text-gray-500 hover:text-gray-300 flex items-center justify-center relative"
            >
              {/* <SvgDelete /> */}
            </button>
          </div>
        </div>
      </section>
    </form>
  );
};

export { ProfileMetasForm };
