import React, { useState, useEffect } from 'react';
import { ReactComponent as SvgEdit } from '../../../assets/icons/icon-edit-audience.svg';
import { ReactComponent as SvgGoogle } from '../../../assets/icons/icon-google.svg';
import { ReactComponent as SvgFacebook } from '../../../assets/icons/icon-facebook-square.svg';
import SvgEditPass from '../../../assets/icons/edit-icon-2.svg';
import Toggler from 'src/components/Form/components/Toggler/Toggler';
import styles from './SettingsContent.module.scss';
import Button from 'src/components/Button';
import FieldInput from 'src/components/Form/components/FieldInput';
import SettingsBox from 'src/pages/Settings/components/SettingsBox/SettingsBox';
import { Formik } from 'formik';
import axios from 'axios';
import { API_ENDPOINT, USERS_ENDPOINT } from '../../../utils/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'src/common/actions';
import { Box, ButtonBase, InputBase, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { NavLink, useHistory } from 'react-router-dom';
import Modal from 'src/components/Modal';

const SettingsContent = ({ value }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isChecked, setIsChecked] = useState({
    pie_chart: false,
    histogram: false,
    tasks_to_review: false,
    most_recent_clients: false,
    email_campaigns: false,
    pie_chart_notf: false,
    histogram_notf: false,
    tasks_notf: false,
    email_notf: false,
    chat_notf: false,
  });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertOpenSave, setAlertOpenSave] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [alertMessageSave, setAlertMessageSave] = useState('');
  const [passwordChange, setPasswordChange] = useState({
    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
  });
  const history = useHistory();
  const deactivateUser = () => {
    dispatch(authActions.deactivateUser(history));
  };
  const toggleModal = () => {
    setIsModalOpened(!isModalOpened);
  };
  const handleChangePassword = ({ uri = '/password/change' }) => {
    if (
      passwordChange.currentPassword &&
      passwordChange.newPassword &&
      passwordChange.repeatPassword
    ) {
      let data = {
        current_password: passwordChange.currentPassword,
        new_password: passwordChange.newPassword,
        new_confirm_password: passwordChange.repeatPassword,
      };
      axios
        .post(`${API_ENDPOINT}${uri}`, data, {
          headers: {
            Authorization: 'Bearer' + localStorage.getItem('crm-client-token'),
            Accept: 'application/json',
          },
        })
        .then((response) => {
          setEditPassword(false);
          setPasswordChange({
            currentPassword: '',
            newPassword: '',
            repeatPassword: '',
          });
          setAlertMessageSave('Password changed succesfully');
          setAlertOpenSave(true);
        })
        .catch((e) => {
          let errors = e.response.data.message + '\n';
          for (let error in e.response.data.errors) {
            errors += e.response.data.errors[error][0] + '\n';
          }
          setAlertMessage(errors);
          setAlertOpen(true);
        });
    } else if (passwordChange.newPassword != passwordChange.repeatPassword) {
      setAlertMessage('Passwords are not the same');
      setAlertOpen(true);
    } else {
      setAlertMessage('Please fill in all fields');
      setAlertOpen(true);
    }
  };

  const dispatch = useDispatch();
  const handleAlertOpen = () => {
    setAlertOpen(true);
  };
  const handleAlertClose = () => {
    setAlertOpen(false);
    setAlertOpenSave(false);
  };
  const user = useSelector((state) => state.auth.user);
  const handleChangeChecked = (e) => {
    setIsChecked({
      ...isChecked,
      [e.target.name]: !isChecked[e.target.name],
    });
  };
  const toggleEdit = () => {
    setEditPassword(!editPassword);
  };
  const toggleIsEditMode = () => setIsEditMode(!isEditMode);

  value = 'George';
  const getContentByMode = () => {
    // if (isEditMode) {
    //   return (
    //     <Formik
    //       initialValues={{
    //         first_name: user.name.split(' ')[0],
    //         last_name: user.name.split(' ')[1],
    //         email: user.email,
    //         password: '',
    //       }}
    //       validate={(values) => {
    //         const errors = {};
    //         if (!values.email) {
    //           errors.email = (
    //             <Alert
    //               variant="filled"
    //               severity={'error'}
    //               style={{
    //                 whiteSpace: 'pre-line',
    //                 marginTop: 10,
    //                 marginBottom: 10,
    //               }}
    //             >
    //               Email is required
    //             </Alert>
    //           );
    //         } else if (
    //           !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    //         ) {
    //           errors.email = (
    //             <Alert
    //               variant="filled"
    //               severity={'error'}
    //               style={{
    //                 whiteSpace: 'pre-line',
    //                 marginTop: 10,
    //                 marginBottom: 10,
    //               }}
    //             >
    //               Email must contain @ and . in it
    //             </Alert>
    //           );
    //         }
    //         if (values.password.length < 8) {
    //           errors.password = (
    //             <Alert
    //               variant="filled"
    //               severity={'error'}
    //               style={{
    //                 whiteSpace: 'pre-line',
    //                 marginTop: 10,
    //                 marginBottom: 10,
    //               }}
    //             >
    //               Password must be at least 8 characters long
    //             </Alert>
    //           );
    //         }
    //         if (!first_name) {
    //           errors.first_name = (
    //             <Alert
    //               variant="filled"
    //               severity={'error'}
    //               style={{
    //                 whiteSpace: 'pre-line',
    //                 marginTop: 10,
    //                 marginBottom: 10,
    //               }}
    //             >
    //               First name is required
    //             </Alert>
    //           );
    //         }
    //         if (!last_name) {
    //           errors.last_name = (
    //             <Alert
    //               variant="filled"
    //               severity={'error'}
    //               style={{
    //                 whiteSpace: 'pre-line',
    //                 marginTop: 10,
    //                 marginBottom: 10,
    //               }}
    //             >
    //               Last name is required
    //             </Alert>
    //           );
    //         }

    //         return errors;
    //       }}
    //       onSubmit={(
    //         values,
    //         { setSubmitting, resetForm },
    //         uri = `/update/${user?.id}`
    //       ) => {
    //         //let image = URL.createObjectURL(images[0].file);

    //         // var formData = new FormData();
    //         // formData.append('name', values.first_name + ' ' + values.last_name);
    //         // formData.append('email', values.email);
    //         // formData.append('password', values.password);
    //         // formData.append('password_confirmation', values.password);
    //         !alertOpen &&
    //           axios
    //             .patch(
    //               `${USERS_ENDPOINT}${uri}`,
    //               {
    //                 name: values.first_name + ' ' + values.last_name,
    //                 email: values.email,
    //                 password: values.password,
    //                 password_confirmation: values.password,
    //               },
    //               {
    //                 headers: {
    //                   Authorization:
    //                     'Bearer' + localStorage.getItem('crm-client-token'),
    //                   Accept: 'application/json',
    //                 },
    //               }
    //             )
    //             .then((response) => {
    //               // handleRefreshComments()
    //               setAlertMessageSave(
    //                 "You've succesfully updated your profile"
    //               );
    //               setAlertOpenSave(true);
    //             })
    //             .catch((e) => {
    //               let errorsPost = e.response?.data?.message + '\n';
    //               for (let error in e.response?.data?.errorsPost) {
    //                 errorsPost += e.response?.data?.errorsPost[error][0] + '\n';
    //               }
    //               // console.log(erorrsPost);
    //             });
    //         // alert(JSON.stringify(values, null, 2));
    //         setSubmitting(false);
    //       }}
    //       validateOnChange={true}
    //       validateOnBlur={true}
    //     >
    //       {(props) => {
    //         const {
    //           errors,
    //           values,
    //           handleBlur,
    //           touched,
    //           handleChange,
    //           isSubmitting,
    //           submitForm,
    //           handleClose,
    //         } = props;
    //         return (
    //           <form
    //             onSubmit={submitForm}
    //             className={`flex flex-col ${styles.form}`}
    //           >
    //             <FieldInput
    //               name="first_name"
    //               onChange={handleChange}
    //               type="text"
    //               labelText="First Name:"
    //               placeholder="Password..."
    //               value={values.first_name}
    //               className={`${styles.customInput} justify-between`}
    //             />
    //             {errors.first_name && touched.first_name && errors.first_name}
    //             <hr
    //               className={`${styles.fullWidthHr} border-1 border-gray-200 relative -left-20 w-full`}
    //             />
    //             <FieldInput
    //               name="last_name"
    //               onChange={handleChange}
    //               defaultValue={values.last_name}
    //               type="text"
    //               labelText="Last Name:"
    //               placeholder=""
    //               value={values.last_name}
    //               className={`${styles.customInput} justify-between`}
    //             />
    //             <hr
    //               className={`${styles.fullWidthHr} border-1 border-gray-200 relative -left-20 w-full`}
    //             />
    //             <FieldInput
    //               name="email"
    //               onChange={handleChange}
    //               type="email"
    //               labelText="Email:"
    //               placeholder=""
    //               value={values.email}
    //               className={`${styles.customInput} `}
    //             />
    //             {errors.email && touched.email && errors.email}
    //             <hr
    //               className={`${styles.fullWidthHr} border-1 border-gray-200 relative -left-20 w-full`}
    //             />
    //             {/* <FieldInput
    //               name="contact_number"
    //               onChange={handleChange}
    //               defaultValue={values.contact_number}
    //               type="text"
    //               labelText="Contact Number:"
    //               placeholder=""
    //               value={values.contact_number}
    //               className={`${styles.customInput} justify-between`}
    //             />
    //             <hr
    //               className={`${styles.fullWidthHr} border-1 border-gray-200 relative -left-20 w-full`}
    //             /> */}
    //             <FieldInput
    //               name="password"
    //               onChange={handleChange}
    //               type="password"
    //               labelText="Password:"
    //               placeholder=""
    //               value={values.password}
    //               className={`${styles.customInput} justify-between`}
    //             />
    //             {errors.password && touched.password && errors.password}
    //             <hr
    //               className={`${styles.fullWidthHr} border-1 border-gray-200 relative -left-20 w-full`}
    //             />
    //             <div className="flex justify-between items-center mt-20">
    //               <Button
    //                 type="button"
    //                 onClick={toggleIsEditMode}
    //                 className={`${styles.editButton} ${
    //                   isEditMode ? 'hidden' : 'flex'
    //                 }`}
    //               >
    //                 <SvgEdit className="text-white mr-5" /> Edit
    //               </Button>
    //               <Button
    //                 type="submit"
    //                 onClick={() => {
    //                   errors.length > 0
    //                     ? null
    //                     : (submitForm(), toggleIsEditMode());
    //                 }}
    //               >
    //                 Save
    //               </Button>
    //             </div>
    //           </form>
    //         );
    //       }}
    //     </Formik>
    //   );
    // } else {
    return (
      <div className="flex flex-col">
        <Snackbar
          open={alertOpenSave}
          onClose={handleAlertClose}
          autoHideDuration={3000}
        >
          <Alert
            onClose={handleAlertClose}
            variant="filled"
            severity={'success'}
            style={{
              whiteSpace: 'pre-line',
            }}
          >
            {alertMessageSave}
          </Alert>
        </Snackbar>
        <Snackbar
          open={alertOpen}
          onClose={handleAlertClose}
          autoHideDuration={3000}
        >
          <Alert
            onClose={handleAlertClose}
            variant="filled"
            severity={'error'}
            style={{
              whiteSpace: 'pre-line',
            }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        <div className="flex mt-20 mb-5">
          <p className="text-sm text-black mb-0 pr-60 font-semibold w-200">
            First Name:
          </p>
          <p className="text-sm text-black mb-0 font-semibold">
            {user.name.split(' ')[0]}
          </p>
        </div>
        <hr
          className={`${styles.fullWidthHr} border-1 border-gray-200 relative -left-20 w-full`}
        />
        <div className="flex mt-20 mb-5 ">
          <p className="text-sm text-black mb-0 pr-60 font-semibold w-200">
            Last Name:
          </p>
          <p className="text-sm text-black mb-0 font-semibold">
            {user.name.split(' ')[1]}
          </p>
        </div>
        <hr
          className={`${styles.fullWidthHr} border-1 border-gray-200 relative -left-20 w-full`}
        />
        <div className="flex mt-20 mb-5 ">
          <p className="text-sm text-black mb-0 pr-60 font-semibold w-200">
            Email:
          </p>
          <p className="text-sm text-black mb-0 font-semibold">{user.email}</p>
        </div>
        <hr
          className={`${styles.fullWidthHr} border-1 border-gray-200 relative -left-20 w-full`}
        />
        <div
          style={{ justifyContent: 'space-between', alignItems: 'center' }}
          className="flex mt-20 mb-5 "
        >
          <Box display="flex">
            <p className=" text-sm text-black mb-0 pr-60 font-semibold w-200">
              {editPassword ? 'Current Password:' : 'Password:'}
            </p>
            {editPassword ? (
              <InputBase
                onChange={(e) =>
                  setPasswordChange({
                    ...passwordChange,
                    currentPassword: e.target.value,
                  })
                }
                placeholder="Current password..."
              />
            ) : (
              <p className="text-sm text-black mb-0 font-semibold">********</p>
            )}
          </Box>
          {!editPassword && (
            <ButtonBase onClick={toggleEdit} style={{ borderRadius: 20 }}>
              <img src={SvgEditPass} />
            </ButtonBase>
          )}
        </div>
        <hr
          className={`${styles.fullWidthHr} border-1 border-gray-200 relative -left-20 w-full`}
        />
        {editPassword ? (
          <>
            <div className="flex mt-20 mb-5 ">
              <p className="text-sm text-black mb-0 pr-60 font-semibold w-200">
                New Password:
              </p>
              <InputBase
                onChange={(e) =>
                  setPasswordChange({
                    ...passwordChange,
                    newPassword: e.target.value,
                  })
                }
                placeholder="New password..."
              />
            </div>
            <hr
              className={`${styles.fullWidthHr} border-1 border-gray-200 relative -left-20 w-full`}
            />
            <div className="flex mt-20 mb-5 ">
              <p className="text-sm text-black mb-0 pr-60 font-semibold w-200">
                Repeat Password:
              </p>
              <InputBase
                onChange={(e) =>
                  setPasswordChange({
                    ...passwordChange,
                    repeatPassword: e.target.value,
                  })
                }
                placeholder="Repeat password..."
              />
            </div>
            <hr
              className={`${styles.fullWidthHr} border-1 border-gray-200 relative -left-20 w-full mb-20`}
            />
            <Box style={{ gap: 10 }} display="flex" justifyContent="flex-end">
              <Button onClick={toggleEdit} variant="transparent">
                <p className="pl-5">Cancel</p>
              </Button>
              <Button onClick={handleChangePassword} variant="primary">
                <p className="pl-5">Change Password</p>
              </Button>
            </Box>
          </>
        ) : null}
        {/* <div className="flex justify-between items-center mt-20">
            <Button
              onClick={toggleIsEditMode}
              className={`${styles.editButton}`}
            >
              <SvgEdit className="mr-5" /> Edit
            </Button>
          </div> */}
      </div>
    );
  };

  return (
    <div className="bg-gray-100">
      <div className="container mx-auto">
        <div className="flex justify-between mb-20">
          <h3 className="font-bold text-2xl">Settings</h3>
          <NavLink to="/profile">
            <Button variant="primary">
              <SvgEdit className="text-white" />
              <p className="pl-5">Edit Profile</p>
            </Button>
          </NavLink>
        </div>
        <div className="grid grid-cols-10 gap-15">
          <SettingsBox
            title="Social media"
            subtitle="Lorem ipsum"
            className="sm:col-span-10 md:col-span-4 row-span-5"
          >
            <Button variant="transparent" className="mb-20 w-4/5">
              <SvgGoogle height="20" width="20" className="text-primary" />
              <p className="font-semibold pl-20 text-center leading-tight">
                Connected via Google
              </p>
            </Button>

            <Button variant="transparent" className="mb-20 w-4/5">
              <SvgFacebook height="20" width="20" fill="" />
              <p className="font-semibold pl-20 text-center leading-tight">
                Connected via Facebook
              </p>
            </Button>
          </SettingsBox>
          <SettingsBox
            title="Overview Settings"
            subtitle="Lorem ipsum"
            className="sm:col-span-10 md:col-span-6 row-span-6"
          >
            <form>
              <Toggler
                labelText="Pie Chart"
                onClick={(e) => handleChangeChecked(e)}
                checked={isChecked.pie_chart}
                className={`my-20 justify-between`}
                name="pie_chart"
              />
              <Toggler
                labelText="Histogram"
                className={`my-20 justify-between`}
                onClick={(e) => handleChangeChecked(e)}
                checked={isChecked.histogram}
                name="histogram"
              />
              <Toggler
                labelText="Tasks to review"
                className={`my-20 justify-between`}
                onClick={(e) => handleChangeChecked(e)}
                checked={isChecked.tasks_to_review}
                name="tasks_to_review"
              />
              <Toggler
                labelText="Most recent clients"
                className={`my-20 justify-between`}
                onClick={(e) => handleChangeChecked(e)}
                checked={isChecked.most_recent_clients}
                name="most_recent_clients"
              />
              <Toggler
                labelText="Email campaigns"
                className={`my-20 justify-between`}
                onClick={(e) => handleChangeChecked(e)}
                checked={isChecked.email_campaigns}
                name="email_campaigns"
              />
            </form>
          </SettingsBox>
          <SettingsBox
            title="Notifications Settings"
            subtitle="Lorem ipsum"
            className="sm:col-span-10 md:col-span-4 row-span-6"
          >
            <form>
              <Toggler
                labelText="Pie chart notifications"
                className={'my-20 justify-between'}
                onClick={(e) => handleChangeChecked(e)}
                checked={isChecked.pie_chart_notf}
                name="pie_chart_notf"
              />
              <Toggler
                labelText="Histogram notifications"
                className={'my-20 justify-between'}
                onClick={(e) => handleChangeChecked(e)}
                checked={isChecked.histogram_notf}
                name="histogram_notf"
              />
              <Toggler
                labelText="Task notifications"
                className={'my-20 justify-between'}
                onClick={(e) => handleChangeChecked(e)}
                checked={isChecked.tasks_notf}
                name="tasks_notf"
              />
              <Toggler
                labelText="Email notifications"
                className={'my-20 justify-between'}
                onClick={(e) => handleChangeChecked(e)}
                checked={isChecked.email_notf}
                name="email_notf"
              />
              <Toggler
                labelText="Chat notifications"
                className={'my-20 justify-between'}
                onClick={(e) => handleChangeChecked(e)}
                checked={isChecked.chat_notf}
                name="chat_notf"
              />
            </form>
          </SettingsBox>
          <SettingsBox
            title="Advanced Settings"
            subtitle="Lorem ipsum"
            className="sm:col-span-10 md:col-span-6 row-span-6"
          >
            {getContentByMode()}
          </SettingsBox>
          <div className="flex justify-between sm:col-span-10 md:col-span-4 gap-15">
            <Button
              onClick={() => dispatch(authActions.logoutUser(history))}
              className={`${styles.boxButton} w-full shadow-md font-bold text-sm h-50`}
            >
              Logout
            </Button>
            <Button
              className={`${styles.boxButton} w-full shadow-md font-bold text-sm h-50`}
              onClick={toggleModal}
            >
              Deactivate my profile
            </Button>
          </div>
          <Modal
            isOpen={isModalOpened}
            onRequestClose={toggleModal}
            headerTitle="Are you sure?"
            className="modal--alert"
          >
            <h2 className={`w-full text-2x font-bold text-black mt-50 mb-20`}>
              Are you sure?
            </h2>
            <p className="my-20 mx-30">
              Current account will be deactivated. In the next 24h you can log
              back in to reactivate, after that you can&#39;t undo this action.
            </p>
            <div className="flex justify-between mt-20">
              <button
                className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-gray-500 hover:bg-gray-400"
                role="menuitem"
                onClick={toggleModal}
              >
                Cancel
              </button>
              <button
                className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-primary hover:bg-secondary whitespace-nowrap"
                role="menuitem"
                onClick={deactivateUser}
              >
                Continue
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default SettingsContent;
