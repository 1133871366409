export const contactsActionTypes = {
  CONTACTS_GET_ALL_CONTACTS_REQUEST: 'CONTACTS_GET_ALL_CONTACTS_REQUEST',
  CONTACTS_GET_ALL_CONTACTS_SUCCESS: 'CONTACTS_GET_ALL_CONTACTS_SUCCESS',
  CONTACTS_GET_ALL_CONTACTS_FAILURE: 'CONTACTS_GET_ALL_CONTACTS_FAILURE',
  CONTACTS_GET_CONTACT_REQUEST: 'CONTACTS_GET_CONTACT_REQUEST',
  CONTACTS_GET_CONTACT_SUCCESS: 'CONTACTS_GET_CONTACT_SUCCESS',
  CONTACTS_GET_CONTACT_FAILURE: 'CONTACTS_GET_CONTACT_FAILURE',
  CONTACTS_GET_CONTACT_REQUEST: 'CONTACTS_GET_CONTACT_REQUEST',
  CONTACTS_GET_CONTACT_SUCCESS: 'CONTACTS_GET_CONTACT_SUCCESS',
  CONTACTS_GET_CONTACT_FAILURE: 'CONTACTS_GET_CONTACT_FAILURE',
  CONTACTS_NEW_CONTACT_REQUEST: 'CONTACTS_NEW_CONTACT_REQUEST',
  CONTACTS_NEW_CONTACT_SUCCESS: 'CONTACTS_NEW_CONTACT_SUCCESS',
  CONTACTS_NEW_CONTACT_FAILURE: 'CONTACTS_NEW_CONTACT_FAILURE',
  CONTACTS_EDIT_CONTACT_REQUEST: 'CONTACTS_EDIT_CONTACT_REQUEST',
  CONTACTS_EDIT_CONTACT_SUCCESS: 'CONTACTS_EDIT_CONTACT_SUCCESS',
  CONTACTS_EDIT_CONTACT_FAILURE: 'CONTACTS_EDIT_CONTACT_FAILURE',
  CONTACTS_UPDATE_IMAGE_REQUEST: 'CONTACTS_UPDATE_IMAGE_REQUEST',
  CONTACTS_UPDATE_IMAGE_SUCCESS: 'CONTACTS_UPDATE_IMAGE_SUCCESS',
  CONTACTS_UPDATE_IMAGE_FAILURE: 'CONTACTS_UPDATE_IMAGE_FAILURE',
  CONTACTS_DELETE_CONTACT_REQUEST: 'CONTACTS_DELETE_CONTACT_REQUEST',
  CONTACTS_DELETE_CONTACT_SUCCESS: 'CONTACTS_DELETE_CONTACT_SUCCESS',
  CONTACTS_DELETE_CONTACT_FAILURE: 'CONTACTS_DELETE_CONTACT_FAILURE',
  CONTACTS_CLEAR_STATE: 'CONTACT_CLEAR_STATE',
  CONTACTS_SEARCH_CONTACTS_REQUEST: 'CONTACTS_SEARCH_CONTACTS_REQUEST',
  CONTACTS_SEARCH_CONTACTS_SUCCESS: 'CONTACTS_SEARCH_CONTACTS_SUCCESS',
  CONTACTS_SEARCH_CONTACTS_FAILURE: 'CONTACTS_SEARCH_CONTACTS_FAILURE',

  CONTACTS_GET_ALL_TAGS_REQUEST: 'CONTACTS_GET_ALL_TAGS_REQUEST',
  CONTACTS_GET_ALL_TAGS_SUCCESS: 'CONTACTS_GET_ALL_TAGS_SUCCESS',
  CONTACTS_GET_ALL_TAGS_FAILURE: 'CONTACTS_GET_ALL_TAGS_FAILURE',
};

export const contactsToastMessages = {
  [contactsActionTypes.CONTACTS_NEW_CONTACT_SUCCESS]: {
    message: 'Contact created',
  },
  [contactsActionTypes.CONTACTS_NEW_CONTACT_FAILURE]: {
    message: 'Contact is not created',
  },
  [contactsActionTypes.CONTACTS_EDIT_CONTACT_SUCCESS]: {
    message: 'Contact updated',
  },
  [contactsActionTypes.CONTACTS_EDIT_CONTACT_FAILURE]: {
    message: 'Contact failed to update',
  },
  [contactsActionTypes.CONTACTS_UPDATE_IMAGE_SUCCESS]: {
    message: "Contact's image updated",
  },

  [contactsActionTypes.CONTACTS_DELETE_CONTACT_SUCCESS]: {
    message: 'Contact deleted',
  },
};
