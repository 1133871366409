import { COMPANIES_ENDPOINT } from 'src/utils/endpoints';
import { client } from '../helpers';

const createCompany = async (data) => {
  const items = { company: {}, user: {} };

  for (const key of Object.keys(data)) {
    let [firstKey, ...value] = key.split('_');
    value = value.join('_');

    items[firstKey] = { ...items[firstKey], [value]: data[key] };
  }

  return client().post(COMPANIES_ENDPOINT, items);
};

export const companyService = {
  createCompany,
};
