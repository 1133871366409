import { dealsService } from '../services/dealsService';
import { dealsActionTypes } from '../constants';
import { actionCreator, history } from '../helpers';
import { toast } from 'react-toastify';

const getAllDeals = () => (dispatch) => {
  dispatch(actionCreator(dealsActionTypes.DEALS_GET_ALL_REQUEST));
  dealsService
    .getAllDeals()
    .then((res) => {
      dispatch(
        actionCreator(dealsActionTypes.DEALS_GET_ALL, {
          list: res,
        })
      );
    })
    .catch((err) => {
      console.log(err);
    });
};
const getAllDealComments = (dealId) => (dispatch) => {
  dispatch(actionCreator(dealsActionTypes.DEALS_GET_COMMENTS_REQUEST));
  dealsService
    .getAllDealComments(dealId)
    .then((res) => {
      dispatch(
        actionCreator(dealsActionTypes.DEALS_GET_COMMENTS, {
          list: res,
        })
      );
    })
    .catch((err) => {
      console.log(err);
    });
};
export const dealsActions = {
  getAllDeals,
  getAllDealComments,
};
