import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { contactActions } from '../../.././../common/actions';
import Checkbox from '../../../../components/Form/components/Checkbox';
import { ReactComponent as SvgDelete } from './../../../../assets/icons/icon-delete-campaign.svg';
import { ReactComponent as SvgEdit } from './../../../../assets/icons/icon-edit-campaign.svg';
import Modal from '../../../../components/Modal';
import { routes } from '../../../../common/constants';
import { useHistory } from 'react-router';
import Button from '../../../../components/Button';

const OfferRow = ({ search, setSearchOffers }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isDeleteOfferModalOpened, setIsDeleteOfferModalOpened] = useState(
    false
  );
  const toggleDeleteModal = () => {
    setIsDeleteOfferModalOpened(!isDeleteOfferModalOpened);
  };

  const handleDeleteOffer = () => {
    dispatch(contactActions.deleteContact(data.offer_id));
    setIsDeleteOfferModalOpened(!isDeleteOfferModalOpened);
  };

  const data = {
    offer_id: '1',
    offer_date: '02/12/2021',
    offer_maker: 'Kristian',
    status: 'Accepted',
    total_price_net: '€ 1,000.00',
    company_name: 'Traupe Consulting',
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <tr>
        <td className="px-15 py-10 whitespace-nowrap">
          <div className="">
            <Checkbox onChange={handleChange} checked={isChecked} />
          </div>
        </td>
        <td className="px-15 py-10 whitespace-nowrap">
          <a
            href="/offer"
            className="text-sm text-blue-400 hover:text-navyBlue font-semibold"
          >
            {data.offer_id}
          </a>
        </td>
        <td className="px-15 py-10 whitespace-nowrap">
          <div className="text-sm text-gray-900">{data.offer_date}</div>
        </td>
        <td className="px-15 py-10 whitespace-nowrap">
          <a
            href="#"
            className="px-20 py-10 whitespace-nowrap text-sm text-blue-400 hover:text-navyBlue font-semibold"
          >
            {data.offer_maker}
          </a>
        </td>
        <td className="px-15 py-10 whitespace-nowrap">
          <div className="text-sm text-gray-900">{data.status}</div>
        </td>
        <td className="px-15 py-10 whitespace-nowrap text-sm text-gray-500">
          {data.total_price_net}
        </td>
        <td className="px-15 py-10 whitespace-nowrap text-sm text-gray-500">
          {data.company_name}
        </td>
        <td className="px-15 py-10 whitespace-nowrap text-sm font-medium h-full">
          <div className="flex justify-around">
            <Button
              variant="link"
              height="20"
              width="100"
              className="text-xs"
              target="/#"
            >
              Download PDF
            </Button>
            <button
              className="text-gray-500 hover:text-gray-300 flex items-center justify-center relative"
              onClick={() => {
                history.push(
                  `${routes.CONTACT.path}?contactId=${data.offer_id}`
                );
                /*closeContactAction()*/
              }}
            >
              <SvgEdit className="text-primary" />
            </button>

            <button
              type="button"
              onClick={toggleDeleteModal}
              className="text-gray-500 hover:text-gray-300 flex items-center justify-center relative outline-none"
            >
              <SvgDelete fill="#0395FF" />
            </button>
          </div>
        </td>
      </tr>

      <Modal
        isOpen={isDeleteOfferModalOpened}
        onRequestClose={toggleDeleteModal}
        headerTitle="Delete offer"
        className="modal--alert"
      >
        <h2 className={`w-full text-2x font-bold text-black mt-50 mb-20`}>
          Delete offer?
        </h2>
        <div className="flex justify-between">
          <button
            className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-primary hover:bg-secondary"
            role="menuitem"
            onClick={handleDeleteOffer}
          >
            Yes
          </button>
          <button
            className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-gray-500 hover:bg-gray-400"
            role="menuitem"
            onClick={toggleDeleteModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default OfferRow;
