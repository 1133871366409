import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../../components/Modal/Modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ReactComponent as SvgCheckCircle } from './.././../../assets/icons/icon-check-circle.svg';
import { ReactComponent as SvgAvatars } from 'src/assets/icons/icon-avatars.svg';
import PickAudienceTable from 'src/pages/Campaigns/components/PickAudienceTable/PickAudienceTable.js';
import SearchInput from '../../../components/Form/components/SearchInput';
import FieldInput from '../../../components/Form/components/FieldInput';
import FavoritesAddButton from '../../../components/FavoritesAddButton/FavoritesAddButton';
import AddContactAudienceTable from '../../Campaigns/components/AddContactAudienceTable';
import { contactActions, mailCampaignsActions } from '../../../common/actions';
import Button from '../../../components/Button';
import { toast } from 'react-toastify';

function PickAudienceModal({
  togglePickAudience,
  isPickAudienceModalOpened,
  togglePredefinedAudience,
  areAudiencesLoaded,
  setMailCampaignAudience,
  searchAudience,
  setSearchAudience,
  pickedAudiences,
  setPickedAudiences,
  allAudiencesList,
  handleEditAudience,
}) {
  //////////////////
  //FORM
  //////////////////
  const {
    audience,
    handleSubmit,
    watch,
    errors,
    register,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onChange',
  });

  return (
    <Modal
      isOpen={isPickAudienceModalOpened}
      onRequestClose={togglePickAudience}
      headerTitle="Pick Audience"
      className="modal--audience"
      initialSearch
    >
      <div className="flex items-center mb-40 ">
        <h2 className={`text-2x font-bold text-black mr-20`}>
          Pick your audience
        </h2>
        <a
          className="group bg-primary h-44 text-white border-1 solid border-primary hover:text-primary hover:bg-white  p-15 px-20 text-sm rounded-default outline-none flex justify-center items-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 ml-20"
          onClick={togglePickAudience}
        >
          <SvgAvatars className="mr-20 text-white group-hover:text-primary transition duration-500 ease-in-out" />
          Add new audience list
        </a>
        <a
          className="group bg-transparent h-44 text-primary border-1 solid border-primary hover:text-primary hover:bg-white  p-15 px-20 text-sm rounded-default outline-none flex justify-center items-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 ml-20"
          onClick={togglePredefinedAudience}
        >
          Add Predefined Audience
        </a>
      </div>
      <form noValidate className="flex flex-col sm:mb-50 md:mb-0 w-full">
        <SearchInput
          /* audience={audience} */
          name="search"
          defaultValue=""
          type="text"
          /* errors={errors} */
          labelText="search"
          placeholder="Search contacts..."
          className="mb-20"
          value={watch('search')}
          onChange={(e) => {
            setSearchAudience(e.target.value);
          }}
        />
        {areAudiencesLoaded && (
          <PickAudienceTable
            search={searchAudience}
            pickedAudiences={pickedAudiences}
            setPickedAudiences={setPickedAudiences}
            audiences={allAudiencesList}
            handleEditAudience={handleEditAudience}
          />
        )}
      </form>
      <button
        isLoading={isSubmitting}
        className="ml-auto mt-20 flex text-center justify-center items-center rounded-default pl-0 px-30 py-10 text-sm text-white bg-primary hover:bg-secondary outline-none"
        role="menuitem"
        onClick={setMailCampaignAudience}
      >
        <SvgCheckCircle className="ml-20 mr-20" />
        Confirm
      </button>
    </Modal>
  );
}

export { PickAudienceModal };
