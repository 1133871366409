import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import ButtonBack from '../../components/ButtonBack/ButtonBack';
import CampaignForm from '../../components/CampaignForm';
import LayoutComponent from '../../components/commons/LayoutComponent';
import Modal from '../../components/Modal/Modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ReactComponent as SvgAvatars } from 'src/assets/icons/icon-avatars.svg';
import { ReactComponent as SvgAddTemplate } from 'src/assets/icons/icon-add-template.svg';
import { ReactComponent as SvgCheckCircle } from 'src/assets/icons/icon-check-circle.svg';
import { ReactComponent as SvgAutomate } from 'src/assets/icons/preview-icon.svg';

import { mailCampaignsActions } from '../../common/actions';
import { contactActions } from '../../common/actions';
import { NewAudienceModal } from './NewAudience';
import { CampaignPreviewEmailModal } from './CampaignPreviewEmailModal';
import { CampaignDatepickerModal } from './CampaignDatepickerModal';
import { CampaignNewTemplateModal } from './CampaignNewTemplateModal';
import { CampaignEditTemplateModal } from './CampaignEditTemplateModal';
import { CampaignPickTemplateModal } from './CampaignPickTemplateModal/CampaignPickTemplateModal';
import { CampaignInactivityModal } from './CampaignInactivityModal';
import { ProductTypeAudienceModal } from './ProductTypeAudience';
import { EditAudienceModal } from './EditAudience';
import { PickAudienceModal } from './PickAudience';

const AudienceSchema = Yup.object().shape({
  campaignName: Yup.string()
    .min(8, 'Enter minimium 8 characters')
    .required('This field is required'),
});

export default function AddCampaign() {
  const dispatch = useDispatch();
  const history = useHistory();
  const pickedAudiencesFromState = useSelector(
    (state) => state.mailCampaigns.mailCampaignState.audiences
  );

  const allAudiencesList = useSelector(
    (state) => state.mailCampaigns.audiencesState.audiences
  );

  //////////////////
  //MODAL PICK AUDIENCE
  //////////////////

  const [searchAudience, setSearchAudience] = useState('');
  const [pickedAudiences, setPickedAudiences] = useState(
    pickedAudiencesFromState || []
  );
  const [isPickAudienceModalOpened, setIsPickAudienceModalOpened] = useState(
    false
  );

  const [isEditAudienceModalOpened, setisEditAudienceModalOpened] = useState(
    false
  );

  const [audienceForEdit, setAudienceForEdit] = useState(null);

  useEffect(() => {
    if (!isPickAudienceModalOpened) {
      if (
        pickedAudiencesFromState &&
        pickedAudiencesFromState.length !== pickedAudiences.length
      ) {
        setPickedAudiences(pickedAudiencesFromState || []);
      }
    }
  }, [isPickAudienceModalOpened]);

  const areAudiencesLoaded = useSelector(
    (state) => state.mailCampaigns.audiencesState.areAudiencesLoaded
  );

  useEffect(() => {
    if (!areAudiencesLoaded) {
      dispatch(mailCampaignsActions.getAllAudiences());
    }
  });

  const togglePickAudience = () => {
    if (searchAudience.length > 0) {
      setSearchAudience('');
    }
    setIsPickAudienceModalOpened(!isPickAudienceModalOpened);
  };

  const toggleEditAudience = () => {
    // if (searchAudience.length > 0) {
    //   setSearchAudience('');
    // }
    setisEditAudienceModalOpened(!isEditAudienceModalOpened);
  };

  //////////////////
  //MODAL NEW AUDIENCE / CONTACT LIST
  //////////////////
  const areContactsLoaded = useSelector(
    (state) => state.contacts.areContactsLoaded
  );

  useEffect(() => {
    if (!areContactsLoaded) {
      dispatch(contactActions.getAllContacts());
    }
  }, [areContactsLoaded]);

  const [isNewAudienceModalOpened, setIsNewAudienceModalOpened] = useState(
    false
  );
  const [
    isPredefinedAudienceModalOpened,
    setIsPredefinedAudienceModalOpened,
  ] = useState(false);

  const toggleNewAudience = () => {
    if (searchAudience.length > 0) {
      setSearchAudience('');
    }
    setIsNewAudienceModalOpened(!isNewAudienceModalOpened);
  };

  const togglePredefinedAudience = () => {
    setIsPredefinedAudienceModalOpened(!isPredefinedAudienceModalOpened);
  };

  const setMailCampaignAudience = () => {
    dispatch(
      mailCampaignsActions.setPickedAudiences(
        pickedAudiences,
        setIsPickAudienceModalOpened
      )
    );
    setIsPickAudienceModalOpened(false);
  };

  //pick template mpodal
  const [
    isCampaignPickTemplateModalOpened,
    setIsCampaignPickTemplateModalOpened,
  ] = useState(false);
  const toggleCampaignPickTemplateModal = () => {
    setIsCampaignPickTemplateModalOpened(!isCampaignPickTemplateModalOpened);
  };
  //new template modal
  const [
    isCampaignNewTemplateModalOpened,
    setIsCampaignNewTemplateModalOpened,
  ] = useState(false);
  const toggleCampaignNewTemplateModal = () => {
    setIsCampaignNewTemplateModalOpened(!isCampaignNewTemplateModalOpened);
    setIsTemplateModalOpened(false);
  };
  //edit template modal
  const [
    isCampaignEditTemplateModalOpened,
    setIsCampaignEditTemplateModalOpened,
  ] = useState(false);
  const toggleCampaignEditTemplateModal = () => {
    setIsCampaignEditTemplateModalOpened(!isCampaignEditTemplateModalOpened);
  };
  //datepicker modal
  const [
    isCampaignDatepickerModalOpened,
    setIsCampaignDatepickerModalOpened,
  ] = useState(false);
  const toggleCampaignDatepickerModal = () => {
    setIsCampaignDatepickerModalOpened(!isCampaignDatepickerModalOpened);
  };
  //campaign preview modal
  const [
    isCampaignPreviewEmailModalOpened,
    setIsCampaignPreviewEmailModalOpened,
  ] = useState(false);
  const toggleCampaignPreviewEmailModal = () => {
    setIsCampaignPreviewEmailModalOpened(!isCampaignPreviewEmailModalOpened);
  };

  //Inactivity modal
  const [
    isCampaignInactivityModalOpened,
    setIsCampaignInactivityModalOpened,
  ] = useState(false);
  const toggleCampaignInactivityModal = () => {
    setIsCampaignInactivityModalOpened(!isCampaignInactivityModalOpened);
  };

  //////////////////
  //FORM
  //////////////////
  const {
    audience,
    handleSubmit,
    watch,
    errors,
    register,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(AudienceSchema),
    mode: 'onChange',
  });

  const handleEditAudience = (audience) => {
    toggleEditAudience();
    setAudienceForEdit(audience);
  };

  return (
    <LayoutComponent title="Add new campaign">
      <ButtonBack className="justify-end -mt-50" />
      <div className="flex justify-end mt-50">
        <a
          className="bg-transparent h-44 text-primary border-1 border-solid border-primary hover:text-navyBlue p-15 px-20 text-sm rounded-default outline-none flex justify-center items-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 mr-20"
          //onClick={toggleCampaignDatepickerModal}
          style={{ maxWidth: '250px' }}
          onClick={toggleCampaignPreviewEmailModal}
        >
          <SvgAutomate className="mr-20 group-hover:text-navyBlue" />
          Preview email
        </a>
        <a
          className="bg-transparent h-44 text-primary border-1 border-solid border-primary hover:text-navyBlue p-15 px-20 text-sm rounded-default outline-none flex justify-center items-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 mr-20"
          onClick={togglePickAudience}
        >
          <SvgAvatars className="mr-20" />
          Pick Audience
        </a>
        <a
          className="bg-transparent h-44 text-primary border-1 border-solid border-primary hover:text-navyBlue p-15 px-20 text-sm rounded-default outline-none flex justify-center items-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 mr-20"
          onClick={toggleCampaignPickTemplateModal}
        >
          <SvgAddTemplate className="mr-20 text-primary" />
          Add Template
        </a>
      </div>
      <div className="flex flex-col mt-50">
        <CampaignForm
          className="mt-50"
          toggleCampaignDatepickerModal={toggleCampaignDatepickerModal}
          toggleCampaignEditTemplateModal={toggleCampaignEditTemplateModal}
          togglePickAudience={togglePickAudience}
          toggleCampaignInactivityModal={toggleCampaignInactivityModal}
        />
      </div>

      <PickAudienceModal
        togglePickAudience={togglePickAudience}
        isPickAudienceModalOpened={isPickAudienceModalOpened}
        togglePredefinedAudience={togglePredefinedAudience}
        areAudiencesLoaded={areAudiencesLoaded}
        setMailCampaignAudience={setMailCampaignAudience}
        searchAudience={searchAudience}
        setSearchAudience={setSearchAudience}
        pickedAudiences={pickedAudiences}
        setPickedAudiences={setPickedAudiences}
        allAudiencesList={allAudiencesList}
        handleEditAudience={handleEditAudience}
      />
      <NewAudienceModal
        toggleNewAudience={toggleNewAudience}
        isNewAudienceModalOpened={isNewAudienceModalOpened}
      />
      <ProductTypeAudienceModal
        togglePredefinedAudience={togglePredefinedAudience}
        isPredefinedAudienceModalOpened={isPredefinedAudienceModalOpened}
      />
      <EditAudienceModal
        toggleEditAudience={toggleEditAudience}
        isEditAudienceModalOpened={isEditAudienceModalOpened}
        audienceForEdit={audienceForEdit}
      />
      <CampaignPickTemplateModal
        toggleCampaignPickTemplateModal={toggleCampaignPickTemplateModal}
        isCampaignPickTemplateModalOpened={isCampaignPickTemplateModalOpened}
      />
      <CampaignNewTemplateModal
        toggleCampaignNewTemplateModal={toggleCampaignNewTemplateModal}
        isCampaignNewTemplateModalOpened={isCampaignNewTemplateModalOpened}
      />
      <CampaignEditTemplateModal
        toggleCampaignEditTemplateModal={toggleCampaignEditTemplateModal}
        isCampaignEditTemplateModalOpened={isCampaignEditTemplateModalOpened}
      />
      <CampaignDatepickerModal
        toggleCampaignDatepickerModal={toggleCampaignDatepickerModal}
        isCampaignDatepickerModalOpened={isCampaignDatepickerModalOpened}
      />
      <CampaignPreviewEmailModal
        toggleCampaignPreviewEmailModal={toggleCampaignPreviewEmailModal}
        isCampaignPreviewEmailModalOpened={isCampaignPreviewEmailModalOpened}
      />
      <CampaignInactivityModal
        toggleCampaignInactivityModal={toggleCampaignInactivityModal}
        isCampaignInactivityModalOpened={isCampaignInactivityModalOpened}
      />
    </LayoutComponent>
  );
}
