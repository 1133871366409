import { chatActionTypes } from '../actions';

const initialState = {
  conversations: [],
  conversation: null,
  isLoading: false,
  isLoaded: false,
  lastPing: new Date().toISOString(),
};

export const chat = (state = initialState, action) => {
  switch (action.type) {
    case chatActionTypes.CHAT_GET_CONVERSATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case chatActionTypes.CHAT_GET_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        conversations: action.payload,
      };
    case chatActionTypes.CHAT_PING_SINGLE_CONVERSATION_SUCCESS:
    case chatActionTypes.CHAT_GET_SINGLE_CONVERSATION_SUCCESS:
      return {
        ...state,

        conversation: {
          conversationData: action.payload.conversationData,
          messages: action.payload.conversation,
        },
      };
    case chatActionTypes.CHAT_PING_SINGLE_CONVERSATION_FAILURE:
      return { ...state, conversation: null };
    case chatActionTypes.CHAT_SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        conversation: {
          ...state.conversation,
          messages: [...state.conversation.messages, ...action.payload],
        },
      };

    case chatActionTypes.CHAT_DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        conversation: {
          ...state.conversation,
          messages: state.conversation.messages.filter(
            (i) => i.id !== action.payload
          ),
        },
      };

    case chatActionTypes.CHAT_DELETE_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversation: null,
        conversations: state.conversations.filter(
          (i) => i.id !== action.payload
        ),
      };

    default:
      return state;
  }
};
