import React from 'react';

const OfferStats = ({ children, value, title, data, variant, className }) => {
  if (variant == 'column') {
    return (
      <div
        className={`${className} w-fifter bg-white overflow-hidden shadow rounded-lg px-15 py-20`}
      >
        <div className="flex flex-col">
          <p className="text-md leading-none font-semibold text-gray-400">
            {title}
          </p>
          <h4 className="text-2x leading-none my-15 font-bold text-gray-500 truncate">
            {value}
          </h4>
          <p className="text-md leading-none font-semibold text-gray-400">
            {data}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg px-15 py-20">
      <div className="flex items-center">
        <div className="ml-10 flex-1 flex-col">
          <p className="text-md leading-none font-semibold text-gray-400">
            {title}
          </p>
          <h4 className="text-2x leading-none my-15 font-bold text-gray-500 truncate">
            {value}
          </h4>
          <p className="text-md leading-none font-semibold text-gray-400">
            {data}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OfferStats;
