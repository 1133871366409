import React, { useState } from 'react';
import { ReactComponent as SvgComment } from '../../../assets/icons/icon-comment.svg';
import styles from './Note.module.scss';
import Modal from '../../Modal';
import NotePopup from '../NotePopup';
import moment from 'moment';

const Note = ({ contact, className, note }) => {
  const [isNotePopupModalOpened, setIsNotePopupModalOpened] = useState(false);

  const openNotePopupModal = () => {
    setIsNotePopupModalOpened(true);
  };

  const renderNotePopupModal = () => {
    return (
      <Modal
        isOpen={isNotePopupModalOpened}
        //onRequestClose={isNotePopupModalOpened}
        headerTitle=""
        className="modal--notepopup"
      >
        <NotePopup
          note={note}
          setIsNotePopupModalOpened={setIsNotePopupModalOpened}
          contact={contact}
        />
      </Modal>
    );
  };

  const momentDate = moment(note.created_at).format('LLL');

  const getBorderType = (type) => {
    switch (type) {
      case 'General':
        return 'border-1 border-solid border-green-400';
      case 'Follow-up':
        return 'border-1 border-solid border-yellow-400';
      case 'Consultation':
        return 'border-1 border-solid border-blue-400';
      default:
        return 'border-1 border-solid border-red';
    }
  };

  return (
    <div className={className}>
      <div
        className={`${
          styles.Note
        } flex bg-white p-20 my-15 rounded-md flex-col cursor-pointer mx-0 h-full ${getBorderType(
          note?.type
        )}`}
        onClick={openNotePopupModal}
      >
        <div className="flex flex-row items-start">
          <span className="text-xs flex items-center justify-center bg-blue-100 text-primary font-semibold border-1 border-solid border-primary rounded-sm px-10 py-2 mb-10">
            {note.group_name
              ? note.group_name
              : note.categories.length
              ? note.categories[0].name
              : 'Group'}
          </span>
        </div>

        <h3
          className="font-bold text-base leading-relaxed mb-5 text-gray-500"
          style={{ minHeight: '58px' }}
        >
          {note.name ? note.name : 'Note'}
        </h3>
        <p
          className="text-xs mt-auto text-gray-400 my-10 leading-5 max-w-xs overflow-hidden"
          style={{
            display: '-webkit-box',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {note.message}
        </p>
        <div className="flex flex-row justify-between items-center">
          <div className="flex items-center mr-10">
            <SvgComment className="mr-5" />
            <span className="text-gray-400 font-semibold text-xxs">
              {note.comments_count || 0}
            </span>
          </div>
          <div className="flex items-center">
            <span className="text-gray-400 font-semibold text-xxs uppercase">
              {momentDate}
            </span>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center">
          <p className="text-xxs font-semibold text-gray-400 capitalize">
            Activity:{' '}
            <span
              className={`text-xxs ${
                note.status_name === 'private' ? 'text-green' : 'text-red'
              }`}
            >
              Active
            </span>
          </p>
          <p className="text-xxs font-semibold text-gray-400 capitalize">
            Status:{' '}
            <span
              className={`text-xxs ${
                note.status_name === 'private' ? 'text-red' : 'text-primary'
              }`}
            >
              {note.status_name}
            </span>
          </p>
        </div>
        <div className="flex flex-row justify-between"></div>
        <p className="text-xxs font-semibold text-gray-400 capitalize">
          Type:{' '}
          <span
            className={`text-xxs ${
              note.status_name === 'private' ? 'text-red' : 'text-primary'
            }`}
          >
            {note?.type}
          </span>
        </p>
      </div>
      {renderNotePopupModal()}
    </div>
  );
};

export default Note;
