import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Box, Tab, Tabs, useTheme } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { React, useState } from 'react';
import OpenProjectsTab from './OpenProjectsTab';
import OpenEmailsTab from './OpenEmailsTab';
import { DashboardCallEmpty } from './DashboardCallEmpty';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#000000',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontSize: '14px',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#000000',
      opacity: 1,
    },
    '&$selected': {
      color: '#000000',
      fontWeight: theme.typography.fontWeightBold,
    },
    '&:focus': {
      color: '#000000',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ paddingTop: '24px' }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '20px',
  },
}));

export const DashboardTabs = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <Box position="static">
        <AntTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="simple tabs example"
        >
          <AntTab label="Latest Contacts" {...a11yProps(0)} />
          <AntTab label="Latest Campaigns" {...a11yProps(1)} />
        </AntTabs>
      </Box>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {/* <TabPanel value={value} index={0} dir={theme.direction}>
                    <Box style={{ padding: 0 }}>
                        <OpenProjectsTab />
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <Box>1</Box>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <Box>2</Box>
                </TabPanel> */}
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Box>
            <OpenEmailsTab />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Box>
            <DashboardCallEmpty />
          </Box>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};
