import React, { useState } from 'react';
import LoginForm from '../../components/Auth/LoginForm/LoginForm';
import Modal from '../../components/Modal';
import styles from './Login.module.scss';

const Login = () => {
  const [IsChangeLoginModalOpened, setIsChangeLoginModalOpened] = useState(
    true
  );
  const toggleChangeAddressModal = () => {
    setIsChangeLoginModalOpened(!IsChangeLoginModalOpened);
    //setSidebarVisible(false);
  };
  return (
    <section className={`${styles.login}`}>
      <Modal
        isOpen={IsChangeLoginModalOpened}
        onRequestClose={toggleChangeAddressModal}
        headerTitle="CRM Soul Group"
        className="modal--login"
        appElement={document.getElementById('app')}
      >
        <h2 className={`w-full text-2x font-bold text-black mt-50 mb-20`}>
          Welcome back
        </h2>
        <p className={`w-full text-sm text-gray-400 mb-40`}>
          Login in to your account, form your campaigns, create tasks for your
          contacts and introduce your team to efficiency.
        </p>
        <LoginForm />
        <hr className="border-gray-100 my-20 sm:hidden md:block" />
        <p className={`w-full text-xs text-center text-gray-400 mb-0`}>
          © 2021 CRM Soul Group. All rights reserved.
        </p>
      </Modal>
    </section>
  );
};

export default Login;
