import React, { useState } from 'react';
import ButtonBack from '../../components/ButtonBack/ButtonBack';

import LayoutComponent from '../../components/commons/LayoutComponent';
import ContactForm from '../../components/ContactForm';

export default function AddContact() {
  return (
    <LayoutComponent title="Add new contact">
      <ButtonBack className="justify-end -mt-30" />
      <div className="flex flex-col mt-50">
        <ContactForm className="mt-50" />
      </div>
    </LayoutComponent>
  );
}
