import { companiesService } from '../services/companiesService';
import { companiesActionTypes } from '../constants';
import { actionCreator, history } from '../helpers';
import { toast } from 'react-toastify';

const getAllCompanies = () => (dispatch) => {
  dispatch(actionCreator(companiesActionTypes.COMPANIES_GET_ALL_REQUEST));
  companiesService
    .getAllCompanies()
    .then((res) => {
      dispatch(
        actionCreator(companiesActionTypes.COMPANIES_GET_ALL, {
          list: res,
        })
      );
    })
    .catch((err) => {
      console.log(err);
    });
};
export const companiesActions = {
  getAllCompanies,
};
