import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import ButtonBack from '../../ButtonBack';
import FieldInput from '../../Form/components/FieldInput';
import Grid from '../../Tailwind/Grid/Grid';
import Button from '../../Button';
import { userService } from '../../../common/services';

const PasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('This field must be valid email')
    .required('This field is required'),
});

const ForgotPasswordForm = ({ setSentTo }) => {
  const {
    register,
    handleSubmit,
    watch,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(PasswordSchema),
    mode: 'onChange',
  });

  const history = useHistory();

  const onSubmit = async ({ email }) => {
    userService.sendPasswordResetLink(email).then(() => {
      setSentTo(email);
    });
  };

  return (
    <>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-full"
      >
        <FieldInput
          register={register}
          name="email"
          type="email"
          errors={errors}
          labelText="Email Address"
          placeholder="Your e-mail addres"
          value={watch('email')}
        />

        <Grid cols={2}>
          <div>
            <ButtonBack
              variant="transparent"
              className="w-full"
              history={history}
            />
          </div>
          <div>
            <Button
              variant="primary"
              height="44"
              width=""
              isLoading={isSubmitting}
              className="m-auto w-full"
            >
              Continue
            </Button>
          </div>
        </Grid>
      </form>
    </>
  );
};

export default ForgotPasswordForm;
