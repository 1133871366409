import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '../../../../components/Form/components/Checkbox/Checkbox';
import AudienceContactRow from '../AudienceContactRow';
import { contactActions } from '../../../../common/actions';

const AddContactAudienceTable = ({
  search,
  setSearchContacts,
  pickedContacts,
  setPickedContacts,
}) => {
  const [active, setActive] = useState(false);
  const [selectedName, setSelectedName] = useState([]);
  const dispatch = useDispatch();
  const handleChange = (e, index) => {
    setActive(index);
  };

  const updatePickedContacts = (contact) => {
    let id = contact.id;
    if (selectedName.includes(id)) {
      let newArray = selectedName.filter((item) => item !== id);
      setSelectedName(newArray);
    } else {
      let newArray = selectedName.concat(id);
      setSelectedName(newArray);
    }
  };

  useEffect(() => {
    setPickedContacts(selectedName);
  }, [selectedName]);

  useEffect(() => {
    if (search && search.length > 0) {
      dispatch(contactActions.searchContacts(search));
    }
  }, [search]);

  useEffect(() => {
    console.log(pickedContacts);
  }, [pickedContacts]);

  const contacts = useSelector((state) => state.contacts.contactsList);
  const searchResults = useSelector(
    (state) => state.contacts.searchResultContactsList
  );

  const handleSelectAll = () => {
    if (pickedContacts.length === contacts.length) {
      setSelectedName([]);
    } else if (pickedContacts.length < contacts.length) {
      let newArray = [];
      for (let i = 0; i < contacts.length; i++) {
        newArray.push(contacts[i].id);
      }
      setSelectedName(newArray);
    }
  };

  const checkSelectAll = () => {
    if (pickedContacts.length === contacts.length) {
      return true;
    } else {
      return false;
    }
  };

  const renderContacts = () => {
    if (search && search.length > 0) {
      return searchResults;
    } else {
      return contacts.slice(0, 5);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-20 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xxs font-medium text-gray-400  tracking-wider"
                  >
                    <Checkbox
                      name="1"
                      labelText=""
                      onChange={handleSelectAll}
                      checked={checkSelectAll()}
                    />
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xxs font-medium text-gray-400  tracking-wider"
                  >
                    Fullname
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xxs font-medium text-gray-400  tracking-wider"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xxs font-medium text-gray-400  tracking-wider"
                  >
                    Company
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xxs font-medium text-gray-400  tracking-wider"
                  >
                    Created at
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-xxs font-medium text-gray-400 tracking-wider text-right"
                  >
                    Position
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {renderContacts().map((contact, index) => {
                  let isContactSelected = false;
                  if (selectedName.includes(contact.id)) {
                    isContactSelected = true;
                  }
                  return (
                    <AudienceContactRow
                      contact={contact}
                      updatePickedContacts={updatePickedContacts}
                      isPreselected={isContactSelected}
                      pickedContacts={selectedName}
                      key={index}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddContactAudienceTable;
