export const authActionTypes = {
  AUTH_LOGIN_USER_REQUEST: 'AUTH_LOGIN_USER_REQUEST',
  AUTH_LOGIN_USER_SUCCESS: 'AUTH_LOGIN_USER_SUCCESS',
  AUTH_LOGIN_USER_FAILURE: 'AUTH_LOGIN_USER_FAILURE',
  AUTH_CLEAR_STATE: 'AUTH_CLEAR_STATE',
  AUTH_FETCH_USER_INFO_REQUEST: 'AUTH_FETCH_USER_INFO_REQUEST',
  AUTH_FETCH_USER_INFO_SUCCESS: 'AUTH_FETCH_USER_INFO_SUCCESS',
  AUTH_FETCH_USER_INFO_FAILURE: 'AUTH_FETCH_USER_INFO_FAILURE',
  AUTH_FETCH_USERS_INFO_REQUEST: 'AUTH_FETCH_USERS_INFO_REQUEST',
  AUTH_FETCH_USERS_INFO_SUCCESS: 'AUTH_FETCH_USERS_INFO_SUCCESS',
  AUTH_FETCH_USERS_INFO_FAILURE: 'AUTH_FETCH_USERS_INFO_FAILURE',
  AUTH_LOGOUT_USER: 'AUTH_LOGOUT_USER',
  AUTH_DEACTIVATE_USER: 'AUTH_DEACTIVATE_USER',
  AUTH_REACTIVATE_USER: 'AUTH_REACTIVATE_USER',
};

export const authToastMessages = {
  [authActionTypes.AUTH_LOGIN_USER_FAILURE]: {
    message: 'Username or password are incorrect.',
  },
};
