import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LayoutComponent from '../../components/commons/LayoutComponent';
import { ContactsContent } from './components';
import { contactActions } from '../../common/actions';
import { TagsContent } from './components/TagsContent';

const Contacts = () => {
  const dispatch = useDispatch();
  const areContactsLoaded = useSelector(
    (state) => state.contacts.areContactsLoaded
  );

  useEffect(() => {
    return () => {
      dispatch(contactActions.clearContactsState());
    };
  }, []);

  useEffect(() => {
    if (!areContactsLoaded) {
      dispatch(contactActions.getAllContacts());
    }
  }, [areContactsLoaded]);

  return (
    <LayoutComponent title="">
      {areContactsLoaded && (
        <>
          <ContactsContent />
          <TagsContent />
        </>
      )}
    </LayoutComponent>
  );
};

export { Contacts };
