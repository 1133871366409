import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '../../../components/Modal/Modal';
import { ReactComponent as SvgCheckCircle } from './.././../../assets/icons/icon-check-circle.svg';
import { ReactComponent as SvgDocument } from './../../../assets/icons/icon-document.svg';
import FieldInput from '../../../components/Form/components/FieldInput';
import { mailCampaignsActions } from '../../../common/actions';
import { toast } from 'react-toastify';

function CampaignNewTemplateModal({
  toggleCampaignNewTemplateModal,
  isCampaignNewTemplateModalOpened,
}) {
  const dispatch = useDispatch();
  const [templateName, setTemplateName] = useState(null);
  const [templateContent, setTemplateContent] = useState(null);

  /* const submitCampaignDate = () => {
    dispatch(
      mailCampaignsActions.addAudience(
        { name: audienceName },
        toggleCampaignNewTemplateModal
      )
    );
  }; */

  const onChangeFileUpload = (e) => {
    var file = e.target.files[0];
    if (file) {
      var r = new FileReader();
      r.onload = function (e) {
        var contents = e.target.result;

        setTemplateContent(contents);
      };
      r.readAsText(file);
    } else {
      toast.error('Failed to load file', { className: 'text-white' });
    }
  };

  const onChangeTemplateName = (e) => {
    const name = e.target.value;
    setTemplateName(name);
    //toast.success('Templated uploaded', { className: 'text-white' });
  };

  const submitTemplate = () => {
    if (templateName && templateContent && templateContent.length > 10) {
      dispatch(
        mailCampaignsActions.newTemplate({
          name: templateName,
          content: templateContent,
        })
      );
      toggleCampaignNewTemplateModal();
      toast.success('Templated added', { className: 'text-white' });
    } else {
      toast.warning('Please fill the fields!', { className: 'text-white' });
    }
  };

  /* const test = toast.success('Html created succesfully!', {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }); */

  return (
    <Modal
      isOpen={isCampaignNewTemplateModalOpened}
      onRequestClose={toggleCampaignNewTemplateModal}
      headerTitle="Add new template"
      className="modal--newTemplate"
    >
      <div className="flex items-center mb-40 ">
        <h2 className={`text-lg font-bold text-black mr-20`}>
          Upload new template
        </h2>
      </div>
      <form
        //onSubmit={handleSubmit(onSubmit)}
        className={`flex flex-wrap`}
      >
        <FieldInput
          /* register={register} */
          name="template"
          defaultValue=""
          type="text"
          labelText="Template name"
          placeholder="custom-template"
          className="w-full mb-0 "
          onChange={onChangeTemplateName}
        />
        {templateContent && (
          <h3 className="text-primary text-lg my-20 text-center w-full">
            File uploaded successfully
          </h3>
        )}
        <label className="w-full flex flex-col mb-20 flex flex-col w-full rounded-default border-dashed border-1 border-primary p-20 pt-60 items-center justify-center cursor-pointer">
          <SvgDocument className="mr-20 text-primary group-hover:text-primary transition duration-500 ease-in-out" />
          <span className="text-primary text-sm mt-10 text-center w-full">
            Drag files here or click to <b>browse</b>
          </span>
          <input type="file" className="hidden" onChange={onChangeFileUpload} />
        </label>
      </form>

      <button
        className="ml-auto mt-20 flex text-center justify-center items-center rounded-default pl-0 px-30 py-10 text-sm text-white bg-primary hover:bg-secondary outline-none"
        role="submit"
        onClick={submitTemplate}
      >
        <SvgCheckCircle className="ml-20 mr-20" />
        Confirm
      </button>
    </Modal>
  );
}

export { CampaignNewTemplateModal };
