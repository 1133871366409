import React, { useState } from 'react';

import { ReactComponent as SvgDelete } from '../../../../assets/icons/icon-delete-campaign.svg';
import { ReactComponent as SvgEdit } from '../../../../assets/icons/icon-edit.svg';
import Modal from '../../../Modal';
import { notesService } from '../../../../common/services/notesService';
import Loader from '../../../Loader';
import FieldTextarea from '../../../Form/components/FieldTextarea';

import { FaSave } from 'react-icons/fa';
import CommentStatusButton from '../CommentStatusButton/CommentStatusButton';
import { toast } from 'react-toastify';
import { CirclePicker } from 'react-color';

const CommentDisplayActions = ({ comment, reload }) => {
  const [message, setMessage] = useState(comment.message);
  const [commentColor, setCommentColor] = useState(
    comment.background_color ? comment.background_color : '#ffffff'
  );

  const [isStatusActive, setStatusActive] = useState(false);

  const [isUpdating, setUpdating] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const [isDeleting, setDeleting] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

  const colors = ['#2ecc71', '#3498db', '#ff6b6b'];

  const handleChangeColor = (color) => {
    setCommentColor(color.hex);
  };

  const handleAllActionsDone = () => {
    setUpdating(false);
    setUpdateModal(false);

    setDeleting(false);
    setDeleteConfirmationModal(false);
  };

  const handleEditComment = () => {
    setUpdateModal(true);
  };

  const handleConfirmUpdate = (status) => {
    setUpdating(true);

    notesService
      .updateComment(comment.id, {
        message,
        background_color: commentColor,
        status,
      })
      .then(() => {
        reload().then(() => {
          handleAllActionsDone();
        });
      })
      .catch(() => {
        handleAllActionsDone();
        toast.error("Something wen't wrong");
      });
  };

  const handleCancelUpdate = () => {
    setUpdateModal(false);
  };

  const handleDeleteComment = () => {
    setDeleteConfirmationModal(true);
  };

  const handleConfirmDelete = () => {
    setDeleting(true);

    notesService
      .deleteComment(comment.id)
      .then(() => {
        reload().then(() => {
          handleAllActionsDone();
        });
      })
      .catch(() => {
        handleAllActionsDone();
        toast.error("Something wen't wrong");
      });
  };

  const handleCancelDelete = () => {
    setDeleteConfirmationModal(false);
  };

  return (
    <>
      <button
        className="appearance-none flex ml-10"
        onClick={handleEditComment}
      >
        <SvgEdit fill="text-gray-400" className="flex"></SvgEdit>
      </button>
      <button
        className="appearance-none flex ml-10"
        onClick={handleDeleteComment}
      >
        <SvgDelete fill="text-gray-400" className="flex"></SvgDelete>
      </button>
      <Modal
        isOpen={deleteConfirmationModal}
        onRequestClose={handleCancelDelete}
        headerTitle="Delete Comment"
        className="modal--alert"
      >
        <h2 className={`w-full text-2x font-bold text-black mt-50 mb-20`}>
          Delete Comment?
        </h2>
        <div className="flex justify-between">
          <a
            className="flex w-half text-center justify-center items-center rounded-default h-50 py-10 text-sm text-white bg-primary hover:bg-secondary"
            role="menuitem"
            onClick={!isDeleting ? handleConfirmDelete : undefined}
          >
            {isDeleting ? <Loader /> : 'Yes'}
          </a>
          <a
            className="flex w-half text-center justify-center items-center rounded-default h-50 py-10 text-sm text-white bg-gray-500 hover:bg-gray-400"
            role="menuitem"
            onClick={!isDeleting ? handleCancelDelete : undefined}
          >
            Cancel
          </a>
        </div>
      </Modal>
      <Modal
        isOpen={updateModal}
        onRequestClose={handleCancelUpdate}
        headerTitle="Update Comment"
        className="modal--alert"
      >
        <h2 className={`w-full text-2x font-bold text-black mt-50 mb-20`}>
          Update Comment
        </h2>

        <FieldTextarea
          name="message"
          type="text"
          labelText=""
          placeholder="Type a comment here..."
          className={`w-full`}
          disabled={isUpdating}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        <div className={`mb-20`}>
          <CirclePicker
            onChangeComplete={handleChangeColor}
            colors={colors}
            color={commentColor}
          />
        </div>

        <div className="flex justify-between">
          <div className="w-half">
            <CommentStatusButton
              icon={<FaSave />}
              onClick={handleConfirmUpdate}
              loading={isUpdating}
              disabled={isUpdating || !String(message).trim()}
              active={isStatusActive}
              setActive={setStatusActive}
              className="w-full outline-none flex text-center justify-center items-center rounded-default h-50 py-10 text-sm text-white bg-primary hover:bg-secondary"
            />
          </div>

          <a
            className="flex w-half text-center justify-center items-center rounded-default h-50 py-10 text-sm text-white bg-gray-500 hover:bg-gray-400"
            role="menuitem"
            onClick={!isUpdating ? handleCancelUpdate : undefined}
          >
            Cancel
          </a>
        </div>
      </Modal>
    </>
  );
};

export default CommentDisplayActions;
