export const contactActionTypes = {
  CONTACT_GET_CONTACT_REQUEST: 'CONTACT_GET_CONTACT_REQUEST',
  CONTACT_GET_CONTACT_SUCCESS: 'CONTACT_GET_CONTACT_SUCCESS',
  CONTACT_GET_CONTACT_FAILURE: 'CONTACT_GET_CONTACT_FAILURE',
  CONTACT_EDIT_CONTACT_REQUEST: 'CONTACT_EDIT_CONTACT_REQUEST',
  CONTACT_EDIT_CONTACT_SUCCESS: 'CONTACT_EDIT_CONTACT_SUCCESS',
  CONTACT_EDIT_CONTACT_FAILURE: 'CONTACT_EDIT_CONTACT_FAILURE',
  CONTACT_UPDATE_CONTACT_IMAGE_REQUEST: 'CONTACT_UPDATE_CONTACT_IMAGE_REQUEST',
  CONTACT_UPDATE_CONTACT_IMAGE_SUCCESS: 'CONTACT_UPDATE_CONTACT_IMAGE_SUCCESS',
  CONTACT_UPDATE_CONTACT_IMAGE_FAILURE: 'CONTACT_UPDATE_CONTACT_IMAGE_FAILURE',
  CONTACTS_ADD_NOTE_REQUEST: 'CONTACTS_ADD_NOTE_REQUEST',
  CONTACTS_ADD_NOTE_SUCCESS: 'CONTACTS_ADD_NOTE_SUCCESS',
  CONTACTS_UPDATE_NOTE_SUCCESS: 'CONTACTS_UPDATE_NOTE_SUCCESS',
  CONTACTS_REMOVE_NOTE_SUCCESS: 'CONTACTS_REMOVE_NOTE_SUCCESS',
  CONTACT_CLEAR_STATE: 'CONTACT_CLEAR_STATE',
};
