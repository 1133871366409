import querystring from 'query-string';

export const getQueryStringParam = (name) => {
  const qs = window.location.search;
  const model = querystring.parse(qs, { arrayFormat: 'comma' });
  const ret = Object.prototype.hasOwnProperty.call(model, name)
    ? model[name]
    : null;
  return ret;
};
