import React, { useState } from 'react';
import Button from '../../../components/Button';
import { ReactComponent as IconDelete } from '../../../assets/icons/icon-delete-campaign.svg';
import { ReactComponent as IconCheck } from '../../../assets/icons/icon-check-circle.svg';
import { useDispatch, useSelector } from 'react-redux';
import NotificationGroup from './NotificationGroup';
import { notificationActions } from '../../../common/actions/notificationActions';
import { groupByDate } from 'src/common/helpers';

const NotificationContent = () => {
  const { notifications, page, isLoading, hasMore } = useSelector(
    (state) => state.notification
  );
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [readingAll, setReadingAll] = useState(false);
  const [removing, setRemoving] = useState(false);

  // Group notifications by created_at date
  const groupped = React.useMemo(() => {
    return groupByDate(notifications);
  }, [notifications]);

  const handleLoadMore = () => {
    dispatch(notificationActions.getNotifications(page));
  };

  const handleDelete = () => {
    setRemoving(true);
    dispatch(
      notificationActions.deleteNotifications(
        selected,
        () => {
          setSelected([]);
          setRemoving(false);
        },
        () => {
          setRemoving(false);
        }
      )
    );
  };

  const handleReadAll = () => {
    setReadingAll(true);
    dispatch(
      notificationActions.readAllNotification(() => setReadingAll(false))
    );
  };

  return (
    <>
      <div className="bg-gray-100">
        <div className="container mx-auto p-5">
          <div className="bg-white p-20 shadow-md rounded-md my-20">
            <div className="flex px-15 justify-between">
              {/* <Checkbox
                labelComponent={
                  <label className="pl-5 text-black font-400">Select All</label>
                }
              /> */}
              <div className="md:flex sm:flex-row justify-between ml-auto">
                <div
                  className={`flex group${
                    !selected.length ? ' pointer-events-none opacity-50' : ''
                  }`}
                >
                  <Button
                    variant="transparent"
                    className={`mr-15 text-red border-red group-hover:text-white group-hover:bg-red w-200`}
                    onClick={handleDelete}
                    isLoading={removing}
                  >
                    <IconDelete className="flex mr-5 w-sm h-sm min-w-sm text-red group-hover:text-white" />
                    Delete Selected
                  </Button>
                </div>
                <div className="flex group">
                  <Button
                    variant="transparent"
                    className="group-hover:bg-primary group-hover:text-white w-200"
                    isLoading={readingAll}
                    onClick={handleReadAll}
                  >
                    <IconCheck className="flex mr-5 w-sm h-sm min-w-sm text-primary group-hover:text-white" />
                    Mark All As Read
                  </Button>
                </div>
              </div>
            </div>
            {Object.keys(groupped).map((key) => {
              const group = groupped[key];

              return (
                <NotificationGroup
                  group={group}
                  key={key}
                  date={key}
                  selected={selected}
                  setSelected={setSelected}
                />
              );
            })}

            {hasMore && (
              <div className="mx-auto text-center text-primary m-10 font-semibold">
                <a onClick={handleLoadMore} className="">
                  {isLoading ? '...' : 'Load More'}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationContent;
