import React, { useState, useRef, useEffect } from 'react';
import EmailEditor from 'react-email-editor';
import CampaignPickTemplateModal from './CampaignPickTemplateModal';
import CampaignEditTemplateModal from './CampaignEditTemplateModal';
import { ReactComponent as SvgAddTemplate } from './../../../../assets/icons/icon-add-template.svg';
import { ReactComponent as SvgFloppy } from './../../../../assets/icons/icon-flopi.svg';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as SvgCheckCircle } from './../../../../assets/icons/icon-check-circle.svg';
import { mailCampaignsActions } from 'src/common/actions';
import { toast } from 'react-toastify';

const DefaultEditorDesign = {
  counters: { u_column: 4, u_row: 2, u_content_button: 1, u_content_image: 1 },
  body: {
    rows: [
      {
        cells: [1],
        columns: [
          {
            contents: [],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              _meta: { htmlID: 'u_column_1', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: '0px',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_1', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
    ],
    values: {
      textColor: '#000000',
      backgroundColor: '#e7e7e7',
      backgroundImage: {
        url: '',
        fullWidth: true,
        repeat: false,
        center: true,
        cover: false,
      },
      contentWidth: '500px',
      contentAlign: 'center',
      fontFamily: { label: 'Arial', value: 'arial,helvetica,sans-serif' },
      preheaderText: '',
      linkStyle: {
        body: true,
        linkColor: '#0000ee',
        linkHoverColor: '#0000ee',
        linkUnderline: true,
        linkHoverUnderline: true,
      },
      _meta: { htmlID: 'u_body', htmlClassNames: 'u_body' },
    },
  },
  schemaVersion: 6,
};

const CampaignAutomationBuilder = ({ onMailBuilderClose, setValues }) => {
  const dispatch = useDispatch();

  const [
    isCampaignPickTemplateModalOpened,
    setIsCampaignPickTemplateModalOpened,
  ] = useState(false);
  const toggleCampaignPickTemplateModal = () => {
    setIsCampaignPickTemplateModalOpened(!isCampaignPickTemplateModalOpened);
  };
  const [
    isCampaignEditTemplateModalOpened,
    setIsCampaignEditTemplateModalOpened,
  ] = useState(false);
  const toggleCampaignEditTemplateModal = () => {
    setIsCampaignEditTemplateModalOpened(!isCampaignEditTemplateModalOpened);
  };
  const [editorLoaded, setEditorLoaded] = useState(false);
  const editorRef = useRef(null);

  const selectedTemplate = useSelector(
    (state) => state.mailCampaigns.mailCampaignAutomatizationState.template
  );

  const saveDesign = () => {
    editorRef.current.editor.saveDesign((data) => {
      dispatch(
        mailCampaignsActions.setAutomatizationMessage(JSON.stringify(data))
      );
      toggleCampaignEditTemplateModal();
    });
  };

  const onEditorLoad = () => {
    setEditorLoaded(true);
    applyEditorTemplate();
  };

  useEffect(() => {
    applyEditorTemplate();
  }, [selectedTemplate]);

  const applyEditorTemplate = () => {
    if (editorLoaded) {
      try {
        let data = JSON.parse(selectedTemplate.content);
        editorRef.current.editor.loadDesign(data);
      } catch (e) {
        if (selectedTemplate?.content) {
          if (
            !(
              selectedTemplate.content == '' || selectedTemplate.content == null
            )
          ) {
            toast.warning('Unable to parse template content', {
              className: 'text-white',
            });
            console.log(e);
          }
        }

        editorRef.current.editor.loadDesign(DefaultEditorDesign);
      }
    }
  };

  const handleClose = () => {
    editorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      setValues((prevState) => ({
        ...prevState,
        html: html,
      }));

      onMailBuilderClose();
    });
  };

  return (
    <>
      <div className="flex justify-end mt-20 mb-20">
        <a
          className="group bg-transparent h-44 text-primary border-1 border-solid border-primary p-15 px-30 text-sm rounded-default outline-none inline-flex justify-center items-center transition duration-500 ease-in-out mr-20"
          onClick={saveDesign}
          style={{ maxWidth: '250px' }}
        >
          <SvgFloppy className="mr-20 group-hover:text-navyBlue" />
          Save new template
        </a>

        <a
          className="bg-transparent h-44 text-primary border-1 border-solid border-primary hover:text-navyBlue p-15 px-20 text-sm rounded-default outline-none flex justify-center items-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 mr-20"
          onClick={toggleCampaignPickTemplateModal}
        >
          <SvgAddTemplate className="mr-20 text-primary" />
          Pick or Add Template
        </a>
      </div>

      <div className="flex justify-start mt-0 w-full">
        <EmailEditor ref={editorRef} onLoad={onEditorLoad} />
      </div>

      <div className="flex flex-col sm:mb-50 md:mb-0 w-full">
        <div className="flex flex-between">
          <button
            type="submit"
            className="ml-auto mt-20 flex text-center justify-center items-center rounded-default px-30 py-10 text-sm text-white bg-primary hover:bg-secondary"
            role="menuitem"
            onClick={handleClose}
          >
            Finish
            <SvgCheckCircle className="ml-20" />
          </button>
        </div>
      </div>

      <CampaignPickTemplateModal
        toggleCampaignPickTemplateModal={toggleCampaignPickTemplateModal}
        isCampaignPickTemplateModalOpened={isCampaignPickTemplateModalOpened}
      />

      <CampaignEditTemplateModal
        toggleCampaignEditTemplateModal={toggleCampaignEditTemplateModal}
        isCampaignEditTemplateModalOpened={isCampaignEditTemplateModalOpened}
      />
    </>
  );
};

export default CampaignAutomationBuilder;
