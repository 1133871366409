import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { routes } from '../../common/constants';
import MailInput from '../Form/components/MailInput/MailInput';
import { ReactComponent as SvgNext } from 'src/assets/icons/icon-next.svg';
import CampaignEditor from './CampaignEditor';
import { mailCampaignsActions } from '../../common/actions';
import { ReactComponent as SvgCalendar } from 'src/assets/icons/icon-calendar.svg';
import { ReactComponent as SvgFloppy } from 'src/assets/icons/icon-flopi.svg';
import Checkbox from '../Form/components/Checkbox/Checkbox';
import { prepareMailCampaingPostModel } from '../../common/helpers';
import { toast } from 'react-toastify';
import EmailEditor from 'react-email-editor';

/* const CampaignScheme = yup.object().shape({
  name: yup
    .string()
    .required('This field is required')
    .min(2, 'Username must be longer then two characters'),
  /* audience: yup.string().required('This field is required'),
  subject: yup.string().required('This field is required'),
}); */

const DefaultEditorDesign = {
  counters: { u_column: 4, u_row: 2, u_content_button: 1, u_content_image: 1 },
  body: {
    rows: [
      {
        cells: [1],
        columns: [
          {
            contents: [],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              _meta: { htmlID: 'u_column_1', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: '0px',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_1', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
    ],
    values: {
      textColor: '#000000',
      backgroundColor: '#e7e7e7',
      backgroundImage: {
        url: '',
        fullWidth: true,
        repeat: false,
        center: true,
        cover: false,
      },
      contentWidth: '500px',
      contentAlign: 'center',
      fontFamily: { label: 'Arial', value: 'arial,helvetica,sans-serif' },
      preheaderText: '',
      linkStyle: {
        body: true,
        linkColor: '#0000ee',
        linkHoverColor: '#0000ee',
        linkUnderline: true,
        linkHoverUnderline: true,
      },
      _meta: { htmlID: 'u_body', htmlClassNames: 'u_body' },
    },
  },
  schemaVersion: 6,
};

/* const roleOptions = [
  { value: 'Administrator', label: 'Administrator' },
  { value: 'User', label: 'User' },
  { value: 'Viewer', label: 'Viewer' },
]; */
const CampaignForm = ({
  className,
  toggleCampaignDatepickerModal,
  toggleCampaignAutomateModal,
  toggleCampaignEditTemplateModal,
  togglePickAudience,
  toggleCampaignInactivityModal,
}) => {
  const dispatch = useDispatch();
  const audiences = useSelector(
    (state) => state.mailCampaigns.mailCampaignState.audiences
  );
  const selectedTemplate = useSelector(
    (state) => state.mailCampaigns.mailCampaignState.template
  );
  const { register, handleSubmit, watch, errors, control, setValue } = useForm({
    mode: 'onChange',
  });

  const mailCampaign = useSelector(
    (state) => state.mailCampaigns.mailCampaignState
  );

  const [isSMSSelected, setIsSMSSelected] = useState(false);
  const [isViaEmailSelected, setIsViaEmailSelected] = useState(true);
  const [isCodeEditorSelected, setIsCodeEditorSelected] = useState(false);
  const [isInactivityAlertSelected, setIsInactivityAlertSelected] = useState(
    false
  );

  const [editorLoaded, setEditorLoaded] = useState(false);

  const editorRef = useRef(null);

  const saveDesign = () => {
    editorRef.current.editor.saveDesign((data) => {
      dispatch(mailCampaignsActions.setMessage(JSON.stringify(data)));
      toggleCampaignEditTemplateModal();
    });
  };

  const onEditorLoad = () => {
    setEditorLoaded(true);
  };

  useEffect(() => {
    if (Array.isArray(audiences)) {
      setValue('audience', ' '.repeat(audiences.length));
    }
  }, [audiences]);

  useEffect(() => {
    if (editorLoaded) {
      try {
        let data = JSON.parse(selectedTemplate.content);
        editorRef.current.editor.loadDesign(data);
      } catch (e) {
        if (selectedTemplate?.content) {
          if (
            !(
              selectedTemplate.content == '' || selectedTemplate.content == null
            )
          ) {
            toast.warning('Unable to parse template content', {
              className: 'text-white',
            });
            console.log(e);
          }
        }

        editorRef.current.editor.loadDesign(DefaultEditorDesign);
      }
    }
  }, [selectedTemplate]);

  const handleChangeSMS = () => {
    setIsSMSSelected(!isSMSSelected);
  };
  const handleChangeViaEmail = () => {
    setIsViaEmailSelected(!isViaEmailSelected);
  };
  const handleChangeCodeEditor = () => {
    setIsCodeEditorSelected(!isCodeEditorSelected);
  };
  const handleInactivity = () => {
    setIsInactivityAlertSelected(!isInactivityAlertSelected);
    if (isInactivityAlertSelected == false) {
      toggleCampaignInactivityModal();
    }
  };

  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const onSubmit = () => {
    submitMailCampaign();
    //history.push(routes.HOME.path);
  };
  const removeSelectedTemplate = () => {
    dispatch(mailCampaignsActions.setPickedTemplate(null));
  };

  const setCampaignName = (e) => {
    dispatch(mailCampaignsActions.setName(e.target.value));
  };

  const setCampaignFrom = (e) => {
    dispatch(mailCampaignsActions.setFrom(user.email));
  };

  const setCampaignSubject = (e) => {
    dispatch(mailCampaignsActions.setSubject(e.target.value));
  };

  const submitMailCampaign = () => {
    editorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      //dispatch(mailCampaignsActions.setMessage(html));
      //dispatch(mailCampaignsActions.setDesign(design));
      mailCampaign.message = html;
      mailCampaign.design = JSON.stringify(design);

      if (
        mailCampaign &&
        mailCampaign.audiences &&
        mailCampaign.audiences.length > 0 &&
        mailCampaign.message &&
        mailCampaign.message.length > 10 &&
        mailCampaign.design &&
        mailCampaign.design.length > 10 &&
        mailCampaign.name
      )
        dispatch(
          mailCampaignsActions.createNewMailCampaign(
            prepareMailCampaingPostModel(mailCampaign),
            history
          )
        );
      else
        toast.error('Please enter all campaign informations!', {
          className: 'text-darkRed',
        });
      if (mailCampaign.audiences.length < 1) {
        toast.warning('Please select an audience group!', {
          className: 'text-white',
        });
      }
      if (mailCampaign.name < 1) {
        toast.warning('Please enter a name!', {
          className: 'text-white',
        });
      }
      if (mailCampaign.subject < 1) {
        toast.warning('Please enter a subject!', {
          className: 'text-white',
        });
      }
      console.log('Campaign data', data);
    });
  };

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      className={`flex flex-wrap pb-50`}
    >
      <MailInput
        labelText="Name:"
        register={register}
        name="name"
        defaultValue=""
        type="text"
        errors={errors}
        value={watch('name')}
        className="sm:w-full"
        onBlur={(e) => {
          setCampaignName(e);
          setCampaignFrom(); //injecting from parallel
        }}
      />
      <MailInput
        labelText={`Audiences: ${(audiences || []).map(
          (audience) => ` ${audience.name}`
        )}`}
        register={register}
        name="audience"
        type="text"
        errors={errors}
        onClick={togglePickAudience}
        value={`${
          audiences ? audiences.map((audience) => ` ${audience.name}`) : ''
        }`}
        cursor={'pointer'}
        className={`sm:w-full cursor-pointer`}
        ref={register({ required: true })}
      />

      <MailInput
        labelText="Subject:"
        register={register}
        name="subject"
        defaultValue=""
        type="text"
        errors={errors}
        value={watch('subject')}
        className="sm:w-full"
        onBlur={setCampaignSubject}
      />
      {selectedTemplate && (
        <a className="group bg-primary h-44 text-white border-1 solid border-primary hover:text-primary hover:bg-white pl-10 text-sm rounded-default outline-none flex justify-center items-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 ml-auto mb-20 overflow-hidden">
          Chosen template:{' '}
          <span className="font-bold ml-5"> {selectedTemplate.name}</span>
          <span
            className="bg-secondary border-left border-solid border-gray-500 text-white h-full flex items-center justify-center ml-10 px-20"
            onClick={removeSelectedTemplate}
          >
            {' '}
            X
          </span>
        </a>
      )}

      {/*<CampaignEditor
        template={
          selectedTemplate && selectedTemplate.content
            ? selectedTemplate.content
            : ''
        }
        visualEditor={!isCodeEditorSelected}
      />*/}

      <div className="flex justify-start mt-0 w-full">
        <EmailEditor ref={editorRef} onLoad={onEditorLoad} />
      </div>

      {/*<div className="flex justify-start mt-0 w-full">
        <div className="ml-15">
          <Checkbox
            name="code_editor"
            labelText="Code editor"
            onChange={handleChangeCodeEditor}
            checked={isCodeEditorSelected}
          />
        </div>
    </div>*/}
      <div className="flex justify-end w-full ">
        <div className="mr-15 relative pr-50">
          <Checkbox
            name="sms"
            labelText="Send as sms"
            onChange={handleChangeSMS}
            checked={isSMSSelected}
          />
          {isSMSSelected && (
            <div className="mr-15 absolute top-40 left-0">
              <Checkbox
                name="inactivity"
                labelText="Inactivity alert"
                onChange={handleInactivity}
                checked={isInactivityAlertSelected}
              />
            </div>
          )}
        </div>

        <div className="mr-15">
          <Checkbox
            name="email"
            labelText="Send via email"
            onChange={handleChangeViaEmail}
            checked={isViaEmailSelected}
          />
        </div>
      </div>
      <div className="flex justify-end mt-50 w-full">
        <a
          className="mr-auto group bg-transparent h-44 text-primary border-1 border-solid border-primary p-15 px-30 text-sm rounded-default outline-none inline-flex justify-center items-center transition duration-500 ease-in-out mr-20"
          onClick={saveDesign}
          style={{ maxWidth: '250px' }}
        >
          <SvgFloppy className="mr-20 group-hover:text-navyBlue" />
          Save new template
        </a>

        <a
          className="bg-secondary h-44 text-white border-1 border-solid border-secondary hover:text-white hover:bg-primary p-15 px-30 text-sm rounded-default outline-none inline-flex justify-center items-center transition mr-20"
          onClick={toggleCampaignDatepickerModal}
          style={{ maxWidth: '250px' }}
        >
          <SvgCalendar className="mr-20" />
          Schedule campaign
        </a>
        <Button
          variant="primary"
          width="220"
          height="44"
          type="submit"
          onClick={handleSubmit}
        >
          <SvgNext className="mr-20" />
          Send immediatley
        </Button>
      </div>
    </form>
  );
};
export default CampaignForm;
