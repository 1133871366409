import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SidebarItem from '../SidebarItem';

import { ReactComponent as SvgDashboard } from 'src/assets/icons/icon-dashboard.svg';
import { ReactComponent as SvgChat } from 'src/assets/icons/icon-chat.svg';
import { ReactComponent as SvgGraph } from 'src/assets/icons/icon-graph.svg';
import { ReactComponent as SvgFile } from 'src/assets/icons/icon-file.svg';
import { ReactComponent as SvgStar } from 'src/assets/icons/icon-star.svg';
import { ReactComponent as SvgNotes } from 'src/assets/icons/notes.svg';
import { ReactComponent as SvgOffer } from 'src/assets/icons/icon-file.svg';
import { ReactComponent as SvgSettings } from 'src/assets/icons/settings-icon.svg';
import { FaBriefcase, FaCalendarAlt, FaUsers } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const allRoutes = {
  'super admin': [
    { name: 'Dashboard', path: '/dashboard', icon: SvgDashboard },
    { name: 'Companies', path: '/admin/companies', icon: FaBriefcase },
    { name: 'Users', path: '/admin/users', icon: FaUsers },
  ],
  admin: [
    { name: 'Dashboard', path: '/dashboard', icon: SvgDashboard },
    { name: 'Users', path: '/admin/users', icon: FaUsers },
  ],
  user: [
    { name: 'Dashboard', path: '/dashboard', icon: SvgDashboard },
    { name: 'Chat', path: '/chat', icon: SvgChat },
    { name: 'Calendar', path: '/calendar', icon: FaCalendarAlt },
    { name: 'Outreach', path: '/outreach', icon: SvgFile },
    { name: 'Stats', path: '/stats', icon: SvgGraph },
    { name: 'Campaigns', path: '/mail-campaigns', icon: SvgFile },
    { name: 'Contacts', path: '/contacts', icon: SvgStar },
    { name: 'Notes', path: '/notes', icon: SvgNotes },
    { name: 'Offers', path: '/offers', icon: SvgOffer },
  ],
};

export default function Sidebar() {
  const history = useHistory();

  const { user, isUserLoggedIn } = useSelector((state) => state.auth);

  const routes = allRoutes[user.role] || allRoutes.user;

  if (!isUserLoggedIn) return <></>;

  return (
    <nav className="bg-darkBlue h-full w-full">
      <NavLink to="/dashboard" type="button">
        <h3 className="text-white head-title font-bold px-20 pt-50">
          CRM Soul Group
        </h3>
      </NavLink>
      <div className="py-40 ">
        <div className="navBarFlex">
          <div>
            {routes.map((route) => (
              <SidebarItem
                key={route.name}
                icon={route.icon}
                title={route.name}
                isActive={history.location.pathname === route.path}
                handleNav={route.path}
              />
            ))}
          </div>
          <div
            style={{
              position: 'fixed',
              bottom: 0,
              maxWidth: '255px',
              width: '100%',
            }}
          >
            <SidebarItem
              key={'SettingsPage'}
              icon={SvgSettings}
              title={'Settings'}
              isActive={history.location.pathname === '/settings'}
              handleNav={'/settings'}
            />
          </div>
        </div>
      </div>
    </nav>
  );
}
