import React, { useState, useEffect } from 'react';
import { ReactComponent as SvgAdd } from './../../../../assets/icons/icon-add.svg';
import { ReactComponent as SvgCheckCircle } from './../../../../assets/icons/icon-check-circle.svg';
import FieldInput from '../../../../components/Form/components/FieldInput';
import { useSelector, useDispatch } from 'react-redux';
import Accordion from 'src/components/commons/Accoordion';
import { AutomationBytagsForm } from './CampaignAutomationForms/AutomationByTagsForm';
import { contactActions, mailCampaignsActions } from 'src/common/actions';
import axios from 'axios';
import { API_ENDPOINT } from 'src/utils/endpoints';
import moment from 'moment';

const getValidationErrors = (validationValues) => {
  const getValidatonMessage = (filedName) => {
    switch (filedName) {
      case 'subject':
        return 'Subject field is required';
      default:
        return 'Field is required';
    }
  };

  let ret = {};
  const validationValuesKeys = Object.keys(validationValues);
  console.log('validationValues', validationValues, validationValuesKeys);
  for (let i = 0; i < validationValuesKeys.length; i++) {
    const currentKey = validationValuesKeys[i];
    const currentValue = validationValues[currentKey];
    if (
      !currentValue ||
      (Array.isArray(currentValue) && currentValue.length < 1) ||
      currentValue === ''
    ) {
      ret[currentKey] = {
        message: getValidatonMessage(currentKey),
      };
    }
  }
  return ret;
};

function AutomationByTags({
  step,
  contacts,
  className,
  user,
  campaign,
  type,
  setIsSecondStepOpened,
  toggleSuccessToast,
}) {
  const dispatch = useDispatch();
  const mailCampaign = useSelector(
    (state) => state.mailCampaigns.mailCampaignState
  );
  console.log('mail_campaign', campaign);

  useEffect(() => {
    dispatch(contactActions.getAllTags());
  }, []);
  const tags = useSelector((state) => state.contacts.tagsList?.data);
  console.log('ovo dispatcham tagove', tags);
  const [isValidating, setIsValidating] = useState(false);
  const [automationLevelsFormErrors, setAutomationLevelsFormErrors] = [];
  const [automationName, setAutomationName] = useState('');
  const [automationLevels, setAutomationLevels] = useState([
    {
      id: 1,
    },
  ]);

  const addNewAutomationLevel = () => {
    setAutomationLevels([
      ...automationLevels,
      {
        id: automationLevels.length + 1,
      },
    ]);
  };

  function deleteAutomationLevel(levelId) {
    const newAutomationLevels = automationLevels.filter(
      (al) => al.id !== levelId
    );
    setAutomationLevels(newAutomationLevels);
  }

  useEffect(() => {
    console.log('automationLevels', automationLevels);
  }, [automationLevels]);

  const isFormValid = () => {
    if (!automationName || automationName === '') {
      return false;
    }
    for (let i = 0; i < automationLevels; i++) {
      const currentAutomation = automationLevels[i];
      if (!currentAutomation.errors) {
        continue;
      }
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!isValidating) return;
    const automationLevelsUpdate = automationLevels.map((al) => {
      return {
        ...al,
        errors: isValidating ? getValidationErrors(al) : null,
      };
    });
    setAutomationLevels(automationLevelsUpdate);
  }, [isValidating]);

  const preparePostDataModel = () => {
    return {
      name: automationName,
      campaign_id: campaign.id,
      type: type,
      //automationTypeTODO
      automationLevels: automationLevels.map((al) => ({
        title: al.title,
        subject: al.subject,
        from: al.from,
        to: al.to,
        daysDelayed: al.daysDelayed,
        tags: al.tags,
        frequency: al.frequency,
        startDate: al.startDate,
        html: al.html,
      })),
    };
  };

  const submitAutomationData = () => {
    if (!isFormValid()) {
      setIsValidating(true);
      return;
    }
    const postModel = preparePostDataModel();
    console.log('should dispatch post data: ', postModel);
    if (postModel) {
      var formData = new FormData();
      var i;
      var a;
      formData.append('name', postModel.name);
      formData.append('type', postModel.type);
      formData.append('campaign_id', postModel.campaign_id);
      for (i = 0; i < postModel.automationLevels.length; i++) {
        if (postModel.automationLevels[i]) {
          formData.append(
            `automation_level[${i}][title]`,
            postModel.automationLevels[i].title
          );
          formData.append(
            `automation_level[${i}][subject]`,
            postModel.automationLevels[i].subject
          );
          formData.append(
            `automation_level[${i}][frequency]`,
            postModel &&
              postModel.automationLevels[i] &&
              postModel.automationLevels[i].frequency
              ? postModel.automationLevels[i].frequency
              : 'Non set'
          );
          formData.append(
            `automation_level[${i}][start_date]`,
            moment(postModel.automationLevels[i].startDate).format('DD-MM-YYYY')
          );
          formData.append(
            `automation_level[${i}][end_date]`,
            moment(postModel.automationLevels[i].startDate).format('DD-MM-YYYY')
          );
          formData.append(
            `automation_level[${i}][html]`,
            postModel &&
              postModel.automationLevels[i] &&
              postModel.automationLevels[i].html
              ? postModel.automationLevels[i].html
              : 'Template'
          );
          for (a = 0; a < postModel.automationLevels[i].tags.length; a++) {
            if (postModel.automationLevels[i].tags[a]) {
              formData.append(
                `automation_level[${i}][tags][${a}]`,
                postModel.automationLevels[i].tags[a].value
              );
            }
          }
        }
      }
      axios
        .post(`${API_ENDPOINT}/automation`, formData, {
          headers: {
            Authorization: 'Bearer' + localStorage.getItem('crm-client-token'),
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          setIsSecondStepOpened(false);
          toggleSuccessToast();
          console.log(response);
        })
        .catch((e) => {
          let errors = e.response.data.message + '\n';
          for (let error in e.response.data.errors) {
            errors += e.response.data.errors[error][0] + '\n';
          }
          // setAlertMessage(errors);
          // handleAlertOpen();
        });
    }
    // dispatch(
    //   mailCampaignsActions.createNewMailCampaignAutomation(data, history)
    // );
  };

  return (
    <div className="flex flex-col mt-20">
      <FieldInput
        name="automationName"
        defaultValue=""
        type="text"
        labelText="Name"
        placeholder="Automation name"
        className=""
        value={automationName}
        errors={
          isValidating
            ? { automationName: { message: 'Automation name is required' } }
            : null
        }
        onChange={(e) => {
          setAutomationName(e.target.value);
        }}
      />
      {automationLevels.map((level, index) => (
        <Accordion
          className="mt-10"
          key={index}
          title={`Automation level ${level.id}`}
          content={
            <AutomationBytagsForm
              currentLevel={level.id}
              automationLevels={automationLevels}
              setAutomationLevels={setAutomationLevels}
              currentUser={user}
              mailCampaign={mailCampaign}
              automationLevelsFormErrors={automationLevelsFormErrors}
              setAutomationLevelsFormErrors={setAutomationLevelsFormErrors}
              isValidating={isValidating}
              tags={tags}
            />
          }
          deleteOption={index !== 0}
          onDelete={() => {
            deleteAutomationLevel(level.id);
          }}
        />
      ))}
      {automationLevels.length < 4 && (
        <div>
          <button
            className="flex items-center rounded-default pl-0 px-30 py-10 text-sm text-gray-500 bg-white outline-none hover:text-primary"
            role="menuitem"
            onClick={addNewAutomationLevel}
          >
            <SvgAdd className="mr-20 text-primary" />
            Add new automation level{' '}
            <span className="text-gray-400 text-xs ml-5">max 4 levels</span>
          </button>
        </div>
      )}

      <div className="flex flex-col sm:mb-50 md:mb-0 w-full">
        <div className="flex flex-between">
          <button
            type="submit"
            className="ml-auto mt-20 flex text-center justify-center items-center rounded-default px-30 py-10 text-sm text-white bg-primary hover:bg-secondary"
            role="menuitem"
            onClick={submitAutomationData}
          >
            Finish
            <SvgCheckCircle className="ml-20" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default AutomationByTags;
