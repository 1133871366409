import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ReactComponent as SvgEdit } from './../../../assets/icons/icon-edit.svg';
import { ReactComponent as SvgFacebook } from './../../../assets/icons/icon-fb.svg';
import { ReactComponent as SvgInstagram } from './../../../assets/icons/icon-ig.svg';
import { ReactComponent as SvgTwitter } from './../../../assets/icons/icon-tw.svg';
import { ReactComponent as SvgCamera } from './../../../assets/icons/icon-camera.svg';
import { ReactComponent as SvgAdd } from '../../../assets/icons/icon-add.svg';
import { contactActions } from '../../../common/actions';
import Note from '../../../components/commons/Note/Note';
import Select from '../../../components/Select/Select';
import Moment from 'moment';
import styles from './ContactContent.module.scss';
import RadioButton from '../../../components/Form/components/RadioButton/RadioButton';
import Button from '../../../components/Button';
import ButtonBack from '../../../components/ButtonBack/ButtonBack';
import { CreateNoteModal } from '../../../components/commons/NoteCreate/CreateNoteModal';

const getSocialNetworkIcon = (network) => {
  switch (network) {
    case 'Twitter':
      return <SvgFacebook />;
    case 'Instagram':
      return <SvgInstagram />;
    case 'Facebook':
      return <SvgTwitter />;
    default:
      return null;
  }
};

const ContactContent = ({ defaultValues = {} }) => {
  Moment.locale('en');
  const dispatch = useDispatch();
  const tagsList = useSelector((state) => state.contacts.tagsList?.data);
  console.log(tagsList);
  const contact = useSelector((state) => state.contact.contact);
  console.log(contact);
  const [isNoteFormOpened, setIsNoteFormOpened] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [isMarketingAllowed, setIsMarketingAllowed] = useState(
    contact.marketing_permission || false
  );

  const handleMarketing = () => {
    setIsMarketingAllowed(!isMarketingAllowed);
  };

  const [isContactingAllowed, setIsContactingAllowed] = useState(
    contact.permission_to_contact || false
  );

  const handlePermissionToContact = () => {
    setIsContactingAllowed(!isContactingAllowed);
  };
  useEffect(() => {
    dispatch(contactActions.getAllTags());
  }, []);
  const timeZones = [
    { value: '12:00', label: '(GMT -12:00) Eniwetok, Kwajalein' },
    { value: '11:00', label: '(GMT -11:00) Midway Island, Samoa' },
    { value: '10:00', label: '(GMT -10:00) Hawaii' },
    { value: '09:50', label: '(GMT -9:30) Taiohae' },
    { value: '09:00', label: '(GMT -9:00) Alaska' },
    { value: '08:00', label: 'GMT -8:00) Pacific Time (US &amp; Canada)' },
    { value: '07:00', label: 'GMT -7:00) Mountain Time (US &amp; Canada)' },
    {
      value: '06:00',
      label: 'GMT -6:00) Central Time (US &amp; Canada), Mexico City',
    },
    {
      value: '05:00',
      label: 'GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima',
    },
    { value: '04:50', label: '(GMT -4:30) Caracas' },
    {
      value: '04:00',
      label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
    },
    { value: '03:50', label: '(GMT -3:30) Newfoundland' },
    { value: '03:00', label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown' },
    { value: '02:00', label: '(GMT -2:00) Mid-Atlantic' },
    { value: '01:00', label: '(GMT -1:00) Azores, Cape Verde Islands' },
    {
      value: '00:00',
      label: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
    },
    {
      value: '01:00',
      label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris',
    },
    { value: '02:00', label: '(GMT +2:00) Kaliningrad, South Africa' },
    {
      value: '03:00',
      label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
    },
    { value: '03:50', label: '(GMT +3:30) Tehran' },
    { value: '04:00', label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
    { value: '04:50', label: '(GMT +4:30) Kabul' },
    {
      value: '05:00',
      label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
    },
    {
      value: '05:50',
      label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi',
    },
    { value: '05:75', label: '(GMT +5:45) Kathmandu, Pokhara' },
    { value: '06:00', label: '(GMT +6:00) Almaty, Dhaka, Colombo' },
    { value: '06:50', label: '(GMT +6:30) Yangon, Mandalay' },
    { value: '07:00', label: '(GMT +7:00) Bangkok, Hanoi, Jakarta' },
    {
      value: '08:00',
      label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
    },
    { value: '08:75', label: '(GMT +8:45) Eucla' },
    {
      value: '09:00',
      label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
    },
    { value: '09:50', label: '(GMT +9:30) Adelaide, Darwin' },
    {
      value: '10:00',
      label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
    },
    { value: '10:50', label: '(GMT +10:30) Lord Howe Island' },
    {
      value: '11:00',
      label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
    },
    { value: '11:50', label: '(GMT +11:30) Norfolk Island' },
    {
      value: '12:00',
      label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
    },
    { value: '12:75', label: '(GMT +12:45) Chatham Islands' },
    { value: '13:00', label: '(GMT +13:00) Apia, Nukualofa' },
    { value: '14:00', label: '(GMT +14:00) Line Islands, Tokelau' },
  ];

  const roleOptions = [
    { value: 'Visitor', label: 'Visitor' },
    { value: 'Client', label: 'Client' },
    { value: 'Healer', label: 'Healer' },
    { value: 'Adv Student', label: 'Adv Student' },
  ];

  const clientSenseOptions = [
    { value: 'Internet search', label: 'Internet search' },
    { value: 'Meetup', label: 'Meetup' },
    { value: 'Friend', label: 'Friend' },
    { value: 'Event_brite', label: 'Event Brite' },
    { value: 'Other', label: 'Other' },
  ];

  const freeEvents = [
    { value: 'facebook', label: 'Facebook' },
    { value: 'Campaign from Google', label: 'Campaign from Google' },
    { value: 'affiliate', label: 'Affiliate' },
  ];

  const paidEvents = [
    { value: 'Webstore', label: 'Webstore' },
    { value: 'Pos', label: 'Pos' },
    { value: 'Eventbrite', label: 'Eventbrite' },
    { value: 'Other', label: 'Other' },
  ];

  const truthyFalsy = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ];

  const { register, handleSubmit, watch, errors, control } = useForm({
    mode: 'onChange',
  });
  const [tags, setTags] = useState([]);
  const handleChangeSelectValue = (e) => {
    setTags(e.target.value);
  };
  const formatter = (u) => ({
    label: u?.name,
    value: u?.id,
  });
  const tagsMapped = React.useMemo(() => tagsList?.map(formatter));
  const onSubmit = (data) => {
    console.log(data);
    dispatch(
      contactActions.updateContact(contact.id, {
        ...data,
        opt_in_ip: data.opt_in_ip.value,
        marketing_permission: isMarketingAllowed,
        permission_to_contact: isContactingAllowed,
        tags: data.tags.split(','),
        client_sense: data.client_sense.value,
        client_since: data.client_since,
        status: data.status.value,
        first_free_event: data.first_free_event.value,
        first_paid_event: data.first_paid_event.value,
        communication_method: data.com_methods,
        time_zone: data.time_zone.value,
        social_links: [
          {
            url: data.facebook ? data.facebook : '-',
            name: 'Facebook',
          },
          {
            url: data.twitter ? data.twitter : '-',
            name: 'Twitter',
          },
          {
            url: data.instagram ? data.instagram : '-',
            name: 'Instagram',
          },
        ],
      })
    );
    setIsEditMode(false);
  };

  const onImageChange = (event) => {
    if (event.target && event.target.files[0]) {
      dispatch(
        contactActions.updateContactImage(contact.id, event.target.files[0])
      );
    }
  };

  const renderContactNotes = () => {
    if (contact.notes && contact.notes.length > 0) {
      return (
        <div className="">
          <hr className="border-gray-100 my-20 sm:hidden md:block" />
          <h3 className="font-bold text-2xl my-2">Notes</h3>

          <div className="flex flex-wrap -ml-15 -mr-15">
            {contact.notes.map((note, index) => {
              return (
                <Note
                  contact={contact}
                  key={index}
                  note={note}
                  className="md:w-1/2 lg:w-1/3 xl:w-1/4 pl-15 pr-15 pb-30"
                />
              );
            })}
          </div>
        </div>
      );
    } else return null;
  };
  const renderProfileImage = () => {
    if (contact.image) {
      return (
        <div className="relative w-full h-full">
          <img
            className="block w-full rounded-sm object-center object-cover h-full"
            src={contact.image}
            alt=""
          />
          <div
            className="profileIconHolder"
            style={{
              height: '44px',
              bottom: '20px',
              right: '20px',
              position: 'absolute',
              zIndex: '2',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <div
              className="profileIconHolderInner"
              style={{
                height: '44px',
                width: '44px',
                backgroundColor: 'white',
                borderRadius: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                boxShadow: '0px 5px 15px rgb(176 190 197 / 20%)',
              }}
            >
              <SvgCamera />
              <input
                type="file"
                onChange={onImageChange}
                accept="image/*"
                className="hidden"
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col items-center justify-center h-full">
          <p className="text-sm uppercase text-primary text-bold px-30 text-center mb-20">
            No image provided!
          </p>
          <SvgCamera />
          <input
            className="hidden"
            style={{ width: '100%' }}
            type="file"
            accept="image/*"
            onChange={onImageChange}
          />
        </div>
      );
    }
  };

  const getContentByMode = () => {
    if (isEditMode) {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <section>
            <div className="flex mt-40 mb-20 bg-gray-300 px-20 py-20 justify-center rounded-default">
              <label
                className="w-2/12 flex flex-col items-center bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white"
                style={{ minHeight: '250px', maxHeight: '250px' }}
              >
                {renderProfileImage()}
              </label>
              <div className="flex flex-col w-10/12 justify-center">
                <div className="flex flex-col ml-40 mb-15">
                  <Select
                    className={`${styles.select} w-1/6`}
                    labelText="Status"
                    name="status"
                    options={roleOptions}
                    placeholder="Status"
                    register={register}
                    control={control}
                    defaultValue={{
                      value: contact.status ? contact.status : 'Non set',
                      label: contact.status ? contact.status : 'Non set',
                    }}
                  />

                  <p className="w-full text-xs text-gray-400">
                    First and last name
                  </p>
                  {errors.first_name && (
                    <span className="from-error-label">*Required</span>
                  )}
                  <div className="flex">
                    <input
                      className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-1/3 mr-5"
                      type="text"
                      defaultValue={contact.first_name}
                      ref={register({ required: true })}
                      name="first_name"
                    />
                    <input
                      className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-1/3"
                      type="text"
                      defaultValue={contact.last_name}
                      ref={register({ required: true })}
                      name="last_name"
                    />
                  </div>
                </div>

                <div className="flex">
                  <div className="flex flex-col ml-40 w-1/4 mr-20">
                    <div className="flex flex-col mb-20">
                      <p className="w-full text-xs text-gray-400 mb-0">
                        Company
                      </p>
                      <input
                        className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                        type="text"
                        name="company"
                        placeholder=""
                        defaultValue={contact.company}
                        ref={register({ required: true })}
                      />
                    </div>
                    <div className="flex flex-col mb-20">
                      <p className="w-full text-xxs text-gray-400 mb-0 font-medium">
                        Position
                      </p>
                      <p className="w-full text-sm text-gray-500 mb-0">
                        <input
                          className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                          type="text"
                          name="contact_position"
                          placeholder=""
                          defaultValue={contact.position}
                          ref={register({ required: true })}
                        />
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col mr-20 w-1/4 mr-20">
                    <div className="flex flex-col mb-20">
                      <p className="w-full text-xxs text-gray-400 mb-0 font-medium">
                        Birthday
                      </p>
                      <p className="w-full text-sm text-gray-500 mb-0">
                        <input
                          className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                          type="text"
                          name="birthday"
                          placeholder=""
                          defaultValue={Moment(contact.birthday).format('L')}
                          ref={register({ required: true })}
                        />
                      </p>
                    </div>
                    <div className="flex flex-col mb-20">
                      <p className="w-full text-xxs text-gray-400 mb-0 font-medium">
                        Mobile phone
                      </p>
                      <input
                        className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                        type="text"
                        name="mobile"
                        placeholder=""
                        defaultValue={contact.mobile}
                        ref={register({ required: true })}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mr-20 w-1/4 mr-20">
                    <div className="flex flex-col mb-20">
                      <p className="w-full text-xxs text-gray-400 mb-0 font-medium">
                        Mail
                      </p>
                      <input
                        className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                        type="text"
                        name="email"
                        placeholder=""
                        defaultValue={contact.email}
                        ref={register({ required: true })}
                      />
                    </div>
                    <div className="flex flex-col mb-20">
                      <p className="w-full text-xxs text-gray-400 mb-0 font-medium">
                        Gender
                      </p>
                      <input
                        className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                        type="text"
                        name="gender"
                        placeholder=""
                        defaultValue={contact.gender}
                        ref={register({ required: true })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="flex justify-between">
            <div className="flex flex-col w-half">
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">Address</p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="text"
                    name="text"
                    placeholder="Address"
                    defaultValue={contact.address}
                    ref={register({ required: false })}
                  />
                </p>
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">Region</p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="text"
                    name="region"
                    placeholder="Region"
                    defaultValue={contact.region}
                    ref={register({ required: false })}
                  />
                </p>
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">Province</p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="text"
                    name="province"
                    placeholder=""
                    defaultValue={contact.province}
                    ref={register({ required: false })}
                  />
                </p>
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">Postal code</p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="text"
                    name="postal_code"
                    placeholder="Postal code"
                    defaultValue={contact.postal_code}
                    ref={register({ required: false })}
                  />
                </p>
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">Country</p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="text"
                    name="country"
                    placeholder=""
                    defaultValue={contact.country}
                    ref={register({ required: false })}
                  />
                </p>
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">Time zone</p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <Select
                    labelText=""
                    name="time_zone"
                    options={timeZones}
                    placeholder=""
                    register={register}
                    control={control}
                    className={`${styles.select}`}
                    defaultValue={{
                      value: contact.time_zone
                        ? contact.time_zone
                        : timeZones[10].value,
                      label: contact.time_zone
                        ? contact.time_zone
                        : timeZones[10].label,
                    }}
                  />
                </p>
              </div>
              <div className="flex flex-col mb-60">
                <p className="w-full text-xs text-gray-400 mb-5">Languages</p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="text"
                    name="language"
                    placeholder="German"
                    defaultValue={contact.language}
                    ref={register({ required: false })}
                  />
                </p>
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">
                  Already have an account with Dr. Sha.com
                </p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="text"
                    name="account_dr_sha"
                    placeholder="0"
                    defaultValue={contact.account_dr_sha}
                    ref={register({ required: false })}
                  />
                </p>
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">
                  Client since
                </p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="text"
                    name="client_since"
                    placeholder=""
                    defaultValue={
                      contact.client_since ? contact.client_since : '00/00/2000'
                    }
                    ref={register({ required: false })}
                  />
                </p>
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">
                  Tao Hands Healer
                </p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="number"
                    name="tao_hands_healer"
                    placeholder="0"
                    defaultValue={contact.tao_hands_healer}
                    ref={register({ required: false })}
                  />
                </p>
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">
                  Tao academy groups
                </p>
                <input
                  className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                  type="text"
                  name="tao_academy_groups"
                  placeholder=""
                  defaultValue={contact.tao_academy_groups}
                  ref={register({ required: false })}
                />
              </div>

              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">
                  Landline (telephone)
                </p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="text"
                    name="text"
                    placeholder="German"
                    defaultValue={contact.phone}
                    ref={register({ required: false })}
                  />
                </p>
              </div>
              <div className="w-full flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">Facebook:</p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    register={register}
                    name="facebook"
                    defaultValue={
                      contact.social_links ? contact.social_links[0].url : '-'
                    }
                    type="text"
                    className="w-full flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm mb-10"
                  />
                </p>
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">Twitter:</p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    register={register}
                    name="twitter"
                    defaultValue={
                      contact.social_links ? contact.social_links[1].url : '-'
                    }
                    type="text"
                    className="w-full flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm mb-10"
                  />
                </p>
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">Instagram:</p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    register={register}
                    name="instagram"
                    defaultValue={
                      contact.social_links ? contact.social_links[2].url : '-'
                    }
                    type="text"
                    className="w-full flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm mb-10"
                  />
                </p>
              </div>
            </div>
            <div className="flex flex-col w-half">
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">
                  Channel origin
                </p>
                <Select
                  labelText=""
                  name="client_sense"
                  options={clientSenseOptions}
                  placeholder="Online"
                  register={register}
                  control={control}
                  className={`${styles.select}`}
                  defaultValue={{
                    value: contact.client_sense
                      ? contact.client_sense
                      : 'Non set',
                    label: contact.client_sense
                      ? contact.client_sense
                      : 'Non set',
                  }}
                />
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">
                  Channel - 1st Free Event signed up
                </p>
                <Select
                  labelText=""
                  name="first_free_event"
                  options={freeEvents}
                  placeholder="Online"
                  register={register}
                  control={control}
                  className={`${styles.select}`}
                  defaultValue={{
                    value: contact.first_free_event
                      ? contact.first_free_event
                      : 'Non set',
                    label: contact.first_free_event
                      ? contact.first_free_event
                      : 'Non set',
                  }}
                />
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">
                  Channel - 1st Paid Event signed up
                </p>
                <Select
                  labelText=""
                  name="first_paid_event"
                  options={paidEvents}
                  placeholder="Online"
                  register={register}
                  control={control}
                  className={`${styles.select}`}
                  defaultValue={{
                    value: contact.first_paid_event
                      ? contact.first_paid_event
                      : 'Non set',
                    label: contact.first_paid_event
                      ? contact.first_paid_event
                      : 'Non set',
                  }}
                />
              </div>

              <div className="flex flex-col mb-60">
                <p className="w-full text-xs text-gray-400 mb-5">
                  Prefered method of communication
                </p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="text"
                    name="com_methods"
                    placeholder=""
                    defaultValue={contact.communication_method}
                    ref={register({ required: false })}
                  />
                </p>
              </div>

              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">
                  Subscription Preferences
                </p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="text"
                    name="subscription_preferences"
                    placeholder="0"
                    defaultValue={contact.subscription_preferences}
                    ref={register({ required: false })}
                  />
                </p>
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">
                  Marketing permissions
                </p>
                <div className="flex items-center" style={{ height: '46px' }}>
                  <div className="flex mr-20 items-center">
                    <RadioButton
                      name="marketing"
                      labelText="Yes"
                      onChange={handleMarketing}
                      checked={isMarketingAllowed == true}
                    />
                  </div>
                  <div className="flex">
                    <RadioButton
                      name="marketing"
                      labelText="No"
                      onChange={handleMarketing}
                      checked={isMarketingAllowed === false}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col mb-60">
                <p className="w-full text-xs text-gray-400 mb-5">
                  Permission to contacts
                </p>
                <div className="flex items-center" style={{ height: '46px' }}>
                  <div className="flex mr-20 items-center">
                    <RadioButton
                      name="permission_to_contact"
                      labelText="Yes"
                      onChange={handlePermissionToContact}
                      checked={isContactingAllowed == true}
                    />
                  </div>
                  <div className="flex">
                    <RadioButton
                      name="marketing"
                      labelText="No"
                      onChange={handlePermissionToContact}
                      checked={isContactingAllowed === false}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">Opt-in time</p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="text"
                    name="opt_in"
                    placeholder="0"
                    defaultValue={contact.opt_in}
                    ref={register({ required: false })}
                  />
                </p>
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">Opt-in IP</p>
                <Select
                  labelText=""
                  name="opt_in_ip"
                  options={truthyFalsy}
                  placeholder="False"
                  register={register}
                  control={control}
                  className={`${styles.select}`}
                  defaultValue={{
                    value: contact.opt_in_ip,
                    label: contact.opt_in_ip ? 'True' : 'False',
                  }}
                />
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">
                  Confirmed IP:
                </p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="text"
                    name="confirm_ip"
                    placeholder=""
                    defaultValue={contact.confirm_ip}
                    ref={register({ required: false })}
                  />
                </p>
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">
                  Confirm time
                </p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="text"
                    name="confirm_time"
                    placeholder="0"
                    defaultValue={contact.confirm_time}
                    ref={register({ required: false })}
                  />
                </p>
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">Longitude:</p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="text"
                    name="longitude"
                    placeholder=""
                    defaultValue={contact.longitude}
                    ref={register({ required: false })}
                  />
                </p>
              </div>

              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">Latitude</p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="text"
                    name="latitude"
                    placeholder="0"
                    defaultValue={contact.latitude}
                    ref={register({ required: false })}
                  />
                </p>
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">
                  Member rating
                </p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="number"
                    name="member_rating"
                    placeholder=""
                    defaultValue={contact.member_rating}
                    ref={register({ required: false })}
                  />
                </p>
              </div>
              <div className="flex flex-col mb-20">
                <p className="w-full text-xs text-gray-400 mb-5">Tags:</p>
                <p className="w-full text-sm text-gray-500 mb-0">
                  <input
                    className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full"
                    type="text"
                    name="tags"
                    placeholder=""
                    defaultValue={tags}
                    ref={register({ required: false })}
                  />
                </p>
              </div>
              <div className="flex flex-col w-full">
                <span className="text-xs font-medium text-gray-400 mb-10">
                  Select tags
                </span>
                <Select
                  labelText=""
                  name="tags"
                  placeholder="Tags"
                  className={`${styles.select}`}
                  register={register}
                  control={control}
                  isMulti
                  options={tagsMapped}
                  defaultValue={contact.tags}
                />
              </div>
            </div>
          </div>
          <div className="flex items-start mt-20 mb-20 w-full">
            <Button
              variant="secondary"
              height="44"
              width="120"
              className="bg-transparent text-sm pointer-events-none"
              onClick={() => {
                setIsEditMode(false);
              }}
            >
              Cancel
            </Button>
            <button
              type="submit"
              className="flex text-center justify-center items-center
              rounded-default px-30 py-10 text-sm text-white bg-primary
              hover:bg-secondary outline-none ml-10"
            >
              Update
            </button>
          </div>
        </form>
      );
    }
    return (
      <>
        <Tabs>
          <TabList className="flex border-b-1 border-solid border-gray-300 mt-40">
            <Tab className="flex flex-col items-center justify-between pr-30 text-sm cursor-pointer outline-none">
              Personal Information
            </Tab>
            <Tab className="flex flex-col items-center justify-between pr-30 text-sm cursor-pointer outline-none">
              Notes
            </Tab>
          </TabList>
          <TabPanel>
            <div className="flex mt-40 flex-wrap">
              <div className="flex flex-col w-1/2">
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Address
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.address ? contact.address : '-'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Region
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.region ? contact.region : '-'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Province:
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.province ? contact.province : '-'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Postal code
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.postal_code ? contact.postal_code : '-'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Country
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.country ? contact.country : '-'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Time zone:
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.time_zone ? contact.time_zone : '-'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Languages
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.language ? contact.language : '-'}
                  </p>
                </div>
                <div className="flex mb-60 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Landline (Telephone)
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.phone ? contact.phone : '-'}
                  </p>
                </div>

                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Already have an account with Dr. Sha.com:
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.account_dr_sha === false ? 'True' : 'False'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Created at
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.created_at
                      ? Moment(contact.created_at).format('L')
                      : '-'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Last Changed
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.updated_at
                      ? Moment(contact.updated_at).format('L')
                      : '-'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Client since
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.client_since
                      ? Moment(contact.client_since).format('L')
                      : '-'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Tao Hands Healer:
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.tao_hands_healer ? contact.tao_hands_healer : '0'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Tao Academy Groups:
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.tao_academy_groups
                      ? contact.tao_academy_groups
                      : '-'}
                  </p>
                </div>
              </div>
              <div className="flex flex-col w-1/2">
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Channel origin
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.client_sense ? contact.client_sense : '-'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Channel - 1st free event
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.first_free_event ? contact.first_free_event : '-'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Channel - 1st paid event
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.first_paid_event ? contact.first_paid_event : '-'}
                  </p>
                </div>

                <div className="flex mb-60 ">
                  <p
                    className="w-half text-xs text-gray-400 mb-0 pr-40"
                    style={{ minWidth: '51%' }}
                  >
                    Prefered method of communication:
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.communication_method
                      ? contact.communication_method
                      : '-'}
                  </p>
                  {/*  <div className="flex w-half flex-wrap">
                     {contact.communication_method && (
                      <>
                        {contact.communication_method.map((method, index) => {
                          return (
                            <span
                              className="text-sm flex items-center justify-center bg-blue-100 text-primary font-semibold border-1 border-solid border-primary rounded-sm px-10 py-2 mr-10 mb-10"
                              key={index}
                            >
                              {method}
                            </span>
                          );
                        })}
                      </>
                    )} 
                  </div> */}
                </div>

                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Subscription Preferences:
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.subscription_preferences
                      ? contact.subscription_preferences
                      : '-'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Marketing Permission:
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.marketing_permission ? 'True' : 'False'}
                  </p>
                </div>
                <div className="flex mb-60 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Permission to contact:
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.permission_to_contact ? 'True' : 'False'}
                  </p>
                </div>

                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    OPT-in time:
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.opt_in ? Moment(contact.opt_in).format('L') : '-'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Opt-in IP:
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.opt_in_ip ? 'True' : 'False'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Confirm date:
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.confirm_time
                      ? Moment(contact.confirm_time).format('L')
                      : '-'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Confirm IP:
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.confirm_ip ? contact.confirm_ip : '-'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Longitude:
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.longitude ? contact.longitude : '-'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Latitude:
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.latitude ? contact.latitude : 'Null'}
                  </p>
                </div>
                <div className="flex mb-20 ">
                  <p className="w-full text-xs text-gray-400 mb-0 pr-40">
                    Member Rating:
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.member_rating ? contact.member_rating : '-'} / 10
                  </p>
                </div>

                <div className="flex mb-20 ">
                  <p className="w-half text-xs text-gray-400 mb-0 pr-40">
                    Tags:
                  </p>
                  <div className="flex w-half flex-wrap">
                    {contact.tags && (
                      <>
                        {contact.tags.map((tag, index) => {
                          return (
                            <span
                              className="text-sm flex items-center justify-center bg-blue-100 text-primary font-semibold border-1 border-solid border-primary rounded-sm px-10 py-2 mr-10 mb-10"
                              key={index}
                            >
                              {tag}
                            </span>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel className="relative">
            <div className="mb-20 md:mb-0 w-full absolute">
              <button
                onClick={() => {
                  setIsNoteFormOpened(true);
                }}
                style={{ marginTop: '-80px' }}
                className="flex text-center justify-center items-center
              rounded-default px-20 py-10 text-sm text-white bg-primary
              hover:bg-secondary outline-none ml-auto"
              >
                <SvgAdd className="mr-20 text-white" />
                Add note
              </button>
            </div>
            {renderContactNotes()}
            <CreateNoteModal
              setIsNoteFormOpened={setIsNoteFormOpened}
              isNoteFormOpened={isNoteFormOpened}
              contact={contact}
            />
          </TabPanel>
        </Tabs>
      </>
    );
  };

  return (
    <>
      <section>
        <ButtonBack className="justify-end -mt-50" />
        <div
          className={` ${
            isEditMode ? 'hidden' : 'flex'
          } flex mt-40 mb-20 bg-gray-300 px-20 py-20 justify-center rounded-default`}
        >
          <label
            className="w-2/12 flex flex-col items-center bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white"
            style={{ minHeight: '250px', maxHeight: '250px' }}
          >
            {renderProfileImage()}
          </label>
          <div className="flex flex-col w-10/12 justify-center">
            <div className="flex flex-col ml-40 mb-15">
              <span
                style={{ width: '150px' }}
                className="inline-flex bg-red text-black text-xxs uppercase font-medium py-5 items-center rounded-default justify-center tracking-wide mb-5"
              >
                {contact.status ? contact.status : 'Viewer'}
              </span>
              <p className="w-full text-xs text-gray-400">
                First and last name
              </p>
              <p className="flex items-center w-full text-2xl font-bold text-black w-full text-sm text-gray-500">
                {contact.first_name} {contact.last_name}
                <button
                  className="flex"
                  onClick={() => {
                    setIsEditMode(true);
                    console.log('this is contact', contact);
                  }}
                >
                  <SvgEdit className="ml-15" fill="text-primary" />
                </button>
              </p>
            </div>

            <div className="flex">
              <div className="flex flex-col ml-40 w-1/4 justify-center">
                <div className="flex flex-col mb-20">
                  <p className="w-full text-xs text-gray-400 mb-0">Company</p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.company}
                  </p>
                </div>
                <div className="flex flex-col mb-20">
                  <p className="w-full text-xxs text-gray-400 mb-0 font-medium">
                    Position
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.position}
                  </p>
                </div>
              </div>
              <div className="flex flex-col w-1/4">
                <div className="flex flex-col mb-20">
                  <p className="w-full text-xxs text-gray-400 mb-0 font-medium">
                    Birthday
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.birthday
                      ? Moment(contact.birthday).format('L')
                      : '-'}
                  </p>
                </div>
                <div className="flex flex-col mb-20">
                  <p className="w-full text-xxs text-gray-400 mb-0 font-medium">
                    Mobile number
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.mobile ? contact.mobile : '+...'}
                  </p>
                </div>
              </div>
              <div className="flex flex-col w-1/4">
                <div className="flex flex-col mb-20">
                  <p className="w-full text-xxs text-gray-400 mb-0 font-medium">
                    Mail
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.email}
                  </p>
                </div>
                <div className="flex flex-col mb-20">
                  <p className="w-full text-xxs text-gray-400 mb-0 font-medium">
                    Gender
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {contact.gender}
                  </p>
                </div>
              </div>
              <div className="flex flex-col w-1/4">
                {contact.social_links && (
                  <div className="flex mb-40 flex-wrap items-center">
                    <p className="w-full text-xs text-gray-400 mb-20">
                      Social medias
                    </p>
                    {contact.social_links.map((network, index) => {
                      return (
                        <li className="flex mx-10" key={index}>
                          <a href={network.url} taret="_blank">
                            {getSocialNetworkIcon(network.name)}
                          </a>
                        </li>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {getContentByMode()}
      </section>
    </>
  );
};
export default ContactContent;
