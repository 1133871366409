import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FieldInput from '../../Form/components/FieldInput';
import Button from '../../Button';
import { NavLink, useHistory } from 'react-router-dom';
import { authActions } from '../../../common/actions';
import Checkbox from '../../Form/components/Checkbox/Checkbox';

const LoginSchema = Yup.object().shape({
  email: Yup.string().required('This field is required'),
  password: Yup.string()
    .min(8, 'Enter minimium 8 characters')
    .required('This field is required'),
});

const LoginForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(LoginSchema),
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    dispatch(authActions.loginUser(data, history));
  };

  //const ref = useRef(null);
  //useScrollLock(fpVisible, ref);

  return (
    <>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col sm:mb-50 md:mb-0 w-full"
      >
        <FieldInput
          register={register}
          name="email"
          defaultValue=""
          type="email"
          errors={errors}
          labelText="Email"
          placeholder="Your e-mail address"
          value={watch('email')}
        />
        <FieldInput
          register={register}
          name="password"
          defaultValue=""
          type="password"
          errors={errors}
          labelText="Password"
          placeholder="Your password"
          value={watch('password')}
        />
        <div className="flex justify-between items center">
          <div className="flex">
            <Checkbox
              name="signed"
              labelText="Keep me signed in"
              onChange={handleChange}
              checked={isChecked}
            />
          </div>
          <NavLink
            to="/forgot-password"
            type="button"
            className="text-sm text-primary"
          >
            Forgot password?
          </NavLink>
        </div>
        <div className={`mt-20 sm:mb-30`}>
          <Button
            variant="primary"
            height="44"
            width=""
            isLoading={isSubmitting}
            className="m-auto w-full"
          >
            Sign in
          </Button>
        </div>

        {/* <div className="flex justify-center w-full">
          <NavLink to="/add-account">
            Not registered yet?{' '}
            <strong className="text-primary text-sm font-bold">
              Create a new account
            </strong>
          </NavLink>
        </div> */}
      </form>
    </>
  );
};

export default LoginForm;
