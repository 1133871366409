import React, { useState } from 'react';
import styles from './FavoritesAddButton.module.scss';
import { ReactComponent as SvgFavorite } from './../../assets/icons/icon-favorite.svg';
import { ReactComponent as SvgFavorited } from './../../assets/icons/icon-favorite-fill.svg';

const FavoritesAddButton = ({
  className,
  onClick,
  isFavorited = false,
  labelText,
}) => {
  const [isActive, setisActive] = useState(false);
  const toggleIsActive = () => {
    setisActive(!isActive);
  };
  return (
    <div className="relative outline-0 flex items-center">
      <button
        onClick={toggleIsActive}
        className={`${className} ${styles.FavoritesAddButton} ${
          isActive ? '' : `${styles.focused}`
        } text-secondary relative flex items-center justify-center p-5 bg:white hover:bg-gray-300 rounded-full`}
      >
        {isActive ? (
          <SvgFavorite className="text-primary" />
        ) : (
          <SvgFavorited className="text-red" />
        )}
      </button>
      <span className="text-xs text-gray-500 ml-5">{labelText}</span>
    </div>
  );
};

export default FavoritesAddButton;
