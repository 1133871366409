import React from 'react';
import LayoutComponent from '../../components/commons/LayoutComponent';
import NewOfferContent from './components/NewOfferContent';

const NewOffer = ({}) => {
  return (
    <LayoutComponent title="NewOffer">
      <NewOfferContent />
    </LayoutComponent>
  );
};

export default NewOffer;
