import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../../../components/Modal/Modal';
import { ReactComponent as SvgCheckCircle } from './../../../../assets/icons/icon-check-circle.svg';
import { ReactComponent as SvgDocument } from './../../../../assets/icons/icon-document.svg';
import { ReactComponent as SvgAvatars } from './../../../../assets/icons/icon-avatars.svg';
import { mailCampaignsActions } from 'src/common/actions';
import { CampaignNewTemplateModal } from '../../CampaignNewTemplateModal';
import { toast } from 'react-toastify';

function CampaignPickTemplateModal({
  toggleCampaignPickTemplateModal,
  isCampaignPickTemplateModalOpened,
}) {
  const dispatch = useDispatch();
  const templates = useSelector(
    (state) => state.mailCampaigns.mailTemplateState.templates
  );

  const areMailTemplatesLoaded = useSelector(
    (state) => state.mailCampaigns.mailTemplateState.areMailTemplatesLoaded
  );

  const selectedTemplate = useSelector(
    (state) => state.mailCampaigns.mailCampaignAutomatizationState.template
  );

  const [isNewTemplateModalOpened, setIsNewTemplateModalOpened] = useState(
    false
  );

  const toggleNewTemplateModal = () => {
    setIsNewTemplateModalOpened(false);
  };

  useEffect(() => {
    if (!areMailTemplatesLoaded) {
      dispatch(mailCampaignsActions.getAllMailTemplates());
    }
  }, [areMailTemplatesLoaded]);

  useEffect(() => {
    setSelectedTemplateIndex(selectedTemplate ? selectedTemplate.id : null);
  }, [selectedTemplate]);

  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(null);

  const confirmSelection = () => {
    dispatch(
      mailCampaignsActions.setPickedAutomatizationTemplate(
        selectedTemplateIndex
      )
    );
    toggleCampaignPickTemplateModal();
    toast.success('Templated picked', { className: 'text-white' });
  };

  return areMailTemplatesLoaded ? (
    <Modal
      isOpen={isCampaignPickTemplateModalOpened}
      onRequestClose={toggleCampaignPickTemplateModal}
      headerTitle="Choose template"
      className="modal--audience"
    >
      <div className="flex items-center mb-40 ">
        <h2 className={`text-2x font-bold text-black mr-20`}>
          Pick your template
        </h2>
        <a
          className="group bg-primary h-44 text-white border-1 solid border-primary hover:text-primary hover:bg-white  p-15 px-20 text-sm rounded-default outline-none flex justify-center items-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 ml-20"
          onClick={() => {
            setIsNewTemplateModalOpened(true);
          }}
        >
          <SvgAvatars className="mr-20 text-white group-hover:text-primary transition duration-500 ease-in-out" />
          Add new template
        </a>
        <CampaignNewTemplateModal
          toggleCampaignNewTemplateModal={toggleNewTemplateModal}
          isCampaignNewTemplateModalOpened={isNewTemplateModalOpened}
        />
      </div>
      <div className="flex flex-wrap">
        {templates.map((template, index) => {
          return (
            <div
              className={`${
                selectedTemplateIndex === template.id
                  ? 'bg-primary'
                  : 'bg-gray-300'
              } group flex p-10 overflow-hidden rounded-default w-third hover:bg-primary transition duration-300 ease-in-out cursor-pointer mb-20 mr-20`}
              //updatePickedContacts={updatePickedContacts}
              onClick={(e) => setSelectedTemplateIndex(template.id)}
              key={index}
            >
              <div className="flex items-center justify-center bg-white p-15 rounded-default mr-20">
                <SvgDocument className="text-primary group-hover:text-primary transition duration-300 ease-in-out" />
              </div>
              <h3
                className={`${
                  selectedTemplateIndex === template.id
                    ? 'text-white'
                    : 'text-black'
                } flex items-center w-full text-sm font-medium group-hover:text-white transition duration-300 ease-in-out`}
              >
                {template.name}
              </h3>
            </div>
          );
        })}
      </div>

      <button
        //isLoading={isSubmitting}
        onClick={confirmSelection}
        className="ml-auto mt-20 flex text-center justify-center items-center rounded-default pl-0 px-30 py-10 text-sm text-white bg-primary hover:bg-secondary outline-none"
        role="menuitem"
      >
        <SvgCheckCircle className="ml-20 mr-20" />
        Select template
      </button>
    </Modal>
  ) : null;
}

export default CampaignPickTemplateModal;
