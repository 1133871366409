import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { calendarActions } from '../../../common/actions/calendarActions';
import ButtonBack from '../../../components/ButtonBack/ButtonBack';
import Modal from '../../../components/Modal';
import CalendarItemForm from './CalendarItemForm';

const isEventInInterceptionWithOtherEvent = (newEvent, events) => {
  for (let i = 0; i < events.length; i++) {
    const iterationEvent = events[i];
    const iterationEventDateObjStart = moment(iterationEvent.start);
    const iterationEventDateObjEnd = moment(iterationEvent.end);
    const newEventDateObjStart = moment(new Date(newEvent.start_date));
    const newEventDateObjEnd = moment(new Date(newEvent.end_date));
    if (
      (newEventDateObjStart <= iterationEventDateObjEnd &&
        newEventDateObjEnd >= iterationEventDateObjEnd) ||
      (newEventDateObjStart <= iterationEventDateObjStart &&
        newEventDateObjEnd >= iterationEventDateObjEnd) ||
      (newEventDateObjStart <= iterationEventDateObjEnd &&
        newEventDateObjEnd >= iterationEventDateObjStart) ||
      (newEventDateObjStart <= iterationEventDateObjStart &&
        newEventDateObjEnd >= iterationEventDateObjEnd)
    ) {
      if (newEvent.allDay || iterationEvent.allDay) return true;
      const iterationEventTimeStart = moment(
        iterationEventDateObjStart.format('HH:mm:ss'),
        'HH:mm:ss'
      );
      const iterationEventTimeEnd = moment(
        iterationEventDateObjEnd.format('HH:mm:ss'),
        'HH:mm:ss'
      );
      const newEventTimeStart = moment(newEvent.start_time, 'HH:mm:ss');
      const newEventTimeEnd = moment(newEvent.end_time, 'HH:mm:ss');
      if (
        (newEventTimeStart <= iterationEventTimeEnd &&
          newEventTimeEnd >= iterationEventTimeEnd) ||
        (newEventTimeStart <= iterationEventTimeStart &&
          newEventTimeEnd >= iterationEventTimeEnd) ||
        (newEventTimeStart <= iterationEventTimeEnd &&
          newEventTimeEnd >= iterationEventTimeStart) ||
        (newEventTimeStart <= iterationEventTimeStart &&
          newEventTimeEnd >= iterationEventTimeEnd)
      ) {
        return true;
      }
    }
  }
  return false;
};

const CreateCalendarItemModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.calendar.calendarItems);
  const [dataToSubmit, setDataToSubmit] = useState(null);
  const [showProceedModal, setShowProceedModal] = useState(false);

  const onSuccess = () => {
    toast.success('Calendar item added', { className: 'text-white' });
    setOpen(false);
    setShowProceedModal(false);
  };

  const onFail = (errors) => {
    setShowProceedModal(false);
    toast.warning(errors, { className: 'text-white' });
  };

  const handleSubmit = (data) => {
    if (isEventInInterceptionWithOtherEvent(data, events)) {
      setShowProceedModal(true);
      setDataToSubmit(data);
      return;
    }
    createCalendarItem(data);
  };

  const createCalendarItem = (data) => {
    dispatch(calendarActions.createCalendarItem(data, onSuccess, onFail));
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      headerTitle=""
      className="modal--password"
      style={{
        position: 'relative',
      }}
    >
      <ButtonBack variant="small" className="mb-15" />

      <h2 className={`text-2xl font-bold text-black mb-25`}>Add new item</h2>

      <CalendarItemForm onSubmit={handleSubmit} events={events} />
      <Modal
        isOpen={showProceedModal}
        onRequestClose={() => setShowProceedModal(false)}
        className="flex flex-col"
      >
        <div className="flex flex-col mb-40">
          <ButtonBack variant="small" className="mb-15" />
          <h2 className={`text-2x font-bold text-black`}>
            Do you want to proceed?
          </h2>
          <p className="text-xs text-gray-400">
            Your event is overlaping with another event.
          </p>
        </div>
        <div className="flex justify-around">
          <button
            onClick={() => {
              setShowProceedModal(false);
              setDataToSubmit(null);
            }}
            className="mb-10 flex text-center justify-center items-center
          rounded-default px-30 py-10 text-sm text-white bg-red outline-none w-full"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              createCalendarItem(dataToSubmit);
            }}
            className="flex text-center justify-center items-center
              rounded-default px-30 py-10 text-sm text-white bg-primary
              hover:bg-secondary outline-none w-full"
          >
            Proceed
          </button>
        </div>
      </Modal>
    </Modal>
  );
};

export default CreateCalendarItemModal;
