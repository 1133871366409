import React, { useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import ReactSelect from 'react-select';
import { capitalize } from '../../utils/capitalize';

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    display: 'flex',
    minHeight: '50px',
    width: '100% !important',
    outline: 'none',
    borderRadius: '10px',
    border: state.isFocused ? '0' : '2px solid #E8ECF3',
  }),
  control: (provided, state) => ({
    ...provided,
    width: '100% !important',
    borderRadius: '10px',
    border: '0',
    background: '#ffffff',
    color: '#E8ECF3',
    paddingLeft: '10px',
    outline: 'none',
    border: state.isFocused ? '2px solid #E8ECF3' : '0',
    boxShadow: 'none',
    ':active': {
      border: state.isFocused ? '2px solid #E8ECF3' : '0',
    },
    ':hover': {
      outline: 'none',
      border: state.isFocused ? '2px solid #E8ECF3' : '0',
      boxShadow: 'none',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    pointerEvents: state.isDisabled ? 'none' : 'all',
    textDecoration: state.isDisabled ? 'line-through' : 'none',
  }),
  menu: (provided, state) => ({
    ...provided,
    background: '#F2F4F4',
    borderRadius: '0',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    background: '#E0F2FF',
    border: '1px solid #0395FF',
    borderRadius: '5px',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: '#0395FF',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#0395FF',
    padding: '0 5px',
  }),
};

const Select = ({
  control,
  errors,
  name,
  options,
  className = '',
  showLabel = true,
  labelText = name,
  placeholder,
  register,
  defaultValue = null,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const getDefaultValues = () => {
    if (defaultValue) {
      return defaultValue;
    }
    return { value: '', label: '' };
  };
  return (
    <div
      className={`${className} mb-25 outline-none field-select ${
        isFocused ? 'isFocused' : ''
      }`}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      {showLabel && (
        <label className="block text-xxs mb-5" htmlFor={name}>
          {capitalize(labelText)}
        </label>
      )}
      {control && (
        <Controller
          instanceId={name}
          as={<ReactSelect {...rest} />}
          name={name}
          options={options}
          control={control}
          defaultValue={getDefaultValues()}
          errors={errors}
          styles={customStyles}
          forwardRef={register}
        />
      )}
      {!control && (
        <ReactSelect
          {...rest}
          instanceId={name}
          name={name}
          options={options}
          control={control}
          defaultValue={getDefaultValues()}
          errors={errors}
          styles={customStyles}
          className={`form-select ${
            errors && name && errors[name]
              ? 'border-3 border-red bg-red bg-opacity-8'
              : ''
          }`}
          placeholder={placeholder}
          ref={register}
        />
      )}
      {errors && errors[name] && (
        <p className="text-xs mt-5 text-red text-right">
          {errors[name].message}
        </p>
      )}
    </div>
  );
};
export default Select;
