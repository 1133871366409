import React from 'react';
import LayoutComponent from '../../components/commons/LayoutComponent';
import ProductsContent from './components';

const Products = () => {
  return (
    <LayoutComponent title="Products">
      <ProductsContent />
    </LayoutComponent>
  );
};

export default Products;
