import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Button from 'src/components/Button';
import ButtonBack from '../../../../components/ButtonBack';
import FieldInput from '../../../../components/Form/components/FieldInput';
import SearchInput from '../../../../components/Form/components/SearchInput';
import Modal from '../../../../components/Modal';
import ChatContact from '../ChatContact';
import { ReactComponent as SvgClose } from './../../../../assets/icons/close-icon.svg';

const ChatModal = ({
  setOpened,
  isOpened,
  contactsList: chatUsers,
  onSubmit,
  editing = false,
  defaultValues = false,
}) => {
  const [search, setSearch] = useState('');

  const [name, setName] = useState(
    defaultValues.name ? defaultValues.name : ''
  );
  const [selected, setSelected] = useState(
    defaultValues.selected ? defaultValues.selected : []
  );

  const handleSelect = (userId) => {
    if (selected.includes(userId)) {
      if (editing && selected.length === 2) {
        toast.warn('Group chat requires at least 2 person', {
          className: 'text-white',
        });
      } else {
        setSelected(selected.filter((i) => i != userId));
      }
    } else {
      setSelected([...selected, userId]);
    }
  };

  const isGroup = selected.length > 1;

  const handleSubmit = () => {
    if (isGroup && !name.trim()) {
      toast.warn('Please enter chat name', {
        className: 'text-white',
      });

      return;
    }

    onSubmit(selected, name);

    setName('');
    setSearch('');
    setSelected([]);

    setOpened(false);
  };

  const filtered = search
    ? chatUsers.filter(
        (i) =>
          String(i.name)
            .toLocaleLowerCase()
            .search(search.toLocaleLowerCase()) !== -1
      )
    : chatUsers;

  return (
    <Modal
      isOpen={isOpened}
      onRequestClose={() => setOpened(false)}
      headerTitle=""
      className="modal--newChat"
      style={{
        position: 'relative',
      }}
    >
      <div className="flex flex-col mb-40">
        <div className="flex justify-between">
          <ButtonBack
            variant="click"
            className="mb-15"
            onClick={() => {
              setOpened(false);
            }}
          />
          <button
            className="appearance-none flex ml-10"
            onClick={() => {
              setOpened(false);
            }}
          >
            <SvgClose fill="text-gray-400" className="flex"></SvgClose>
          </button>
        </div>
        <h2 className={`text-2x font-bold text-black`}>
          {editing ? 'Edit conversation' : 'New conversation'}
        </h2>
        <p className="text-xs text-gray-400">
          Select a list of contacts you want to chat with
        </p>
      </div>
      <div className="flex items-center mb-20">
        {isGroup && (
          <FieldInput
            name="chat"
            type="text"
            labelText="Chat Name"
            placeholder="Name your chat"
            className="w-half"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        )}
        <Button
          variant="primary"
          className={`h-50 ml-auto ${isGroup ? 'w-half' : 'w-full mb-25'} ${
            selected.length === 0 ? 'pointer-events-none opacity-50' : ''
          }`}
          onClick={handleSubmit}
        >
          {editing ? 'Save' : 'Start'}
        </Button>
      </div>
      <SearchInput
        name="search"
        type="text"
        labelText="search"
        placeholder="Search contacts..."
        className="mb-20"
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <h2 className="text-xs font-medium text-gray-400 tracking-wide">
        Selected contacts
      </h2>
      <h3 className="text-xs font-medium pb-20">
        {chatUsers
          .filter((u) => selected.includes(u.id))
          .map((u) => u.name)
          .join(', ')}
      </h3>
      {filtered.map((contact, index) => {
        return (
          <ChatContact
            contact={contact}
            key={index}
            clickable={true}
            onSelect={handleSelect}
            checked={selected.includes(contact.id)}
          />
        );
      })}
    </Modal>
  );
};

export default ChatModal;
