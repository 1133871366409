import React from 'react';
import { Provider } from 'react-redux';
import { Router as AppRouter } from 'react-router-dom';
import { Router } from './router';
import { ToastContainer } from 'react-toastify';
import { ReactQueryDevtools } from 'react-query-devtools';
import { history } from './common/history';
import { Loader } from './components/commons/Loader';
import './styles/toast/main.scss';
import { store } from './common/store';

function App() {
  return (
    <Provider store={store}>
      <AppRouter history={history}>
        <Loader />
        <Router />
        <ToastContainer />
        <ReactQueryDevtools />
      </AppRouter>
    </Provider>
  );
}

export default App;
