import React from 'react';

const Completed = () => {
  const toHome = () => {
    window.location.href = '/';
  };
  return (
    <div className="flex flex-col rounded-default p-20 bg-white">
      <header className="flex justify-center mb-20">
        <div className="w-3/5">
          <h1 className="text-3xl font-bold text-gray-500 text-center">
            Completed
          </h1>
          <p className="text-sm text-gray-400 text-center whitespace-nowrap">
            The offer is created and sent to the customer. Well done!
          </p>
        </div>
      </header>
      <main className="flex justify-center my-20">
        <button
          onClick={toHome}
          className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-50 outline-none transition duration-500"
        >
          To home page
        </button>
      </main>
    </div>
  );
};

export default Completed;
