import { contactsActionTypes } from '../constants';

const initialState = {
  contactsList: [],
  contact: null,
  searchResultContactsList: [],
  tagsList: [],
  areContactsLoading: false,
  areContactsLoaded: false,
  error: null,
};

export const contacts = (state = initialState, action) => {
  switch (action.type) {
    case contactsActionTypes.CONTACTS_GET_ALL_CONTACTS_REQUEST:
      return {
        ...state,
        areContactsLoading: true,
        areContactsLoaded: false,
      };
    case contactsActionTypes.CONTACTS_GET_ALL_CONTACTS_SUCCESS:
      return {
        ...state,
        areContactsLoading: false,
        areContactsLoaded: true,
        contactsList: action.payload.list.data,
      };
    case contactsActionTypes.CONTACTS_GET_ALL_CONTACTS_FAILURE:
      return {
        ...state,
        areContactsLoading: false,
        areContactsLoaded: false,
        error: action.payload.message,
      };
    case contactsActionTypes.CONTACTS_GET_CONTACT_REQUEST:
      return {
        ...state,
        areContactsLoading: true,
        areContactsLoaded: false,
      };
    case contactsActionTypes.CONTACTS_GET_CONTACT_SUCCESS:
      return {
        ...state,
        areContactsLoading: false,
        areContactsLoaded: true,
        contact: action.payload.contact,
      };
    case contactsActionTypes.CONTACTS_GET_CONTACT_FAILURE:
      return {
        ...state,
        areContactsLoading: false,
        areContactsLoaded: false,
      };
    case contactsActionTypes.CONTACTS_DELETE_CONTACT_REQUEST:
      return state;
    case contactsActionTypes.CONTACTS_DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contactsList: state.contactsList.filter(
          (item) => item.id !== action.payload.deletedContactId
        ),
      };
    case contactsActionTypes.CONTACTS_DELETE_CONTACT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case contactsActionTypes.CONTACTS_EDIT_CONTACT_SUCCESS:
      return {
        ...state,
        contactsList: state.contactsList.map((contact) => {
          if (contact.id !== action.payload.contact.id) return contact;
          return action.payload.contact;
        }),
      };
    case contactsActionTypes.CONTACTS_UPDATE_IMAGE_SUCCESS:
      return {
        ...state,
        contactsList: state.contactsList.map((contact) => {
          if (contact.id !== action.payload.contact.id) return contact;
          return action.payload.contact;
        }),
      };
    case contactsActionTypes.CONTACTS_UPDATE_IMAGE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };

    case contactsActionTypes.CONTACTS_CLEAR_STATE:
      return initialState;

    case contactsActionTypes.CONTACTS_SEARCH_CONTACTS_REQUEST:
      return state;
    case contactsActionTypes.CONTACTS_SEARCH_CONTACTS_SUCCESS:
      return {
        ...state,
        searchResultContactsList: action.payload.searchedContacts,
      };
    case contactsActionTypes.CONTACTS_SEARCH_CONTACTS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case contactsActionTypes.CONTACTS_GET_ALL_TAGS_REQUEST:
      return state;
    case contactsActionTypes.CONTACTS_GET_ALL_TAGS_SUCCESS:
      return {
        ...state,
        tagsList: action.payload.list,
      };
    case contactsActionTypes.CONTACTS_GET_ALL_TAGS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
