import React from 'react';
import { useHistory } from 'react-router';
import { routes } from '../../common/constants';
import ResetPasswordForm from '../../components/Auth/ResetPasswordForm';
import Modal from '../../components/Modal';
import styles from './Login.module.scss';

const ResetPassword = () => {
  const history = useHistory();

  const handleRedirectLogin = () => {
    history.push(routes.LOGIN.path);
  };

  return (
    <section className={`${styles.login}`}>
      <Modal
        isOpen={true}
        headerTitle="CRM Soul Group"
        className="modal--password"
        closeContent={
          <span className="text-primary font-bold underline pt-5">Sign in</span>
        }
        onRequestClose={handleRedirectLogin}
      >
        <h2 className={`w-full text-2x font-bold text-black mt-50 mb-20`}>
          Reset Password
        </h2>
        <p className={`w-full text-sm text-gray-400 mb-40`}>
          Enter your new password
        </p>
        <ResetPasswordForm />
        <hr className="border-gray-100 my-20 sm:hidden md:block" />
        <p className={`w-full text-xs text-center text-gray-400 mb-0`}>
          © 2021 CRM Soul Group. All rights reserved.
        </p>
      </Modal>
    </section>
  );
};

export default ResetPassword;
