import React from 'react';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-icon.svg';

export default function Header({ children, onRequestClose, closeContent }) {
  return (
    <div className="flex justify-between  pt-40">
      {/*items-center*/}
      <h1 className="text-md text-gray-500 pb-30 ">{children}</h1>
      <button
        className="appearance-none flex modal-close outline-none"
        onClick={onRequestClose}
      >
        {closeContent ? (
          closeContent
        ) : (
          <CloseIcon fill="gray" className="flex"></CloseIcon>
        )}
      </button>
    </div>
  );
}
