import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../Modal';
import ButtonBack from '../../ButtonBack/ButtonBack';
import { toast } from 'react-toastify';
import { notesActions } from '../../../common/actions/notesActions';
import { contactActions } from '../../../common/actions/contactsActions';
import NoteForm from './NoteForm';

const CreateNoteModal = ({
  setIsNoteFormOpened,
  isNoteFormOpened,
  contact,
}) => {
  const dispatch = useDispatch();
  const areContactsLoaded = useSelector(
    (state) => state.contacts.areContactsLoaded
  );

  useEffect(() => {
    if (!areContactsLoaded) {
      dispatch(contactActions.getAllContacts());
    }
  }, [areContactsLoaded]);

  const handleCreateNote = (data) => {
    dispatch(
      notesActions.addNote(
        { ...data, contacts_id: contact ? contact.id : null },
        setIsNoteFormOpened
      )
    );
  };

  return (
    <Modal
      isOpen={isNoteFormOpened}
      onRequestClose={() => setIsNoteFormOpened(false)}
      headerTitle=""
      className="modal--password"
      style={{
        position: 'relative',
      }}
    >
      <ButtonBack variant="small" className="mb-15" />

      <h2 className={`text-2xl font-bold text-black`}>Add new Note </h2>
      <NoteForm contact={contact} onSubmit={handleCreateNote} />
    </Modal>
  );
};

export { CreateNoteModal };
