import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContactRow from './ContactRow';
import { ReactComponent as SvgAdd } from '../../../assets/icons/icon-add.svg';
import ArrowDownAZ from '../../../assets/icons/sort-az-down.svg';
import ArrowUpAZ from '../../../assets/icons/sort-za-down.svg';
import ArrowUp from '../../../assets/icons/sort-up.svg';
import ArrowDown from '../../../assets/icons/sort-down.svg';
import ArrowDownAZActive from '../../../assets/icons/sort-az-down-active.svg';
import ArrowUpAZActive from '../../../assets/icons/sort-za-down-active.svg';
import ArrowUpActive from '../../../assets/icons/sort-up-active.svg';
import ArrowDownActive from '../../../assets/icons/sort-down-active.svg';
import Button from '../../../components/Button';
import SearchInput from '../../../components/Form/components/SearchInput';
import { contactActions } from '../../../common/actions';
import ServerlessPagination from '../../../components/commons/ServerlessPagination/ServerlessPagination';
import { Box } from '@material-ui/core';

const ContactsContent = () => {
  const dispatch = useDispatch();
  const contactsList = useSelector((state) => state.contacts.contactsList);
  console.log(contactsList, 'mounta li se?');
  const [searchContactList, setSearchContactList] = useState('');
  const searchResults = useSelector(
    (state) => state.contacts.searchResultContactsList
  );
  const searching = searchContactList && searchContactList.length > 0;
  const [currentPage, setCurrentPage] = useState(0);
  const [sortBy, setSortBy] = useState('CreatedAt');
  const totalRows =
    searching && searchResults && searchResults.length
      ? searchResults.length
      : contactsList && contactsList.length && contactsList.length;
  const lastPage = Math.ceil(totalRows / 10);

  const renderContacts = () => {
    return searching
      ? searchResults
      : contactsList && contactsList.length > 0
      ? contactsList
      : [];
  };
  const handleSort = (item) => {
    if (sortBy === 'User' && item === 'User') {
      setSortBy('UserReversed');
    } else if (sortBy === 'Company' && item === 'Company') {
      setSortBy('CompanyReversed');
    } else if (sortBy === 'CreatedAt' && item === 'CreatedAt') {
      setSortBy('CreatedAtReversed');
    } else {
      setSortBy(item);
    }
  };
  useEffect(() => {
    if (searchContactList && searchContactList.length > 0) {
      dispatch(contactActions.searchContacts(searchContactList));
    }

    if (currentPage !== 0) setCurrentPage(0);
  }, [searchContactList]);

  console.log('setSearchContacts', searchContactList);
  console.log('ovo su rezultati', searchResults);
  return (
    <>
      <div className="flex">
        <h2 className="text-3xl font-bold  mr-20 text-black  border-l-0 border-t-0 border-b-0 pr-20 border-1 border-gray-400">
          Contact list
        </h2>
        <SearchInput
          name="search"
          type="text"
          //errors={errors}
          labelText="search"
          placeholder="Search contacts..."
          defaultValue={searchContactList}
          onChange={(e) => {
            setSearchContactList(e.target.value);
          }}
        />
        <div className="flex justify-end relative z-9 ml-auto ">
          <Button
            variant="link"
            height="44"
            width="220"
            className="text-sm "
            target="/add-contact"
          >
            <SvgAdd className="mr-20 text-white" />
            Add New Contact
          </Button>
        </div>
      </div>
      <div className="flex flex-col mt-50">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-20 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      style={{ cursor: 'pointer', userSelect: 'none' }}
                      onClick={() => handleSort('User')}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ gap: 10 }}
                      >
                        Full Name{' '}
                        <img
                          src={
                            sortBy == 'UserReversed'
                              ? ArrowUpAZActive
                              : sortBy == 'User'
                              ? ArrowDownAZActive
                              : ArrowDownAZ
                          }
                        />
                      </Box>
                    </th>
                    <th
                      scope="col"
                      className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      style={{ cursor: 'pointer', userSelect: 'none' }}
                      onClick={() => handleSort('Company')}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ gap: 10 }}
                      >
                        Company{' '}
                        <img
                          src={
                            sortBy == 'CompanyReversed'
                              ? ArrowUpAZActive
                              : sortBy == 'Company'
                              ? ArrowDownAZActive
                              : ArrowDownAZ
                          }
                        />
                      </Box>
                    </th>
                    <th
                      scope="col"
                      className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      style={{ cursor: 'pointer', userSelect: 'none' }}
                      onClick={() => handleSort('CreatedAt')}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ gap: 5 }}
                      >
                        Created at{' '}
                        <img
                          style={{ height: 14 }}
                          src={
                            sortBy == 'CreatedAtReversed'
                              ? ArrowUpActive
                              : sortBy == 'CreatedAt'
                              ? ArrowDownActive
                              : ArrowDown
                          }
                        />
                      </Box>
                    </th>
                    <th
                      scope="col"
                      className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Phone
                    </th>
                    <th
                      scope="col"
                      className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Socials
                    </th>
                    <th
                      scope="col"
                      className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {renderContacts()
                    .sort((a, b) =>
                      sortBy == 'User'
                        ? a.first_name.localeCompare(b.first_name)
                        : sortBy == 'UserReversed'
                        ? b.first_name.localeCompare(a.first_name)
                        : sortBy == 'Company'
                        ? a.company.localeCompare(b.company)
                        : sortBy == 'CompanyReversed'
                        ? b.company.localeCompare(a.company)
                        : sortBy == 'CreatedAt'
                        ? new Date(a.created_at) - new Date(b.created_at)
                        : sortBy == 'CreatedAtReversed'
                        ? new Date(b.created_at) - new Date(a.created_at)
                        : null
                    )
                    .slice(currentPage * 10, (currentPage + 1) * 10)
                    .map((contact, index) => {
                      return <ContactRow contact={contact} key={index} />;
                    })}
                </tbody>
              </table>
              <ServerlessPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                lastPage={lastPage}
                total={totalRows}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ContactsContent };
