export const sortMailCampaignsByDate = (mailCampaigns) => {
  return mailCampaigns.sort((a, b) => {
    var c = new Date(a.created_at);
    var d = new Date(b.created_at);
    return c - d;
  });
};

export const prepareMailCampaingPostModel = (mailCampaignData) => {
  console.log('mailCampaignData', mailCampaignData);
  return {
    name: mailCampaignData.name,
    from: mailCampaignData.from ? mailCampaignData.from : 'info@telefoni.ba',
    subject: mailCampaignData.subject,
    message: mailCampaignData.message,
    design: mailCampaignData.design,
    sent: null,
    audience_list: mailCampaignData.audiences.map((audience) => {
      return {
        audience_id: audience.id,
      };
    }),
    time_scheduled_at: mailCampaignData.scheduledTime,
    created_by: 2,
    inactivity_alert_days: mailCampaignData.inactivityDays,
  };
};

export const prepareMailCampaingPutModel = (mailCampaignData) => {
  return {
    name: mailCampaignData.name,
    from: mailCampaignData.from ? mailCampaignData.from : 'info@telefoni.ba',
    subject: mailCampaignData.subject,
    message: mailCampaignData.message,
    design: mailCampaignData.design,
    sent: mailCampaignData.sent,
    time_sent_at: mailCampaignData.time_sent_at,
    // audience_list: mailCampaignData.audience_list.map((audience) => {
    //   return {
    //     audience_id: audience.id,
    //   };
    // }),
    // time_scheduled_at: mailCampaignData.scheduledTime,
    created_by: 2,
  };
};

export const prepareMailCampaingAutomationPostModel = (data) => {
  return {
    name: data.name,
    email: data.from ? data.from : 'info@telefoni.ba',
    to: data.to,
    type: data.type,
    frequency: data.frequency,
    send_time: data.send_time,
    email_template: data.email_template,
    subject: data.subject,
    title: data.title,
    campaign_id: data.campaign_id,
  };
};
