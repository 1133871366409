import React, { useState } from 'react';

const CountInput = ({
  register,
  errors,
  name,
  placeholder,
  labelText = name,
  touched = false,
  value,
  className,
  inputValue,
  setInputValue,
  ...inputProps
}) => {
  const [isHidden, setIsHidden] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const toggleIsHidden = () => setIsHidden(!isHidden);
  return (
    <div
      className={`${className} mb-25 flex flex-col
			
				${errors && errors[name] ? 'isInvalid' : ''} 
			`}
    >
      <label className={`block text-xs text-primary mb-5`} htmlFor={name}>
        {labelText}
      </label>
      <div
        className={` flex w-full focus:shadow-outline h-50 relative placeholder-secondary flex-wrap `}
      >
        <button
          onClick={() => setInputValue(inputValue + 1)}
          className="bg-gray-300 rounded-sm absolute right-0 outline-none  rounded-b-none"
          style={{ top: 0, width: '25px', height: '25px' }}
        >
          +
        </button>

        <input
          className={`flex items-center w-full h-full text-xs px-20 h-full rounded-default ${
            errors && name && errors[name]
              ? 'border-1 border-red bg-red bg-opacity-8'
              : 'border-1 border-gray-200 bg-white'
          }  outline-none ${isFocused ? 'border-1 border-primary' : ''} `}
          id={name}
          step="1"
          type="number"
          name={name}
          placeholder={placeholder}
          ref={register}
          value={inputValue}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          {...inputProps}
        />
        <button
          onClick={() => setInputValue(inputValue - 1)}
          className="bg-gray-300 rounded-sm absolute right-0 outline-none rounded-t-none"
          style={{ top: '25px', width: '25px', height: '25px' }}
        >
          -
        </button>
      </div>
      {errors && name && errors[name] && (
        <p
          data-testid={`${name}-input-error`}
          className="text-xs mt-5 text-red text-right"
        >
          {errors[name].message}
        </p>
      )}
    </div>
  );
};
export default CountInput;
