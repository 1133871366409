import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ButtonBack from '../../components/ButtonBack/ButtonBack';
import LayoutComponent from '../../components/commons/LayoutComponent';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import EditCampaignForm from './EditCampaignForm';
import CampaignsStats from '../Campaigns/components/CampaignStats';
import { LineGraph } from '../../components/Visualization/line';
import { BarGraph } from '../../components/Visualization/bar';

export default function EditCampaign(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const editMailCampaign = useSelector(
    (state) => state.mailCampaigns.editCampaign
  );

  let campaingEdit = null;
  if (location && location.state) {
    campaingEdit = location.state.editCampaign;
  }
  const [editableMailCampaign, setEditableMailCampaign] = useState(
    campaingEdit
  );

  useEffect(() => {
    if (editMailCampaign) {
    }
  }, [editMailCampaign]);

  return (
    <LayoutComponent title="EditCampaign">
      <ButtonBack className="justify-end -mt-50" />
      <div className="flex flex-col mt-50">
        <EditCampaignForm
          className="mt-50"
          editableMailCampaign={editableMailCampaign}
        />
      </div>
    </LayoutComponent>
  );
}
