import React from 'react';
import ModalHeaderActions from 'src/components/Misc/ModalHeaderActions';
import Modal from 'src/components/Modal';
import UserForm from './UserForm';
import { userService } from 'src/common/services';
import { toast } from 'react-toastify';

export default function UserModal({ open, onClose }) {
  const handleSubmit = async (data = null) => {
    return userService
      .createUser(data)
      .then(() => {
        toast.success('User created', { className: 'text-white' });
        onClose();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <Modal isOpen={open} className="modal--notepopup">
      <ModalHeaderActions name="Add user" onClose={onClose} />
      <UserForm onSubmit={handleSubmit} />
    </Modal>
  );
}
