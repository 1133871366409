import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import FieldTextarea from '../../../../components/Form/components/FieldTextarea';
import { ReactComponent as SvgSend } from '../../../../assets/icons/icon-send.svg';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from 'src/common/actions';
import Loader from 'src/components/Loader';
import { FaFile } from 'react-icons/fa';
import { FileIcon } from 'react-file-icon';

const ChatInput = () => {
  const [sendingMessage, setSendingMessage] = useState(false);
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const fileRef = useRef(null);

  const conversationId = useSelector(
    (state) => state.chat.conversation.conversationData.id
  );

  const { register, watch, errors, handleSubmit: onSubmit, reset } = useForm({
    mode: 'onChange',
  });

  const message = watch('message');
  const messageTrimmed = String(message || '').trim();

  const buttonDisabled = sendingMessage || !messageTrimmed;

  const handleSuccess = () => {
    setSendingMessage(false);

    reset();
  };

  const handleFail = () => {
    setSendingMessage(false);

    alert('failed to send message');
  };

  const handleCommentSubmit = (values) => {
    setSendingMessage(true);

    dispatch(
      chatActions.sendMessage(
        { conversationId, ...values, formRef, fileRef },
        handleSuccess,
        handleFail
      )
    );
  };

  return (
    <form
      noValidate
      onSubmit={onSubmit(handleCommentSubmit)}
      className="flex w-full items-center border-t-1 border-gray-400 mt-20"
      encType="multipart/form-data"
      ref={formRef}
    >
      <FieldTextarea
        register={register}
        name="message"
        defaultValue=""
        type="text"
        errors={errors}
        labelText=""
        placeholder="Type a message here..."
        value={message}
        className={`w-full`}
        disabled={sendingMessage}
      />

      <input
        type="file"
        name="file"
        ref={fileRef}
        style={{ position: 'absolute', pointerEvents: 'none', left: -99999 }}
      />

      <div className="relative">
        <button
          className={`flex items-center justify-center px-10 bg-primary rounded relative w-50 h-50 rounded-full outline-none mr-20 ${
            sendingMessage ? ' cursor-not-allowed' : ''
          }`}
          disabled={sendingMessage}
          type="button"
          onClick={() => {
            fileRef.current.click();
          }}
        >
          {fileRef.current && fileRef.current.value ? (
            <FileIcon extension={fileRef.current.value.split('.').pop()} />
          ) : (
            <FaFile fill="#fff" />
          )}
        </button>
      </div>
      <div className="relative">
        <button
          className={`flex items-center justify-center px-10 bg-primary rounded relative w-50 h-50 rounded-full outline-none mr-20 ${
            buttonDisabled ? ' cursor-not-allowed' : ''
          }`}
          disabled={buttonDisabled}
          type="submit"
        >
          {sendingMessage ? <Loader /> : <SvgSend />}
        </button>
      </div>
    </form>
  );
};
export default ChatInput;
