import React, { useState } from 'react';
import Grid from '../../../components/Tailwind/Grid/Grid';
import BlockWithTitle from '../../../components/Misc/BlockWithTitle';
import { GoCheck, GoX } from 'react-icons/go';
import Loader from '../../../components/Loader';
import { calendarService } from '../../../common/services';
import { formatFullTimeToHHMM } from 'src/common/helpers';

const CalendarItemDetails = ({ calendarItem, onUpdate }) => {
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingDecline, setLoadingDecline] = useState(false);

  const handleAccept = () => {
    setLoadingAccept(true);

    calendarService
      .acceptInvitation(calendarItem.id)
      .then(() => {
        onUpdate(false).then(() => {
          setLoadingAccept(false);
        });
      })
      .catch(() => {
        setLoadingAccept(false);
      });
  };

  const handleDecline = () => {
    setLoadingDecline(true);

    calendarService
      .declineInvitation(calendarItem.id)
      .then(() => {
        onUpdate(false).then(() => {
          setLoadingDecline(false);
        });
      })
      .catch(() => {
        setLoadingDecline(false);
      });
  };

  const actionButtonsDisabled = loadingAccept || loadingDecline;

  return (
    <>
      <BlockWithTitle
        title="Type"
        content={[
          <span key={0} style={{ color: calendarItem.type.color }}>
            {calendarItem.type.label}
          </span>,
        ]}
      />
      <BlockWithTitle title="Date" content={calendarItem.periodDate} />
      <BlockWithTitle
        title="Time"
        content={
          calendarItem.start_time && calendarItem.end_time
            ? `${formatFullTimeToHHMM(
                calendarItem.start_time
              )} - ${formatFullTimeToHHMM(calendarItem.end_time)}`
            : 'All day'
        }
      />
      <BlockWithTitle
        title="Description"
        content={String(calendarItem.description || '')
          .split('\n')
          .filter(Boolean)
          .map((val, key) => (
            <p key={key} className="mb-5">
              {val}
            </p>
          ))}
      />

      <BlockWithTitle
        title="Attendees"
        content={calendarItem.attendees.map((item) => (
          <div key={item.id}>
            <strong>{item.fullName}</strong>
            {item.isInternal ? ` (${item.invitationStatus})` : ''}
          </div>
        ))}
      />

      {Boolean(calendarItem.me) && (
        <>
          <br />

          <Grid cols={2}>
            <div>
              {Boolean(
                calendarItem.url && calendarItem.me.status === 'accepted'
              ) && (
                <a
                  href={calendarItem.url}
                  rel="noreferrer"
                  target="_blank"
                  className="bg-primary hover:bg-secondary text-white p-15 px-20 text-sm rounded-default outline-none flex justify-center items-center h-50"
                >
                  Join meeting
                </a>
              )}
            </div>
            <div>
              <Grid cols={2}>
                <button
                  onClick={handleAccept}
                  className="bg-green-400 hover:bg-green-600 text-white p-15 px-20 text-sm rounded-default outline-none inline-flex justify-center items-center h-50"
                  disabled={actionButtonsDisabled}
                >
                  {loadingAccept ? (
                    <Loader />
                  ) : (
                    <>
                      <GoCheck /> &nbsp; Accept
                    </>
                  )}
                </button>
                <button
                  onClick={handleDecline}
                  disabled={actionButtonsDisabled}
                  className="bg-red hover:bg-darkRed text-white p-15 px-20 text-sm rounded-default outline-none inline-flex justify-center items-center h-50"
                >
                  {loadingDecline ? (
                    <Loader />
                  ) : (
                    <>
                      <GoX /> &nbsp; Decline
                    </>
                  )}
                </button>
              </Grid>
            </div>
          </Grid>
        </>
      )}
    </>
  );
};

export default CalendarItemDetails;
