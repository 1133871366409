import React from 'react';
import { Button, Grid, Box, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const DeleteDeal = (props) => {
  const { name, handleClose, handleConfirm, openDelete } = props;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box
            fontWeight="fontWeightBold"
            fontSize="h5.fontSize"
            align="center"
            mt={2}
          >
            Are you sure?
          </Box>
          <InputLabel
            align="center"
            style={{
              marginTop: 8,
              fontSize: 14,
            }}
          >
            {openDelete
              ? 'Are you sure that you want to delete this comment'
              : `Are you sure that you want to archive deal ${name}`}
          </InputLabel>
        </Grid>

        <Grid
          item
          container
          xs={12}
          direction="column"
          style={{
            marginTop: 25,
          }}
        >
          <Grid item>
            <Box display="flex" justifyContent="center">
              <Box p={1}>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  size="large"
                  color="secondary"
                  style={{
                    backgroundColor: 'white',
                    marginRight: '10px',
                    color: '#FF5F52',
                    border: '1px solid #FF5F52',
                    boxShadow: 'none',
                  }}
                >
                  Cancel
                </Button>
              </Box>
              <Box p={1}>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={handleConfirm}
                  style={{
                    boxShadow: 'none',
                  }}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
