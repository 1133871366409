import { contactService } from '../services';
import {
  contactsActionTypes,
  contactActionTypes,
  routes,
  contactsToastMessages,
  contactToastMessages,
} from '../constants';
import { actionCreator, history } from '../helpers';
import { toast } from 'react-toastify';

const getAllContacts = () => (dispatch) => {
  dispatch(
    actionCreator(contactsActionTypes.CONTACTS_GET_ALL_CONTACTS_REQUEST)
  );
  contactService
    .getAllContacts()
    .then((res) => {
      dispatch(
        actionCreator(contactsActionTypes.CONTACTS_GET_ALL_CONTACTS_SUCCESS, {
          list: res,
        })
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(contactsActionTypes.CONTACTS_GET_ALL_CONTACTS_FAILURE, {
          error: err,
        })
      );
    });
};
const getAllTags = () => (dispatch) => {
  dispatch(actionCreator(contactsActionTypes.CONTACTS_GET_ALL_TAGS_REQUEST));
  contactService
    .getAllTags()
    .then((res) => {
      dispatch(
        actionCreator(contactsActionTypes.CONTACTS_GET_ALL_TAGS_SUCCESS, {
          list: res,
        })
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(contactsActionTypes.CONTACTS_GET_ALL_TAGS_FAILURE, {
          error: err,
        })
      );
    });
};
const getContact = (contactId) => (dispatch) => {
  dispatch(actionCreator(contactActionTypes.CONTACT_GET_CONTACT_REQUEST));
  contactService
    .getContact(contactId)
    .then((res) => {
      dispatch(
        actionCreator(contactActionTypes.CONTACT_GET_CONTACT_SUCCESS, {
          contact: res,
        })
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(contactActionTypes.CONTACT_GET_CONTACT_FAILURE, {
          error: err,
        })
      );
    });
};

const deleteContact = (contactId) => (dispatch) => {
  dispatch(actionCreator(contactsActionTypes.CONTACTS_DELETE_CONTACT_REQUEST));
  contactService
    .deleteContact(contactId)
    .then((res) => {
      toast(
        contactsToastMessages[
          contactsActionTypes.CONTACTS_DELETE_CONTACT_SUCCESS
        ].message
      );
      dispatch(
        actionCreator(contactsActionTypes.CONTACTS_DELETE_CONTACT_SUCCESS, {
          deletedContactId: contactId,
        })
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(contactsActionTypes.CONTACTS_DELETE_CONTACT_FAILURE, {
          error: err,
        })
      );
    });
};

const updateContactInSidebar = (contactId, contactData, setIsSidebarOpened) => (
  dispatch
) => {
  dispatch(actionCreator(contactsActionTypes.CONTACTS_EDIT_CONTACT_REQUEST));
  contactService
    .updateContact(contactId, contactData)
    .then((res) => {
      toast(
        contactsToastMessages[contactsActionTypes.CONTACTS_EDIT_CONTACT_SUCCESS]
          .message
      );
      if (typeof setIsSidebarOpened === 'function') {
        setIsSidebarOpened(false);
      }
      dispatch(
        actionCreator(contactsActionTypes.CONTACTS_EDIT_CONTACT_SUCCESS, {
          contact: res,
        })
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(contactsActionTypes.CONTACTS_EDIT_CONTACT_FAILURE, {
          error: err,
        })
      );
    });
};

const updateContact = (contactId, contactData) => (dispatch) => {
  dispatch(actionCreator(contactActionTypes.CONTACT_EDIT_CONTACT_REQUEST));
  contactService
    .updateContact(contactId, contactData)
    .then((res) => {
      toast.success(
        contactsToastMessages[contactsActionTypes.CONTACTS_EDIT_CONTACT_SUCCESS]
          .message
      );
      dispatch(
        actionCreator(contactActionTypes.CONTACT_EDIT_CONTACT_SUCCESS, {
          contact: res,
        })
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(contactActionTypes.CONTACT_EDIT_CONTACT_FAILURE, {
          error: err,
        })
      );
      toast.error(
        contactsToastMessages[contactsActionTypes.CONTACTS_EDIT_CONTACT_FAILURE]
          .message
      );
    });
};

const createNewContact = (contactData, history) => (dispatch) => {
  dispatch(actionCreator(contactsActionTypes.CONTACTS_NEW_CONTACT_REQUEST));
  contactService
    .createNewContact(contactData)
    .then((res) => {
      toast(
        contactsToastMessages[contactsActionTypes.CONTACTS_NEW_CONTACT_SUCCESS]
          .message
      );
      dispatch(actionCreator(contactsActionTypes.CONTACTS_NEW_CONTACT_SUCCESS));
      history.push(routes.CONTACTS.path);
    })
    .catch((err) => {
      dispatch(
        actionCreator(contactsActionTypes.CONTACTS_NEW_CONTACT_FAILURE, {
          error: err,
        })
      );
      toast.error(err);
    });
};

const updateContactImageInSidebar = (id, image) => (dispatch) => {
  dispatch(actionCreator(contactsActionTypes.CONTACTS_UPDATE_IMAGE_REQUEST));
  contactService
    .updateContactImage(id, image)
    .then((res) => {
      toast(
        contactsToastMessages[contactsActionTypes.CONTACTS_UPDATE_IMAGE_SUCCESS]
          .message
      );
      dispatch(
        actionCreator(contactsActionTypes.CONTACTS_UPDATE_IMAGE_SUCCESS, {
          contact: res,
        })
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(contactsActionTypes.CONTACTS_UPDATE_IMAGE_FAILURE, {
          error: err,
        })
      );
    });
};

const updateContactImage = (id, image) => (dispatch) => {
  dispatch(
    actionCreator(contactActionTypes.CONTACT_UPDATE_CONTACT_IMAGE_REQUEST)
  );
  contactService
    .updateContactImage(id, image)
    .then((res) => {
      // TODO INSERT TOAST MESSAGE HERE
      dispatch(
        actionCreator(contactActionTypes.CONTACT_UPDATE_CONTACT_IMAGE_SUCCESS, {
          contact: res,
        })
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(contactActionTypes.CONTACT_UPDATE_CONTACT_IMAGE_FAILURE, {
          error: err,
        })
      );
    });
};

const searchContacts = (query) => (dispatch) => {
  dispatch(actionCreator(contactsActionTypes.CONTACTS_SEARCH_CONTACTS_REQUEST));
  if (query && query.length > 0) {
    contactService
      .searchContacts(query)
      .then((res) => {
        dispatch(
          actionCreator(contactsActionTypes.CONTACTS_SEARCH_CONTACTS_SUCCESS, {
            searchedContacts: res,
          })
        );
      })
      .catch((err) => {
        dispatch(
          actionCreator(contactsActionTypes.CONTACTS_SEARCH_CONTACTS_FAILURE, {
            error: err,
          })
        );
      });
  } else {
    dispatch(
      actionCreator(contactsActionTypes.CONTACTS_SEARCH_CONTACTS_SUCCESS, {
        searchedContacts: [],
      })
    );
  }
};

const clearContactsState = () => (dispatch) => {
  dispatch(actionCreator(contactsActionTypes.CONTACTS_CLEAR_STATE));
};

const clearContactState = () => (dispatch) => {
  dispatch(actionCreator(contactActionTypes.CONTACT_CLEAR_STATE));
};

export const contactActions = {
  getAllContacts,
  deleteContact,
  createNewContact,
  updateContactInSidebar,
  updateContact,
  updateContactImageInSidebar,
  updateContactImage,
  searchContacts,
  getAllTags,
  getContact,
  clearContactState,
  clearContactsState,
};
