import { API_ENDPOINT } from '../../utils/endpoints';
import { handleResponse, openFetch, authFetch, client } from '../helpers';

const getAllDeals = () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(`${API_ENDPOINT}/deals`, requestOptions).then(
    handleResponse
  );
};
const getAllDealComments = (dealId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(
    `${API_ENDPOINT}/deal-comments?deal_id${dealId}`,
    requestOptions
  ).then(handleResponse);
};

export const dealsService = {
  getAllDeals,
  getAllDealComments,
};
