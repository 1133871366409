import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './pages/Login';
import AddAcount from './pages/AddAccount';
import { Contacts } from './pages/Contacts';
import AddContact from './pages/AddContact/AddContact';
import Stats from './pages/Stats/Stats';
import AddCampaign from './pages/AddCampaign';
import { routes } from './common/constants';
import { PrivateRoute } from './components/Routes';
import Campaigns from './pages/Campaigns/Campaigns';
import EditCampaign from './pages/EditCampaign';
import { Contact } from './pages/Contact';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Notes from './pages/Notes/Notes';
import { Profile } from './pages/Profile';
import StatisticsCampaign from './pages/Stats/components/StatisticsCampaign';
import Calendar from './pages/Calendar';
import { Chat } from './pages/Chat';
import NotificationList from './pages/NotificationList';
import Users from './pages/Admin/Users';
import Companies from './pages/Admin/Companies';
import Settings from './pages/Settings';
import Offers from './pages/Offers';
import OfferReport from './pages/Reports/ReportOffer';
import Products from './pages/Products';
import Product from './pages/Product';
import AddProduct from './pages/AddProduct';
import NewOffer from './pages/NewOffer';
import Outreach from './pages/Outreach/Outreach';
import { Dashboard } from './pages/Dashboard/pages/Dashboard';
import Search from './pages/Search/Search';

export const Router = () => {
  return (
    <Switch>
      <PrivateRoute exact path={routes.HOME.path} component={Dashboard} />
      <PrivateRoute exact path={routes.DASHBOARD.path} component={Dashboard} />
      <PrivateRoute exact path={routes.CALENDAR.path}>
        <Calendar />
      </PrivateRoute>
      <PrivateRoute exact path={routes.OUTREACH.path} component={Outreach} />
      <PrivateRoute exact path={routes.MAIL_CAMPAIGNS.path}>
        <Campaigns />
      </PrivateRoute>
      <PrivateRoute exact path={routes.ADD_CAMPAIGN.path}>
        <AddCampaign />
      </PrivateRoute>
      <PrivateRoute exact path={routes.EDIT_CAMPAIGN.path}>
        <EditCampaign />
      </PrivateRoute>
      <PrivateRoute exact path={routes.STATISTICS_CAMPAIGN.path}>
        <StatisticsCampaign />
      </PrivateRoute>
      <PrivateRoute exact path={routes.STATS.path} component={Stats} />
      <PrivateRoute exact path={routes.CONTACTS.path} component={Contacts} />
      <PrivateRoute exact path={routes.ADD_CONTACT.path}>
        <AddContact />
      </PrivateRoute>
      <PrivateRoute exact path={routes.CONTACT.path} component={Contact} />
      <PrivateRoute exact path={routes.NOTES.path} component={Notes} />
      <PrivateRoute exact path={routes.SEARCH.path} component={Search} />
      <Route exact path={routes.LOGIN.path}>
        <Login />
      </Route>
      <Route exact path={routes.FORGOT_PASSWORD.path}>
        <ForgotPassword />
      </Route>
      <Route exact path={routes.RESET_PASSWORD.path}>
        <ResetPassword />
      </Route>
      <PrivateRoute exact path={routes.ADD_ACCOUNT.path}>
        <AddAcount />
      </PrivateRoute>
      <PrivateRoute exact path={routes.PROFILE.path}>
        <Profile />
      </PrivateRoute>
      <PrivateRoute exact path={routes.CHAT.path}>
        <Chat />
      </PrivateRoute>
      <PrivateRoute exact path={routes.NOTIFICATIONS.path}>
        <NotificationList />
      </PrivateRoute>
      <PrivateRoute exact path={routes.USERS.path}>
        <Users />
      </PrivateRoute>
      <PrivateRoute exact path={routes.COMPANIES.path}>
        <Companies />
      </PrivateRoute>
      <PrivateRoute exact path={routes.SETTINGS.path}>
        <Settings />
      </PrivateRoute>
      <PrivateRoute exact path={routes.OFFERS.path}>
        <Offers />
      </PrivateRoute>
      <PrivateRoute exact path={routes.OFFER.path}>
        <OfferReport />
      </PrivateRoute>
      <PrivateRoute exact path={routes.PRODUCTS.path}>
        <Products />
      </PrivateRoute>
      <PrivateRoute exact path={routes.PRODUCT.path}>
        <Product />
      </PrivateRoute>
      <PrivateRoute exact path={routes.ADDPRODUCT.path}>
        <AddProduct />
      </PrivateRoute>
      <PrivateRoute exact path={routes.NEWOFFER.path}>
        <NewOffer />
      </PrivateRoute>
    </Switch>
  );
};
