import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { notificationActions } from '../../../common/actions/notificationActions';
import { routes } from '../../../common/constants';
import { useNotificationData } from '../../../hooks/useNotification';

const Notifications = ({ onClose }) => {
  const history = useHistory();
  const notifications = useSelector(
    (state) => state.notification.notifications
  );

  const handleAll = () => {
    onClose();

    history.push({ pathname: routes.NOTIFICATIONS.path });
  };

  return (
    <>
      {notifications.slice(0, 3).map((notification) => (
        <NotificationItem
          id={notification.id}
          item={notification.data}
          read={notification.read}
          key={notification.id}
          onClose={onClose}
        />
      ))}
      <div
        className="block px-20 py-10 text-sm text-gray-700 bg-gray-100 hover:bg-gray-200 cursor-pointer"
        onClick={handleAll}
      >
        view all...
      </div>
    </>
  );
};

const NotificationItem = ({ id, item, onClose, read }) => {
  const dispatch = useDispatch();
  const [content, action] = useNotificationData(item);

  const handleAction = () => {
    onClose();
    if (!read) dispatch(notificationActions.readNotification(id, action));
    else action();
  };

  return (
    <div
      className="block px-20 py-10 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
      onClick={handleAction}
    >
      {content}
    </div>
  );
};

export default memo(Notifications);
