import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ReactComponent as SvgEdit } from './../../../assets/icons/icon-edit.svg';
import { ReactComponent as SvgCamera } from './../../../assets/icons/icon-camera.svg';
import { ReactComponent as SvgFB } from './../../../assets/icons/facebook-icon.svg';
import { ReactComponent as SvgIG } from './../../../assets/icons/instagram-icon.svg';
import { ReactComponent as SvgTW } from './../../../assets/icons/twitter-icon.svg';
import { ReactComponent as SvgConfirm } from './../../../assets/icons/confirm-icon.svg';
import { ReactComponent as SvgCalendar } from './../../../assets/icons/calendar-icon-profile.svg';
import { ReactComponent as CircleX } from './../../../assets/icons/circle-x.svg';
import ReactDatePicker from 'react-datepicker';
import Select from '../../../components/Select/Select';
import Moment from 'moment';
import styles from './ProfileContent.module.scss';
import ButtonBack from '../../../components/ButtonBack/ButtonBack';
import Button from 'src/components/Button';
import { authActions, contactActions } from 'src/common/actions';
import axios from 'axios';
import moment from 'moment';
import {
  API_ENDPOINT,
  USERS_ENDPOINT,
  API_SERVER_ENDPOINT,
} from 'src/utils/endpoints';
import { Box, Grid } from '@material-ui/core';
import { IconButton } from 'rsuite';
import Modal from 'src/components/Modal';

const ProfileContent = ({ user }) => {
  Moment.locale('en');
  console.log('this is the user', user);
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const toggleIsEditMode = () => setIsEditMode(!isEditMode);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [startDate, setStartDate] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const roleOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Preffer not to say', label: 'Preffer not to say' },
  ];
  const roleOptionsLang = [
    { value: 'English', label: 'English' },
    { value: 'German', label: 'German' },
    { value: 'Russian', label: 'Russian' },
  ];

  const truthyFalsy = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ];

  const { register, handleSubmit, watch, errors, control } = useForm({
    mode: 'onChange',
  });
  const toggleModal = () => {
    setIsModalOpened(!isModalOpened);
  };
  const onSubmit = (data, { uri = '/update/' + user.id }) => {
    console.log(data.language);
    axios
      .patch(
        `${USERS_ENDPOINT}${uri}`,
        {
          name: data.first_name + ' ' + data.last_name,
          email: data.email,
          birthday: startDate,
          phone: data.phone_number,
          gender: data.gender.value,
          language: data.language.value,
          username: data.username,
        },
        {
          headers: {
            Authorization: 'Bearer' + localStorage.getItem('crm-client-token'),
            Accept: 'application/json',
          },
        }
      )
      .then((response) => {
        dispatch(authActions.fetchUser());
        setIsEditMode(false);
      });
  };

  const onImageChange = (event) => {
    console.log(event.target.files[0]);
    var formData = new FormData();
    formData.append('avatar', event.target.files[0]);
    formData.append('_method', 'PATCH');
    axios
      .post(`${API_ENDPOINT}/users/update/${user.id}`, formData, {
        headers: {
          Authorization: 'Bearer' + localStorage.getItem('crm-client-token'),
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(authActions.fetchUser());
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const onImageRemove = () => {
    var formData = new FormData();
    formData.append('avatar', '');
    formData.append('_method', 'PATCH');
    axios
      .post(`${API_ENDPOINT}/users/update/${user.id}`, formData, {
        headers: {
          Authorization: 'Bearer' + localStorage.getItem('crm-client-token'),
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(authActions.fetchUser());
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const renderProfileImage = () => {
    if (user.avatar) {
      return (
        <div className="relative w-full h-full">
          <img
            className="block w-full rounded-sm object-center object-cover h-full"
            src={API_SERVER_ENDPOINT + '/storage/avatars/' + user.avatar}
            alt=""
            style={{
              boxShadow: 'none',
            }}
          />

          <div
            className="profileIconHolder"
            style={{
              height: '44px',
              bottom: '20px',
              right: '20px',
              position: 'absolute',
              zIndex: '2',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <div
              className="profileIconHolderInner"
              style={{
                height: '44px',
                width: '44px',
                backgroundColor: 'white',
                borderRadius: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                boxShadow: 'none',
              }}
            >
              <SvgCamera />
              <input
                type="file"
                onChange={(e) => onImageChange(e)}
                accept="image/*"
                className="hidden"
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col items-center justify-center h-full">
          <p className="text-sm uppercase text-primary text-bold px-30 text-center mb-20">
            No image provided!
          </p>
          <SvgCamera />
          <input
            className="hidden"
            style={{ width: '100%' }}
            type="file"
            accept="image/*"
            onChange={(e) => onImageChange(e)}
          />
        </div>
      );
    }
  };

  const getContentByMode = () => {
    if (isEditMode) {
      return (
        <form id="submit-profile" onSubmit={handleSubmit(onSubmit)}>
          <section>
            <div className="flex mt-40 mb-20 bg-gray-100 justify-center rounded-default">
              <label
                className="w-2/12 mr-20 flex flex-col items-center bg-white text-blue rounded-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white"
                style={{
                  minHeight: '250px',
                  minWidth: '260px',
                  maxHeight: '250px',
                }}
              >
                {renderProfileImage()}
                {user && user.avatar ? (
                  <div style={{ marginTop: 20 }}>
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => toggleModal()}
                    >
                      <CircleX style={{ marginRight: 5 }} />
                      Remove Photo
                    </Button>
                  </div>
                ) : null}
              </label>
              <Modal
                isOpen={isModalOpened}
                onRequestClose={toggleModal}
                headerTitle="Are you sure?"
                className="modal--alert"
              >
                <h2
                  className={`w-full text-2x font-bold text-black mt-50 mb-20`}
                >
                  Are you sure?
                </h2>
                <p className="my-20 mx-30">
                  Current profile picture will be removed permamently. You
                  can&#39;t undo this action.
                </p>
                <div className="flex justify-between mt-20">
                  <button
                    className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-gray-500 hover:bg-gray-400"
                    role="menuitem"
                    onClick={toggleModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-primary hover:bg-secondary whitespace-nowrap"
                    role="menuitem"
                    onClick={onImageRemove}
                  >
                    Remove
                  </button>
                </div>
              </Modal>
              <Grid spacing={3} className="mt-1" container>
                <Grid item xs={6} style={{ maxHeight: '75px !important' }}>
                  <div className="flex flex-col">
                    <p className="w-full text-xxs text-gray-400 mb-2 font-medium">
                      First name
                    </p>
                    {errors.first_name && (
                      <span className="from-error-label">*Required</span>
                    )}
                    <input
                      className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full font-semibold"
                      type="text"
                      defaultValue={
                        user && user.name && user.name.split(' ')[0]
                      }
                      ref={register({ required: true })}
                      name="first_name"
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="flex flex-col">
                    <p className="w-full text-xxs text-gray-400 mb-2 font-medium">
                      Last name
                    </p>
                    <input
                      className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full font-semibold"
                      type="text"
                      defaultValue={
                        user && user.name && user.name.split(' ')[1]
                      }
                      ref={register({ required: true })}
                      name="last_name"
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="flex flex-col">
                    <p className="w-full text-xxs text-gray-400 mb-2 font-medium">
                      Username
                    </p>
                    <input
                      className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full font-semibold"
                      type="text"
                      name="username"
                      placeholder=""
                      defaultValue={
                        '@' + user && user.username ? user.username : ''
                      }
                      ref={register({ required: true })}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="flex flex-col">
                    <p className="w-full text-xxs text-gray-400 mb-2 font-medium">
                      Password
                    </p>
                    <p className="w-full text-sm text-gray-500 mb-0">
                      <input
                        className="disabled pointer-events-none opacity-75 flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full font-semibold"
                        type="password"
                        name="password"
                        placeholder=""
                        defaultValue={'*********'}
                        ref={register({ required: true })}
                      />
                    </p>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="flex flex-col">
                    <p className="w-full text-xxs text-gray-400 mb-2 font-medium">
                      Phone number
                    </p>
                    <input
                      className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full font-semibold"
                      type="tel"
                      name="phone_number"
                      placeholder=""
                      defaultValue={
                        user && user.phone ? user.phone : '+381 57 555 333'
                      }
                      ref={register({ required: true })}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="flex flex-col">
                    <p className="w-full text-xxs text-gray-400 mb-2 font-medium">
                      Email
                    </p>
                    <input
                      className="flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full font-semibold"
                      type="email"
                      required
                      name="email"
                      placeholder=""
                      defaultValue={
                        user && user.email ? user.email : 'crm@soul.com'
                      }
                      ref={register({ required: true })}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="flex flex-col">
                    <p className="w-full text-xxs text-gray-400 mb-2 font-medium">
                      Company
                    </p>
                    <input
                      className="disabled pointer-events-none opacity-75 flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full font-semibold"
                      type="text"
                      name="company"
                      placeholder=""
                      defaultValue={
                        user && user.company && user.company.name
                          ? user.company.name
                          : 'Company name'
                      }
                      ref={register({ required: true })}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="flex flex-col">
                    <p className="w-full text-xxs text-gray-400 mb-2 font-medium">
                      Position
                    </p>
                    <input
                      className="disabled pointer-events-none opacity-75 flex bg-white border-1 border-solid border-gray-300 rounded-default px-15 py-10 text-sm w-full font-semibold"
                      type="text"
                      name="position"
                      placeholder=""
                      defaultValue={
                        user && user.position ? user.position : 'Position'
                      }
                      ref={register({ required: true })}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="flex flex-col">
                    <p className="w-full text-xxs text-gray-400 mb-2 font-medium">
                      Gender
                    </p>
                    <Select
                      showLabel={false}
                      className={`${styles.select} w-full font-semibold`}
                      name="gender"
                      options={roleOptions}
                      register={register}
                      control={control}
                      defaultValue={{
                        value: user && user.gender ? user.gender : 'Male',
                        label: user && user.gender ? user.gender : 'Male',
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="flex flex-col">
                    <p className="w-full text-xxs text-gray-400 mb-2 font-medium">
                      Birthday
                    </p>
                    <div className="flex items-center w-full justify-between border-1 border-gray-300 rounded-default p-10 bg-white">
                      <ReactDatePicker
                        className={`ReactDatePicker font-semibold text-sm outline-none pl-5 w-full`}
                        dateFormat="dd-MM-yyyy"
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                      />
                      <SvgCalendar className="w-sm h-sm min-w-sm text-primary right-0" />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="flex flex-col">
                    <p className="w-full text-xxs text-gray-400 mb-2 font-medium">
                      Language
                    </p>
                    <Select
                      showLabel={false}
                      className={`${styles.select} w-full font-semibold`}
                      name="language"
                      options={roleOptionsLang}
                      register={register({ required: true })}
                      control={control}
                      defaultValue={{
                        value:
                          user && user.language ? user.language : 'English',
                        label:
                          user && user.language ? user.language : 'English',
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </section>
          {/* <div className="flex items-start mt-20 mb-20 w-full">
            <button
              variant="secondary"
              height="44"
              width="120"
              className="flex text-center justify-center items-center
              rounded-default px-30 py-10 text-sm text-white bg-red
              hover:bg-darkRed outline-none ml-10"
              onClick={() => {
                setIsEditMode(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex text-center justify-center items-center
              rounded-default px-30 py-10 text-sm text-white bg-primary
              hover:bg-secondary outline-none ml-10"
            >
              Update
            </button>
          </div> */}
        </form>
      );
    }
  };

  return (
    <>
      <section>
        <Box className="flex justify-end -mt-50">
          {isEditMode ? (
            <button
              className={` bg-primary hover:bg-secondary text-white p-15 px-20 text-sm rounded-default h-45 outline-none flex justify-center items-center string`}
              type="submit"
              form="submit-profile"
              variant="primary"
            >
              <SvgConfirm className="text-white" />
              <p className="pl-5">Confirm</p>
            </button>
          ) : (
            <Button
              type="button"
              onClick={() => {
                toggleIsEditMode();
              }}
              variant="primary"
            >
              <SvgEdit className="text-white" />
              <p className="pl-5">Edit Profile</p>
            </Button>
          )}
        </Box>
        {/* <ButtonBack className="justify-end -mt-50" /> */}
        {!isEditMode && (
          <div
            style={{ height: 132, marginBottom: '-110px' }}
            className={'mt-40 bg-gray-300 px-20 py-20'}
          ></div>
        )}
        <div
          className={`${
            isEditMode ? 'hidden' : 'flex'
          } flex mb-20 px-20 py-20 justify-center rounded-default`}
        >
          <div style={{ width: 1180 }} className="flex flex-col">
            <div className="flex justify-start">
              <label
                className="w-2/12 flex flex-col items-center bg-white text-blue rounded-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white"
                style={{
                  minHeight: '170px',
                  maxHeight: '170px',
                  minWidth: '180px',
                  maxWidth: '180px',
                }}
              >
                {renderProfileImage()}
              </label>
              <div style={{ gap: '45px' }} className="flex items-end mb-3">
                <div className="flex flex-col justify-end ml-20">
                  <p className="flex items-center text-md font-bold text-black text-gray-500">
                    {user.name}
                  </p>
                  <p className="flex  items-center text-xxss text-black text-gray-500">
                    {user.email}
                  </p>
                </div>
                <div>
                  <div className="flex flex-col justify-end">
                    <p
                      style={{ fontWeight: 500 }}
                      className="flex items-center text-md text-black text-sm text-gray-500"
                    >
                      {user && user.phone ? user.phone : '+381 67 555 333'}
                    </p>
                    <p
                      style={{ fontWeight: 500 }}
                      className="flex items-center text-xs text-black text-gray-500"
                    >
                      <p style={{ color: '#0395FF', fontStyle: 'italic' }}>@</p>
                      {user && user.username
                        ? user.username
                        : user && user.name
                        ? user.name.split(' ')[0]
                        : 'User'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-40">
              <Grid container justifyContent="space-between" spacing={6}>
                <Grid item xs={2}>
                  <Box className="flex flex-col">
                    <Box>
                      <p
                        style={{ fontWeight: 500 }}
                        className="flex items-end text-xs text-gray-400"
                      >
                        Company
                      </p>
                    </Box>
                    <Box>
                      <p
                        style={{ fontWeight: 500 }}
                        className="flex items-end text-base text-black"
                      >
                        {user && user.company && user.company.name
                          ? user.company.name
                          : 'Company name'}
                      </p>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box className="flex flex-col">
                    <Box>
                      <p
                        style={{ fontWeight: 500 }}
                        className="flex items-end text-xs text-gray-400"
                      >
                        Position
                      </p>
                    </Box>
                    <Box>
                      <p
                        style={{ fontWeight: 500 }}
                        className="flex items-end text-base text-black"
                      >
                        {user && user.position ? user.position : 'Position'}
                      </p>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box className="flex flex-col">
                    <Box>
                      <p
                        style={{ fontWeight: 500 }}
                        className="flex items-end text-xs text-gray-400"
                      >
                        Language
                      </p>
                    </Box>
                    <Box>
                      <p
                        style={{ fontWeight: 500 }}
                        className="flex items-end text-base text-black"
                      >
                        {user && user.language ? user.language : 'English'}
                      </p>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box className="flex flex-col">
                    <Box>
                      <p
                        style={{ fontWeight: 500 }}
                        className="flex items-end text-xs text-gray-400"
                      >
                        Gender
                      </p>
                    </Box>
                    <Box>
                      <p
                        style={{ fontWeight: 500 }}
                        className="flex items-end text-base text-black"
                      >
                        {user && user.gender ? user.gender : 'Male'}
                      </p>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box className="flex flex-col">
                    <Box>
                      <p
                        style={{ fontWeight: 500 }}
                        className="flex items-end text-xs text-gray-400"
                      >
                        Birthday
                      </p>
                    </Box>
                    <Box>
                      <p
                        style={{ fontWeight: 500 }}
                        className="flex items-end text-base text-black"
                      >
                        {user && user.birthday
                          ? moment(user.birthday).format('DD.MM.YYYY')
                          : '16.08.1983'}
                      </p>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box style={{ gap: '95px' }} className="flex items-center">
                    <p
                      style={{ fontWeight: 700 }}
                      className="flex items-end text-base text-black"
                    >
                      Social Media Links
                    </p>
                    <Box style={{ gap: 20 }} className="flex items-center">
                      <IconButton>
                        <SvgFB />
                      </IconButton>
                      <IconButton>
                        <SvgIG />
                      </IconButton>
                      <IconButton>
                        <SvgTW />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={8}></Grid>
              </Grid>
            </div>
          </div>
          {/* <div className="flex flex-col w-10/12 justify-center">
            <div className="flex flex-col ml-40 mb-15">
              <span
                style={{ width: '150px' }}
                className="inline-flex bg-red text-black text-xxs uppercase font-medium py-5 items-center rounded-default justify-center tracking-wide mb-5"
              >
                {user.role}
              </span>
              <p className="w-full text-xs text-gray-400">
                First and last name
              </p>
              <p className="flex items-center w-full text-2xl font-bold text-black w-full text-sm text-gray-500">
                {user.name}
                <button
                  className="flex"
                  onClick={() => {
                    toggleIsEditMode();
                  }}
                >
                  <SvgEdit className="ml-15" fill="text-primary" />
                </button>
              </p>
            </div>

            <div className="flex">
              <div className="flex flex-col ml-40 w-1/4 justify-center">
                <div className="flex flex-col">
                  <p className="w-full text-xs text-gray-400 mb-0">ID</p>
                  <p className="w-full text-sm text-gray-500 mb-0">{user.id}</p>
                </div>
                <div className="flex flex-col mb-20">
                  <p className="w-full text-xxs text-gray-400 mb-2 font-medium">
                    Position
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">pos</p>
                </div>
              </div>
              <div className="flex flex-col w-1/4">
                <div className="flex flex-col mb-20">
                  <p className="w-full text-xxs text-gray-400 mb-0 font-medium">
                    Created at
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {user.created_at
                      ? Moment(user.created_at).format('L')
                      : '-'}
                  </p>
                </div>
                <div className="flex flex-col mb-20">
                  <p className="w-full text-xxs text-gray-400 mb-0 font-medium">
                    Email verified at
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {user.email_verified_at ? user.email_verified_at : '-'}
                  </p>
                </div>
              </div>
              <div className="flex flex-col w-1/4">
                <div className="flex flex-col mb-20">
                  <p className="w-full text-xxs text-gray-400 mb-0 font-medium">
                    Mail
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {user.email}
                  </p>
                </div>
                <div className="flex flex-col mb-20">
                  <p className="w-full text-xxs text-gray-400 mb-0 font-medium">
                    Updated at
                  </p>
                  <p className="w-full text-sm text-gray-500 mb-0">
                    {user.updated_at
                      ? Moment(user.updated_at).format('L')
                      : '-'}
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {getContentByMode()}
      </section>
    </>
  );
};
export default ProfileContent;
