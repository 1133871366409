import React, { useEffect } from 'react';
import LayoutComponent from '../../components/commons/LayoutComponent';
import NotesContent from './components/NotesContent';
import { notesActions } from '../../common/actions';
import { useDispatch, useSelector } from 'react-redux';

const Notes = () => {
  const dispatch = useDispatch();
  const { areNotesLoaded } = useSelector((state) => state.notes);

  useEffect(() => {
    if (!areNotesLoaded) {
      dispatch(notesActions.getAllNotes());
    }
  }, [areNotesLoaded]);

  return (
    <LayoutComponent title="">
      {Boolean(areNotesLoaded) && <NotesContent />}
    </LayoutComponent>
  );
};

export default Notes;
