import * as React from 'react';

function TwoEnvelopes(props) {
  return (
    <svg width={140} height={140} fill="none" {...props}>
      <rect width={140} height={140} rx={30} fill="#0395FF" fillOpacity={0.1} />
      <path
        d="M111.852 81.903V70.97a1.4 1.4 0 10-2.801 0v10.056a18.525 18.525 0 00-3.975-.436c-2.632 0-5.264.566-7.726 1.726L86.552 71.852l22.499-21.807v25.978a1.4 1.4 0 002.801 0V46.738a4.29 4.29 0 00-4.286-4.286h-2.984V30.845c0-2.08-1.691-3.77-3.77-3.77H38.704a3.775 3.775 0 00-3.771 3.77v11.607h-2.984a4.291 4.291 0 00-4.286 4.286v50.228a4.29 4.29 0 004.286 4.286h34.54a1.4 1.4 0 100-2.8H31.95a1.49 1.49 0 01-1.395-.98l24.422-23.67 5.154 4.995c5.392 5.227 13.913 5.18 19.256 0l5.154-4.995 10.276 9.959a18.184 18.184 0 00-7.916 14.69H61.438a1.4 1.4 0 100 2.801h25.63c1.213 8.854 8.826 15.697 18.008 15.697 10.024 0 18.179-8.155 18.179-18.179 0-7.63-4.725-14.175-11.403-16.867zM38.704 29.875h62.108c.313 0 .65.168.841.502l-12.46 12.075H50.322L37.863 30.377a.975.975 0 01.841-.502zm63.078 4.277v8.3h-8.564l8.564-8.3zm-64.048 0l8.563 8.3h-8.563v-8.3zm-7.27 59.506V50.045l22.5 21.807-22.5 21.806zm46.973-16.872a10.984 10.984 0 01-15.358 0L30.554 46.232c.224-.606.794-.98 1.395-.98h75.617a1.49 1.49 0 011.395.98c-1.06 1.029-30.01 29.087-31.524 30.554zm27.639 37.361c-8.175 0-14.994-6.444-15.361-14.711-.375-8.791 6.671-16.044 15.361-16.044 8.47 0 15.378 6.89 15.378 15.378 0 8.48-6.898 15.377-15.378 15.377z"
        fill="#0395FF"
      />
      <path
        d="M111.538 93.605l-8.119 8.119-4.112-4.112a1.401 1.401 0 00-1.98 1.98l5.102 5.102a1.4 1.4 0 001.981 0l9.109-9.108a1.402 1.402 0 000-1.981 1.402 1.402 0 00-1.981 0z"
        fill="#0395FF"
      />
    </svg>
  );
}

const MemoTwoEnvelopes = React.memo(TwoEnvelopes);
export default MemoTwoEnvelopes;
