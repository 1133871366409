import React, { useState } from 'react';
import ServerlessPagination from 'src/components/commons/ServerlessPagination/ServerlessPagination';
import ArrowDown from '../../../../assets/icons/sort-down.svg';
import ArrowUpActive from '../../../../assets/icons/sort-up-active.svg';
import ArrowDownActive from '../../../../assets/icons/sort-down-active.svg';
import ArrowDownAZActive from '../../../../assets/icons/sort-az-down-active.svg';
import ArrowUpAZActive from '../../../../assets/icons/sort-za-down-active.svg';
import ArrowDownAZ from '../../../../assets/icons/sort-az-down.svg';
import { Box } from '@material-ui/core';
import CompanyRow from './CompanyRow';

const CompaniesTable = ({ companies, children, value, title }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [sortBy, setSortBy] = useState('CreatedAt');
  const totalRows = companies.length;
  const lastPage = Math.ceil(totalRows / 10);
  const handleSort = (item) => {
    if (sortBy === 'User' && item === 'User') {
      setSortBy('UserReversed');
    } else if (sortBy === 'Company' && item === 'Company') {
      setSortBy('CompanyReversed');
    } else if (sortBy === 'CreatedAt' && item === 'CreatedAt') {
      setSortBy('CreatedAtReversed');
    } else {
      setSortBy(item);
    }
  };
  return (
    <div className="flex flex-col mt-50">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-20 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ cursor: 'pointer', userSelect: 'none' }}
                    onClick={() => handleSort('User')}
                  >
                    <Box display="flex" alignItems="center" style={{ gap: 10 }}>
                      Company Name{' '}
                      <img
                        src={
                          sortBy == 'UserReversed'
                            ? ArrowUpAZActive
                            : sortBy == 'User'
                            ? ArrowDownAZActive
                            : ArrowDownAZ
                        }
                      />
                    </Box>
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ cursor: 'pointer', userSelect: 'none' }}
                    onClick={() => handleSort('Company')}
                  >
                    <Box display="flex" alignItems="center" style={{ gap: 10 }}>
                      Company Owner{' '}
                      <img
                        src={
                          sortBy == 'CompanyReversed'
                            ? ArrowUpAZActive
                            : sortBy == 'Company'
                            ? ArrowDownAZActive
                            : ArrowDownAZ
                        }
                      />
                    </Box>
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ cursor: 'pointer', userSelect: 'none' }}
                    onClick={() => handleSort('CreatedAt')}
                  >
                    <Box display="flex" alignItems="center" style={{ gap: 5 }}>
                      Created at{' '}
                      <img
                        style={{ height: 14 }}
                        src={
                          sortBy == 'CreatedAtReversed'
                            ? ArrowUpActive
                            : sortBy == 'CreatedAt'
                            ? ArrowDownActive
                            : ArrowDown
                        }
                      />
                    </Box>
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Phone
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Approved
                  </th>
                  <th
                    scope="col"
                    className="px-20 py-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {companies
                  .sort((a, b) =>
                    sortBy == 'User'
                      ? a.name.localeCompare(b.name)
                      : sortBy == 'UserReversed'
                      ? b.name.localeCompare(a.name)
                      : sortBy == 'Company'
                      ? a &&
                        a.owner &&
                        a.owner.name &&
                        a.owner.name.localeCompare(
                          b && b.owner && b.owner.name && b.owner.name
                        )
                      : sortBy == 'CompanyReversed'
                      ? b &&
                        b.owner &&
                        b.owner.name &&
                        b.owner.name.localeCompare(
                          a && a.owner && a.owner.name && a.owner.name
                        )
                      : sortBy == 'CreatedAt'
                      ? new Date(a.created_at) - new Date(b.created_at)
                      : sortBy == 'CreatedAtReversed'
                      ? new Date(b.created_at) - new Date(a.created_at)
                      : null
                  )
                  .slice(currentPage * 10, (currentPage + 1) * 10)
                  .map((company, index) => {
                    return (
                      <CompanyRow
                        companies={companies}
                        company={company}
                        key={index}
                      />
                    );
                  })}
              </tbody>
            </table>
            <ServerlessPagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              lastPage={lastPage}
              total={totalRows}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompaniesTable;
