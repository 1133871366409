import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import Checkbox from '../../../../components/Form/components/Checkbox/Checkbox';

const AudienceProductTyepesRow = ({
  product,
  updatePickedProducts,
  isPreselected,
  pickedProducts,
}) => {
  const [isChecked, setIsChecked] = useState(isPreselected);
  Moment.locale('en');
  const dt = `{contact.updated_at}`;

  useEffect(() => {
    if (pickedProducts.includes(product.id)) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  });

  const handleChange = () => {
    updatePickedProducts(product);
    setIsChecked(!isChecked);
  };

  return (
    <>
      <tr>
        <td className="px-20 py-0 whitespace-nowrap border-t-1 border-b-1 border-solid border-gray-300">
          <div className="flex items-center">
            <Checkbox
              name="1"
              labelText=""
              onChange={handleChange}
              checked={isChecked}
            />
          </div>
        </td>
        <td className="px-20 py-0 whitespace-nowrap border-t-1 border-b-1 border-solid border-gray-300">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-24 w-24"></div>
            <div className="ml-15">
              <div className="text-xxs text-gray-500">{product.name}</div>
              <div className="text-xxs text-gray-500"></div>
            </div>
          </div>
        </td>
        <td className="px-20 py-0 whitespace-nowrap border-t-1 border-b-1 border-solid border-gray-300">
          <div className="text-xxs text-gray-500">{product.product_type}</div>
        </td>
        <td className="px-20 py-0 whitespace-nowrap border-t-1 border-b-1 border-solid border-gray-300">
          <div className="flex items-center">
            <div className="text-xxs text-gray-500">Company 1</div>
          </div>
        </td>
        <td className="px-20 py-0 whitespace-nowrap border-t-1 border-b-1 border-solid border-gray-300 ">
          <div className="text-xxs text-gray-500">
            {Moment({ dt }).format('L')}
          </div>
        </td>
        <td className="px-20 py-0 whitespace-nowrap border-t-1 border-b-1 border-solid border-gray-300 text-right">
          <div className="text-xxs text-gray-500">Credit Card</div>
        </td>
      </tr>
    </>
  );
};

export default AudienceProductTyepesRow;
