import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import CampaignsStats from '../../../Campaigns/components/CampaignStats';
import LayoutComponent from '../../../../components/commons/LayoutComponent';
import ButtonBack from '../../../../components/ButtonBack';
import { LineGraph } from '../../../../components/Visualization/line';
import { ReactComponent as SvgAutomate } from 'src/assets/icons/icon-automate.svg';
import { CampaignAutomateModal } from 'src/pages/AddCampaign/CampaignAutomateModal/CampaingAutomateModal';
import { mailCampaignsActions } from 'src/common/actions';
import { getQueryStringParam } from 'src/utils/getQueryParams';

const StatisticsCampaign = () => {
  moment.locale('en');
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  let campaignStatistics = null;
  const campaignId = getQueryStringParam('campaignId');
  if (location && location.state) {
    campaignStatistics = location.state.campaignStats;
  }
  useEffect(() => {
    if (campaignId) {
      dispatch(mailCampaignsActions.getSingleMailCampaigns(campaignId));
    }
  }, [campaignId]);
  if (campaignId) {
    campaignStatistics = useSelector(
      (state) => state.mailCampaigns.mailCampaignsList.singleMailCampaign
    );
  }
  console.log('state', campaignStatistics);
  //Automate modal
  const [
    isCampaignAutomateModalOpened,
    setIsCampaignAutomateModalOpened,
  ] = useState(false);
  const toggleCampaignAutomateModal = () => {
    setIsCampaignAutomateModalOpened(!isCampaignAutomateModalOpened);
  };
  const renderStatsGraphs = () => {
    let dataLine = [
      {
        id: 'Opens',
        color: 'hsl(208, 70%, 50%)',
        data: [
          {
            x: '00:00',
            y: 21,
          },
          {
            x: '08:00',
            y: 81,
          },
          {
            x: '16:00',
            y: 53,
          },
          {
            x: '21:00',
            y: 24,
          },
        ],
      },
      {
        id: 'Clicks',
        color: 'hsl(292, 70%, 50%)',
        data: [
          {
            x: '00:00',
            y: 21,
          },
          {
            x: '08:00',
            y: 81,
          },
          {
            x: '16:00',
            y: 53,
          },
          {
            x: '21:00',
            y: 24,
          },
        ],
      },
    ];

    return (
      <div className="flex justify-between flex-wrap w-full -mt-10">
        <LineGraph
          title="24 hour perfomance"
          excerpt="Line graph about campaign delivery actions"
          graphData={dataLine}
          className="w-full"
        />
      </div>
    );
  };

  return (
    <LayoutComponent>
      <div className="flex flex-col">
        <div className="flex w-full mb-40">
          <h2 className="flex text-3xl font-bold mr-20 mb-20 text-black pr-20">
            {campaignStatistics && campaignStatistics.name
              ? campaignStatistics.name
              : ''}
          </h2>
          <a
            className="group bg-transparent h-44 text-primary border-1 border-solid border-primary p-15 px-30 text-sm rounded-default outline-none inline-flex justify-center items-center transition duration-500 ease-in-out mr-auto"
            //onClick={toggleCampaignDatepickerModal}
            style={{ maxWidth: '250px' }}
            onClick={toggleCampaignAutomateModal}
          >
            <SvgAutomate className="mr-20 group-hover:text-navyBlue" />
            Automate
          </a>
          <ButtonBack
            className="ml-auto w-3/12 justify-end"
            style={{ width: 'initial !important', border: '5px solid red' }}
          />
        </div>
      </div>
      <section className="mb-40">
        <div className="flex items-center">
          <p className="text-sm text-gray-500 font-bold tracking-wide mr-30">
            Subject:
            <span className="text-xs ml-20 text-gray-400 font-normal">
              {campaignStatistics && campaignStatistics.subject
                ? campaignStatistics.subject
                : null}
            </span>
          </p>
          <p className="text-sm text-gray-500 font-bold tracking-wide mr-30">
            Created at:
            <span className="text-xs ml-20 text-gray-400 font-normal">
              {campaignStatistics.created_at
                ? moment(
                    campaignStatistics && campaignStatistics.created_at
                      ? campaignStatistics.created_at
                      : null
                  ).format('L')
                : '-'}
            </span>
          </p>
          <p className="text-sm text-gray-500 font-bold tracking-wide mr-30">
            From:
            <span className="text-xs ml-20 text-gray-400 font-normal">
              {campaignStatistics && campaignStatistics.from
                ? campaignStatistics.from
                : ''}
            </span>
          </p>
        </div>

        <div className="w-full mx-auto mt-20 flex">
          <CampaignsStats
            variant="column"
            className="mr-20"
            title="Sent emails"
            value={10}
          />
          <CampaignsStats
            variant="column"
            className="mr-20"
            title="Opened emails"
            value={215}
          />
          <CampaignsStats
            variant="column"
            className="mr-20"
            title="Open rate"
            value={`34.19%`}
          />
          <CampaignsStats
            variant="column"
            className="mr-20"
            title="Clicked"
            value={37}
          />
          <CampaignsStats
            variant="column"
            className="mr-20"
            title="Click rate"
            value={`5.97%`}
          />
        </div>
        <div className="w-full mx-auto mt-20 flex">
          <CampaignsStats
            variant="column"
            className="mr-20"
            title="Unsubcribed"
            value={31}
          />
          <CampaignsStats
            variant="column"
            className="mr-20"
            title="Unsubscribed Rate"
            value={`3%`}
          />
          <CampaignsStats
            variant="column"
            className="mr-20"
            title="Abuse reported"
            value={0}
          />
          <CampaignsStats
            variant="column"
            className="mr-20"
            title="Abuse rate"
            value={`0,00%`}
          />
        </div>
        <div className="w-full mx-auto mt-20 flex">
          <CampaignsStats
            variant="column"
            className="mr-20"
            title="Bounced"
            value={0}
          />
          <CampaignsStats
            variant="column"
            className="mr-20"
            title="Soft Bounced"
            value={1}
          />
          <CampaignsStats
            variant="column"
            className="mr-20"
            title="Hard Bounced"
            value={0}
          />
        </div>
      </section>
      {renderStatsGraphs()}
      <CampaignAutomateModal
        campaignStatistics={campaignStatistics}
        toggleCampaignAutomateModal={toggleCampaignAutomateModal}
        isCampaignAutomateModalOpened={isCampaignAutomateModalOpened}
      />
    </LayoutComponent>
  );
};

export default StatisticsCampaign;
