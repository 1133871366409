import {
  API_ENDPOINT,
  NOTES_COMMENTS_ENDPOINT,
  NOTES_ENDPOINT,
  NOTES_CATEGORIES_ENDPOINT,
} from '../../utils/endpoints';
import { handleResponse, authFetch } from '../helpers';

const getAllNotes = async (track = true) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(NOTES_ENDPOINT, requestOptions, track).then(handleResponse);
};

//TODO THIS SHOULD BE MOVED TO NOTEACTIONS
const addNote = (noteData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(noteData),
  };

  return authFetch(`${API_ENDPOINT}/notes`, requestOptions).then(
    handleResponse
  );
};

const deleteNote = (noteId) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(`${API_ENDPOINT}/notes/${noteId}`, requestOptions).then(
    handleResponse
  );
};

const updateNote = async (noteId, noteData) => {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(noteData),
  };

  return authFetch(`${API_ENDPOINT}/notes/${noteId}`, requestOptions).then(
    handleResponse
  );
};

const createComment = async (data) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return authFetch(NOTES_COMMENTS_ENDPOINT, requestOptions, false).then((res) =>
    res.json()
  );
};

const updateComment = async (id, data) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return authFetch(
    NOTES_COMMENTS_ENDPOINT + '/' + id,
    requestOptions,
    false
  ).then((res) => res.json());
};

const deleteComment = async (commentId) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return authFetch(
    NOTES_COMMENTS_ENDPOINT + '/' + commentId,
    requestOptions,
    false
  ).then((res) => res.json());
};

const getComments = async (noteId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return authFetch(
    NOTES_COMMENTS_ENDPOINT + '?note_id=' + noteId,
    requestOptions,
    false
  ).then((res) => res.json());
};

const getCategories = async () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return authFetch(
    NOTES_CATEGORIES_ENDPOINT,
    requestOptions,
    false
  ).then((res) => res.json());
};

export const notesService = {
  getAllNotes,
  addNote,
  deleteNote,
  updateNote,
  getComments,
  createComment,
  updateComment,
  deleteComment,
  getCategories,
};
