import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import FieldInput from '../Form/components/FieldInput';
import Button from '../Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { routes } from '../../common/constants';
import styles from './ContactForm.module.scss';
import { contactActions } from '../../common/actions/contactsActions';
import Select from '../Select/Select';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import RadioButton from '../Form/components/RadioButton/RadioButton';
import moment from 'moment';
import { Box, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import { validateYupSchema } from 'formik';

const AddContactSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('This field is required')
    .min(2, 'Firstname must be longer then two characters'),
  last_name: Yup.string()
    .required('This field is required')
    .min(2, 'Lastname must be longer then two characters'),
  phone: Yup.string().required('This field is required'),
  mobile_number: Yup.string().required('This field is required'),
  email: Yup.string().email().required('This field is required'),
});

const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Other', label: 'Other' },
];

const roleOptions = [
  { value: 'Visitor', label: 'Visitor' },
  { value: 'Client', label: 'Client' },
  { value: 'Healer', label: 'Healer' },
  { value: 'Adv Student', label: 'Adv Student' },
];

const clientSenseOptions = [
  { value: 'Internet_search', label: 'Internet search' },
  { value: 'Meetup', label: 'Meetup' },
  { value: 'Friend', label: 'Friend' },
  { value: 'Event_brite', label: 'Event Brite' },
  { value: 'Other', label: 'Other' },
];

const timeZones = [
  { value: '12:00', label: '(GMT -12:00) Eniwetok, Kwajalein' },
  { value: '11:00', label: '(GMT -11:00) Midway Island, Samoa' },
  { value: '10:00', label: '(GMT -10:00) Hawaii' },
  { value: '09:50', label: '(GMT -9:30) Taiohae' },
  { value: '09:00', label: '(GMT -9:00) Alaska' },
  { value: '08:00', label: 'GMT -8:00) Pacific Time (US &amp; Canada)' },
  { value: '07:00', label: 'GMT -7:00) Mountain Time (US &amp; Canada)' },
  {
    value: '06:00',
    label: 'GMT -6:00) Central Time (US &amp; Canada), Mexico City',
  },
  {
    value: '05:00',
    label: 'GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima',
  },
  { value: '04:50', label: '(GMT -4:30) Caracas' },
  {
    value: '04:00',
    label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
  },
  { value: '03:50', label: '(GMT -3:30) Newfoundland' },
  { value: '03:00', label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown' },
  { value: '02:00', label: '(GMT -2:00) Mid-Atlantic' },
  { value: '01:00', label: '(GMT -1:00) Azores, Cape Verde Islands' },
  {
    value: '00:00',
    label: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
  },
  { value: '01:00', label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris' },
  { value: '02:00', label: '(GMT +2:00) Kaliningrad, South Africa' },
  {
    value: '03:00',
    label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
  },
  { value: '03:50', label: '(GMT +3:30) Tehran' },
  { value: '04:00', label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
  { value: '04:50', label: '(GMT +4:30) Kabul' },
  {
    value: '05:00',
    label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
  },
  { value: '05:50', label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi' },
  { value: '05:75', label: '(GMT +5:45) Kathmandu, Pokhara' },
  { value: '06:00', label: '(GMT +6:00) Almaty, Dhaka, Colombo' },
  { value: '06:50', label: '(GMT +6:30) Yangon, Mandalay' },
  { value: '07:00', label: '(GMT +7:00) Bangkok, Hanoi, Jakarta' },
  { value: '08:00', label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong' },
  { value: '08:75', label: '(GMT +8:45) Eucla' },
  {
    value: '09:00',
    label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
  },
  { value: '09:50', label: '(GMT +9:30) Adelaide, Darwin' },
  {
    value: '10:00',
    label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
  },
  { value: '10:50', label: '(GMT +10:30) Lord Howe Island' },
  {
    value: '11:00',
    label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
  },
  { value: '11:50', label: '(GMT +11:30) Norfolk Island' },
  {
    value: '12:00',
    label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
  },
  { value: '12:75', label: '(GMT +12:45) Chatham Islands' },
  { value: '13:00', label: '(GMT +13:00) Apia, Nukualofa' },
  { value: '14:00', label: '(GMT +14:00) Line Islands, Tokelau' },
];

const freeEvents = [
  { value: 'facebook', label: 'Facebook' },
  { value: 'Campaign from Google', label: 'Campaign from Google' },
  { value: 'affiliate', label: 'Affiliate' },
];

const paidEvents = [
  { value: 'Webstore', label: 'Webstore' },
  { value: 'Pos', label: 'Pos' },
  { value: 'Eventbrite', label: 'Eventbrite' },
  { value: 'Other', label: 'Other' },
];

const truthyFalsy = [
  { value: true, label: 'True' },
  { value: false, label: 'False' },
];

const prefferedMethodOfCommunication = [
  { value: 'Email', label: 'Email' },
  { value: 'Phone', label: 'Phone' },
  { value: 'SMS', label: 'SMS' },
];

const ContactForm = (className) => {
  const [isMarketingAllowed, setIsMarketingAllowed] = useState(false);
  const handleMarketing = () => {
    setIsMarketingAllowed(!isMarketingAllowed);
  };
  const [isContactingAllowed, setIsContactingAllowed] = useState(false);
  const handlePermissionToContact = () => {
    setIsContactingAllowed(!isContactingAllowed);
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, watch, errors, control } = useForm({
    resolver: yupResolver(AddContactSchema),
    mode: 'onChange',
  });
  const steps = ['Personal Information', 'Additional Information'];
  const [activeStep, setActiveStep] = React.useState(0);
  console.log('active', activeStep);
  const handleNext = () => {
    yupResolver(AddContactSchema)
      ? setActiveStep((prevActiveStep) => prevActiveStep + 1)
      : null;
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleFinish = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const onSubmit = (data) => {
    let gender = 'Optional';
    if (data.gender && data.gender.value) gender = data.gender.value;
    let newData = {
      status: data.status.value,
      first_name: data.first_name,
      last_name: data.last_name,
      address: data.address,
      email: data.email,
      phone: data.phone,
      mobile: data.mobile_number,
      gender: gender,
      birthday: moment(data.birthday),
      company: data.company,
      postal_code: data.postalcode,
      region: data.region,
      province: data.province,
      position: data.position,
      country: data.country,
      time_zone: data.time_zone.label,

      opt_in_ip: data.opt_in_ip ? data.opt_in_ip.value : false,
      opt_in_ip: false,
      marketing_permission: isMarketingAllowed,
      permission_to_contact: isContactingAllowed,
      tags: data.tags ? data.tags.split(' ') : '',
      client_sense: moment(data.cliens_sense)
        ? data.client_sense.value
        : 'Internet_search',
      first_free_event: data.first_free_event
        ? data.first_free_event.value
        : 'Facebook',
      first_paid_event: data.first_paid_event
        ? data.first_paid_event.value
        : 'Webstore',
      communication_method: data.com_methods ? data.com_methods.value : 'Email',
      social_links: [
        {
          url: data.facebook ? data.facebook : '-',
          name: 'Facebook',
        },
        {
          url: data.twitter ? data.twitter : '-',
          name: 'Twitter',
        },
        {
          url: data.instagram ? data.instagram : '-',
          name: 'Instagram',
        },
      ],
    };
    console.log(data, 'Ovo je novokreairana data');
    dispatch(contactActions.createNewContact(newData, history));
  };
  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      className={`flex flex-wrap pb-50 sm:w-full`}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <Stepper
        style={{ backgroundColor: 'transparent' }}
        activeStep={activeStep}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label + index} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div
        className={
          activeStep == 0
            ? 'flex mt-40 flex-wrap w-full justify-between'
            : 'hidden'
        }
      >
        <Select
          labelText="Role"
          name="status"
          options={roleOptions}
          placeholder="role"
          register={register}
          control={control}
          value={watch('status')}
          defaultValue={{
            value: roleOptions[0].value,
            label: roleOptions[0].label,
          }}
          className="sm:w-full md:w-half"
        />
        <FieldInput
          labelText="First name"
          register={register}
          name="first_name"
          placeholder="Plutus"
          type="text"
          errors={errors}
          value={watch('first_name')}
          className="sm:w-full md:w-half"
        />
        <FieldInput
          labelText="Last name"
          register={register}
          name="last_name"
          placeholder="Media"
          type="text"
          errors={errors}
          value={watch('last_name')}
          className="sm:w-full md:w-half "
        />
        <FieldInput
          labelText="Company"
          register={register}
          name="company"
          placeholder="Plutus d.o.o."
          type="text"
          errors={errors}
          value={watch('company')}
          className="sm:w-full md:w-half"
        />
        <FieldInput
          labelText="Position"
          register={register}
          name="position"
          placeholder="Employee"
          type="text"
          errors={errors}
          value={watch('position')}
          className="sm:w-full md:w-half"
        />
        <FieldInput
          labelText="Birthday"
          register={register}
          name="birthday"
          placeholder="DD/MM/YYYY"
          type="text"
          errors={errors}
          value={watch('birthday')}
          className="sm:w-full md:w-half "
        />
        <Select
          labelText="Gender"
          name="gender"
          options={genderOptions}
          placeholder="Gender"
          register={register}
          control={control}
          value={watch('gender')}
          className="sm:w-full md:w-half"
          defaultValue={{
            value: genderOptions[0].value,
            label: genderOptions[0].label,
          }}
        />
        <FieldInput
          labelText="Email"
          register={register}
          name="email"
          placeholder="email@email.com"
          type="text"
          errors={errors}
          value={watch('email')}
          className="sm:w-full md:w-half "
        />
        <FieldInput
          labelText="Landing (phone number)"
          register={register}
          name="phone"
          placeholder="021506286"
          type="phone"
          value={watch('phone')}
          className="sm:w-full md:w-half"
        />
        <FieldInput
          labelText="Mobile number"
          register={register}
          name="mobile_number"
          placeholder="+391 0000 000 0"
          type="text"
          errors={errors}
          value={watch('mobile_number')}
          className="sm:w-full md:w-half"
        />

        <FieldInput
          labelText="Country"
          //register={register}
          name="country"
          placeholder="Germany"
          type="text"
          //errors={errors}
          //value={watch('country')}
          className="sm:w-full md:w-half"
        />

        <FieldInput
          labelText="Address"
          register={register}
          name="address"
          placeholder="Germany 2"
          type="text"
          value={watch('address')}
          className="sm:w-full md:w-half"
        />

        <FieldInput
          labelText="Region"
          register={register}
          name="region"
          placeholder="Bavaria"
          type="text"
          value={watch('region')}
          className="sm:w-full md:w-half"
        />
        <FieldInput
          labelText="Province"
          //register={register}
          name="province"
          placeholder="Das Bavaria"
          type="text"
          //value={watch('province')}
          className="sm:w-full md:w-half"
        />

        <FieldInput
          labelText="Postal Code"
          register={register}
          name="postalcode"
          placeholder="21111"
          type="text"
          value={watch('postalcode')}
          className="sm:w-full md:w-half"
        />
        <div className="flex flex-col mb-20 w-half">
          <p className="w-full text-xs text-gray-500 mb-5">Timezone</p>
          <Select
            labelText=""
            name="time_zone"
            options={timeZones}
            placeholder="Test"
            register={register}
            control={control}
            className={`${styles.select}`}
            defaultValue={{
              value: timeZones[10].value,
              label: timeZones[10].label,
            }}
          />
        </div>

        <FieldInput
          labelText="Languages"
          register={register}
          name="languages"
          placeholder="Deutsch, Spanisch, Englisch"
          type="text"
          errors={errors}
          value={watch('languages')}
          className="sm:w-full md:w-half"
        />

        <FieldInput
          labelText="IP address"
          register={register}
          name="ipaddress"
          placeholder="192.168.0.1"
          type="text"
          errors={errors}
          //value={watch('ipaddress')}
          className="sm:w-full md:w-half"
        />
      </div>
      <div
        className={
          activeStep == 1
            ? 'flex mt-40 flex-wrap w-full justify-between'
            : 'hidden'
        }
      >
        <FieldInput
          labelText=" Already have an account with Dr. Sha.com"
          type="text"
          placeholder="False"
          name="account_dr_sha"
          register={register}
          value={watch('account_dr_sha')}
          className="sm:w-full md:w-half"
        />
        <FieldInput
          labelText="Client since"
          type="text"
          placeholder="21/09/2020"
          name="account_dr_sha"
          register={register}
          value={watch('account_dr_sha')}
          className="sm:w-full md:w-half"
        />
        <FieldInput
          labelText="Facebook"
          register={register}
          placeholder="@lennardarrand26"
          name="facebook"
          defaultValue=""
          type="text"
          errors={errors}
          value={watch('facebook')}
          className="sm:w-full md:w-half"
        />
        <FieldInput
          labelText="Twitter"
          register={register}
          placeholder="@lennardarrand26"
          name="twitter"
          defaultValue=""
          type="text"
          errors={errors}
          value={watch('twitter')}
          className="sm:w-full md:w-half"
        />

        <FieldInput
          labelText="Instagram"
          placeholder="@lennardarrand26"
          register={register}
          name="instagram"
          defaultValue=""
          type="text"
          errors={errors}
          value={watch('instagram')}
          className="sm:w-full md:w-half"
        />

        <FieldInput
          labelText="Tao hands healer"
          register={register}
          name="tao_hands_healer"
          defaultValue=""
          type="number"
          placeholder="1"
          value={watch('tao_hands_healer')}
          className="sm:w-full md:w-half"
        />
        <FieldInput
          labelText="Tao academy groups"
          register={register}
          name="tao_academy_groups"
          defaultValue=""
          type="number"
          placeholder="1"
          value={watch(' tao_academy_groups')}
          className="sm:w-full md:w-half"
        />

        <div className="flex flex-col mb-20 w-half">
          <p className="w-full text-xs text-gray-500 mb-5">Channel origin</p>
          <Select
            labelText=""
            name="client_sense"
            options={clientSenseOptions}
            placeholder="Online"
            register={register}
            control={control}
            className={`${styles.select}`}
            defaultValue={{
              value: clientSenseOptions[0].value,
              label: clientSenseOptions[0].label,
            }}
          />
        </div>
        <div className="flex flex-col mb-20 w-half">
          <p className="w-full text-xs text-gray-500 mb-5">
            Channel - 1st Free Event signed up
          </p>
          <Select
            labelText=""
            name="first_free_event"
            options={freeEvents}
            register={register}
            control={control}
            className={`${styles.select}`}
            defaultValue={{
              value: freeEvents[0].value,
              label: freeEvents[0].label,
            }}
          />
        </div>
        <div className="flex flex-col mb-20 w-half">
          <p className="w-full text-xs text-gray-500 mb-5">
            Channel - 1st Paid Event signed up
          </p>
          <Select
            labelText=""
            name="first_free_event"
            options={paidEvents}
            register={register}
            control={control}
            className={`${styles.select}`}
            defaultValue={{
              value: paidEvents[0].value,
              label: paidEvents[0].label,
            }}
          />
        </div>
        <div className="flex flex-col mb-20 sm:w-full md:w-half">
          <p className="w-full text-xs text-gray-500 mb-5">
            Prefered method of communication
          </p>
          <Select
            labelText=""
            name="com_methods"
            options={prefferedMethodOfCommunication}
            register={register}
            control={control}
            className={`${styles.select}`}
            defaultValue={{
              value: prefferedMethodOfCommunication[0].value,
              label: prefferedMethodOfCommunication[0].label,
            }}
          />
        </div>
        <div className="flex flex-col mb-20 w-half">
          <p className="w-full text-xs text-gray-500 mb-5">
            Subscription Preferences
          </p>
          <Select
            labelText=""
            name="subscription_preferences"
            options={truthyFalsy}
            register={register}
            control={control}
            className={`${styles.select}`}
            defaultValue={{
              value: truthyFalsy[0].value,
              label: truthyFalsy[0].label,
            }}
          />
        </div>
        <div className="flex flex-col mb-20 w-half">
          <p className="w-full text-xs text-gray-500 mb-5">
            Marketing permissions
          </p>
          <div className="flex items-center" style={{ height: '46px' }}>
            <div className="flex mr-20 items-center">
              <RadioButton
                name="marketing"
                labelText="Yes"
                onChange={handleMarketing}
                checked={isMarketingAllowed == true}
              />
            </div>
            <div className="flex">
              <RadioButton
                name="marketing"
                labelText="No"
                onChange={handleMarketing}
                checked={isMarketingAllowed === false}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col mb-20 w-half">
          <p className="w-full text-xs text-gray-500 mb-5">
            Permission to contact
          </p>
          <div className="flex items-center" style={{ height: '46px' }}>
            <div className="flex mr-20 items-center">
              <RadioButton
                name="marketing"
                labelText="Yes"
                onChange={handlePermissionToContact}
                checked={isContactingAllowed == true}
              />
            </div>
            <div className="flex">
              <RadioButton
                name="marketing"
                labelText="No"
                onChange={handlePermissionToContact}
                checked={isContactingAllowed === false}
              />
            </div>
          </div>
        </div>
        <FieldInput
          labelText="Opt-in time"
          register={register}
          name="opt_in"
          placeholder="DD/MM/YYYY"
          type="number"
          value={watch('opt_in')}
          className="sm:w-full md:w-half"
        />
        <div className="flex flex-col mb-20 w-half">
          <p className="w-full text-xs text-gray-500 mb-5">Opt-in ip</p>
          <Select
            labelText=""
            name="opt_in_ip"
            options={truthyFalsy}
            register={register}
            control={control}
            className={`${styles.select}`}
            defaultValue={{
              value: truthyFalsy[0].value,
              label: truthyFalsy[0].label,
            }}
          />
        </div>
        <FieldInput
          labelText="Confirm ip"
          register={register}
          name="confirm_ip"
          placeholder="192.168.0.1."
          type="number"
          value={watch('confirm_ip')}
          className="sm:w-full md:w-half"
        />
        <FieldInput
          labelText="Longitude"
          register={register}
          name="longitude"
          placeholder="+180° eastward and −180° westward."
          type="number"
          value={watch('longitude')}
          className="sm:w-full md:w-half"
        />
        <FieldInput
          labelText="Member Rating"
          register={register}
          name="member_rating"
          placeholder="2"
          type="number"
          value={watch('member_rating')}
          className="sm:w-full md:w-half"
        />
        <FieldInput
          labelText="Latitude"
          register={register}
          name="latitude"
          placeholder="+180° northward and −180° southward."
          type="number"
          value={watch('latitude')}
          className="sm:w-full md:w-half"
        />
        <FieldInput
          labelText="Tags"
          register={register}
          name="tags"
          placeholder="Tag1 Tag2 Tag3"
          type="text"
          value={watch('tags')}
          className="sm:w-full md:w-half"
        />
      </div>

      <div className="flex w-full mt-10">
        {activeStep === steps.length ? (
          <React.Fragment>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                pt: 2,
                gap: 20,
              }}
            >
              <Typography
                className="text-black font-medium text-sm"
                style={{ textAlign: 'center' }}
              >
                You&apos;re finished. <br />
                Go back and edit details or create the Contact
              </Typography>

              <Box display="flex" style={{ gap: 20 }}>
                <Button
                  type="button"
                  variant="primary"
                  height="55"
                  width="150px"
                  onClick={handleBack}
                >
                  Go Back
                </Button>
                <Button
                  variant="primary"
                  height="55"
                  width="150px"
                  onClick={handleSubmit}
                >
                  Create Contact
                </Button>
              </Box>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                gap: 20,
              }}
            >
              <Button
                type="button"
                variant="primary"
                height="55"
                width="220px"
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                type="button"
                variant="primary"
                height="55"
                width="220px"
                onClick={() => handleNext()}
              >
                {activeStep === 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </div>
    </form>
  );
};
export default ContactForm;
