import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/Button';
import ReactDatePicker from 'react-datepicker';
import { ReactComponent as SvgCalendar } from './../../../assets/icons/icon-calendar.svg';
import { ReactComponent as SvgMail } from './../../../assets/icons/mail.svg';
import { ReactComponent as SvgMailOpened } from './../../../assets/icons/mail-opened.svg';
import { ReactComponent as SvgMailSpam } from './../../../assets/icons/mail-spam.svg';
import { ReactComponent as SvgMailAction } from './../../../assets/icons/mail-action.svg';
import { ReactComponent as SvgAdd } from './../../../assets/icons/icon-add.svg';
import { ReactComponent as SvgSearch } from './../../../assets/icons/icon-search.svg';
import { NavLink } from 'react-router-dom';
import CampaignsStats from './CampaignStats';
import CampaignTable from './CampaignTable/CampaignTable';
import ButtonBack from '../../../components/ButtonBack/ButtonBack';
import { LineGraph } from '../../../components/Visualization/line';
import { BarGraph } from '../../../components/Visualization/bar';
import { mailCampaignsActions } from '../../../common/actions';
import * as moment from 'moment';

const CampaignContent = () => {
  const dispatch = useDispatch();
  const [viewAllSection, setViewAllSection] = useState(null);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [startDate, setStartDate] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());

  const recentMailCampaignsList = useSelector(
    (state) => state.mailCampaigns.mailCampaignsList.recentMailCampaignsList
  );
  const scheduledMailCampaignsList = useSelector(
    (state) => state.mailCampaigns.mailCampaignsList.scheduledMailCampaignsList
  );
  const automatedMailCampaignsList = useSelector(
    (state) => state.mailCampaigns.mailCampaignsList.automatedMailCampaignsList
  );
  const allMailsCampaignsList = useSelector(
    (state) => state.mailCampaigns.mailCampaignsList.allMailCampaignsList
  );

  const setFilterData = () => {
    let dateFrom = null;
    let dateTo = null;

    if (startDate) dateFrom = moment(startDate).format('YYYY-MM-DD');
    if (endDate) dateTo = moment(endDate).format('YYYY-MM-DD');
    dispatch(mailCampaignsActions.getAllMailCampaignsFilter(dateFrom, dateTo));
  };
  const calculateSentEmails = () => {
    // TODO: implement different calculation once API response is provided
    let sentEmails = allMailsCampaignsList.filter(
      (campaign) => campaign.sent === true
    );
    return sentEmails.length;
  };

  const calculateOpenedEmails = () => {
    // TODO: implement different calculation once API response is provided
    let openedEmails = allMailsCampaignsList.filter(
      (campaign) => campaign.opened === true
    );
    return openedEmails.length;
  };

  const calculateSpamEmails = () => {
    // TODO: implement calculation once API response is provided
    let spamSum = 0;
    // allMailsCampaignsList.map(campaign => {
    //   spamSum = spamSum + campaign.spam
    // });
    return spamSum;
  };

  const calculateActionsFromEmail = () => {
    // TODO: implement calculation once API response is provided
    let actionsSum = 0;
    // allMailsCampaignsList.map(campaign => {
    //   actionsSum = actionsSum + campaign.actions
    // });

    return actionsSum;
  };

  const onViewAll = (section) => {
    if (section === viewAllSection) {
      setViewAllSection(null);
      return;
    }
    setViewAllSection(section);
  };

  const renderButtons = (section) => {
    if (viewAllSection) {
      return (
        <div onClick={() => onViewAll(section)}>
          <ButtonBack
            variant="secondary"
            height="44"
            width="120"
            className="bg-transparent text-sm pointer-events-none"
          />
        </div>
      );
    } else {
      return (
        <Button
          variant="secondary"
          height="44"
          width="120"
          className="bg-transparent text-sm pointer-events-none"
          onClick={() => onViewAll(section)}
        >
          View All
        </Button>
      );
    }
  };

  const calculateListLength = (list) => {
    if (viewAllSection) {
      return list;
    }
    if (list && list.length > 0) return list.slice(0, 7);
    else return [];
  };

  const renderDateSelection = () => {
    return (
      <div>
        <div className="flex justify-start items-center mt-50">
          <div className="flex justify-start">
            <div className="flex flex-col relative sm:mb-50 md:mb-0 w-half ">
              <span className="text-xs font-medium text-gray-400 mb-10">
                Start date
              </span>
              <div className="flex items-center justify-between border-1 border-gray-300 rounded-default p-10 bg-white mr-10">
                <ReactDatePicker
                  className="ReactDatePicker text-sm outline-none"
                  dateFormat="dd-MM-yyyy"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                />
                <SvgCalendar className="w-sm h-sm min-w-sm text-primary right-0" />
              </div>
            </div>
            <div className="flex flex-col relative sm:mb-50 md:mb-0 w-half">
              <span className="text-xs font-medium text-gray-400 mb-10">
                End date
              </span>
              <div className="flex items-center justify-between border-1 border-gray-300 rounded-default p-10 bg-white">
                <ReactDatePicker
                  className="ReactDatePicker text-sm outline-none"
                  dateFormat="dd-MM-yyyy"
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                />
                <SvgCalendar className="w-sm h-sm min-w-sm text-primary right-0" />
              </div>
            </div>
            <div className="flex flex-col relative sm:mb-50 md:mb-0 w-half">
              <div className="flex items-center justify-between pl-10 p-30">
                <button
                  className="flex items-center rounded-default pl-0 px-20 py-10 text-sm text-white bg-primary outline-none hover:text-white"
                  role="menuitem"
                  onClick={setFilterData}
                >
                  <SvgSearch className="ml-10 mr-10 pl-5 text-white" />
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderStatsGraphs = () => {
    let dataLine = [
      {
        id: 'Campaigns',
        color: 'hsl(208, 70%, 50%)',
        data: [
          {
            x: 'Sent Emails',
            y: calculateSentEmails,
          },
          {
            x: 'Opened Emails',
            y: calculateOpenedEmails,
          },
          {
            x: 'Spam Emails',
            y: calculateSpamEmails,
          },
          {
            x: 'Actions from email',
            y: calculateActionsFromEmail,
          },
        ],
      },
      {
        id: 'Scheduled Campaigns',
        color: 'hsl(292, 70%, 50%)',
        data: [
          {
            x: 'Sent Emails',
            y: calculateSentEmails,
          },
          {
            x: 'Opened Emails',
            y: calculateOpenedEmails,
          },
          {
            x: 'Spam Emails',
            y: calculateSpamEmails,
          },
          {
            x: 'Actions from email',
            y: calculateActionsFromEmail,
          },
        ],
      },
    ];

    let dataPie = [
      {
        service: 'Campaigns',
        products: 170,
        productsColor: 'hsl(208, 70%, 50%)',
        contacts: 154,
        contactsColor: 'hsl(208, 71%, 55%)',
      },
      {
        service: 'Scheduled Campaigns',
        products: 170,
        productsColor: 'hsl(208, 70%, 50%)',
        contacts: 154,
        contactsColor: 'hsl(208, 71%, 55%)',
      },
    ];
    return (
      <div className="flex justify-between flex-wrap">
        <LineGraph
          title="Campaign delivery"
          excerpt="Line graph about campaign delivery actions"
          graphData={dataLine}
          className="w-half"
        />
        <BarGraph
          className="w-half"
          title="Audience"
          excerpt="Bar graph for Campaign Audience"
          graphData={dataPie}
          legendTitleBottom="Audience"
          legendTitleLeft="Campaign"
          keysData={['products', 'contacts']}
          fillData={[
            {
              match: {
                id: 'notes',
              },
              id: 'dots',
            },
            {
              match: {
                id: 'contacts',
              },
              id: 'lines',
            },
          ]}
        />
      </div>
    );
  };
  const renderRecentCampaigns = () => {
    if (viewAllSection === 'recent' || viewAllSection === null) {
      return (
        <div>
          <div className="flex justify-between items-center mt-50">
            <h2 className="text-xl font-bold text-black">
              Most recent campaigns
            </h2>
            <div className="flex">
              <NavLink className="relative mr-20" to="/add-campaign">
                <Button
                  variant="primary"
                  height="44"
                  width="220"
                  className="bg-primary text-sm pointer-events-none"
                >
                  <SvgAdd className="mr-20" />
                  New mail campaign
                </Button>
              </NavLink>
              {renderButtons('recent')}
            </div>
          </div>
          <CampaignTable
            campaigns={calculateListLength(recentMailCampaignsList)}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const renderScheduledCampaigns = () => {
    if (viewAllSection === 'scheduled' || viewAllSection === null) {
      return (
        <div>
          <div className="flex justify-between items-center mt-50">
            <h2 className="text-xl font-bold text-black">
              Scheduled campaigns
            </h2>
            <div className="flex">
              <NavLink className="relative mr-20" to="/add-campaign">
                <Button
                  variant="primary"
                  height="44"
                  width="220"
                  className="bg-primary text-sm pointer-events-none"
                >
                  <SvgAdd className="mr-20" />
                  New mail campaign
                </Button>
              </NavLink>
              {renderButtons('scheduled')}
            </div>
          </div>
          <CampaignTable
            campaigns={calculateListLength(scheduledMailCampaignsList)}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const renderAutomatedCampaigns = () => {
    if (viewAllSection === 'automated' || viewAllSection === null) {
      return (
        <div>
          <div className="flex justify-between items-center mt-50">
            <h2 className="text-xl font-bold text-black">
              Automated campaigns
            </h2>
            <div className="flex">
              <NavLink className="relative mr-20" to="/add-campaign">
                <Button
                  variant="primary"
                  height="44"
                  width="220"
                  className="bg-primary text-sm pointer-events-none"
                >
                  <SvgAdd className="mr-20" />
                  New mail campaign
                </Button>
              </NavLink>
              {renderButtons('automated')}
            </div>
          </div>
          <CampaignTable
            campaigns={calculateListLength(automatedMailCampaignsList)}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="flex">
        <h2 className="text-3xl font-bold text-black mr-auto">Campaigns</h2>
        <NavLink className="relative mr-20" to="/add-campaign">
          <Button
            variant="primary"
            height="44"
            width="220"
            className="bg-primary text-sm pointer-events-none"
          >
            <SvgAdd className="mr-20" />
            New mail campaign
          </Button>
        </NavLink>
      </div>

      <div className="w-full mx-auto mt-20 flex justify-between">
        <CampaignsStats title="Sent emails" value={calculateSentEmails()}>
          <SvgMail />
        </CampaignsStats>
        <CampaignsStats title="Opened emails" value={calculateOpenedEmails()}>
          <SvgMailOpened />
        </CampaignsStats>
        <CampaignsStats title="Spam emails" value={calculateSpamEmails()}>
          <SvgMailSpam />
        </CampaignsStats>
        <CampaignsStats
          title="Actions from email"
          value={calculateActionsFromEmail()}
        >
          <SvgMailAction />
        </CampaignsStats>
      </div>
      {renderStatsGraphs()}
      {renderDateSelection()}
      {renderRecentCampaigns()}
      {renderScheduledCampaigns()}
      {renderAutomatedCampaigns()}
    </>
  );
};

export { CampaignContent };
