import React, { useState } from 'react';
import { ReactComponent as SvgArrowDown } from 'src/assets/icons/arrow-down-icon.svg';
import { ReactComponent as SvgDelete } from 'src/assets/icons/icon-delete-campaign.svg';

const Accordion = ({ title, content, className, deleteOption, onDelete }) => {
  const [isActive, setIsActive] = useState(true);

  return (
    <div
      className={`${className} accordion-item bg-gray-100 p-30 flex flex-col justify-center`}
    >
      <div className="accordion-title">
        <div className="flex justify-between items-center">
          <h3 className="text-xl font-medium text-black">{title}</h3>
          <div className="flex">
            <SvgArrowDown
              onClick={() => setIsActive(!isActive)}
              className={`text-primary -rotate-90 transition-transform duration-500 ease-in-out cursor-pointer`}
              style={{
                transform: isActive ? 'rotate(-90deg)' : 'rotate(0)',
                widht: '30px',
              }}
            />
            {deleteOption && (
              <SvgDelete
                onClick={onDelete}
                className="text-primary flex icon-delete cursor-pointer focus:bg-red"
              />
            )}
          </div>
        </div>
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
    </div>
  );
};

export default Accordion;
