import React, { useState } from 'react';
import Button from '../../../components/Button';
import Note from '../../../components/commons/Note/Note';
import { CreateNoteModal } from '../../../components/commons/NoteCreate/CreateNoteModal';
import SearchInput from '../../../components/Form/components/SearchInput';
import { ReactComponent as SvgAdd } from '../../../assets/icons/icon-add.svg';
import { useSelector } from 'react-redux';
import styles from './NotesContent.module.scss';
import { ClickAwayListener } from '@material-ui/core';
const NotesContent = () => {
  const [search, setSearch] = useState('');
  const { notesList } = useSelector((state) => state.notes);
  const [isNotesFilterOpened, setIsNotesFilterOpened] = useState(false);
  const [filter, setFilter] = useState('');
  const handleSetFilter = (item) => {
    if (filter === item) {
      setFilter('');
    } else {
      setFilter(item);
    }
  };
  const handleNotesFilter = () => {
    setIsNotesFilterOpened(!isNotesFilterOpened);
  };
  const handleNotesFilterClose = () => {
    setIsNotesFilterOpened(false);
  };

  const [newNoteModal, setNewNoteModal] = useState(false);

  const handleNewNote = () => {
    setNewNoteModal(true);
  };

  let notes = notesList;

  if (search) {
    notes = notes.filter(
      (i) =>
        String(i.name)
          .toLocaleLowerCase()
          .search(search.toLocaleLowerCase()) !== -1
    );
  }

  return (
    <>
      <div className="flex">
        <h2 className="text-3xl font-bold  mr-20 text-black  border-l-0 border-t-0 border-b-0 pr-20 border-1 border-gray-400">
          Notes
        </h2>
        <SearchInput
          name="search"
          type="text"
          //errors={errors}
          labelText="search"
          placeholder="Search notes..."
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <div className="flex justify-end relative z-9 ml-auto ">
          <Button
            variant="primary"
            height="44"
            width="220"
            className="text-sm "
            onClick={handleNewNote}
          >
            <SvgAdd className="mr-20 text-white" />
            Add New Note
          </Button>
        </div>
      </div>
      <div className="flex">
        <div className="flex items-center w-full justify-end">
          <ClickAwayListener
            onClickAway={() =>
              isNotesFilterOpened ? handleNotesFilterClose() : null
            }
          >
            <div
              className={`relative inline-block text-left ${styles.dropdown} ${
                isNotesFilterOpened ? styles.dropdownActive : ''
              }`}
            >
              <span className="rounded-md shadow-sm">
                <button
                  className="inline-fflex justify-center items-center w-full px-60 h-44 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                  type="button"
                  aria-haspopup="true"
                  aria-expanded="true"
                  aria-controls="headlessui-menu-items-117"
                  onClick={handleNotesFilter}
                >
                  <span>Filter by</span>
                </button>
              </span>

              <div
                className={`opacity-0 invisible transition-all duration-300 transform origin-top-right -translate-y-2 scale-95  ${
                  isNotesFilterOpened ? styles.dropdownMenuOpened : ''
                } `}
              >
                <div
                  className="absolute w-full mt-5 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                  aria-labelledby="headlessui-menu-button-1"
                  id="headlessui-menu-items-117"
                  role="menu"
                >
                  <div className="py-2">
                    {/* <a
                    href="javascript:void(0)"
                    tabIndex="0"
                    className="text-gray-700 flex justify-between w-full px-15 py-10 text-sm leading-5 text-left"
                    role="menuitem"
                    onClick={() => handleSetFilter('Active')}
                  >
                    Active
                  </a>
                  <a
                    href="javascript:void(0)"
                    tabIndex="0"
                    className="text-gray-700 flex justify-between w-full px-15 py-10 text-sm leading-5 text-left"
                    role="menuitem"
                    onClick={() => handleSetFilter('Inactive')}
                  >
                    Inactive
                  </a> */}
                    <a
                      href="javascript:void(0)"
                      tabIndex="1"
                      className="text-gray-700 flex justify-between w-full px-15 py-10 text-sm leading-5 text-left"
                      role="menuitem"
                      onClick={() => handleSetFilter('Private')}
                      style={{
                        color: filter === 'Private' ? '#0395FF' : null,
                      }}
                    >
                      Private
                    </a>
                    <a
                      href="javascript:void(0)"
                      tabIndex="1"
                      className="text-gray-700 flex justify-between w-full px-15 py-10 text-sm leading-5 text-left"
                      role="menuitem"
                      onClick={() => handleSetFilter('Public')}
                      style={{
                        color: filter === 'Public' ? '#0395FF' : null,
                      }}
                    >
                      Public
                    </a>
                    <a
                      href="javascript:void(0)"
                      tabIndex="1"
                      className="text-gray-700 flex justify-between w-full px-15 py-10 text-sm leading-5 text-left"
                      role="menuitem"
                      onClick={() => handleSetFilter('Center Level')}
                      style={{
                        color: filter === 'Center Level' ? '#0395FF' : null,
                      }}
                    >
                      Center Level
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </div>
      <div className="flex flex-wrap -ml-15 -mr-15 mt-30">
        {notes.map((note, index) => {
          return filter ? (
            note && note.status_name.toLowerCase() === filter.toLowerCase() ? (
              <Note
                key={index}
                note={note}
                className="md:w-1/2 lg:w-1/3 xl:w-1/4 pl-15 pr-15 pb-30"
              />
            ) : null
          ) : !filter ? (
            <Note
              key={index}
              note={note}
              className="md:w-1/2 lg:w-1/3 xl:w-1/4 pl-15 pr-15 pb-30"
            />
          ) : null;
        })}
      </div>
      <CreateNoteModal
        setIsNoteFormOpened={setNewNoteModal}
        isNoteFormOpened={newNoteModal}
      />
    </>
  );
};

export default NotesContent;
