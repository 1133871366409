import React, { useState } from 'react';
import { ReactComponent as SvgDelete } from './../../../assets/icons/icon-delete-campaign.svg';
import { ReactComponent as SvgRecycle } from './../../../assets/icons/recycle-icon.svg';
import { ReactComponent as SvgEdit } from './../../../assets/icons/icon-edit.svg';
import { ReactComponent as SvgNext } from './../../../assets/icons/icon-next.svg';
import { ReactComponent as SvgExit } from './../../../assets/icons/exit-icon.svg';
import { ReactComponent as SvgCheck } from './../../../assets/icons/check-icon.svg';
import ProductForm from './ProductForm';
import Modal from '../../../components/Modal';
import Select from './../../../components/Select/Select';

const ProductContent = ({ placeholder }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const toggleIsEditMode = () => setIsEditMode(!isEditMode);

  const [isDeleteProductModalOpened, setIsDeleteProductModalOpened] = useState(
    false
  );
  const toggleDeleteModal = () => {
    setIsDeleteProductModalOpened(!isDeleteProductModalOpened);
  };
  const handleDeleteProduct = () => {
    dispatch(contactActions.deleteProduct(product.id));
    setIsDeleteProductModalOpened(!isDeleteProductModalOpened);
  };

  const [
    isPermaDeleteProductModalOpened,
    setIsPermaDeleteProductModalOpened,
  ] = useState(false);
  const togglePermaDeleteModal = () => {
    setIsPermaDeleteProductModalOpened(!isPermaDeleteProductModalOpened);
  };
  const handlePermaDeleteProduct = () => {
    dispatch(contactActions.deleteProduct(product.id));
    setIsPermaDeleteProductModalOpened(!isPermaDeleteProductModalOpened);
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  const activity_status = true;

  const roleOptions = [
    { value: 'selectActivity', label: 'Select Activity' },
    { value: 'inactive', label: 'inactive' },
    { value: 'active', label: 'active' },
  ];

  const getContentByMode = () => {
    if (isEditMode) {
      return <ProductForm />;
    } else {
      return (
        <div className="bg-white rounded-default shadow-md py-10 px-20 mb-20">
          <div className="flex border-b-1 border-gray-200 px-20 -mx-20 items-center">
            <div className="w-2/5 py-10">
              <h4 className="text-sm font-semibold">ID</h4>
            </div>
            <div className="w-3/5 py-10">
              <p className="text-sm font-semibold">1</p>
            </div>
          </div>
          <div className="flex border-b-1 border-gray-200 px-20 -mx-20 ">
            <div className="w-2/5 py-20 ">
              <h4 className="text-sm font-semibold">Name</h4>
            </div>
            <div className="w-3/5 py-20">
              <p className="text-sm font-semibold">Product 1</p>
            </div>
          </div>
          <div className="flex border-b-1 border-gray-200 px-20 -mx-20 ">
            <div className="w-2/5 py-20 ">
              <h4 className="text-sm font-semibold">Description</h4>
            </div>
            <div className="w-3/5 py-20">
              <p className="text-sm font-semibold">This product is active!</p>
            </div>
          </div>
          <div className="flex border-b-1 border-gray-200 px-20 -mx-20 ">
            <div className="w-2/5 py-20 ">
              <h4 className="text-sm font-semibold">URL</h4>
            </div>
            <div className="w-3/5 py-20">
              <p className="text-sm font-semibold">https://crm.com/</p>
            </div>
          </div>
          <div className="flex border-b-1 border-gray-200 px-20 -mx-20 ">
            <div className="w-2/5 py-20 ">
              <h4 className="text-sm font-semibold">Duration in months</h4>
            </div>
            <div className="w-3/5 py-20">
              <p className="text-sm font-semibold">5</p>
            </div>
          </div>
          <div className="flex px-20 -mx-20 ">
            <div className="w-2/5 py-20 ">
              <h4 className="text-sm font-semibold">Active</h4>
            </div>
            <div className="w-3/5 py-20">
              <p className="text-sm font-semibold flex">
                {activity_status === false ? (
                  <div className="border-2 border-red rounded-full p-5">
                    <SvgExit className="text-red" />
                  </div>
                ) : (
                  <div className="border-2 border-green-100 rounded-full p-3">
                    <SvgCheck className="text-green-100" />
                  </div>
                )}
              </p>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div>
        <div className="">
          <div className="flex items-center justify-between mb-20">
            <h1 className="text-2xl font-bold ">
              Product details:{' '}
              {activity_status === true ? 'Product active' : 'Product inactive'}
            </h1>
            <div
              className={`
          ${isEditMode ? 'hidden' : 'flex'}`}
            >
              <div className="flex items-center">
                <Select
                  showLabel={false}
                  name="status"
                  placeholder="Select action"
                  className="mr-15 -mb-5 p-0 w-200"
                  options={roleOptions}
                  defaultValue={{
                    value: roleOptions[0].value,
                    label: roleOptions[0].label,
                  }}
                />

                <button className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-45 outline-none transition duration-500 mr-15">
                  <SvgNext />
                </button>
              </div>
              <button
                className="bg-white hover:bg-gray-300 text-black text-sm p-10 px-20 rounded-default h-45 outline-none transition duration-500 mr-15"
                onClick={toggleDeleteModal}
              >
                <SvgDelete />
              </button>
              <button
                className="bg-white hover:bg-gray-300 text-black text-sm p-10 px-20 rounded-default h-45 outline-none transition duration-500 mr-15"
                onClick={togglePermaDeleteModal}
              >
                <SvgRecycle />
              </button>
              <button
                className="bg-primary hover:bg-secondary text-white text-sm p-10 px-20 rounded-default h-45 outline-none transition duration-500 mr-15"
                onClick={toggleIsEditMode}
              >
                <SvgEdit />
              </button>
            </div>
          </div>
          <div>{getContentByMode()}</div>
          <Modal
            isOpen={isDeleteProductModalOpened}
            onRequestClose={toggleDeleteModal}
            headerTitle="Delete product"
            className="modal--alert"
          >
            <h2 className={`w-full text-2x font-bold text-black mt-50 mb-20`}>
              Delete product?
            </h2>
            <div className="flex justify-between">
              <button
                className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-primary hover:bg-secondary"
                role="menuitem"
                onClick={handleDeleteProduct}
              >
                Yes
              </button>
              <button
                className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-gray-500 hover:bg-gray-400"
                role="menuitem"
                onClick={toggleDeleteModal}
              >
                Cancel
              </button>
            </div>
          </Modal>
          <Modal
            isOpen={isPermaDeleteProductModalOpened}
            onRequestClose={togglePermaDeleteModal}
            headerTitle="Delete product"
            className="modal--alert"
          >
            <h2 className={`w-full text-2x font-bold text-black mt-50 mb-20`}>
              Permanently delete product?
            </h2>
            <p className="mb-20">
              Are you sure you want to permanently delete this product?
            </p>
            <div className="flex justify-between">
              <button
                className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-primary hover:bg-secondary"
                role="menuitem"
                onClick={handlePermaDeleteProduct}
              >
                Yes
              </button>
              <button
                className="flex w-half text-center justify-center rounded-default px-20 py-10 text-sm text-white bg-gray-500 hover:bg-gray-400"
                role="menuitem"
                onClick={togglePermaDeleteModal}
              >
                Cancel
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};
export default ProductContent;
