import React, { useState, useEffect, useRef } from 'react';
import Content from '../Content';
import Sidebar from '../Sidebar';
import useWindowSize from '../../../hooks/useWindowSize';

const LayoutComponent = ({ children, title, icon = null, addAction }) => {
  const isMobile = useWindowSize('(max-width: 768px)');

  // const ref = useRef(null);
  // const logout = () => {
  //   handleLogout();
  //   history.push('/login');
  // };
  // useScrollLock(isChangeAddressModalOpened, ref);

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  return (
    <div className="flex sm:flex-col md:flex-row overflow-hidden">
      <div
        className={`${
          isSidebarVisible ? 'hidden' : 'block'
        } w-2/6 max-w-sidebar`}
      >
        {!isMobile && <Sidebar />}
      </div>
      <Content
        title={title}
        icon={icon}
        isSidebarVisible={isSidebarVisible}
        setIsSidebarVisible={setIsSidebarVisible}
        addAction={addAction}
      >
        {children}
      </Content>
    </div>
  );
};

export default LayoutComponent;
