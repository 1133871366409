import {
  CALENDAR_ITEM_ENDPOINT,
  CALENDAR_POSSIBLE_ATTENDEES,
} from '../../utils/endpoints';
import { authFetch, handleResponse } from '../helpers';

const getCalendarItem = async (itemId, loading = true) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return authFetch(
    CALENDAR_ITEM_ENDPOINT + `/${itemId}`,
    requestOptions,
    loading
  ).then(handleResponse);
};
const getCalendarItems = async (periodFrom, periodTo, track = true) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return authFetch(
    CALENDAR_ITEM_ENDPOINT + `?period_from=${periodFrom}&period_to=${periodTo}`,
    requestOptions,
    track
  ).then(handleResponse);
};

const createCalendarItem = async (data) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return authFetch(CALENDAR_ITEM_ENDPOINT, requestOptions, false).then(
    handleResponse
  );
};

const updateCalendarItem = async (calendarItemId, data) => {
  const requestOptions = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return authFetch(
    CALENDAR_ITEM_ENDPOINT + '/' + calendarItemId,
    requestOptions,
    false
  ).then(handleResponse);
};

const deleteCalendarItem = async (calendarItemId) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(
    CALENDAR_ITEM_ENDPOINT + '/' + calendarItemId,
    requestOptions
  ).then(handleResponse);
};

const calendarItemTypes = {
  event: {
    value: 'event',
    label: 'Event',
    color: '#8BB174',
  },
  task: {
    value: 'task',
    label: 'Task',
    color: '#dbd56e',
  },
  reminder: {
    value: 'reminder',
    label: 'Reminder',
    color: '#403d58',
  },
  consultation: {
    value: 'consultation',
    label: 'Consultation',
    color: '#fc7753',
  },
  booking: {
    value: 'booking',
    label: 'Booking',
    color: '#66d7d1',
  },
  offline: {
    value: 'offline',
    label: 'Offline',
    color: '#ff0000',
  },
};

const acceptInvitation = async (calendarItemId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(
    CALENDAR_ITEM_ENDPOINT + '/' + calendarItemId + '/accept',
    requestOptions,
    false
  ).then(handleResponse);
};

const declineInvitation = async (calendarItemId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(
    CALENDAR_ITEM_ENDPOINT + '/' + calendarItemId + '/decline',
    requestOptions,
    false
  ).then(handleResponse);
};

const getPossibleAttendees = async () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return authFetch(CALENDAR_POSSIBLE_ATTENDEES, requestOptions, false).then(
    handleResponse
  );
};

export const calendarService = {
  getPossibleAttendees,
  getCalendarItem,
  getCalendarItems,
  createCalendarItem,
  deleteCalendarItem,
  updateCalendarItem,
  calendarItemTypes,
  acceptInvitation,
  declineInvitation,
};
