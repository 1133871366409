import { mailCampaignsService } from '../services';
import {
  mailCampaignsActionTypes,
  mailCampaignToastMessages,
  routes,
} from '../constants';
import { actionCreator, history } from '../helpers';
import { toast } from 'react-toastify';

const getAllMailCampaignsFilter = (startDate = null, endDate = null) => (
  dispatch
) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_MAIL_CAMPAIGNS_REQUEST
    )
  );
  mailCampaignsService
    .getAllMailCampaignsFilter(startDate, endDate)
    .then((res) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_MAIL_CAMPAIGNS_SUCCESS,
          {
            list: res,
          }
        )
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_MAIL_CAMPAIGNS_FAILURE,
          {
            error: err,
          }
        )
      );
    });
};

const getAllMailCampaigns = () => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_MAIL_CAMPAIGNS_REQUEST
    )
  );
  mailCampaignsService
    .getAllMailCampaigns()
    .then((res) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_MAIL_CAMPAIGNS_SUCCESS,
          {
            list: res,
          }
        )
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_MAIL_CAMPAIGNS_FAILURE,
          {
            error: err,
          }
        )
      );
    });
};
const getSingleMailCampaigns = (id) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_SINGLE_MAIL_CAMPAIGNS_REQUEST
    )
  );
  mailCampaignsService
    .getSingleMailCampaigns(id)
    .then((res) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_SINGLE_MAIL_CAMPAIGNS_SUCCESS,
          {
            list: res,
          }
        )
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_SINGLE_MAIL_CAMPAIGNS_FAILURE,
          {
            error: err,
          }
        )
      );
    });
};

const deleteMailCampaign = (CampaignId) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_DELETE_MAIL_CAMPAIGN_REQUEST
    )
  );
  mailCampaignsService
    .deleteMailCampaign(CampaignId)
    .then((res) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_DELETE_MAIL_CAMPAIGN_SUCCESS,
          {
            deletedMailCampaignId: CampaignId,
          }
        )
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_DELETE_MAIL_CAMPAIGN_FAILURE,
          {
            error: err,
          }
        )
      );
    });
};

const updateMailCampaign = (mailCampaign, mailCampaignId, history) => (
  dispatch
) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_EDIT_MAIL_CAMPAIGN_REQUEST
    )
  );
  mailCampaignsService
    .updateMailCampaign(mailCampaign, mailCampaignId)
    .then((res) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_EDIT_MAIL_CAMPAIGN_SUCCESS,
          {
            editedMailCampaign: res,
          }
        )
      ),
        toast.success(
          mailCampaignToastMessages[
            mailCampaignsActionTypes.MAIL_CAMPAIGNS_EDIT_MAIL_CAMPAIGN_SUCCESS
          ].message
        ),
        history.push(routes.MAIL_CAMPAIGNS.path);
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_EDIT_MAIL_CAMPAIGN_FAILURE,
          {
            error: err,
          }
        )
      );
    });
};

const createNewMailCampaign = (mailCampaignData, history) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_REQUEST
    )
  );
  mailCampaignsService
    .createNewMailCampaign(mailCampaignData)
    .then((res) => {
      toast.success(
        mailCampaignToastMessages[
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SUCCESS
        ].message,
        { className: 'text-white' }
      ),
        dispatch(
          actionCreator(
            mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SUCCESS
          )
        );
      history.push(routes.MAIL_CAMPAIGNS.path);
    })
    .catch((err) => {
      toast(
        mailCampaignToastMessages[
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_FAILURE
        ].message
      );
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_FAILURE,
          {
            error: err,
          }
        )
      );
    });
};

const createNewMailCampaignAutomation = (mailCampaignData, history) => (
  dispatch
) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_AUTOMATION_REQUEST
    )
  );
  mailCampaignsService
    .createNewMailCampaignAutomation(mailCampaignData)
    .then((res) => {
      toast.success(
        mailCampaignToastMessages[
          mailCampaignsActionTypes
            .MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_AUTOMATION_SUCCESS
        ].message
      ),
        dispatch(
          actionCreator(
            mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_AUTOMATION_SUCCESS
          )
        );
      history.push(routes.MAIL_CAMPAIGNS.path);
    })
    .catch((err) => {
      toast(
        mailCampaignToastMessages[
          mailCampaignsActionTypes
            .MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_AUTOMATION_FAILURE
        ].message
      );
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_AUTOMATION_FAILURE,
          {
            error: err,
          }
        )
      );
    });
};

const getAllAudiences = () => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_AUDIENCE_REQUEST
    )
  );
  mailCampaignsService
    .getAllAudiences()
    .then((res) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_AUDIENCE_SUCCESS,
          {
            audiences: res,
          }
        )
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_AUDIENCE_FAILURE,
          {
            error: err,
          }
        )
      );
    });
};

const addAudience = (audiencePostData, toggleModal) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_NEW_AUDIENCE_REQUEST
    )
  );
  mailCampaignsService
    .addAudience(audiencePostData)
    .then((res) => {
      toggleModal(false);
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_NEW_AUDIENCE_SUCCESS,
          {
            audience: res,
          }
        )
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_NEW_AUDIENCE_FAILURE,
          {
            error: err,
          }
        )
      );
    });
};

const editAudience = (audiencePostData, setModalOpened) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_NEW_AUDIENCE_REQUEST
    )
  );
  mailCampaignsService
    .edit(audiencePostData)
    .then((res) => {
      // toast...
      // setModalOpened(false);
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_NEW_AUDIENCE_SUCCESS,
          {
            audience: res,
          }
        )
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_NEW_AUDIENCE_FAILURE,
          {
            error: err,
          }
        )
      );
    });
};

const clearAudienceState = () => (dispatch) => {
  dispatch(
    actionCreator(mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_CLEAR_STATE)
  );
};

const setPickedAudiences = (audiences) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_AUDIENCE,
      { audiences }
    )
  );
};

const getAllMailTemplates = () => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_MAIL_TEMPLATES_REQUEST
    )
  );
  mailCampaignsService
    .getAllMailTemplates()
    .then((res) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_MAIL_TEMPLATES_SUCCESS,
          {
            templates: res,
          }
        )
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_MAIL_TEMPLATES_FAILURE,
          {
            error: err,
          }
        )
      );
    });
};

const newTemplate = (templateData, modalToggler) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_TEMPLATE_REQUEST
    )
  );
  mailCampaignsService
    .createNewMailTemplate(templateData)
    .then((res) => {
      modalToggler();
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_TEMPLATE_SUCCESS,
          {
            template: res,
          }
        )
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_TEMPLATE_FAILURE,
          {
            error: err,
          }
        )
      );
    });
};

const setPickedTemplate = (pickedTemplateId) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_TEMPLATE,
      { selectedTemplateId: pickedTemplateId }
    )
  );
};

const setPickedAutomatizationTemplate = (pickedTemplateId) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_TEMPLATE_AUTOMATIZATION,
      { selectedTemplateId: pickedTemplateId }
    )
  );
};

const previewEmailCampaign = (previewData) => (dispatch) => {
  dispatch(
    actionCreator(mailCampaignsActionTypes.MAIL_CAMPAIGNS_PREVIEW_EMAIL_REQUEST)
  );
  mailCampaignsService
    .previewEmailCampaign(previewData)
    .then((res) => {
      toast.success(
        mailCampaignToastMessages[
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_PREVIEW_EMAIL_SUCCESS
        ].message
      ),
        dispatch(
          actionCreator(
            mailCampaignsActionTypes.MAIL_CAMPAIGNS_PREVIEW_EMAIL_SUCCESS
          )
        );
    })
    .catch((err) => {
      toast.warning(
        mailCampaignToastMessages[
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_PREVIEW_EMAIL_FAILURE
        ].message
      ),
        dispatch(
          actionCreator(
            mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_TEMPLATE_FAILURE,
            {
              error: err,
            }
          )
        );
    });
};

const setScheduledTime = (scheduledTime) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_SCHEDULED_TIME,
      { scheduledTime }
    )
  );
};

const setName = (name) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_NAME,
      { name }
    )
  );
};

const setFrom = (from) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_FROM,
      { from }
    )
  );
};

const setSubject = (subject) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_SUBJECT,
      { subject }
    )
  );
};

const setMessage = (message) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_MESSAGE,
      { message }
    )
  );
};

const setDesign = (design) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_DESIGN,
      { design }
    )
  );
};

const setAutomatizationMessage = (message) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_MESSAGE_AUTOMATIZATION,
      { message }
    )
  );
};

const setAutomatizationDesign = (design) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_DESIGN_AUTOMATIZATION,
      { design }
    )
  );
};

const setInactivity = (inactivityDays) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_NEW_MAIL_CAMPAIGN_SET_INACTIVITY,
      { inactivityDays }
    )
  );
};

const getAllProducts = () => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_PRODUCTS_REQUEST
    )
  );
  mailCampaignsService
    .getAllProducts()
    .then((res) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_PRODUCTS_SUCCESS,
          {
            products: res,
          }
        )
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_GET_ALL_PRODUCTS_FAILURE,
          {
            error: err,
          }
        )
      );
    });
};

const searchProducts = (query) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_SEARCH_PRODUCTS_REQUEST
    )
  );
  if (query && query.length > 0) {
    mailCampaignsService
      .searchProducts(query)
      .then((res) => {
        dispatch(
          actionCreator(
            mailCampaignsActionTypes.MAIL_CAMPAIGNS_SEARCH_PRODUCTS_SUCCESS,
            {
              searchedProducts: res,
            }
          )
        );
      })
      .catch((err) => {
        dispatch(
          actionCreator(
            mailCampaignsActionTypes.MAIL_CAMPAIGNS_SEARCH_PRODUCTS_FAILURE,
            {
              error: err,
            }
          )
        );
      });
  } else {
    dispatch(
      actionCreator(
        mailCampaignsActionTypes.MAIL_CAMPAIGNS_SEARCH_PRODUCTS_SUCCESS,
        {
          searchedProducts: [],
        }
      )
    );
  }
};

const audienceAssign = (audienceData, toggleModal, id) => (dispatch) => {
  dispatch(
    actionCreator(
      mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_ASSIGN_REQUEST
    )
  );
  mailCampaignsService
    .audienceAssign(audienceData, id)
    .then((res) => {
      toggleModal(false);
      false;
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_ASSIGN_SUCCESS,
          {
            editAudience: res,
          }
        )
      );
    })
    .catch((err) => {
      dispatch(
        actionCreator(
          mailCampaignsActionTypes.MAIL_CAMPAIGNS_AUDIENCE_ASSIGN_FAILURE,
          {
            error: err,
          }
        )
      );
    });
};

export const mailCampaignsActions = {
  getAllMailCampaigns,
  getSingleMailCampaigns,
  getAllMailCampaignsFilter,
  deleteMailCampaign,
  updateMailCampaign,
  createNewMailCampaign,
  createNewMailCampaignAutomation,
  getAllAudiences,
  clearAudienceState,
  setPickedAudiences,
  addAudience,
  getAllMailTemplates,
  setPickedTemplate,
  setPickedAutomatizationTemplate,
  newTemplate,
  previewEmailCampaign,
  setScheduledTime,
  setSubject,
  setFrom,
  setMessage,
  setDesign,
  setAutomatizationMessage,
  setAutomatizationDesign,
  setName,
  setInactivity,
  getAllProducts,
  searchProducts,
  audienceAssign,
};
