import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from '../../../components/Modal/Modal';
import { ReactComponent as SvgCheckCircle } from './.././../../assets/icons/icon-check-circle.svg';
import { mailCampaignsActions } from '../../../common/actions';
import moment from 'moment';
import Checkbox from '../../../components/Form/components/Checkbox/Checkbox';
import ButtonBack from '../../../components/ButtonBack/ButtonBack';
import { toast } from 'react-toastify';
import AutomationByTags from './CampaingAutomationTypeModals/AutomationByTags';
import AutomationByNewContacts from './CampaingAutomationTypeModals/AutomationByNewContacts';
import { contactActions } from 'src/common/actions/contactsActions';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import AutomationByBirthday from './CampaingAutomationTypeModals/AutomationByBirthday';
import AutomationByAudience from './CampaingAutomationTypeModals/AutomationByAudience';

function CampaignAutomateModal({
  toggleCampaignAutomateModal,
  isCampaignAutomateModalOpened,
  campaignStatistics,
}) {
  //Automate modal
  moment.locale('en');
  const dispatch = useDispatch();
  const [stringCheckedStep1, setStringCheckedStep1] = useState('');
  const handleChangeStep1 = (type) => {
    setStringCheckedStep1(type);
  };

  useEffect(() => {
    dispatch(contactActions.getAllContacts());
    //dispatch(contactActions.getAllContacts());
  }, []);
  const contacts = useSelector((state) => state.contacts?.contactsList);
  const filteredContacts = useSelector(
    (state) => state.contacts?.filteredContacts
  );
  const user = useSelector((state) => state.auth.user);
  console.log('ovo je data od campaigna', campaignStatistics);
  console.log('ovo je lista kontakata', contacts);
  console.log('ovo je lista filtriranih kontakta', filteredContacts);

  const [isSecondStepOpened, setIsSecondStepOpened] = useState(false);
  const toggleSecondStep = () => {
    if (stringCheckedStep1 == '') {
      toast.warning('Please select one of four types', {
        className: 'text-white',
      });
      return;
    }

    setIsSecondStepOpened(!isSecondStepOpened);
  };
  const toggleSuccessToast = () => {
    toast.success("You've succesfully created an automation", {
      className: 'text-white',
    });
  };

  const generateSecondStepName = () => {
    switch (stringCheckedStep1) {
      case 'contact_tags':
        return 'Based on contacts tags';
      case 'contact_welcome':
        return 'Welcome email for new contacts';
      case 'contact_birthday':
        return 'Celebrate contacts birthday';
      case 'contact_audience':
        return 'Contact joined audience';
      default:
        return 'Based on XYZ';
    }
  };

  const generateSecondModal = () => {
    switch (stringCheckedStep1) {
      case 'contact_tags':
        return (
          <AutomationByTags
            type={'by_tags'}
            user={user}
            contacts={contacts}
            step={stringCheckedStep1}
            campaign={campaignStatistics}
            setIsSecondStepOpened={setIsSecondStepOpened}
            toggleSuccessToast={toggleSuccessToast}
          />
        );
      case 'contact_welcome':
        return (
          <AutomationByNewContacts
            type={'by_new_contacts'}
            user={user}
            contacts={contacts}
            step={stringCheckedStep1}
            campaign={campaignStatistics}
            setIsSecondStepOpened={setIsSecondStepOpened}
            toggleSuccessToast={toggleSuccessToast}
          />
        );
      case 'contact_birthday':
        return (
          <AutomationByBirthday
            type={'by_birthday'}
            user={user}
            contacts={contacts}
            step={stringCheckedStep1}
            campaign={campaignStatistics}
            setIsSecondStepOpened={setIsSecondStepOpened}
            toggleSuccessToast={toggleSuccessToast}
          />
        );
      case 'contact_audience':
        return (
          <AutomationByAudience
            type={'by_audience'}
            user={user}
            contacts={contacts}
            step={stringCheckedStep1}
            campaign={campaignStatistics}
            setIsSecondStepOpened={setIsSecondStepOpened}
            toggleSuccessToast={toggleSuccessToast}
          />
        );
      default:
        return 'Based on XYZ';
    }
  };

  const submitAutomationData = () => {
    //dispatch(mailCampaignsActions.setScheduledTime(startDate));
    //toggleCampaignAutomateModal();
  };

  return (
    <Modal
      isOpen={isCampaignAutomateModalOpened}
      headerTitle="Automate campaign"
      className="modal--automate"
      onRequestClose={toggleCampaignAutomateModal}
    >
      <div className="flex flex-col mb-40">
        <ButtonBack variant="small" className="mb-15" />
        <h2 className={`text-2x font-bold text-black`}>
          Create an Automation Email
        </h2>
        <p className="text-xs text-gray-400">
          Select what type of automation you want to set up
        </p>
      </div>
      <Tabs>
        <TabList className="flex border-b-1 border-solid border-gray-300">
          <Tab className="flex flex-col items-center justify-between pr-30 text-sm cursor-pointer outline-none">
            Contacts & Audience
          </Tab>
          <Tab
            className="flex flex-col items-center justify-between pr-30 text-sm cursor-pointer outline-none pointer-events-none opacity-50 cursor-not-allowed"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            E-commerce
          </Tab>
        </TabList>
        <TabPanel>
          <div className="flex flex-col mt-30">
            <div className="flex flex-wrap spaxe-x-10">
              <div className="flex w-half mr-20 mb-20 items-center">
                <Checkbox
                  name="1"
                  variant="checkblock"
                  labelText="Based on contacts tags"
                  subtitleText="Send an email to a tagged contact"
                  onChange={() => handleChangeStep1('contact_tags')}
                  checked={stringCheckedStep1 === 'contact_tags' ? true : false}
                  className="h-full"
                />
              </div>
              <div className="flex w-half mr-20 mb-20 items-center ">
                <Checkbox
                  name="1"
                  variant="checkblock"
                  labelText="Welcome email for new contacts"
                  subtitleText="Welcome contacts when they are added
              to CRM"
                  onChange={() => handleChangeStep1('contact_welcome')}
                  checked={
                    stringCheckedStep1 === 'contact_welcome' ? true : false
                  }
                  className="h-full"
                />
              </div>
              <div className="flex w-half mr-20 mb-20 items-center">
                <Checkbox
                  name="1"
                  variant="checkblock"
                  labelText="Happy Birthday"
                  subtitleText="Celebrate your contacts with cheerful
              message on their birthdays"
                  onChange={() => handleChangeStep1('contact_birthday')}
                  checked={
                    stringCheckedStep1 === 'contact_birthday' ? true : false
                  }
                  className="h-full"
                />
              </div>
              <div className="flex w-half mr-20 mb-20 items-center ">
                <Checkbox
                  name="1"
                  variant="checkblock"
                  labelText="Contact joined audience"
                  subtitleText="Send emails to contacts that added to
              specific audience"
                  onChange={() => handleChangeStep1('contact_audience')}
                  checked={
                    stringCheckedStep1 === 'contact_audience' ? true : false
                  }
                  className="h-full"
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>b</TabPanel>
      </Tabs>
      <div className="flex flex-col sm:mb-50 md:mb-0 w-full">
        <div className="flex flex-between">
          <button
            type="submit"
            className="ml-auto mt-20 flex text-center justify-center items-center rounded-default px-30 py-10 text-sm text-white bg-primary hover:bg-secondary"
            role="menuitem"
            onClick={toggleSecondStep}
          >
            Next
            <SvgCheckCircle className="ml-20" />
          </button>
        </div>
      </div>
      {isSecondStepOpened && (
        <div className="flex flex col">
          <Modal
            isOpen={isSecondStepOpened}
            headerTitle="Automate campaign second step"
            className="modal--automate"
            onRequestClose={toggleSecondStep}
          >
            <div className="flex flex-col mb-20 ">
              <ButtonBack
                variant="click"
                onClick={toggleSecondStep}
                className="mb-15"
              />
              <h2 className={`text-2xl font-bold text-black`}>
                {generateSecondStepName()}
              </h2>
              <p className="text-xs text-gray-400">
                Define here how to follow up with your contacts based on how
                they interact with your campaign.{' '}
              </p>
            </div>
            {generateSecondModal()}
          </Modal>
        </div>
      )}
    </Modal>
  );
}

export { CampaignAutomateModal };
